import styled, { ThemeProvider } from 'styled-components';
import { HTMLAttributes, ReactNode } from 'react';

const theme = {
  error: {
    bgColor: 'rgba(232, 104, 74, 0.2)',
    borderColor: '#E8684A',
  },
  basic: {
    bgColor: '##28282A',
    borderColor: '#373737',
  },
  default: {
    bgColor: '#373737',
    borderColor: '#28282A',
  },
  white: {
    bgColor: '#FBFBFB',
    borderColor: '#FBFBFB',
  },
};

export type typeStatus = keyof typeof theme;

export interface Props extends HTMLAttributes<HTMLDivElement> {
  status?: typeStatus;
  children?: ReactNode;
}

const Container = styled.div<{ status?: Props['status'] }>`
  width: 100%;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 6px;
  padding: 16px;
  background: #28282a;
  background: ${props => props.theme.bgColor};
`;

export default function Card({ status, children, ...props }: Props) {
  return (
    <ThemeProvider theme={theme[status || 'default']}>
      <Container status={status} {...props}>
        {children}
      </Container>
    </ThemeProvider>
  );
}
