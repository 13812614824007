import { atom } from 'recoil';

export const reportExtraFilterStructure = atom({
  key: 'reportExtraFilterStructure',
  default: [
    {
      label: 'assets.asset',
      value: 'assets',
      children: [],
    },
    {
      label: 'general.department',
      value: 'departments',
      children: [],
    },
    {
      label: 'general.line',
      value: 'lines',
      children: [],
    },
    {
      label: 'general.establishment',
      value: 'plants',
      children: [],
    },
/*     {
      label: 'general.type',
      value: 'types',
      children: [],
    }, */
  ],
});
