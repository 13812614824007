import Button from '@components/atoms/button';
import Divider from '@components/atoms/divider';
import Icon from '@components/atoms/icon';
import { fontSize } from '@src/globalStyle';
import { mediaQuery, useMediaQuery } from '@src/hooks/useMediaQuery';
import { useLayout } from '@src/modules/asset/hooks/useLayout';
import { Col, Row, Space } from 'antd';
import { ReactNode, useCallback, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';

export interface Props {
  title: string;
  extra?: ReactNode;
}

const StyledHeader = styled(Row)`
  width: 100%;
  justify-content: space-between;
  padding: 16px 0 8px;
`;

// Typography
const Title = styled.h1`
  font-family: 'Satoshi-Black';
  font-style: normal;
  font-weight: 700;
  ${fontSize.h1}
  color: #373737;
`;

const StyledDivider = styled(Divider)`
  border-color: #c8c8c8;
  margin: 34px 0;
`;

const ColMain = styled(Col)`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const ColExtra = styled(Col)`
  display: flex;
  /* flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'flex-end', */
`;

export default function Header({ title, extra, ...props }: Props) {
  const { visible, setVisible, setCollapsed } = useLayout();
  const [showMenuButton, setShowMenuButton] = useState(false);
  const isLaptop = useMediaQuery('laptop');

  const onClickMenu = useCallback(() => {
    document.body.classList.add('disable-scroll');
    setVisible(true);
    setCollapsed(false);
  }, [setCollapsed, setVisible]);

  // const showMenuButton = !(isLaptop && visible);
  useLayoutEffect(() => {
    mediaQuery((width: number) => {
      setShowMenuButton(width < 1024);
    });
  }, [isLaptop, visible]);

  return (
    <>
      <StyledHeader {...props}>
        <ColMain>
          {showMenuButton && (
            <Button
              center
              shape="square"
              variant="grey"
              shapeSize={44}
              onClick={onClickMenu}
            >
              <Icon style={{ fontSize: 24 }} name="menu" color="#1C1B1F" />
            </Button>
          )}
          <Title style={{}}>{title}</Title>
        </ColMain>
        {extra && (
          <ColExtra>
            <Space size={8}>{extra}</Space>
          </ColExtra>
        )}
      </StyledHeader>
      <StyledDivider />
    </>
  );
}
