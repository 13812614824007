import { TemperatureIcon, VibrationIcon } from '@assets';
import { TagType } from '@components/atoms/tag';
import { OverviewListItem } from '@components/organisms/overview';
import { formatTime } from '@src/utils/date.utils';
import { t } from 'i18next';

const formatWorkedTimeUnitOfMeasure = (unitOfMeasure: string): string => {
  // ns" | "mu" | "ms" | "s" | "min" | "h" | "d" | "week" | "month" | "year"
  switch (unitOfMeasure) {
    case 'min':
      return t('general.workedMinutes');
    case 'h':
      return t('general.workedHours');
    case 'd':
      return t('general.workingDays');
    case 'week':
      return t('general.weeks');
    case 'month':
      return t('general.workedMonths');
    case 'year':
      return t('general.workedYears');
  }
  return '';
};

const transformHourAndMinutes = (time: any): any => {
  if (time !== undefined && time !== null && time !== '' && time !== 0) {
    const modifiedString = time.replace(/ore/g, 'h').replace(/minuti/g, `'`);
    return modifiedString;
  } else {
    return time;
  }
};

const monitronStatusColorMapper: any = {
  0: '#ffffff',
  4: '#E8684A',
  3: '#F6BD16',
  2: '#18CD4B',
  1: '#18CD4B',
};

export const overviewMonitronList: (
  uptime: string,
  workedHours: string,
  workedHoursUnitOfMeasure: string,
  temperature: string,
  temperatureUnitOfMeasure: string,
  vibration: string,
  vibrationUnitOfMeasure: string,
  temperatureStatus: number,
  vibrationStatus: number,
) => OverviewListItem[] = (
  uptime,
  workedHours,
  workedHoursUnitOfMeasure,
  temperature,
  temperatureUnitOfMeasure,
  vibration,
  vibrationUnitOfMeasure,
  temperatureStatus,
  vibrationStatus,
) => [
  {
    title: t('status.healthStatus'),
    info: t('status.healthPercentage'),
    icon: '',
    value: `${uptime} %`,
    label: '',
    labelColor: '',
  },
  {
    title: t('status.healthTime'),
    info: '',
    icon: '',
    value: transformHourAndMinutes(workedHours),
    label: '',
  },
  {
    title: t('status.actualTemperature'),
    info: t('status.actualAssetTemperature'),
    iconComponent: (
      <TemperatureIcon
        style={{ marginRight: 10 }}
        fill={monitronStatusColorMapper[temperatureStatus]}
      />
    ),
    value: temperature,
    label: temperatureUnitOfMeasure,
  },
  {
    title: t('status.actualVibration'),
    info: t('status.actualAssetVibration'),
    iconComponent: (
      <VibrationIcon
        style={{ marginRight: 10 }}
        fill={monitronStatusColorMapper[vibrationStatus]}
      />
    ),
    value: vibration,
    label: vibrationUnitOfMeasure,
  },
];

export const overviewList: (
  uptime: string,
  workedHours: string,
  workedHoursUnitOfMeasure: string,
  stops: string,
  consumption: string,
  consumptionUnitOfMeasure: string,
  options?: { thresholds?: any; noData?: any },
) => OverviewListItem[] = (
  uptime,
  workedHours,
  workedHoursUnitOfMeasure,
  stops,
  consumption,
  consumptionUnitOfMeasure,
  options,
) => {
  const totalTimeForStatuses = options?.thresholds
    ?.map((item: any) => Number(item?.value ?? 0))
    .reduce((a: number, b: number) => a + b, 0);
  // + Number(options?.noData?.totalTimeInNoData ?? 0);

  return [
    // {
    //   title: t('general.uptime'),
    //   info: t('stops.percentageStop'),
    //   icon: '',
    //   value: `${uptime} %`,
    //   label: '',
    //   labelColor: '',
    // },
    // {
    //   title: `${formatWorkedTimeUnitOfMeasure(workedHoursUnitOfMeasure)}`,
    //   info: '',
    //   icon: '',
    //   value: transformHourAndMinutes(workedHours),
    //   label: '',
    // },
    // { title: t('stops.numberOfStops'), info: '', icon: '', value: stops, label: '' },
    {
      title: t('general.consumption'),
      info: t('assets.totalConsumption'),
      icon: 'trending_up',
      iconColor: '#2972FF',
      value: consumption,
      label: consumptionUnitOfMeasure,
    },
    ...(options?.thresholds?.map((item: any) => {
      const timeVal = formatTime(Number(item?.value ?? 0), t);
      const percentVal = parseFloat(
        ((100 * Number(item?.value ?? 0)) / totalTimeForStatuses).toFixed(1),
      );

      return {
        title: item.title,
        info: '',
        icon: '',
        iconColor: '',
        value: timeVal,
        label: '',
        titleAdditionalInfo: ` ${isNaN(percentVal) ? 0 : percentVal}%`,
        titleColor: item.color,
        footer: item.consumption,
      };
    }) ?? []),
    {
      title: t('general.noData'),
      info: t('general.noDataInfo'),
      icon: '',
      iconColor: '',
      value: formatTime(Number(options?.noData?.totalTimeInNoData ?? 0), t),
      label: '',
    },
  ];
};

export const stopsListOld = Array(25)
  .fill(null)
  .map(_ => [
    {
      label: '10/02/2023 - 15:30',
      value: t('general.machineName'),
    },
    {
      label: t('general.duration'),
      value: '35 min',
    },
  ]);

interface StopInterface {
  minutes?: string;
  date?: string;
  time?: string;
  value?: string;
  statusTag?: TagType;
  labelTag?: string;
}

export const shortStopsList: StopInterface[] = [
  {
    labelTag: t('stops.brokenDown'),
    statusTag: 'error',
    minutes: '35 min',
    date: '10/02/2023',
    time: '15:30',
  },
  {
    labelTag: '',
    statusTag: undefined,
    minutes: '35 min',
    date: '10/02/2023',
    time: '15:30',
  },
  {
    labelTag: t('stops.equipment'),
    statusTag: 'success',
    minutes: '35 min',
    date: '10/02/2023',
    time: '15:30',
  },
  {
    labelTag: t('stops.maintenance'),
    statusTag: 'warning',
    minutes: '35 min',
    date: '10/02/2023',
    time: '15:30',
  },
];

export const stopsList: StopInterface[] = [
  ...shortStopsList,
  ...shortStopsList,
  ...shortStopsList,
  ...shortStopsList,
  ...shortStopsList,
];
