import { Column, ColumnConfig } from '@ant-design/plots';
import Slider from '@components/atoms/slider';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { CommonChart } from '../common.interface';

import dayjs from 'dayjs';
import { debounce } from 'lodash';
import i18next from 'i18next';
import Legend from '../components/legend';

dayjs.extend(require('dayjs/plugin/utc'));

interface Props extends CommonChart, Partial<ColumnConfig> {
  unit?: string;
  startDate: number;
  endDate: number;
  legendLabelColor?: string;
  darkSlider?: boolean;
}

const colorMap = {
  HIGH: '#FF4D4F',
  LOW: '#F6BD16',
};

const legendMap = () => {
  return {
    HIGH: i18next.t('general.highPriority'),
    LOW: i18next.t('general.normalPriority'),
  };
};

const ChartColumn = ({
  data,
  startDate,
  endDate,
  legendLabelColor = '#fff',
  darkSlider = true,
  ...props
}: Props) => {
  const minMax = useMemo<[number, number]>(
    () => [0, dayjs(endDate).diff(dayjs(startDate), 'day')],
    [startDate, endDate],
  );

  const [range, setRange] = useState<[number, number]>([startDate, endDate]);

  useEffect(() => {
    setRange([startDate, endDate]);
  }, [startDate, endDate]);

  const columnData = useMemo(
    () =>
      data?.filter(
        (r, i) =>
          dayjs.utc(r?.date).valueOf() >= range[0] &&
          dayjs.utc(r?.date).valueOf() <= range[1],
      ) || [],
    [data, range],
  );

  const sliderFormatter = useCallback(
    (value?: number): ReactNode => {
      try {
        if (value === undefined || value === null) return value;
        const date = dayjs(startDate).add(value, 'day').format('DD/MM');
        return date;
      } catch (e) {
        return value;
      }
    },
    [startDate],
  );

  const updateRange = debounce((value: [number, number]) => {
    const start = dayjs(startDate).add(value[0], 'day').valueOf();
    const end = dayjs(startDate).add(value[1], 'day').valueOf();

    setRange([start, end]);
  }, 300);

  return (
    <>
      <Column
        data={columnData}
        xField="date"
        xAxis={{
          label: {
            formatter: (text: any) => {
              return dayjs.utc(text).format('DD/MM');
            },
          },
        }}
        yField="value"
        seriesField="type"
        isStack={true}
        animation={false}
        color={({ type }) => {
          return colorMap[type as keyof typeof colorMap];
        }}
        height={props?.height || 150}
        // style={{ height: props?.height || 150, marginTop: 16 }}
        autoFit={!!props?.autoFit}
        label={false}
        legend={false}
        tooltip={{
          formatter: data => {
            return {
              name: legendMap()[data.type as keyof typeof legendMap], // data.type === "HIGH" ? "Priorità alta" : "Priorità normale",
              value: data.value,
            };
          },
        }}
      />
      <div style={{ marginLeft: 16 }}>
        <Slider
          dark={darkSlider}
          key={`${minMax[1]}_${data?.[0]?.time}`}
          tooltip={{
            formatter: sliderFormatter,
          }}
          range={{ draggableTrack: true }}
          min={minMax[0]}
          max={minMax[1]}
          defaultValue={[0, data ? minMax[1] : 0]}
          onChange={updateRange}
          style={{ marginTop: 16 }}
        />
        <Legend
          style={{ marginTop: 16 }}
          data={[
            { color: '#E8684A', label: i18next.t('general.highPriority') },
            { color: '#F6BD16', label: i18next.t('general.normalPriority') },
          ]}
        />
      </div>
    </>
  );
};

export default ChartColumn;
