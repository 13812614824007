import {
  consumptionColorMapping,
  consumptionMapping,
  previousConsumptionMapping,
} from '@src/utils/mapping.utils';
import {
  differenceInMinutes,
  eachMinuteOfInterval,
  format,
  subDays,
} from 'date-fns';

export const extractMinutesBetween = (
  startTimestamp: string,
  endTimestamp: string,
) => {
  const start = new Date(startTimestamp);
  const end = new Date(endTimestamp);

  const numMinutes = differenceInMinutes(end, start);

  return numMinutes;
};

export const extractFormattedMinutesBetween = (
  startTimestamp: string,
  endTimestamp: string,
  step?: number,
) => {
  const start = new Date(startTimestamp);
  const end = new Date(endTimestamp);
  // const startDate = set(new Date(), { hours: startHour, minutes: 0, seconds: 0 });
  // const endDate = set(new Date(), { hours: endHour, minutes: 0, seconds: 0 });
  //10:30 * 12:30
  const interval = { start, end };
  // const numMinutes = differenceInMinutes(end, start);

  const minutes = eachMinuteOfInterval(interval, { step });

  // const f1 = 'yyyy-MM-dd HH:mm';
  const f2 = 'HH:mm';
  return minutes.map(timestamp => format(timestamp.getTime(), f2));
};

export const minutesInDay = (step?: number) => {
  const startTimestamp = '2022-03-16T00:00:00';
  const endTimestamp = '2022-03-17T00:00:00';
  const minutesOfInterval = extractFormattedMinutesBetween(
    startTimestamp,
    endTimestamp,
    step,
  );
  return minutesOfInterval;
};

export const lastDays = (count: number) => {
  const today = new Date();
  const lastDays = Array(count).fill(null);
  const dates = lastDays.map((_, index) => {
    const date = subDays(today, index + 1);
    return format(date, 'dd-MM-yyyy');
  });
  return dates;
};

export const threeColorsBlender = (
  color1: string,
  color2: string,
  color3: string,
  percentage1: number,
  percentage2: number,
  percentage3: number,
) => {
  const red1 = parseInt(color1.slice(1, 3), 16);
  const green1 = parseInt(color1.slice(3, 5), 16);
  const blue1 = parseInt(color1.slice(5, 7), 16);

  const red2 = parseInt(color2.slice(1, 3), 16);
  const green2 = parseInt(color2.slice(3, 5), 16);
  const blue2 = parseInt(color2.slice(5, 7), 16);

  const red3 = parseInt(color3.slice(1, 3), 16);
  const green3 = parseInt(color3.slice(3, 5), 16);
  const blue3 = parseInt(color3.slice(5, 7), 16);

  const red = Math.round(
    (red1 * percentage1 + red2 * percentage2 + red3 * percentage3) /
      (percentage1 + percentage2 + percentage3),
  );
  const green = Math.round(
    (green1 * percentage1 + green2 * percentage2 + green3 * percentage3) /
      (percentage1 + percentage2 + percentage3),
  );
  const blue = Math.round(
    (blue1 * percentage1 + blue2 * percentage2 + blue3 * percentage3) /
      (percentage1 + percentage2 + percentage3),
  );

  return `${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;
};

export const twoColorsBlender = (
  color1: string,
  color2: string,
  percentage: number,
) => {
  const red1 = parseInt(color1.slice(1, 3), 16);
  const green1 = parseInt(color1.slice(3, 5), 16);
  const blue1 = parseInt(color1.slice(5, 7), 16);

  const red2 = parseInt(color2.slice(1, 3), 16);
  const green2 = parseInt(color2.slice(3, 5), 16);
  const blue2 = parseInt(color2.slice(5, 7), 16);

  const red = Math.round(red1 + percentage * (red2 - red1));
  const green = Math.round(green1 + percentage * (green2 - green1));
  const blue = Math.round(blue1 + percentage * (blue2 - blue1));

  return `${red.toString(16)}${green.toString(16)}${blue.toString(16)}`;
};

export function mapRangeValue(
  value: number,
  oldRange: number[],
  newRange: number[],
) {
  return (
    ((value - oldRange[0]) * (newRange[1] - newRange[0])) /
      (oldRange[1] - oldRange[0]) +
    newRange[0]
  );
}

export function mapPointValueToColorBlend(pointValue: number) {
  let g = 0,
    y = 0,
    r = 0;

  if (pointValue > 0.66) {
    const inverseValue = mapRangeValue(pointValue * 100, [67, 100], [33, 100]);
    g = Math.min(Math.round(inverseValue), 100);
    y = Math.round(((100 - g) / 3) * 2);
    r = 100 - g - y;
  } else if (pointValue > 0.33) {
    if (pointValue < 0.5) {
      y = Math.min(Math.round((pointValue * 100) / 0.5), 100);
      r = Math.round(((100 - y) / 3) * 2);
      g = 100 - y - r;
    } else {
      const inverseValue = mapRangeValue(pointValue * 100, [50, 66], [50, 34]);
      y = Math.min(Math.round(inverseValue / 0.5), 100);
      g = Math.round(((100 - y) / 3) * 2);
      r = 100 - y - g;
    }
  } else if (pointValue > -1) {
    const inverseValue = mapRangeValue(pointValue * 100, [0, 33], [0, 33]);
    r = Math.min(Math.round(100 - inverseValue), 100);
    y = Math.round(((100 - r) / 3) * 2);
    g = 100 - r - y;
  }

  return threeColorsBlender(
    consumptionColorMapping[consumptionMapping.HIGH],
    consumptionColorMapping[consumptionMapping.MEDIUM],
    consumptionColorMapping[consumptionMapping.LOW],
    g,
    y,
    r,
  );
}

type Percentage = {
  prop: string;
  value: string;
};

export function generateHeatMapColours(percentages: Percentage[]) {
  let work = 0;
  let idle = 0;
  let stop = 0;

  for (const percentage of percentages) {
    if (percentage.prop.toLowerCase() === previousConsumptionMapping.HIGH) {
      work = Number(percentage.value);
    } else if (
      percentage.prop.toLowerCase() === previousConsumptionMapping.MEDIUM
    ) {
      idle = Number(percentage.value);
    } else if (
      percentage.prop.toLowerCase() === previousConsumptionMapping.STOP
    ) {
      stop = Number(percentage.value);
    }
  }

  if (work !== null && idle !== null && stop !== null) {
    // If value is between 50 and 70 we apply a more gradient colours of the principle three colours
    if (
      (work >= 50 && work <= 70) ||
      (idle >= 50 && idle <= 70) ||
      (stop >= 50 && stop <= 70)
    ) {
      if (work > idle && work > stop) {
        return consumptionColorMapping[consumptionMapping.HIGH].replace(
          '#',
          '',
        ); //'#96B436'; // Work is the highest
      } else if (idle > work && idle > stop) {
        return '3279A3'; // Idle is the highest
      } else if (stop > work && stop > idle) {
        return '8BB2C8'; // Stop is the highest
      } else if (work === 50 && idle === 50) {
        return '3279A3'; // Work and Idle both equal to 50
      } else if (work === 50 && stop === 50) {
        return '8BB2C8'; // Work and Stop both equal to 50
      } else if (idle === 50 && stop === 50) {
        return '8BB2C8'; // Idle and Stop both equal to 50
      }
    } else {
      // Principal color is apply on one of these three if value is higher than 70
      if (work > idle && work > stop) {
        return consumptionColorMapping[consumptionMapping.HIGH].replace(
          '#',
          '',
        );
      } else if (stop > work && stop > idle) {
        return consumptionColorMapping[consumptionMapping.STOP].replace(
          '#',
          '',
        );
      } else {
        return consumptionColorMapping[consumptionMapping.MEDIUM].replace(
          '#',
          '',
        );
      }
    }
  }

  return 'ECECEC';
}
