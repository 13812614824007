import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const StopSvg = (props: SVGProps<SVGSVGElement>) => (
     <svg
          width={24}
          height={24}
          fill="none"
          viewBox="0 0 16 16"
          {...props}
     >
          <path id="stop_circle_2" d="M5.33398 10.6666H10.6673V5.33325H5.33398V10.6666ZM8.00065 14.6666C7.07843 14.6666 6.21176 14.4916 5.40065 14.1416C4.58954 13.7916 3.88398 13.3166 3.28398 12.7166C2.68398 12.1166 2.20898 11.411 1.85898 10.5999C1.50898 9.78881 1.33398 8.92214 1.33398 7.99992C1.33398 7.0777 1.50898 6.21103 1.85898 5.39992C2.20898 4.58881 2.68398 3.88325 3.28398 3.28325C3.88398 2.68325 4.58954 2.20825 5.40065 1.85825C6.21176 1.50825 7.07843 1.33325 8.00065 1.33325C8.92287 1.33325 9.78954 1.50825 10.6007 1.85825C11.4118 2.20825 12.1173 2.68325 12.7173 3.28325C13.3173 3.88325 13.7923 4.58881 14.1423 5.39992C14.4923 6.21103 14.6673 7.0777 14.6673 7.99992C14.6673 8.92214 14.4923 9.78881 14.1423 10.5999C13.7923 11.411 13.3173 12.1166 12.7173 12.7166C12.1173 13.3166 11.4118 13.7916 10.6007 14.1416C9.78954 14.4916 8.92287 14.6666 8.00065 14.6666ZM8.00065 13.3333C9.48954 13.3333 10.7507 12.8166 11.784 11.7833C12.8173 10.7499 13.334 9.48881 13.334 7.99992C13.334 6.51103 12.8173 5.24992 11.784 4.21659C10.7507 3.18325 9.48954 2.66659 8.00065 2.66659C6.51176 2.66659 5.25065 3.18325 4.21732 4.21659C3.18398 5.24992 2.66732 6.51103 2.66732 7.99992C2.66732 9.48881 3.18398 10.7499 4.21732 11.7833C5.25065 12.8166 6.51176 13.3333 8.00065 13.3333Z" fill="#1C1B1F" />
     </svg>
);

const StopIcon = (props: Partial<CustomIconComponentProps>) => (
     <Icon component={StopSvg} {...props} />
);

export default StopIcon;
