import { ASSET_API, ReportQueryParams } from '@src/api';
import { AssetFilterPlantOptions } from '@store/asset/assetFilterPlantOptions';
import { API } from 'aws-amplify';

export async function fetchAssets(
  id?: string,
  filter?: AssetFilterPlantOptions,
  dateRange?: ReportQueryParams,
) {
  const params = new URLSearchParams();

  if (filter?.name) params.append('text', filter.name);
  if (filter?.departments)
    params.append('departmentIds', filter.departments.join(','));
  if (filter?.plants) params.append('plantIds', filter.plants.join(','));
  if (filter?.types) params.append('types', filter.types.join(','));
  if (filter?.assets) params.append('assets', filter.assets.join(','));
  if (filter?.limit) params.append('limit', String(filter.limit));
  if (filter?.skip) params.append('skip', String(filter.skip));

  // add filter.lines as array of strings to params
  if (filter?.lines) params.append('lineIds', filter.lines.join(','));

  if (dateRange?.startDate)
    params.append('startDate', String(dateRange.startDate));
  if (dateRange?.endDate) params.append('endDate', String(dateRange.endDate));

  if (id) {
    return await API.get(ASSET_API, `/tenant/${id}?${params.toString()}`, {});
  }

  return [];
}
