import {
  baseConfigAxis,
  baseConfigLegend,
  baseConfigSlider,
} from '../common-configs';

const dataMapping = {
  data: [],
  xField: 'date',
  yField: 'value',
  seriesField: 'country',
  // seriesField: 'alue',
};

const plotComponents = (dark: boolean, units?: string) => ({
  yAxis: {
    ...baseConfigAxis,
    title: {
      text: units || '',
    },
  },
  legend: {
    ...baseConfigLegend(dark),
    position: 'top-left',
  },
  slider: { ...baseConfigSlider },
});

export { dataMapping, plotComponents };
