const duration = require('humanize-duration');

export const formatDateOnRangePicker = (
  val: string | number,
  dateRange: number,
) => {
  const opt: Intl.DateTimeFormatOptions =
    dateRange === 0
      ? {
          hour: '2-digit',
          minute: '2-digit',
        }
      : {
          hour: '2-digit',
          minute: '2-digit',
          month: '2-digit',
          day: '2-digit',
        };

  return dateWithTimezone(Number(val)).toLocaleTimeString('it-IT', opt);
};

export const dateWithTimezone = (date: string | number | Date) => {
  if (date instanceof Date) return new Date(date);
  return new Date(Number(date));
};

export const convertDateToUTC = (date: string | number | Date) => {
  date = new Date(date);
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

export const durationToLang = (eta: number) => {
  return duration(eta, {
    language: 'it',
    delimiter: ' ',
    spacer: ' ',
    largest: 2,
    round: true,
    units: ['d', 'h', 'm'],
  });
};

export const minToMs = (min: number) => min * 60 * 1000;

export const stopPropagationHelper = (
  e: React.MouseEvent<HTMLElement, MouseEvent>,
) => e.stopPropagation();
