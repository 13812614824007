import { API } from 'aws-amplify';
import { GET_ASSET_THRESHOLDS_QUERY_KEY, REPORT_API } from '@src/api';
import { ReportUpdateThresholdsDto } from '@asset-manager/types';
import { useQuery } from 'react-query';
import { fetchConfig } from '@src/config/http';
import { minToMs } from '@src/utils';

export async function fetchAssetThresholds(
  assetId: string,
  tenantId: string,
): Promise<ReportUpdateThresholdsDto | undefined> {
  if (!tenantId || !assetId) {
    return undefined;
  }
  
  return await API.get(
    REPORT_API,
    `/mqtt-connector/${tenantId}/${assetId}/thresholds`,
    {},
  );
}
