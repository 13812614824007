import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ContractContentSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width="24"
    height="24"
  >
    <g>
      <path d="M14 4V10H20V8H16V4H14Z" fill={props.fill} />
      <path d="M8 20V16H4V14H10V20H8Z" fill={props.fill} />
    </g>
  </svg>
);

const ContractContentIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={ContractContentSvg} {...props} />
);

export default ContractContentIcon;
