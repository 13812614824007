import Divider from '@components/atoms/divider';
import { fontSize, utils } from '@src/globalStyle';
import { ReactNode } from 'react';
import styled from 'styled-components';

// NICE: Typography.Title level={3} | Typography.Text

export interface Props {
  title?: string;
  subtitle?: string;
  actions?: ReactNode;
  text?: string;
}

const CommonHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FirstRow = styled(Row)`
  align-items: center;
`;

const SecondaryRow = styled(Row)`
  margin-top: 8px;
`;

const Title = styled.h2`
  ${fontSize.l};
  font-weight: 700;
  color: white;
  ${utils.ellipsis};
  text-transform: capitalize;
`;

const Subtitle = styled.span`
  ${fontSize.s};
  font-weight: 400;
  color: #919191;
`;

const Actions = styled.div`
  display: flex;
  & > * {
    &:not(:first-of-type) {
      margin-left: 8px;
    }
  }
`;

export default function DrawerHeader({
  title,
  subtitle,
  actions,
  text,
}: Props) {
  return (
    <>
      <CommonHeader
        style={{
          paddingBottom: subtitle || text ? 0 : 32,
        }}
      >
        <FirstRow>
          {!!title && <Title>{title}</Title>}
          {!!actions && <Actions>{actions}</Actions>}
        </FirstRow>
        {(!!subtitle || !!text) && (
          <SecondaryRow>
            {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
            {!!text && <Subtitle>{text}</Subtitle>}
          </SecondaryRow>
        )}
      </CommonHeader>
      <Divider dark />
    </>
  );
}
