import { Input, Row, Space, Spin, Table } from 'antd';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import DetailsDrawer from './components/DetailsDrawer';
import { AssetTableData, useTenant } from '@src/modules/asset';
import Tag from '@components/atoms/tag';
import CompanyPreview from '@components/organisms/company-preview';
import { useMediaQuery, useWindowWidth } from '@src/hooks/useMediaQuery';
import Header from '@components/organisms/header';
import Icon from '@components/atoms/icon';
import {
  AssetLabel,
  ConnectorCompanyPreview,
  ItemsFound,
  RowFilters,
  RowPreview,
  StylizedTable,
} from './assets.style';
import {
  SorterResult,
  TableLocale,
  TablePaginationConfig,
} from 'antd/es/table/interface';
import { useRecoilState } from 'recoil';
import { assetsTableSelectedRowState } from '@store/asset';
import { useAssetsFilters } from '@asset-manager/api';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import {
  assetFilterPlant,
  defaultAssetFilterState,
} from '@store/asset/assetFilterPlantOptions';
import Cascader from '@components/atoms/cascader/cascader';
import { assetsFilterLineState } from '@store/asset/assetsFiltersLineState';
import EmptyState from '@components/atoms/empty-state';
import Card from '@components/molecules/card/card';
import classNames from 'classnames';
import { useTenantSlimReport } from '@src/modules/tenant';
import { useDebounce } from '@hooks';
import { useGetAssetsWithPagination } from '@src/api/asset/use-get-assets-with-pagination';
import { filterCascaderAssetsState } from '@store/filters/filter-state-assets';
import { Loader } from './components/drawer-contents/common';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '@src/utils/string.utils';
import { consumptionMapping } from '@src/utils/mapping.utils';

const tableLocale: TableLocale = {
  filterReset: i18next.t('general.reset'),
  filterConfirm: i18next.t('general.ok'),
};

const renderTextCellValue = (value: any, item: AssetTableData) => ({
  props: {
    style: {
      //
    },
  },
  children: <span>{value}</span>,
});

// TODO: remove this ugly thing
const assetTypeMapping: any = {
  machine: 'general.machine',
  monitron: 'general.monitron',
};

export default function Assets() {
  const { t } = useTranslation();
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [assetFilters, setAssetFilters] = useRecoilState(assetFilterPlant);
  const [cascaderOptions] = useRecoilState(assetsFilterLineState);

  const updateDefaultCascader = useCallback((): [string, string][] => {
    const result: [string, string][] = [];
    for (const val of assetFilters.assets || []) {
      result.push(['assets', val]);
    }
    for (const val of assetFilters.lines || []) {
      result.push(['lines', val]);
    }
    for (const val of assetFilters.departments || []) {
      result.push(['department', val]);
    }
    for (const val of assetFilters.plants || []) {
      result.push(['plants', val]);
    }
    for (const val of assetFilters.types || []) {
      result.push(['type', val]);
    }
    return result;
  }, [
    assetFilters.departments,
    assetFilters.lines,
    assetFilters.plants,
    assetFilters.types,
    assetFilters.assets,
  ]);

  const isLaptopM = useMediaQuery('laptopM');
  const windowWidth = useWindowWidth();
  const isSafari = useMemo(
    () => /^((?!chrome|android).)*safari/i.test(navigator.userAgent),
    [],
  );
  // const userAgent = navigator.userAgent.toLowerCase();

  const showExtraColumn = useMemo(() => {
    return windowWidth && windowWidth >= 992;
  }, [windowWidth]);

  const {
    getAssets: assetsData,
    pageNumber,
    pageSize,
    updateFilters,
    selectedAsset,
    setSelectedAssetId,
  } = useGetAssetsWithPagination(selectedTenantId, assetFilters.name);

  const [assetsTableSelectedRow, setAssetsTableSelectedRow] = useRecoilState(
    assetsTableSelectedRowState,
  );
  const { isReportLoading, isFetching: isReportFetching } =
    useTenantSlimReport();
  const { tenant } = useTenant();

  const [showDrawer, setShowDrawer] = useState(false);
  const [defaultCascader, setDefaultCascader] = useState<[string, string][]>(
    updateDefaultCascader(),
  );
  const [searchName, setSearchName] = useState<string>('');
  const searchQuery = useDebounce(searchName, 500);

  // custom hooks
  useAssetsFilters(selectedTenantId);

  useEffect(() => {
    if (searchQuery.length >= 3 || searchQuery === '') {
      if (searchQuery.length >= 3) {
        updateFilters({
          page: 1,
        });
      }
      setAssetFilters(filters => ({ ...filters, name: searchQuery }));
    }
  }, [searchQuery, setAssetFilters]);

  useEffect(() => {
    setDefaultCascader(() => updateDefaultCascader());
  }, [assetFilters, updateDefaultCascader]);

  const onClickRow = (record: AssetTableData, rowIndex: any) => {
    return {
      onClick: () => {
        setAssetsTableSelectedRow(rowIndex);
        setSelectedAssetId(record.id);
        setShowDrawer(true);
      },
    };
  };

  const handleAssetsTableChange = (
    pagination: TablePaginationConfig,
    _filter: Record<string, any>,
    _sorter: SorterResult<any>,
  ) => {
    updateFilters({
      page: pagination.current || 1,
      limit: pagination.pageSize || 10,
    });
  };

  const rowClassName = useCallback(
    (_record: any, index: number) => {
      return index === assetsTableSelectedRow ? 'enh-selected-row' : '';
    },
    [assetsTableSelectedRow],
  );

  const columns = useMemo(
    () => [
      {
        responsive: ['lg'],
        width: '4%',
        title: ' ',
        dataIndex: 'upTimek',
        key: 'id',
        render: (value: any, record: any, index: number) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {assetsTableSelectedRow === index ? (
              <Icon name="visibility" color="white" />
            ) : (
              <Icon name="chevron_right" color="#373737" />
            )}
          </div>
        ),
      },
      {
        width: showExtraColumn ? '20%' : '50%',
        title: t('assets.assets'),
        dataIndex: ['name'],
        key: 'id',
        render: (value: any, record: any, index: number) => (
          <Space direction="vertical" size={0}>
            <AssetLabel dark={assetsTableSelectedRow === index}>
              {value}
            </AssetLabel>
          </Space>
        ),
      },
      {
        responsive: ['lg'],
        width: '20%',
        title: t('general.type'),
        dataIndex: 'type',
        key: 'id',
        render: renderTextCellValue,
      },
      {
        responsive: ['lg'],
        width: '20%',
        title: t('general.establishment'),
        dataIndex: 'plantName',
        key: 'id',
        render: renderTextCellValue,
        // sorter: (a, b) => a.name.length - b.name.length,
        // sortDirections: ['descend'],
      },
      {
        responsive: ['lg'],
        width: '20%',
        title: t('general.line'),
        dataIndex: 'lineName',
        key: 'id',
        render: renderTextCellValue,
      },
      {
        width: showExtraColumn ? '10%' : '30%',
        title: t('general.state'),
        dataIndex: 'status',
        key: 'id',
        render: (_: any, record: any, index: any) => {
          const { currentStatus, type } = record as any; // TODO: remove cast after defining if this type should be in @enhancers/types or common

          let status = 'na';
          let label: string = t('general.NA');
          if (currentStatus === 'IDLE') {
            status = 'warning';
            label =
              type === 'Monitron'
                ? 'Warning'
                : capitalizeFirstLetter(t(consumptionMapping.MEDIUM));
          }
          if (currentStatus === 'WORKING') {
            status = 'success';
            label =
              type === 'Monitron'
                ? 'Normal'
                : capitalizeFirstLetter(t(consumptionMapping.HIGH));
          }
          if (currentStatus === 'STOP') {
            status = 'error';
            label =
              type === 'Monitron'
                ? 'Alarm'
                : capitalizeFirstLetter(t(consumptionMapping.LOW));
          }
          return (
            <Tag
              dark={assetsTableSelectedRow === index}
              size="small"
              status={status as any}
              label={label as any}
              backgroundColor={status === 'na' ? '#ECECEC' : '#ECECEC'}
              personalizedTextColor={status === 'na' ? '#7A7A7A' : '#7A7A7A'}
            />
          );
        },
      },
      ...(tenant?.type === 'GENERIC'
        ? [
            {
              width: showExtraColumn ? '6%' : '20%',
              title: ' ',
              dataIndex: '',
              key: '',
              render: renderTextCellValue,
            },
          ]
        : [{ title: ' ', width: showExtraColumn ? '6%' : '20%' }]),
    ],
    [assetsTableSelectedRow, showExtraColumn, t, tenant?.type],
  );

  const onClickCompanyPreview = useCallback(() => {
    setShowDrawer(true);
    setSelectedAssetId('');
    setAssetsTableSelectedRow(null);
  }, [setAssetsTableSelectedRow, setSelectedAssetId]);

  const [widthRow, setWidthRow] = useState('calc(100%)');
  const [companyPreviewCompact, setCompanyPreviewCompact] = useState(true);

  const listener = useCallback(() => {
    setCompanyPreviewCompact(window.innerWidth < 1024);
    if (window.innerWidth >= 1358 /*sizeNumber('laptopM')*/) {
      setShowDrawer(true);
    }

    setWidthRow(
      window.innerWidth >= 1358 &&
        ((selectedAsset && tenant?.type === 'GENERIC') ||
          tenant?.type !== 'GENERIC')
        ? 'calc(100% - 378px)'
        : 'calc(100%)',
    );
  }, [selectedAsset, tenant?.type]);

  useLayoutEffect(() => {
    listener();
    window.addEventListener('resize', listener);
    return () => {
      window.removeEventListener('resize', listener);
    };
  }, [
    listener,
    setAssetsTableSelectedRow,
    setSelectedAssetId,
    selectedAsset,
    tenant?.tenantType,
  ]);

  const [filterCascader, setFilterCascader] = useRecoilState(
    filterCascaderAssetsState,
  );

  const onCascaderReset = useCallback(() => {
    setAssetFilters(defaultAssetFilterState);
    setDefaultCascader([]);
    setFilterCascader({});
  }, [setAssetFilters]);

  const onCascaderConfirm = useCallback(
    (value: Record<string, string[]>) => {
      setAssetFilters(filters => ({
        ...filters,
        assets: value?.assets || undefined,
        departments: value?.department || undefined,
        lines: value?.lines || undefined,
        plants: value?.plants || undefined,
        types: value?.type || undefined,
      }));
    },
    [setAssetFilters],
  );

  const onCascaderClear = useCallback(() => {
    setAssetFilters(defaultAssetFilterState);
  }, [setAssetFilters]);

  const onInputChange = useCallback((e: any) => {
    setSearchName(e.target.value);
    const value = e.target.value;
    if (value.length >= 3) {
      // ? DEPRECATED
    }
  }, []);

  if (!tenant?.id) {
    return (
      <Loader>
        <Spin size="large" />
      </Loader>
    );
  }

  return (
    <Row
      style={{
        width: widthRow,
      }}
    >
      <Header title={t('assets.asset')} />
      {tenant?.type !== 'GENERIC' && (
        <RowPreview>
          <CompanyPreview
            className={classNames(
              'cssid-company-preview',
              !selectedAsset && 'selected',
            )}
            data-cypress="company-preview"
            onClickArrow={
              isLaptopM && !selectedAsset ? undefined : onClickCompanyPreview
            }
            compact={companyPreviewCompact}
          />
          {isLaptopM && !selectedAsset && (
            <ConnectorCompanyPreview
              spinning={(!isReportLoading && isReportFetching).toString()}
            />
          )}
          {/* <ConnectorCompanyPreview /> */}
        </RowPreview>
      )}
      <RowFilters data-cypress="filter-input-nrAssets">
        <Cascader
          filter={filterCascader}
          setFilter={setFilterCascader}
          onReset={onCascaderReset}
          defaultValue={defaultCascader}
          onConfirm={onCascaderConfirm}
          onClear={onCascaderClear}
          options={cascaderOptions}
        />
        <Input
          allowClear
          value={searchName}
          // value={assetFilters.name}
          onChange={onInputChange}
          placeholder={t('general.search')}
          prefix={<Icon name="search" color="#373737" />}
        />
        <ItemsFound>
          {assetsData.data?.count} {t('assets.asset')}
        </ItemsFound>
      </RowFilters>
      {assetsData.isError ? (
        // TODO improve
        <Card status="white">
          <EmptyState
            image={<Icon name="precision_manufacturing" color="currentColor" />}
            title={t('assets.noAsset')}
            subtitle={t('assets.assetListsAvailable')}
          />
        </Card>
      ) : (
        <StylizedTable isSafari={isSafari.toString()}>
          <Table
            locale={tableLocale}
            dataSource={
              assetsData.data?.items?.map((el: any) => ({
                ...el,
                type: t(assetTypeMapping[el.type]),
              })) || []
            }
            columns={columns as any}
            rowKey="id"
            onRow={onClickRow}
            // @ts-ignore
            onChange={handleAssetsTableChange}
            rowClassName={rowClassName}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: assetsData.data?.count,
            }}
            loading={assetsData.isLoading || assetsData.isFetching}
          />
        </StylizedTable>
      )}
      <DetailsDrawer
        setShowDrawer={setShowDrawer}
        showDrawer={showDrawer}
        tenantType={tenant?.type}
      />
    </Row>
  );
}
