import { useTenantSlimReport as useTenantSlimReportQuery } from '@src/api';
import { assetFilterDateState } from '@store/asset/assetFilterDateState';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useRecoilState } from 'recoil';

export function useTenantSlimReport() {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [reportFilters] = useRecoilState(assetFilterDateState);

  const {
    data: tenantReport,
    isLoading: isReportLoading,
    isFetching,
    refetch,
  } = useTenantSlimReportQuery(selectedTenantId, reportFilters);

  return {
    selectedTenantId,
    tenantReport,
    isReportLoading,
    refetch,
    isFetching,
  };
}
