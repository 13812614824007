import { device } from '@theme/breakpoints';
import { Row } from 'antd';
import styled, { keyframes } from 'styled-components';

export const ContainerOverview = styled.div<{ fullscreen: boolean }>`
  padding: 16px 0;

  @media ${device.laptop} {
    margin: ${props => (props.fullscreen ? '0 0' : '0')};
  }
`;

const fadeIn = keyframes`
  from {
    display: none;

    transform: scale(.25);
    opacity: 0;
  }
  to {
    display: block;
    transform: scale(1);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    display: block;
    transform: scale(1);
    opacity: 1;
  }

  to {
    transform: scale(.25);
    opacity: 0;
    display: none;
  }
`;

export const RowExtraOverview = styled(Row)<{ fadeIn: boolean }>`
  visibility: ${props => (props.fadeIn ? 'visible' : 'hidden')};
  animation: ${props => (props.fadeIn ? fadeIn : fadeOut)} 0.5s ease-in-out;
  transition: visibility 0.5s ease-in-out;
  display: ${props => (props.fadeIn ? 'flex' : 'none')};

  .ant-select,
  .ant-picker {
    width: 302px;
  }
  .ant-select {
    margin-right: 8px;
  }
`;

export const Loader = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
