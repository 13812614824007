import { API } from 'aws-amplify';
import { AuthorsQueryParams, AuthorsResponse, USER_API } from '@src/api';

export async function fetchAuthors(
  tenantId: string,

  filters: AuthorsQueryParams,
) {
  const qs = filters ? `?${new URLSearchParams(filters).toString()}` : '';
  return (await API.get(
    USER_API,
    `/tenant/${tenantId}${qs}`,
    {},
  )) as AuthorsResponse;
}
