import { useInfiniteQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { fetchAssetNotes, GET_ASSET_NOTES_QUERY_KEY } from '@src/api';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { selectedAssetIdState } from '@store/asset/selectedAssetIdState';
import { noteFiltersState } from '@store/note/note-filters-state';
import { fetchConfig } from '@src/config/http';

export function useAssetsNotes() {
  const [tenantId] = useRecoilState(selectedTenantIdState);
  const [assetId] = useRecoilState(selectedAssetIdState);
  const [filters] = useRecoilState(noteFiltersState);

  return useInfiniteQuery(
    [GET_ASSET_NOTES_QUERY_KEY, tenantId, assetId, filters],
    ({ pageParam = 0 }) =>
      fetchAssetNotes(tenantId, assetId, filters, pageParam),
    {
      ...(fetchConfig as any),
      enabled: !!tenantId && !!assetId,
      getNextPageParam: (_lastPage, allPages) => {
        return allPages.length;
      },
    },
  );
}
