import { OverviewListItem } from '@components/organisms/overview';
import { t } from 'i18next';

/*
TODO
buildOverviewList di company-preview.utils.ts e 
overviewList di tenant-drawer-content.utils.tsx
andrebbero unificate
*/

export const overviewList: (
  stops: string,
  uptime: string,
  uptimeVariation: number,
  consumption: string,
  consumptionUnitOfMeasure: string,
  totalTimeInNoData: string,
) => OverviewListItem[] = (
  stops,
  uptime,
  uptimeVariation = 0,
  consumption,
  consumptionUnitOfMeasure,
  totalTimeInNoData,
) => [
  {
    title: t('general.totalConsumption'),
    info: t('assets.totalConsumptionOnPeriod'),
    icon: 'trending_up',
    iconColor: '#E8684A',
    value: consumption,
    label: consumptionUnitOfMeasure,
  },
  {
    title: t('general.noData'),
    info: '',
    iconColor: '#FFFFFF',
    value: totalTimeInNoData,
    label: '',
  },
  // TODO: ENHAM-740 set NO DATA total
];
