import { Form, FormInstance } from 'antd';
import { FormFields } from './modal-add-alert.types';
import { Dayjs } from 'dayjs';
import {
  FormErrorList,
  IntervalsLabel,
  IntervalsTimePicker,
} from './modal-add-alert.components';
import Button from '@components/atoms/button';
import Icon from '@components/atoms/icon';
import { useTranslation } from 'react-i18next';

const Intervals = ({
  form,
  initialValues,
}: {
  form: FormInstance;
  initialValues: Partial<FormFields>;
}) => {
  const { t } = useTranslation();
  const isExtraSmall = window.innerWidth < 420;

  const getValueProps = (i: any) => i;
  const getValueFromEvent = (e: Dayjs) => {
    if (!e) return null;
    return e.format('HH:mm');
  };

  return (
    <Form.List
      name="intervals"
      rules={[
        {
          validator: async (_, intervals) => {
            if (!intervals || intervals.length < 1) {
              return Promise.reject(
                new Error(t('modal.addAtLeastOneInterval')),
              );
            }
            return Promise.resolve();
          },
        },
      ]}
      initialValue={[{}]}
    >
      {(fields, { add, remove }) => (
        <div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {fields.map(({ name, key, ...field }, index) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
                key={key}
              >
                {!isExtraSmall && <IntervalsLabel>Dalle</IntervalsLabel>}
                <Form.Item
                  name={[name, 'startTime']}
                  rules={[{ required: true, message: t('modal.enterTime') }]}
                  getValueProps={getValueProps}
                  getValueFromEvent={getValueFromEvent}
                  {...field}
                >
                  <IntervalsTimePicker
                    size="large"
                    format="HH:mm"
                    placeholder={
                      isExtraSmall ? t('general.from') : t('general.select')
                    }
                    defaultValue={
                      (initialValues.intervals?.[index]?.startTime as Dayjs) ||
                      undefined
                    }
                  />
                </Form.Item>
                {!isExtraSmall && <IntervalsLabel>alle</IntervalsLabel>}
                <Form.Item
                  name={[name, 'endTime']}
                  rules={[
                    {
                      validator: (_rule, value) => {
                        if (!value) {
                          return Promise.reject(
                            new Error(t('modal.enterTime')),
                          );
                        }
                        if (
                          value <=
                          form.getFieldValue(['intervals', name, 'startTime'])
                        ) {
                          return Promise.reject(
                            new Error(t('modal.errorTime')),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  getValueProps={getValueProps}
                  getValueFromEvent={getValueFromEvent}
                  {...field}
                >
                  <IntervalsTimePicker
                    size="large"
                    format="HH:mm"
                    placeholder={
                      isExtraSmall ? t('general.to') : t('general.select')
                    }
                    defaultValue={
                      (initialValues.intervals?.[index]?.endTime as Dayjs) ||
                      undefined
                    }
                  />
                </Form.Item>
                <Button
                  type="button"
                  style={{
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    height: 'fit-content',
                  }}
                  icon={<Icon name="delete" />}
                  variant="ghost-black"
                  onClick={() => remove(name)}
                />
              </div>
            ))}
          </div>
          <Button
            type="button"
            style={{
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              width: '100%',
              marginBlock: 8,
              marginLeft: 40,
            }}
            icon={<Icon name="add_circle" style={{ fontSize: 16 }} />}
            variant="ghost-black"
            onClick={() => add()}
          >
            {t('general.addInterval')}
          </Button>
          <FormErrorList errors={form.getFieldError('intervals')} />
        </div>
      )}
    </Form.List>
  );
};

export default Intervals;
