import { atom } from 'recoil';
import dayjs from 'dayjs';
import { AlertQueryParams } from '@src/api/alert/types';
import { AlertSeverityEnumDto } from '@asset-manager/types';

dayjs.extend(require('dayjs/plugin/utc'));

export const alertFiltersState = atom({
  key: 'alertFiltersState',
  default: {
    limit: '100',
    skip: '0',
    sort: 'updatedAt',
    order: 'desc',
    severities: [
      AlertSeverityEnumDto.HIGH,
      AlertSeverityEnumDto.MEDIUM,
      AlertSeverityEnumDto.LOW,
    ],
  } as AlertQueryParams,
});

export const alertTableDefaultSort = 'updatedAt' as const;
export const alertTableDefaultOrder = 'desc' as const;

export const alertDefaultSeverities = [
  AlertSeverityEnumDto.HIGH,
  AlertSeverityEnumDto.MEDIUM,
  AlertSeverityEnumDto.LOW,
];

export const alertTableFiltersState = atom({
  key: 'alertTableFiltersState',
  default: {
    limit: '10',
    skip: '0',
    sort: alertTableDefaultSort,
    order: alertTableDefaultOrder,
  } as AlertQueryParams,
});

export const currentAlertIdState = atom<string | null>({
  key: 'currentAlertFiltersState',
  default: null,
});
