import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useAssetReport as useAssetReportQuery } from '@src/api';
import { selectedAssetIdState } from '@store/asset/selectedAssetIdState';
import { assetFilterDateState } from '@store/asset/assetFilterDateState';

export function useAssetReport() {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [selectedAssetId] = useRecoilState(selectedAssetIdState);
  const [reportFilters] = useRecoilState(assetFilterDateState);

  const {
    data: assetReport,
    isLoading: isReportLoading,
    refetch: assetRefetch,
  } = useAssetReportQuery(selectedTenantId, selectedAssetId, reportFilters);

  return {
    selectedTenantId,
    assetReport,
    isReportLoading,
    assetRefetch,
  };
}
