import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useRecoilState } from 'recoil';
import { useGetAssets } from '../asset';
import { useCallback, useEffect } from 'react';
import { reportExtraFilterStructure } from '@store/report/report-extra-filter-structure';
import { reportExtraFilterState } from '@store/report/report-extra-filter-state';

export default function useReportExtraFilters() {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);

  const [reportExtraFilterStruct, setReportExtraFilterStruct] = useRecoilState(
    reportExtraFilterStructure,
  );

  const [reportExtraFilter, setReportExtraFilter] = useRecoilState(
    reportExtraFilterState,
  );

  const { data: assetsData, isSuccess: isSuccessAssets } =
    useGetAssets(selectedTenantId);

  useEffect(() => {
    if (isSuccessAssets) {
      setReportExtraFilterStruct(prev => {
        const newExtraFilters = JSON.parse(JSON.stringify(prev));

        const assets = newExtraFilters.find((f: any) => f.value === 'assets');
        if (assets) {
          assets.children = assetsData.filters?.assets?.map((asset: any) => ({
            label: asset.name,
            value: asset.id,
          }));
        }

        const departments = newExtraFilters.find(
          (f: any) => f.value === 'departments',
        );
        if (departments) {
          departments.children = assetsData.filters?.departments?.map(
            (dep: any) => ({
              label: dep.name,
              value: dep.id,
            }),
          );
        }

        const plants = newExtraFilters.find((f: any) => f.value === 'plants');
        if (plants) {
          plants.children = assetsData.filters?.plants?.map((dep: any) => ({
            label: dep.name,
            value: dep.id,
          }));
        }

        const lines = newExtraFilters.find((f: any) => f.value === 'lines');
        if (lines) {
          lines.children = assetsData.filters?.lines?.map((dep: any) => ({
            label: dep.name,
            value: dep.id,
          }));
        }

/*         const types = newExtraFilters.find((f: any) => f.value === 'types');
        if (types) {
          types.children = assetsData.filters?.types?.map((dep: any) => ({
            label: dep,
            value: dep,
          }));
        } */

        return newExtraFilters;
      });
    }
  }, [assetsData, isSuccessAssets, setReportExtraFilterStruct]);

  const updateReportsExtraFilters = useCallback(
    (extraFilters: Record<string, string[]>) => {
      const newFilters = { ...reportExtraFilter };

      if ('assets' in extraFilters) {
        newFilters.assets = extraFilters.assets;
      } else {
        delete newFilters.assets;
      }

      if ('plants' in extraFilters) {
        newFilters.plantIds = extraFilters.plants;
      } else {
        delete newFilters.plantIds;
      }

      if ('lines' in extraFilters) {
        newFilters.lineIds = extraFilters.lines;
      } else {
        delete newFilters.lineIds;
      }

      if ('departments' in extraFilters) {
        newFilters.departmentIds = extraFilters.departments;
      } else {
        delete newFilters.departmentIds;
      }

/*       if ('types' in extraFilters) {
        newFilters.types = extraFilters.types;
      } else {
        delete newFilters.types;
      } */

      setReportExtraFilter(newFilters);
    },
    [reportExtraFilter, setReportExtraFilter],
  );

  return {
    reportExtraFilter,
    updateReportsExtraFilters,
  };
}
