import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useAssetVibrationStateReport as useAssetVibrationStateReportQuery } from '@src/api';
import { selectedAssetIdState } from '@store/asset/selectedAssetIdState';
import { GetReportAssetStateResponseDto } from '@asset-manager/types';
import { assetFilterDateState } from '@store/asset/assetFilterDateState';

export interface IUseAssetVibrationStateReport {
  selectedTenantId: string;
  assetVibrationStateReport?: GetReportAssetStateResponseDto;
  isReportLoading: boolean;
  assetVibrationRefetch?: () => void;
}
export function useAssetVibrationStateReport(
  type: string,
): IUseAssetVibrationStateReport {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [selectedAssetId] = useRecoilState(selectedAssetIdState);
  const [reportFilters] = useRecoilState(assetFilterDateState);

  const {
    data: assetVibrationStateReport,
    isLoading: isReportLoading,
    refetch: assetVibrationRefetch,
  } = useAssetVibrationStateReportQuery(
    selectedTenantId,
    selectedAssetId,
    reportFilters,
    type,
  );

  return {
    selectedTenantId,
    assetVibrationStateReport,
    isReportLoading,
    assetVibrationRefetch,
  };
}
