import { useCurrentUser } from '@asset-manager/api';
import { PrecisionManufacturingIcon } from '@assets';
import Button, {
  btnColor,
  btnConfigInterface,
  getBtn,
  themeConfig,
} from '@components/atoms/button';
import CircleAcronyms from '@components/atoms/circle-acronyms/circle-acronyms';
import Divider from '@components/atoms/divider';
import Icon from '@components/atoms/icon';
import { utils } from '@src/globalStyle';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import { useTenant } from '@src/modules/asset';
import { useLayout } from '@src/modules/asset/hooks/useLayout';
import { history } from '@src/routes/HistoryRouter';
import {
  clickedSamePageSiderMenuState,
  closeOrContractDrawerState,
} from '@store/general/layout';
import { device, size } from '@theme/breakpoints';
import { Layout, Menu, MenuProps } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled, { createGlobalStyle, css } from 'styled-components';

const { Sider } = Layout;
const { SubMenu } = Menu;

type MenuItem = Required<MenuProps>['items'][number];

export const AppLayoutGlobalStyle = createGlobalStyle<{
  platform?: string;
  btnConfig?: btnConfigInterface;
}>`
  html{
    scroll-behavior: smooth;
  }
  body {
    background-color: #ECECEC;
  }

  //////////
  // windows

  ${({ platform }) =>
    //@ts-ignore
    platform &&
    typeof platform === 'string' &&
    /(win32|win64|windows|wince)/i.test(platform) &&
    `
    ${utils.xAxisWindows}
    ${utils.hotfixXaxisWindows}
    `};

  //////////
  // slider
  .ant-slider {
    .ant-slider-handle{
      &:before
        {
          padding: 1.5em;
          margin: -1.5em;
        }
      }
  }

  // under 900 px width
  @media (max-width: 900px) {
    .ant-slider {
      .ant-slider-handle{
        &:before
          {
            padding: 2.5em;
            margin: -2.5em;

        }
      }
    }
  }

  ////////
  // input
  input[type=number]::-webkit-inner-spin-button {
    opacity: 0;
  }
  .ant-input-affix-wrapper-lg {
    padding: 10px 11px;
  }

  .ant-input-affix-wrapper-focused:focus{
    box-shadow: none
  }

  // ANTD input rules
  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused,
  .ant-input:focus,
  .ant-input-focused {
    /*
      border-color: #373737;
      box-shadow: 0 0 0 2px rgba(0, 60, 180, 0.15);
      border-inline-end-width: 1px;
      outline: 0;
    */
    box-shadow: none;
    border-color: #4786ff !important;
  }

  .ant-input-affix-wrapper .ant-input-prefix {
    margin-inline-end: 14px;
  }

  ///////////////
  // range-picker
  @media (max-width: 767px) {
    .ant-picker-dropdown-range{
      .ant-picker-presets ul{
        height: auto !important;
      }
      .ant-picker-panels{
        display: none !important;
      }
    }
  }

  /////////////
  // ant-picker
  .ant-picker .ant-picker-input {
    // altri test :valid * :not(:empty)
    input:not([value=""]) + .ant-picker-suffix{
        color: white;
      }
  }

  //////////////
  // time-picker
  .ant-picker-dropdown .ant-picker-time-panel-column >li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
    background-color: #373737 !important;
  }
  .ant-picker-footer{
    /* .ant-picker-now a {
      //test 1
      ${props =>
        props &&
        props.btnConfig &&
        css`
          color: ${props.btnConfig['outline-black'].default.color} !important;
        `};

      // test 2
      ${getBtn({ type: 'danger', theme: 'default' })}
      &:hover {
        ${getBtn({ type: 'danger', theme: 'hover' })}
      }
    } */


    .ant-picker-now a { // black
      color: ${btnColor.white};
      &:hover{
        color: ${btnColor.grey7A};
      }

    }
    .ant-picker-ok button{
      color: ${btnColor.ultraBlack};
      background-color: ${btnColor.white};
      &:hover {
        color: ${btnColor.grey7A};
        background-color: ${btnColor.white};
      }
    }
  }

  /////////
  // layout
  .ant-layout .ant-layout-sider-light{
    background-color: #ECECEC;
    border-right: 1px solid #C8C8C8;
  }
  .ant-layout{
    background-color: #ECECEC;
  }

  ///////
  // tag
  .ant-tag-processing {
    background: rgba(71, 134, 255, 0.2);
    border-color: transparent;
  }

  //////
  //menu
  .ant-menu-light{
    background-color: #ECECEC;
  }
  .ant-menu-vertical >.ant-menu-item{
    width: 40px;
    &:not(:last-of-type){
      padding-inline: calc(50% - 15px);
    }
    &:last-of-type{
      padding-inline: 0;
    }
  }

  //////////////////
  // dropdown filter
  .ant-table-filter-dropdown{

    .ant-dropdown-menu .ant-dropdown-menu-item-selected {
      background-color: #E3E3E3;
      &:hover{
        background-color: #E3E3E3;
      }
    }
    .ant-dropdown-menu {
    &, .ant-dropdown-menu-item {
      border-radius: 0;
    }}

    .ant-checkbox-wrapper+span{
      font-weight: 500;
    }

    &,& .ant-dropdown-menu{
      background-color: #ECECEC !important;
    }

    .ant-checkbox .ant-checkbox-inner{
      border-width: 2px;
    }
    .ant-dropdown-menu{
      padding: 0;
    }

    .ant-table-filter-dropdown-btns{
      border-color: #C8C8C8 !important;
      .ant-btn{
        font-weight: 500;
      }
      .ant-btn-link{
        color: #373737;
        &:disabled{
          color: #C8C8C8
        }
      }
      .ant-btn-primary{
        background-color: #E3E3E3;
        color: #373737;
      }
    }

  }

`;

/*
    dentro a questo createGlobalStyle generale
    potrei importarne altri - tipo ModalGlobalStyle
    ma devono essere css`${..}`e non createGlobalStyle`${..}`
    NB: possibile problema con le props?
  */

const StyledLayout = styled(Layout)<{ showMask: boolean }>`
  min-height: 100vh;

  .ant-menu-light:not(.ant-menu-horizontal)
    .ant-menu-item:not(.ant-menu-item-selected):last-of-type:hover {
    // equals user
    background-color: unset !important;
  }

  ${props =>
    props.showMask &&
    css`
      &:before {
        content: '';
        display: block;
        background-color: rgba(28, 28, 30, 0.6);
        position: absolute;
        height: 100vh;
        width: 100vw;
        z-index: 1;
      }
    `};
`;

const UserCircle = styled(CircleAcronyms)`
  background: #e3e3e3;
  transition: color 0.3s, background 0.3s;
  :hover {
    background: #c8c8c8;
  }
`;

const Content = styled(Layout.Content)`
  padding: 16px;
  /* background: #ececec; */
`;

const StyledMenu = styled(Menu)`
  border: none !important;
  height: 100%;
  height: calc(100vh - 25vh);
  .ant-menu-item {
    margin-inline: 0;
  }
  @media ${device.tablet} {
    height: calc(100vh - 153px);
    margin-left: 4px;
  }
  @media screen and (max-width: ${size.laptop}) {
    .ant-menu-item {
      padding-left: 12px !important;
    }
  }
  display: flex;
  flex-direction: column;
  :where(.css-dev-only-do-not-override-yy2spi).ant-menu-inline .ant-menu-item,
  :where(.css-dev-only-do-not-override-yy2spi).ant-menu-vertical .ant-menu-item,
  :where(.css-dev-only-do-not-override-yy2spi).ant-menu-inline
    .ant-menu-submenu-title,
  :where(.css-dev-only-do-not-override-yy2spi).ant-menu-vertical
    .ant-menu-submenu-title {
    margin-bottom: 8px;
    margin-top: 8px;
  }
  .ant-menu-item-divider {
    flex: 1;
    border: none;
  }
  .ant-menu {
  }

  .ant-menu-item-selected[data-menu-id$='user'] {
    background: inherit;
    .ant-menu-item-icon {
      background: white;
      color: #373737;
    }
  }
`;

const StyledSider = styled(Sider)<{ isLaptop: boolean; visible: boolean }>`
  position: ${props => (props.isLaptop ? 'sticky' : 'fixed')} !important;
  display: ${props => (props.visible ? 'block' : 'none')} !important;
  z-index: 1;
  top: 0;

  padding: 32px 16px;
  height: 100vh;

  /*
  min-width: unset !important;
  max-width: unset !important;
  width: unset !important;
  flex: unset !important;
  */
  ${props =>
    // @ts-ignore
    !props.isLaptop &&
    css`
      width: 272px !important;
      max-width: 272px !important;
    `};

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const HeaderSider = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  @media ${device.laptop} {
    justify-content: center;
    margin-bottom: 32px;
  }
`;

const StyledDiv = styled.div`
  height: 40px;
  padding-top: 6px;
  padding-left: 8px;

  @media ${device.laptop} {
    padding-left: 0;
  }
`;

function getItem(
  label: React.ReactNode,
  key: React.Key,
  subMenuKey: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  style?: React.CSSProperties,
  disabled: boolean = false,
): MenuItem {
  return {
    subMenuKey,
    key,
    icon,
    children,
    label,
    style,
    disabled,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem(
    'Assets',
    'dashboard',
    'sub1',
    <PrecisionManufacturingIcon
      style={{
        fontSize: '22px',
        marginLeft: '2px',
        transform: 'translate(0, 2px)',
      }}
    />,
    undefined,
    {
      position: 'relative',
    },
  ),
  getItem(
    'Report',
    'report',
    'sub1',
    <Icon
      name="area_chart"
      color="currentColor"
      style={{
        fontSize: '24px',
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
      }}
    />,
    undefined,
    {
      position: 'relative',
    },
  ),
  getItem(
    'Alerts',
    'alerts',
    'sub1',
    <Icon
      name="emergency_home"
      color="currentColor"
      style={{
        fontSize: '24px',
      }}
    />,
    undefined,
    {
      position: 'relative',
    },
  ),
  // getItem(
  //   'Sensors',
  //   'sensors',
  //   'sub1',
  //   <Icon name="extension" color="currentColor" />,
  // ),
  // getItem(
  //   'Stats',
  //   'stats',
  //   'sub1',
  //   <Icon name="notifications" color="currentColor" />,
  // ),
  getItem(
    'Help',
    'help',
    'sub1',
    <Icon
      name="help"
      color="currentColor"
      style={{
        fontSize: '24px',
        // position: 'absolute',
        // top: '50%',
        // left: '50%',
        // transform: 'translate(-50%, -50%)',
      }}
    />,
    undefined,
    {
      position: 'relative',
    },
  ),
  { type: 'divider' },
];

export default function AppLayout() {
  const location = useLocation();
  const isLaptop = useMediaQuery('laptop');
  const [userName, setUserName] = useState('');
  const [userSurname, setUserSurname] = useState('');
  const { tenant } = useTenant();
  const { collapsed, setCollapsed, visible, setVisible } = useLayout();
  const { data: currentUser, isLoading: isCurrentUserLoading } = useCurrentUser(
    tenant?.id,
  );

  const [clickedSamePage, setClickedSamePage] = useRecoilState(
    clickedSamePageSiderMenuState,
  );

  const [closeOrContractDrawer, setCloseOrContractDrawer] = useRecoilState(
    closeOrContractDrawerState,
  );

  const subMenus = Array.from(
    // @ts-ignore
    new Set(items.filter(i => i && i?.subMenuKey).map(item => item.subMenuKey)),
  );

  useEffect(() => {
    if (!!currentUser && currentUser.name && currentUser.surname) {
      setUserName(currentUser.name);
      setUserSurname(currentUser.surname);
    }
  }, [currentUser]);

  const onClickCloseMenu = useCallback(() => {
    setVisible(false);
    setCollapsed(true);
    document.body.classList.remove('disable-scroll');
  }, [setCollapsed, setVisible]);

  const showCloseMenu = useMemo(
    () => !(isLaptop && visible),
    [isLaptop, visible],
  );

  const menuItems = useMemo(
    () => [
      ...items,
      getItem(
        `${userName} ${userSurname}`, // 'User',
        'user',
        'sub2',
        <UserCircle
          fontSize="16px"
          size="38px" // 40
          style={{
            // paddingInline: 'calc(50% - 12px)',
            // transform: 'translate(-11px, -1px)',
            color: 'black',
          }}
          data-cypress="user"
        >
          {`${userName[0] ?? ''}${userSurname[0] ?? ''}`.toUpperCase()}
        </UserCircle>,
        undefined,
        undefined,
        !currentUser || typeof currentUser === 'string',
      ),
    ],
    [currentUser, userName, userSurname],
  );

  const menuClick = useCallback(
    (e: any) => {
      const clickedSamePageTemp = e.key === location.pathname.slice(1);
      setClickedSamePage(clickedSamePageTemp);
      if (clickedSamePageTemp) {
        setCloseOrContractDrawer(true);
      } else {
        document.body.classList.remove('disable-scroll');
      }
      if (e.key === 'assets') {
        history.push('/');
      } else {
        history.push(`/${e.key}`);
      }
    },
    [location.pathname, setClickedSamePage, setCloseOrContractDrawer],
  );

  return (
    <StyledLayout showMask={!isLaptop && visible}>
      <AppLayoutGlobalStyle
        platform={navigator?.userAgent || ''}
        btnConfig={themeConfig}
      />
      <StyledSider
        theme="light"
        // collapsible // TODO servirà per il mobile, fare un check con la versione old per vedere cosa rompe l'animazione di default
        visible={visible}
        isLaptop={isLaptop}
        collapsed={collapsed}
        /*
          mettendo collapsed anzichè true si vede aumentare e diminuire
          la larghezza del menù al cambio della viewport
        */
        onCollapse={setCollapsed}
        data-cypress="menu"
      >
        <div>
          <HeaderSider>
            <StyledDiv style={{}} data-cypress="logo-astra">
              <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.5137 19.8062C21.5137 22.1812 23.4387 24.1125 25.8199 24.1125C28.2012 24.1125 30.1262 22.1875 30.1262 19.8062C30.1262 17.425 28.1949 15.5 25.8199 15.5C23.4449 15.5 21.5137 17.425 21.5137 19.8062Z"
                  fill="#373737"
                />
                <path
                  d="M8.36289 26.0375C5.98789 26.0375 4.05664 27.9625 4.05664 30.3437C4.05664 32.7187 5.98164 34.65 8.36289 34.65C10.7379 34.65 12.6691 32.725 12.6691 30.3437C12.6691 27.9625 10.7441 26.0375 8.36289 26.0375Z"
                  fill="#373737"
                />
                <path
                  d="M31.6575 26C29.2012 25.9875 28.795 27.6437 25.8325 27.6312C22.87 27.6187 22.4825 25.9562 20.0262 25.9437C17.6512 25.9312 15.7137 27.85 15.7012 30.225C15.6887 32.6 17.6075 34.5375 19.9825 34.55C22.4387 34.5625 22.845 32.9062 25.8075 32.9187C28.77 32.9312 29.1637 34.5937 31.6137 34.6062C33.9887 34.6187 35.9262 32.7 35.9387 30.325C35.9512 27.95 34.0325 26.0125 31.6575 26Z"
                  fill="#373737"
                />
                <path
                  d="M17.9377 21.9125C19.1377 19.7688 17.8877 18.6125 19.3439 16.0313C20.7939 13.4438 22.4377 13.9125 23.6377 11.7688C24.8002 9.69375 24.0627 7.075 21.9939 5.9125C19.9189 4.75 17.3002 5.4875 16.1377 7.55625C14.9377 9.7 16.1877 10.8563 14.7314 13.4375C13.2814 16.025 11.6377 15.5563 10.4377 17.7C9.27519 19.775 10.0127 22.3937 12.0814 23.5562C14.1502 24.7188 16.7689 23.9813 17.9377 21.9125Z"
                  fill="#373737"
                />
              </svg>
            </StyledDiv>
            {showCloseMenu && (
              <Button
                center
                shape="square"
                variant="grey"
                shapeSize={44}
                onClick={onClickCloseMenu}
              >
                <Icon
                  style={{ fontSize: 24 }}
                  name="chevron_left"
                  color="#1C1B1F"
                />
              </Button>
            )}
          </HeaderSider>
          <Divider style={{ margin: '32px 0 22px' }} />
          <StyledMenu
            defaultSelectedKeys={[location.pathname.slice(1) ?? 'dashboard']}
            mode="inline"
            items={menuItems}
            onClick={menuClick}
            data-cypress="sider-menu-items"
          ></StyledMenu>
        </div>
      </StyledSider>
      <Layout>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </StyledLayout>
  );
}
