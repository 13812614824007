import Icon from '@components/atoms/icon';
import Tooltip from '@components/atoms/tooltip';
import { fontSize } from '@src/globalStyle';
import classNames from 'classnames';
import { Property } from 'csstype';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

export interface Props {
  title?: string;
  info?: string;
  icon?: string;
  iconComponent?: React.ReactNode;
  iconColor?: Property.Color | string | undefined;
  contentSmall?: boolean;
  value?: string;
  label?: string;
  labelColor?: Property.Color | string | undefined;
  displayMode?: 'row' | 'column';
  verticalDivider?: boolean;
  titleColor?: string;
  titleAdditionalInfo?: string;
  footer?: string;
}

export const Container = styled.div<{ direction: Props['displayMode'] }>`
  ${props =>
    props.direction === 'row'
      ? css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        `
      : css`
          gap: 4px;
          display: flex;
          flex-direction: column;
        `};
`;

const Header = styled.div`
  .info-header {
    &-title {
      ${fontSize.s}
    }
    &-i {
      font-size: 13px;
      margin-left: 5px;
    }
    &-title,
    &-i {
      color: #919191;
    }
  }
`;

const Content = styled.div<{ contentSmall?: boolean }>`
  margin-top: 4px;
  display: flex;
  align-items: center;

  .info-content {
    &-value,
    &-label {
      color: #ffffff;
    }
    &-value {
      ${props => (props.contentSmall ? fontSize.l : fontSize.h2)}
      font-weight: 700;
    }
    &-label {
      ${props => (props.contentSmall ? fontSize.xs : fontSize.s)}
      font-weight: 400;
      margin-left: 4px;
      align-self: end;
    }
    &-icon {
      margin-right: 10px;
      font-size: 20px;
    }
  }
`;

export default function Overview({
  title,
  info,
  icon,
  iconComponent,
  iconColor, // = 'currentColor',
  value,
  label,
  labelColor = 'white',
  contentSmall = false,
  displayMode = 'column',
  verticalDivider = false,
  titleColor,
  titleAdditionalInfo,
  footer,
}: Props) {
  const { t } = useTranslation();
  return (
    <Container
      className="info"
      direction={displayMode}
      style={{
        borderRight: verticalDivider ? '1px solid #373737' : undefined,
      }}
    >
      <Header className="info-header">
        <span className="info-header-title" style={{ color: titleColor }}>
          {title}
        </span>
        <span className="info-header-title">{titleAdditionalInfo ?? ''}</span>
        {info ? (
          <Tooltip title={info}>
            <span className="material-symbols-outlined info-header-i">
              {t('general.info')}
            </span>
          </Tooltip>
        ) : null}
      </Header>
      <Content className="info-content" contentSmall={contentSmall}>
        {!!icon && (
          <Icon
            className="info-content-icon"
            name={icon}
            style={{
              color: iconColor,
            }}
            color={iconColor}
          />
        )}
        {!!iconComponent && iconComponent}
        <span className="info-content-value">{value}</span>
        <span
          className={classNames('info-content-label', {
            // color: labelColor || 'white',
          })}
          style={{
            color: labelColor,
          }}
        >
          {label}
        </span>
      </Content>
      {!!footer && (
        <Content className="info-content" style={{ marginTop: '-5px' }}>
          <span className="info-header-title">{footer}</span>
        </Content>
      )}
    </Container>
  );
}
