import { API } from 'aws-amplify';
import { ASSET_API } from '@src/api';
import { GeneratedEventsByAssetQueryParams } from './types';
import { AssetEventGetListResponseDto } from '@asset-manager/types';

export async function fetchAssetGeneratedEvents(
  tenantId: string,
  assetId: string,
  filters: GeneratedEventsByAssetQueryParams,
) {
  const qs = `?${new URLSearchParams(filters).toString()}`;
  return (await API.get(
    ASSET_API,
    `/tenant/${tenantId}/${assetId}/event${qs}`,
    {},
  )) as AssetEventGetListResponseDto;
}
