import Button from '@components/atoms/button';
import Modal, { Footer, ModalProp } from '@components/atoms/modal';
import { useCreateEvent } from '@src/api/event/use-create-event';
import { fontSize } from '@src/globalStyle';
import { device, size } from '@theme/breakpoints';
import {
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select as SelectAntd,
  Space,
  TimePicker,
} from 'antd';
import { addMinutes, formatISO } from 'date-fns';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import SelectStopCategory from '../modal-detail-stop/SelectStopCategory';
import {
  SingleOption,
  SingleOptionIcon,
} from '../modal-detail-stop/modal-detail-stop.style';
import { SearchOutlined } from '@ant-design/icons';
import { StyledSelectNewCategory } from '../modal-detail-stop/modal-detail-stop';
import Icon from '@components/atoms/icon';
import { darkTheme } from '@theme/dark';
import { optionsColor } from '../modal-detail-stop/modal-detail-stop.config';
import { useCreateEventSubType } from '@src/api/tenant/create-event-sub-type';
import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useTranslation } from 'react-i18next';

dayjs.extend(require('dayjs/plugin/utc'));

// @ts-ignore
const utcDayjs = dayjs.utc;
interface Prop extends ModalProp {}

const { Option } = SelectAntd;

const commonText = css`
  font-family: 'Satoshi-Variable';
  font-weight: 500;
  ${fontSize.s}
  color: #fff;
`;

const LabelCol = styled(Col)`
  ${commonText}
`;

const DurationFieldSuffix = styled.span`
  ${commonText}
  margin-left: 16px;
`;

export const StyledModal = styled(Modal)`
  .ant-modal-body {
    margin-top: 24px;
  }
  @media (max-width: ${size.tablet}) {
    .ant-modal {
      height: -webkit-fill-available;
      max-width: 100vw;
    }
    .ant-modal-content {
      height: 100vh;
      top: 0;
      overflow: auto;
      margin: -15px;
    }
    .ant-modal-centered::before {
      content: unset;
    }
    .ant-modal-footer {
      position: absolute;
      bottom: 12px;
      width: 90%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
  /* .ant-col {
    @media (max-width: 1023px) {
      max-width: unset;
    }
  } */
`;

const StyledDatePicker = styled(DatePicker)`
  width: 140px;

  @media ${device.laptop} {
    width: 302px;
  }
`;

const StyledInput = styled(Input)`
  width: 64px;
  :where(
      .ant-input-affix-wrapper:focus,
      .ant-input-affix-wrapper-focused,
      .ant-input:focus,
      .ant-input-focused
    ) {
    border-color: rgb(71, 134, 255) !important;
  }
`;

const NewCategory = styled.div`
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  row-gap: 8px;

  @media ${device.mobileL} {
    flex-direction: row;
  }
`;

const baseInitialValues = {
  startDate: utcDayjs(),
  startTime: dayjs(), // utcDayjs(),
  duration: 0,
  category: undefined,
};

// const darkTheme = true;
const isDarkTheme = true;
const formatTimePicker = 'HH:mm';

export default function ModalAddStop({ onOk, onCancel, ...props }: Prop) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const createEvent = useCreateEvent();
  const [initialValues, setInitialValues] = useState<any>(baseInitialValues);

  const [selectedCategory, setSelectedCategory] = useState<
    string | string[] | undefined
  >(undefined);

  const isNewCategory = selectedCategory === 'new_category';

  useEffect(() => {
    form.resetFields();
    const startTime = dayjs();
    form.setFieldValue('startTime', startTime);
    setInitialValues((prev: any) => ({ ...prev, startTime }));
  }, [form, props.open]);

  // TODO: types
  const onFinish = (values: any) => {
    console.log("Values: ", values);
    const startTime = `${formatISO(values.startDate.toDate()).split('T')[0]}T${
      formatISO(values.startTime.toDate()).split('T')[1]
    }`;

    const stop = {
      type: 'STOP',
      startTime: new Date(startTime).toISOString(),
      endTime: addMinutes(new Date(startTime), values.duration).toISOString(),
      description: '',
      subTypeInfoId: selectedCategory,
    };
    createEvent.mutate(stop, { onSuccess: onOk });
  };

  const [newCategoryColor, setNewCategoryColor] = useState<string>('HIGH');
  const [newCategoryName, setNewCategoryName] = useState<string>('');

  const createEventSubType = useCreateEventSubType();

  const [tenantId] = useRecoilState(selectedTenantIdState);

  const callbackCreateEventSubType = useCallback(() => {
    createEventSubType.mutate(
      {
        key: newCategoryName,
        name: newCategoryName,
        severity: newCategoryColor,
        tenantId: tenantId,
      },
      {
        onSuccess: () => {
          setSelectedCategory(newCategoryName);
        },
      },
    );
  }, [createEventSubType, newCategoryColor, newCategoryName, tenantId]);

  return (
    <Form
      name="add-stop"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      style={{ maxWidth: 600 }}
      initialValues={initialValues}
      form={form}
      onFinish={onFinish}
      autoComplete="off"
    >
      <StyledModal
        width={640}
        dark={isDarkTheme}
        title={t('stops.addStop')}
        onCancel={onCancel}
        footer={
          <Footer style={{ marginTop: 40 }}>
            <Button variant="outline-white" onClick={onCancel}>
              {t('general.cancel')}
            </Button>
            <Button form="add-stop" key="submit" htmlType="submit">
              {t('general.add')}
            </Button>
          </Footer>
        }
        {...props}
      >
        <Space size={16} direction="vertical" style={{ width: '100%' }}>
          <Row>
            <LabelCol xs={24} sm={9}>
              {t('general.date')}
            </LabelCol>
            <Col>
              <Form.Item
                id="startDate"
                name="startDate"
                initialValue={initialValues.startDate}
              >
                <StyledDatePicker
                  format="DD/MM/YYYY"
                  onChange={(date, dateString) =>
                    form.setFieldValue('startDate', date)
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <LabelCol xs={24} sm={9}>
              {t('general.startTime')}
            </LabelCol>
            <Col xs={24} sm={12}>
              {/* <Input defaultValue="08:00" /> */}
              <Form.Item id="startTime" name="startTime">
                <TimePicker
                  // defaultValue={dayjs('12:08', formatTimePicker)}
                  defaultValue={initialValues.startTime}
                  format={formatTimePicker}
                  style={{ width: 96 }}
                  onChange={(date, dateString) => {
                    form.setFieldValue('startTime', date);
                  }}
                  onPanelChange={(date, dateString) => {
                    form.setFieldValue('startTime', date);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <LabelCol xs={24} sm={9}>
              {t('general.duration')}
            </LabelCol>
            <Col xs={24} sm={12}>
              <Form.Item id="duration" name="duration">
                {/* TODO: change with InputNumber? */}
                <StyledInput
                  type="number"
                  min={0}
                  style={
                    {
                      // maxWidth: 'fit-content'
                    }
                  }
                  onChange={e =>
                    form.setFieldValue('duration', Number(e.target.value))
                  }
                />
                <DurationFieldSuffix>
                  {t('general.minutes')}
                </DurationFieldSuffix>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <LabelCol xs={24} sm={9}>
              {t('general.type')}
            </LabelCol>
            <Col xs={24} sm={12}>
              <Form.Item
                id="subtype"
                name="subtype"
                initialValue={initialValues.category}
              >
                <SelectStopCategory
                  withNewCategory
                  defaultValue={initialValues.category}
                  onChange={setSelectedCategory}
                />
              </Form.Item>
            </Col>
          </Row>
          {isNewCategory && (
            <Row>
              <LabelCol xs={24} sm={9}>
                {t('general.newCategory')}
              </LabelCol>
              <Col xs={24} sm={12}>
                <NewCategory>
                  <StyledInput
                    prefix={
                      <SearchOutlined
                        style={{
                          color: '#909090',
                        }}
                      />
                    }
                    size="large"
                    placeholder={t('general.nameCategory')}
                    style={{ minWidth: 180, flexShrink: 0 }}
                    onChange={e => setNewCategoryName(() => e.target.value)}
                  />
                  <StyledSelectNewCategory
                    size="large"
                    placeholder={undefined}
                    options={undefined}
                    defaultValue={newCategoryColor}
                    onChange={setNewCategoryColor}
                    suffixIcon={
                      <Icon
                        name="expand_more"
                        color={darkTheme ? '#fff' : '#1C1B1F'}
                        style={{ fontSize: 16 }}
                      />
                    }
                  >
                    {optionsColor.map((item, index) => (
                      <Option value={item.value} label={item.label} key={index}>
                        <SingleOption>
                          <SingleOptionIcon
                            name="radio_button_checked"
                            color={item.color}
                            aria-label={item.label}
                          />
                        </SingleOption>
                      </Option>
                    ))}
                  </StyledSelectNewCategory>
                  <Button onClick={callbackCreateEventSubType}>
                    {t('general.add')}
                  </Button>
                </NewCategory>
              </Col>
            </Row>
          )}
        </Space>
      </StyledModal>
    </Form>
  );
}
