import { useEffect, useState } from 'react';

/* 
  This hook is used to implement infinite scrolling
  It takes a callback function and a ref to the bottom of the list
  When the bottom element is visible, the callback function is called.
*/

const useInfiniteScrolling = (
  callback: () => Promise<unknown>,
  ref: React.RefObject<HTMLDivElement>,
) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const savedRef = ref.current;
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          setIsLoading(true);
          callback().then(() => {
            setIsLoading(false);
          });
        }
      },
      { root: null, rootMargin: '0px', threshold: 0.4 },
    );

    if (savedRef) {
      observer.observe(savedRef);
    }

    return () => {
      if (savedRef) {
        observer.unobserve(savedRef);
      }
    };
  }, [ref, callback]);

  return { isLoading };
};

export default useInfiniteScrolling;
