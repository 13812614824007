import { API } from 'aws-amplify';
import { ASSET_API } from '@src/api';

export async function postEventUpdate(
  tenantId?: string,
  assetId?: string,
  eventId?: string,
  body?: any,
) {
  if (tenantId && assetId) {
    return API.put(
      ASSET_API,
      `/tenant/${tenantId}/${assetId}/event/${eventId}`,
      {
        body,
      },
    );
  }

  return [];
}
