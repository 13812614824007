import {
  consumptionColorMapping,
  consumptionMapping,
} from '@src/utils/mapping.utils';
import {
  baseConfigAxis,
  baseConfigLegend,
  baseConfigSlider,
} from '../common-configs';
import { mixed_list2 } from './mock';
import {
  GetReportAssetPointResponseDto,
  GetReportAssetPointStatus,
} from '@asset-manager/types';

const data = mixed_list2;

const dataMapping = {
  data,
  xField: 'date',
  yField: 'value',
  seriesField: 'country',
  // seriesField: 'alue',
};

const plotComponents = (dark: boolean, units?: string) => ({
  yAxis: {
    ...baseConfigAxis,
    title: {
      text: units || '',
    },
  },
  legend: {
    ...baseConfigLegend(dark),
    position: 'top-left',
  },
  slider: { ...baseConfigSlider },
});

const graphicStyle = {
  // areaStyle: (a: any) => {

  //   // vedi i campi specificati in  xField * yField * seriesField
  //   return { ...a };
  // },
  areaStyle: {
    // fillOpacity: 1,
  },
  colorField: 'country', // or seriesField in some cases
  color: (data: GetReportAssetPointResponseDto) => {
    if (data.status.includes(GetReportAssetPointStatus.IDLE))
      return consumptionColorMapping[consumptionMapping.IDLE];
    if (data.status.includes(GetReportAssetPointStatus.ON))
      return consumptionColorMapping[consumptionMapping.WORK];
    // if (country === 'error') return '#E8684A';
    if (data.status.includes(GetReportAssetPointStatus.OFF))
      return consumptionColorMapping[consumptionMapping.STOP];
    return '#ececec';
  },
};

export { dataMapping, plotComponents, graphicStyle };
