import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { GET_ALERT_DETAILS_QUERY_KEY } from '@src/api';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { fetchConfig } from '@src/config/http';
import { fetchAlertDetails } from './fetch-alert-details';
import { currentAlertIdState } from '@store/alert/alert-filter-state';

export function useAlertDetails() {
  const [tenantId] = useRecoilState(selectedTenantIdState);
  const [alertId] = useRecoilState(currentAlertIdState);

  return useQuery(
    [GET_ALERT_DETAILS_QUERY_KEY, tenantId, alertId],
    () => fetchAlertDetails(tenantId, alertId),
    {
      ...(fetchConfig as any),
      enabled: !!tenantId && !!alertId,
    },
  );
}
