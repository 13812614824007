import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const PrecisionManufacturingSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={19}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 19"
    {...props}
  >
    <path
      d="M1.975 19v-3h3.1l-2.55-8.35a2.99 2.99 0 0 1-1.112-1.1C1.121 6.067.975 5.55.975 5c0-.833.292-1.542.875-2.125A2.893 2.893 0 0 1 3.975 2c.65 0 1.23.187 1.738.562.508.375.862.855 1.062 1.438h3.2V3c0-.283.096-.521.288-.713A.967.967 0 0 1 10.975 2c.15 0 .296.033.438.1a.963.963 0 0 1 .362.3l1.7-1.6c.15-.15.329-.246.537-.288a.932.932 0 0 1 .613.088l3.9 1.8c.2.1.338.246.413.437.075.192.07.38-.013.563-.1.2-.246.329-.437.387a.773.773 0 0 1-.563-.037l-3.6-1.65-2.35 2.2v1.4l2.35 2.15 3.6-1.65a.761.761 0 0 1 .575-.025c.2.067.342.192.425.375.1.2.108.392.025.575a.877.877 0 0 1-.425.425l-3.9 1.85c-.2.1-.404.129-.613.087a1.026 1.026 0 0 1-.537-.287l-1.7-1.6c-.1.1-.22.192-.362.275a.855.855 0 0 1-.438.125.965.965 0 0 1-.712-.288A.965.965 0 0 1 9.975 7V6h-3.2c-.05.133-.104.258-.162.375a2.528 2.528 0 0 1-.238.375l5 9.25h3.6v3h-13Zm2-13a.97.97 0 0 0 .713-.287A.97.97 0 0 0 4.975 5a.97.97 0 0 0-.287-.713A.97.97 0 0 0 3.975 4a.967.967 0 0 0-.712.287.968.968 0 0 0-.288.713c0 .283.096.521.288.713A.967.967 0 0 0 3.975 6Zm3.15 10h1.95l-4.3-8h-.1l2.45 8Z"
      fill={props.fill}
    />
  </svg>
);

const PrecisionManufacturingIcon = (
  props: Partial<CustomIconComponentProps>,
) => <Icon component={PrecisionManufacturingSvg} {...props} />;

export default PrecisionManufacturingIcon;
