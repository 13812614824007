import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useGetTenantById, useGetTenants } from '@src/api';

export function useTenant() {
  const [selectedTenantId, setSelectedTenantId] = useRecoilState(
    selectedTenantIdState,
  );
  const { data: tenants, isLoading: isLoadingTenants } = useGetTenants();
  const { data: tenant, isLoading: isTenantLoading } =
    useGetTenantById(selectedTenantId);

  useEffect(() => {
    if (!isLoadingTenants && tenants) {
      if (tenants?.count) {
        setSelectedTenantId(tenants.items[0].id);
      }
    }
  }, [tenants, isLoadingTenants, setSelectedTenantId]);

  return {
    selectedTenantId,
    tenant,
    tenants,
    isTenantLoading,
  };
}
