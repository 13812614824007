import { useMutation } from 'react-query';
import { UserUpdateUserRequestDto } from '@asset-manager/types';
import { postUserUpdate } from '@src/api/user/post-user-update';

export function useUpdateUser() {
  return useMutation({
    // TODO: remove description after be deploy
    mutationFn: (data: {
      tenantId: string;
      userId: string;
      req: UserUpdateUserRequestDto;
    }) => postUserUpdate(data.tenantId, data.userId, data.req),
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: [ASSET_REPORT_QUERY_KEY] });
    },
  });
}
