// noinspection CssInvalidPropertyValue

import { fontSize } from '@src/globalStyle';
import { darkTheme } from '@theme/dark';
import { lightTheme } from '@theme/light';
import { ConfigProvider, Tag as AntTag } from 'antd';
import styled, { css } from 'styled-components';
import Icon from '../icon';

// NB: for circle background in light theme
export const mapStatus = {
  success: '#2972FF',
  error: '#C5D9FF',
  warning: '#8BB2FF',
  na: '#C8C8C8',
  processing: '#4786FF',
  LOW: '#C5D9FF',
  MEDIUM: '#8BB2FF',
  HIGH: '#2972FF',
  PURPLE: '#8C54FF',
  BLUE: '#1890FF',
  WHITE: '#FFFFFF',
  BROWN: '#D9BFA5',
  PINK: '#FF85A5',
  GRAY: '#D9D9D9',
};

export const semaphoreMapStatus = {
  success: '#18CD4B',
  error: '#E8684A',
  warning: '#F6BD16',
};

export type typeStatus = keyof typeof mapStatus;

export interface Props {
  label?: string;
  status?: typeStatus;
  size?: 'label' | 'small';
  dark?: boolean;
  withBrighterForeground?: boolean;
  showBorder?: boolean;
  hideIcon?: boolean;
  backgroundColor?: string;
  personalizedTextColor?: string;
  chosenMapStatus?: any;
}

const StyledTag = styled(AntTag)<{
  size: Props['size'];
  color: Props['status'];
  withBrighterForeground: Props['withBrighterForeground'];
  showBorder: Props['showBorder'];
  backgroundColor: Props['backgroundColor'];
  personalizedTextColor: Props['personalizedTextColor'];
  chosenMapStatus: any;
}>`
  display: inline-flex;
  align-items: center;
  font-weight: 700;
  ${({ backgroundColor }) =>
    backgroundColor ? `background-color: ${backgroundColor};` : ''}
  ${({ personalizedTextColor }) =>
    personalizedTextColor ? `color: ${personalizedTextColor} !important;` : ''}
  ${({ color, withBrighterForeground, chosenMapStatus }) =>
    color && withBrighterForeground ? `color: ${chosenMapStatus[color]};` : ''}

  ${({ size }) =>
    (size === 'label' &&
      css`
        ${fontSize.label};
        text-transform: uppercase;
      `) ||
    (size === 'small' &&
      css`
        ${fontSize.s};
        line-height: 22px;
      `)}
  ${({ showBorder, color, chosenMapStatus }) =>
    showBorder &&
    color &&
    css`
      border: 1px solid ${chosenMapStatus[color]};
      border-radius: 96px;
    `}
`;

const Circle = styled.span`
  display: 'inline-block';
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
`;

export default function Tag({
  label,
  status,
  size = 'label',
  dark = false,
  withBrighterForeground,
  showBorder = false,
  hideIcon = false,
  backgroundColor,
  personalizedTextColor,
  chosenMapStatus = mapStatus,
}: Props) {
  return (
    <ConfigProvider theme={dark ? darkTheme : lightTheme}>
      <StyledTag
        size={size}
        icon={
          !hideIcon &&
          !!status && (
            <Circle
              style={{
                background: chosenMapStatus[status],
              }}
            />
          )
        }
        chosenMapStatus={chosenMapStatus}
        color={status}
        withBrighterForeground={withBrighterForeground}
        showBorder={showBorder}
        backgroundColor={backgroundColor}
        personalizedTextColor={personalizedTextColor}
      >
        {label}
      </StyledTag>
    </ConfigProvider>
  );
}
