import { API } from 'aws-amplify';
import { ALERT_API } from '@src/api';
import { AlertUpdateRuleRequestDto } from '@asset-manager/types';

export async function updateAlert(
  tenantId?: string,
  alertId?: string | null,
  body?: AlertUpdateRuleRequestDto,
) {
  if (tenantId && alertId && body) {
    return API.put(ALERT_API, `/tenant/${tenantId}/${alertId}`, {
      body,
    });
  }

  return [];
}
