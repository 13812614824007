import { HeathMapData } from '@src/routes/Report/Report';
import styled from 'styled-components';
import { HeatmapConfigType } from '../heatmap';
import { formatDateOnRangePicker } from '@src/utils';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { capitalizeFirstLetter } from '@src/utils/string.utils';
import {
  consumptionColorMapping,
  consumptionMapping,
} from '@src/utils/mapping.utils';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(timezone);
dayjs.extend(utc);

const TooltipCustom = styled.div`
  background-color: rgba(40, 40, 42, 1);
  padding: 8px;
  display: flex;
  flex-direction: column;
`;

const TooltipHeader = styled.span`
  font-family: 'Satoshi-Variable';
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #ffffff;
`;

const TooltipItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const TooltipLabel = styled.span<{ color?: string }>`
  font-family: 'Satoshi-Variable';
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;
  /* color: #7a7a7a; */
  color: ${props => props.color ?? '#7a7a7a'};
`;

const TooltipValue = styled.span<{ color?: string }>`
  /* color: #ffffff; */
  color: ${props => props.color ?? '#ffffff'};
`;

interface TooltipDataItem {
  name?: string;
  nameColor?: string;
  value?: string;
  percentage?: number;
}

const getTitle = (title: string, dateRange?: number) => {
  try {
    const date = new Date(title);
    if (dateRange !== undefined)
      return formatDateOnRangePicker(date.getTime(), dateRange);
  } catch (e) {
    return title;
  }
};

export const tooltip_domStyles = {
  'g2-tooltip': {
    padding: 0,
    boxShadow: '0px 0px 16px 4px rgba(0, 0, 0, 0.12)',
  },
  // 'g2-tooltip-title'?: CSSProperties;
  // 'g2-tooltip-list'?: CSSProperties;
  // 'g2-tooltip-list-item'?: CSSProperties;
  // 'g2-tooltip-marker'?: CSSProperties;
  // 'g2-tooltip-value'?: CSSProperties;
  // 'g2-tooltip-name'?: CSSProperties;
};

export default function ChartTooltip({
  title,
  data,
  type = 'Consumo',
  unitOfMeasure = '',
}: // color,
{
  title: string;
  data: TooltipDataItem[];
  color?: string;
  type?: string;
  unitOfMeasure?: string;
}) {
  const { t } = useTranslation();

  return (
    <TooltipCustom>
      <TooltipHeader>{title}</TooltipHeader>
      <div style={{ marginTop: 8 }}>
        <TooltipItem>
          <TooltipLabel>
            {t('general.totalConsumption')}:{' '}
            <TooltipValue>
              {data.reduce((p, c) => {
                return p + +(c.value ?? 0);
              }, 0)}{' '}
              {unitOfMeasure}
            </TooltipValue>
          </TooltipLabel>
        </TooltipItem>
      </div>
      {data.map((item: TooltipDataItem, index: number) => (
        <div key={index} style={{ marginTop: 8 }}>
          <TooltipItem>
            {item.name && (
              <>
                <TooltipLabel>
                  {t('general.state')}:{' '}
                  <TooltipValue color={item.nameColor}>
                    {item.name}
                  </TooltipValue>
                </TooltipLabel>
              </>
            )}
          </TooltipItem>
          <TooltipItem>
            <TooltipLabel>
              {item.value && (
                <>
                  {type}:{' '}
                  <TooltipValue>
                    {item.value} {unitOfMeasure}
                  </TooltipValue>
                  {item.percentage && (
                    <>
                      <br />
                      {t('general.percentage')}:{' '}
                      <TooltipValue>
                        {item.percentage?.toFixed(2)} %
                      </TooltipValue>
                    </>
                  )}
                </>
              )}
            </TooltipLabel>
          </TooltipItem>
        </div>
      ))}
    </TooltipCustom>
  );
}

export function TooltipHeatmap({
  title,
  data,
  configType,
}: {
  title: any;
  data: any;
  configType?: HeatmapConfigType;
}) {
  const { t } = useTranslation();

  const dataMap = useMemo(
    () =>
      data.map((item: { data: HeathMapData }, index: number) => {
        return (
          <>
            {configType === HeatmapConfigType.SQUARE && (
              <TooltipHeader>
                {dayjs(item.data.TimeDate)
                  .utc()
                  .tz('Europe/Rome')
                  .format('DD/MM/YYYY - HH:mm')}
              </TooltipHeader>
            )}
            <div style={{ marginTop: 8 }}>
              <TooltipItem>
                <TooltipLabel>
                  {t('general.totalConsumption')}:{' '}
                  <TooltipValue>
                    {item.data.Tooltips.reduce((p, c) => {
                      return p + +(c.consumption ?? 0);
                    }, 0).toFixed(2)}{' '}
                    kWh
                  </TooltipValue>
                </TooltipLabel>
              </TooltipItem>
            </div>
            {item.data &&
            item.data.Tooltips &&
            item.data.Tooltips.length > 0 ? (
              <div key={index}>
                {item.data.Tooltips.every(tooltip => tooltip.value === '0') ? (
                  <TooltipItem key={index}>
                    <TooltipLabel color={'#ECECEC'}>{'No Data'}</TooltipLabel>
                  </TooltipItem>
                ) : (
                  item.data.Tooltips.filter(r => (r.value as number) > 0)
                    .map(i => ({
                      ...i,
                      value: parseFloat(String(i.value)) || 0,
                    }))
                    .sort((a, b) => b.value - a.value)
                    .map((tooltip, index: number) => (
                      <div key={index} style={{ marginTop: 8 }}>
                        <TooltipItem key={index}>
                          <TooltipLabel>
                            {t('general.state')}:{' '}
                            <TooltipValue
                              color={
                                consumptionColorMapping[
                                  tooltip.prop.toLowerCase() as consumptionMapping
                                ]
                              }
                            >
                              {capitalizeFirstLetter(
                                t(tooltip.prop.toLowerCase()),
                              )}{' '}
                            </TooltipValue>
                          </TooltipLabel>
                        </TooltipItem>
                        <TooltipItem>
                          <TooltipLabel>
                            {t('general.consumption')}:{' '}
                            <TooltipValue>
                              {tooltip.consumption?.toFixed(2)} kWh
                            </TooltipValue>
                            <br />
                            {t('general.percentage')}:{' '}
                            <TooltipValue>
                              {tooltip.value?.toFixed(2)} %
                            </TooltipValue>
                          </TooltipLabel>
                        </TooltipItem>
                      </div>
                    ))
                )}
              </div>
            ) : null}
          </>
        );
      }),
    [configType, data, t],
  );

  return (
    <TooltipCustom>
      {configType === HeatmapConfigType.BAR && (
        <TooltipHeader>{title}</TooltipHeader>
      )}
      {dataMap}
    </TooltipCustom>
  );
}

export function LineTooltip({
  title,
  data,
  dateRange,
  tooltipLabel,
  unitOfMeasure,
  tooltipColor,
}: // color,
{
  title: string;
  data: TooltipDataItem[];
  color?: string;
  dateRange: number;
  tooltipLabel?: string;
  tooltipColor?: string;
  unitOfMeasure?: string;
}) {
  //getTitle(title, dateRange);
  return (
    <TooltipCustom>
      <TooltipHeader>{title}</TooltipHeader>
      {data.map((item: TooltipDataItem, index: number) => {
        const label =
          tooltipLabel ?? (item.value === '1' ? 'Evento' : 'Eventi');

        return (
          <div key={index} style={{ marginTop: 8 }}>
            <TooltipItem>
              <TooltipLabel {...(tooltipColor ? { color: tooltipColor } : {})}>
                {`${label}: `}
                <TooltipValue>{`${item.value} ${
                  unitOfMeasure || ''
                }`}</TooltipValue>
              </TooltipLabel>
            </TooltipItem>
          </div>
        );
      })}
    </TooltipCustom>
  );
}

export function TooltipDual({
  title,
  data,
  dateRange,
}: {
  title: any;
  data: any;
  dateRange: number;
}) {
  const header = getTitle(title, dateRange);

  const dataMap = useMemo(
    () =>
      data.map((item: any, index: number) => {
        const labelUpTime = 'Uptime';
        const labelConsumption = 'Consumo';
        let itemFormatted = {
          name: '',
          value: '',
        };
        if (item.name === 'value') {
          itemFormatted = {
            name: labelUpTime,
            value: item.value + ' %',
          };
        }
        if (item.name === 'count') {
          itemFormatted = {
            name: labelConsumption,
            value: item.value + ' kWh',
          };
        }
        return (
          <div key={index} style={{ marginTop: 8 }}>
            <TooltipItem key={index}>
              <TooltipLabel color={item.color}>
                {itemFormatted.name}
                <TooltipValue> {itemFormatted.value}</TooltipValue>
              </TooltipLabel>
            </TooltipItem>
          </div>
        );
      }),
    [data],
  );

  return (
    <TooltipCustom>
      <TooltipHeader>{header}</TooltipHeader>
      {dataMap}
    </TooltipCustom>
  );
}
