import Button from '@components/atoms/button';
import { Footer, ModalProp } from '@components/atoms/modal';
import Select from '@components/atoms/select';
import { useUpdateEvent } from '@src/api/event/use-update-event';
import { durationToLang } from '@src/utils';
import { Col, Input, Row, Select as SelectAntd } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { SearchOutlined } from '@ant-design/icons';

import { optionsColor } from './modal-detail-stop.config';
import {
  Item,
  NewCategory,
  SingleOption,
  SingleOptionIcon,
  StyledModal,
  StyledSpaceRight,
  Title,
  TitleField,
  Value,
} from './modal-detail-stop.style';
import SelectStopCategory from './SelectStopCategory';
import { useCreateEventSubType } from '@src/api/tenant/create-event-sub-type';
import styled from 'styled-components';
import Icon from '@components/atoms/icon';
import { useTranslation } from 'react-i18next';

interface Prop extends ModalProp {
  tenantId: string;
}

const { Option } = SelectAntd;
const darkTheme = true;

const StyledInput = styled(Input)`
  font-size: 14px;
  width: 11.4rem;
  max-width: 100%;
  height: 44px;

  // under 675px width 100%
  @media (max-width: 675px) {
    width: 100%;
  }
`;

export const StyledSelectNewCategory = styled(Select)`
  :where(.css-dev-only-do-not-override-19oi6yr).ant-select-single.ant-select-lg
    .ant-select-selector {
    align-items: flex-end;
  }
`;

export default function ModalDetailStop({ data, ...props }: Prop) {
  const { t } = useTranslation()
  const updateEvent = useUpdateEvent();

  const [selectedCategory, setSelectedCategory] = useState<
    string | string[] | undefined
  >(data?.item?.subTypeInfoId);

  const [note, set_note] = useState<string>(data.item?.description);

  const isNewCategory = useMemo(
    () => selectedCategory === 'new_category',
    [selectedCategory],
  );

  useEffect(() => {
    setSelectedCategory(() => data.item?.subTypeInfoId);
    set_note(() => data.item?.description);
  }, [data.item, props.open]);

  useLayoutEffect(() => {
    setSelectedCategory(() => data.item?.subTypeInfoId);
    set_note(() => data.item?.description);
  }, [data.item, props.open]);

  const updateStop = useCallback(() => {
    updateEvent.mutate(
      {
        assetId: data.item?.assetId,
        tenantId: props.tenantId,
        body: {
          description: note,
          subTypeInfoId: selectedCategory,
        },
        eventId: data.item?.id,
      },
      { onSuccess: props.onOk },
    );
  }, [
    data.item?.assetId,
    data.item?.id,
    note,
    props,
    selectedCategory,
    updateEvent,
  ]);

  const [newCategoryColor, setNewCategoryColor] = useState<string>('HIGH');
  const [newCategoryName, setNewCategoryName] = useState<string>('');

  const createEventSubType = useCreateEventSubType();

  const callbackCreateEventSubType = useCallback(() => {
    createEventSubType.mutate(
      {
        key: newCategoryName,
        name: newCategoryName,
        severity: newCategoryColor,
        tenantId: props.tenantId,
      },
      {
        onSuccess: () => {
          setSelectedCategory(newCategoryName);
        },
      },
    );
  }, [
    createEventSubType,
    newCategoryColor,
    newCategoryName,
    props.tenantId, // do not change
  ]);

  return (
    <StyledModal
      mobile={window.innerWidth < 576}
      width={640}
      dark={darkTheme}
      title={t('stops.stopDetails')}
      footer={
        <Footer>
          <Button variant="outline-white" onClick={props.onCancel}>
            {t('general.cancel')}
          </Button>
          <Button onClick={updateStop}>{t('general.update')}</Button>
        </Footer>
      }
      {...props}
    >
      <Row gutter={[16, 16]}>
        <Col xs={18} sm={9}>
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={24}>
              <Item>
                <Title>{t('assets.asset')}</Title>
                <Value>{data.item?.assetName}</Value>
              </Item>
            </Col>

            <Col xs={12} sm={24}>
              <Item>
                <Title>{t('general.date')}</Title>
                <Value>{dayjs(data.item?.from).format('DD/MM/YYYY')}</Value>
              </Item>
            </Col>

            <Col xs={12} sm={24}>
              <Item>
                <Title>{t('general.hour')}</Title>
                <Value>{dayjs(data.item?.from).format('HH:mm')}</Value>
              </Item>
            </Col>

            <Col xs={12} sm={24}>
              <Item>
                <Title>{t('general.duration')}</Title>
                <Value>
                  {durationToLang(
                    new Date(data.item?.to).getTime() -
                    new Date(data.item?.from).getTime(),
                  )}
                </Value>
              </Item>
            </Col>
          </Row>
        </Col>
        <Col xs={30} sm={15} style={{ flexGrow: 1 }}>
          <StyledSpaceRight
            style={{ width: '100%' }}
            id={`id${isNewCategory ? '' : '-not'}-new-category`}
          >
            <SelectStopCategory
              withNewCategory
              className="cssid-modal-detail-stop-select"
              defaultValue={selectedCategory}
              // @ts-ignore
              onChange={setSelectedCategory}
            />
            {isNewCategory && (
              <NewCategory size={8} id="id-modal-detail-stop-new-category">
                <StyledInput
                  prefix={
                    <SearchOutlined
                      style={{
                        color: '#909090',
                      }}
                    />
                  }
                  size="large"
                  placeholder={t('general.nameCategory')}
                  width={'100%'}
                  onChange={e => setNewCategoryName(() => e.target.value)}
                />
                <StyledSelectNewCategory
                  size="large"
                  placeholder={undefined}
                  options={undefined}
                  defaultValue={newCategoryColor}
                  onChange={setNewCategoryColor}
                  suffixIcon={
                    <Icon
                      name="expand_more"
                      color={darkTheme ? '#fff' : '#1C1B1F'}
                      style={{ fontSize: 16 }}
                    />
                  }
                >
                  {optionsColor.map((item, index) => (
                    <Option value={item.value} label={item.label} key={index}>
                      <SingleOption>
                        <SingleOptionIcon
                          name="radio_button_checked"
                          color={item.color}
                          aria-label={item.label}
                        />
                      </SingleOption>
                    </Option>
                  ))}
                </StyledSelectNewCategory>
                <Button onClick={callbackCreateEventSubType}>{t('general.add')}</Button>
              </NewCategory>
            )}
            {!isNewCategory && (
              <TitleField id="id-modal-detail-stop-title">{t('general.notes')}</TitleField>
            )}
            <TextArea
              id="id-modal-detail-stop-textarea"
              style={{ height: 120 }}
              placeholder={t('general.writeNotes')}
              onChange={e => set_note(e.target.value)}
              value={note}
            />
          </StyledSpaceRight>
        </Col>
      </Row>
    </StyledModal>
  );
}
