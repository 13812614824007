import { OverviewListItem } from '@components/organisms/overview';
import { t } from 'i18next';

export const buildOverviewList = (
  sensorRssi?: number,
  battery?: number,
): OverviewListItem[] => {
  type Classification = {
    label: string;
    color: string;
    icon?: string;
  };

  function classifyNumber(num?: number): Classification {
    if (!num || num >= 0 || isNaN(num)) {
      return { label: t('sensor.notConnected'), color: 'gray' };
    } else if (num >= -30) {
      return { label: t('sensor.optimal'), color: 'green' };
    } else if (num >= -110) {
      return { label: t('sensor.good'), color: 'green' };
    } else if (num >= -130) {
      return { label: t('sensor.weak'), color: 'red' };
    } else {
      return { label: t('sensor.rare'), color: '#A20000' };
    }
  }

  function classifyBatteryLevel(num?: number): Classification {
    if (!num || isNaN(num)) {
      return {
        label: t('general.notAvailable'),
        icon: 'battery_horiz_000',
        color: 'gray',
      };
    }
    if (num >= 0 && num <= 10) {
      return {
        label: `${battery}%`,
        icon: 'badge_critical_battery',
        color: 'red',
      };
    }
    if (num > 10 && num <= 30) {
      return {
        label: `${battery}%`,
        icon: 'battery_very_low',
        color: 'red',
      };
    }
    if (num > 30 && num <= 50) {
      return { label: `${battery}%`, icon: 'battery_low', color: 'orange' };
    }
    if (num > 50 && num <= 70) {
      return {
        label: `${battery}%`,
        icon: 'battery_horiz_050',
        color: 'green',
      };
    }
    if (num > 70 && num <= 90) {
      return {
        label: `${battery}%`,
        icon: 'battery_horiz_075',
        color: 'green',
      };
    }
    if (num > 90 && num <= 100) {
      return { label: `${battery}%`, icon: 'battery_full_alt', color: 'green' };
    }

    return {
      label: t('general.notAvailable'),
      icon: 'battery_horiz_000',
      color: 'gray',
    };
  }

  const classification = classifyNumber(sensorRssi);
  const batteryClassification = classifyBatteryLevel(battery);
  return [
    {
      title: t('sensor.estimatedAutonomy'),
      info: t('sensor.estimatedBatteryLevel'),
      icon: batteryClassification.icon as string,
      iconColor: batteryClassification.color,
      value:
        batteryClassification.label === 'Non disponibile'
          ? ''
          : battery
          ? battery.toString()
          : batteryClassification.label,
      label:
        batteryClassification.label === 'Non disponibile'
          ? battery
            ? battery.toString()
            : batteryClassification.label
          : battery
          ? '%'
          : '',
    },
    {
      title: t('sensor.signal'),
      info: t('sensor.signalQuality'),
      icon: 'podcasts',
      iconColor: classification.color,
      value: '',
      label: classification.label,
    },
  ];
};
