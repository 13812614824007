import { API } from 'aws-amplify';
import { ALERT_API } from '@src/api';
import { AlertQueryParams, AlertResponse } from './types';
import { createSearchParams } from 'react-router-dom';

export async function fetchAssetAlerts(
  tenantId: string,
  filters: AlertQueryParams,
  numPagedAlreadyFetched?: number,
) {
  const newFilters = { ...filters };
  if (numPagedAlreadyFetched) {
    newFilters.skip = `${numPagedAlreadyFetched * Number(filters.limit)}`;
  }

  const params = createSearchParams(newFilters);

  const qs = newFilters ? `?${params.toString()}` : '';
  return (await API.get(
    ALERT_API,
    `/tenant/${tenantId}${qs}`,
    {},
  )) as AlertResponse;
}
