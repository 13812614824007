import { queryClient } from '@src/App';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useMutation } from 'react-query';
import { useRecoilState } from 'recoil';
import {
  GET_ASSET_ALERTS_QUERY_KEY,
  GET_ASSET_ALERTS_TABLE_QUERY_KEY,
} from '../consts';
import { createAlert } from './create-alert';
import { AlertUpdateRuleRequestDto } from '@asset-manager/types';
import analytics from '@src/utils/analytics';
import { useTenant } from '@src/modules/asset';
import { useAmplify } from '@src/modules/amplify';
import { format } from 'date-fns';

export function useCreateAlert() {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const { tenant } = useTenant();
  const { user } = useAmplify();

  return useMutation({
    mutationFn: (data: { body: AlertUpdateRuleRequestDto }) =>
      createAlert(selectedTenantId, data.body),
    onSettled: () => {
      queryClient.invalidateQueries(GET_ASSET_ALERTS_QUERY_KEY);
      queryClient.invalidateQueries(GET_ASSET_ALERTS_TABLE_QUERY_KEY);
    },
    onSuccess: () => {
      analytics.logEvent('create_alert', {
        touchpoint: 'Astra web',
        tenant: tenant?.name,
        tenantId: tenant?.id,
        userId: user?.username,
        time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      });
    },
  });
}
