import { queryClient } from '@src/App';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useMutation, UseMutationOptions } from 'react-query';
import { useRecoilState } from 'recoil';
import {
  GET_ALERT_DETAILS_QUERY_KEY,
  GET_ASSET_ALERTS_QUERY_KEY,
  GET_ASSET_ALERTS_TABLE_QUERY_KEY,
} from '../consts';
import { updateAlert } from './update-alert';
import { currentAlertIdState } from '@store/alert/alert-filter-state';
import { AlertUpdateRuleRequestDto } from '@asset-manager/types';

export function useUpdateAlert(
  params?: UseMutationOptions<any, any, any, any>,
) {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [alertId] = useRecoilState(currentAlertIdState);

  return useMutation({
    mutationFn: (data: { body: AlertUpdateRuleRequestDto }) =>
      updateAlert(selectedTenantId, alertId, data.body),
    onSettled: () => {
      queryClient.invalidateQueries(GET_ASSET_ALERTS_QUERY_KEY);
      queryClient.invalidateQueries(GET_ALERT_DETAILS_QUERY_KEY);
      queryClient.invalidateQueries(GET_ASSET_ALERTS_TABLE_QUERY_KEY);
    },
    ...params,
  });
}
