import { useUsers } from '@src/api/user/use-users';
import { useWatch } from 'antd/es/form/Form';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import {
  datePeriodSelect,
  daysOptionsDefault,
  FormFields,
  timePeriodSelect,
} from './modal-add-alert.types';
import {
  CheckboxGroup,
  LabelCol,
  MultipleSelect,
  OperandsSelect,
  StyledCheckbox,
  StyledRow,
  StyledSingleCheckbox,
} from './modal-add-alert.components';
import { Form } from 'antd';
import RangePicker from '@components/atoms/range-picker';
import Intervals from './modal-add-alert-intervals';
import { FormInstance } from 'antd/lib/form/Form';
import { useScreenType } from '@hooks';
import { useTranslation } from 'react-i18next';

const EditableFields = ({
  form,
  initialValues,
}: {
  form: FormInstance<FormFields>;
  initialValues: Partial<FormFields>;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenType();

  const datePeriodSelectValue = useWatch('datePeriodSelect', form);
  const timePeriodSelectValue = useWatch('timePeriodSelect', form);
  const datePeriodValue = useWatch('datePeriod', form);

  const [daysOptions, setDaysOptions] = useState(daysOptionsDefault());

  const sendEmail = useWatch('sendEmail', form);
  const { data: users } = useUsers();

  const disableUnreachableDays = useCallback(() => {
    if (datePeriodSelectValue === 'any' || !datePeriodValue) {
      return setDaysOptions(daysOptionsDefault());
    }

    const daysSpan = dayjs(datePeriodValue[1]).diff(
      dayjs(datePeriodValue[0]),
      'day',
    );

    if (daysSpan >= 6) {
      return setDaysOptions(daysOptionsDefault());
    }

    const startDay = dayjs(datePeriodValue[0]).day();
    const endDay = dayjs(datePeriodValue[1]).day();

    const daysOptionsMapped = daysOptionsDefault().map(option => {
      const excludeDayCondition =
        endDay >= startDay
          ? option.value < startDay || option.value > endDay
          : option.value < startDay && option.value > endDay;

      if (excludeDayCondition) {
        form.setFieldValue(
          'days',
          form
            .getFieldValue('days')
            ?.filter((day: number) => day !== option.value),
        );
        return {
          ...option,
          disabled: true,
        };
      }
      return option;
    });

    setDaysOptions(daysOptionsMapped);
  }, [datePeriodSelectValue, datePeriodValue, form]);

  useEffect(() => {
    disableUnreachableDays();
  }, [disableUnreachableDays]);

  return (
    <>
      <StyledRow isMobile={isMobile} paddingTop>
        <Form.Item name="datePeriodSelect">
          <OperandsSelect
            size="large"
            popupClassName={'custom-dropdown'}
            options={datePeriodSelect(t)}
            onSelect={value => {
              if (value === 'any') {
                form.setFieldValue('datePeriod', null);
              }
            }}
          />
        </Form.Item>
        {datePeriodSelectValue === 'during' && (
          <Form.Item
            name="datePeriod"
            rules={[
              {
                required: true,
                message: t('modal.selectPeriod'),
              },
            ]}
            getValueFromEvent={e => {
              if (!e) {
                return null;
              }
              return e.map((date: Dayjs) => date.format('YYYY-MM-DD'));
            }}
            getValueProps={i => i}
          >
            <RangePicker
              disabledDate={current =>
                current && current < dayjs().startOf('day')
              }
              size="large"
              defaultValue={initialValues.datePeriod || null}
            />
          </Form.Item>
        )}
      </StyledRow>

      <StyledRow isMobile={isMobile} paddingTop>
        <Form.Item name="timePeriodSelect">
          <OperandsSelect
            size="large"
            options={timePeriodSelect()}
            popupClassName={'custom-dropdown'}
          />
        </Form.Item>
        {timePeriodSelectValue === 'interval' && (
          <Intervals form={form} initialValues={initialValues} />
        )}
      </StyledRow>

      <StyledRow isMobile={isMobile} style={{ paddingBlock: 10 }}>
        <LabelCol>{t('modal.rangeDays')}</LabelCol>
        <Form.Item
          name="days"
          rules={[
            {
              required: true,
              message: t('modal.selectAtLeastOneDay'),
            },
          ]}
        >
          <CheckboxGroup>
            {daysOptions.map(option => {
              return (
                <StyledCheckbox
                  value={option.value}
                  key={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </StyledCheckbox>
              );
            })}
          </CheckboxGroup>
        </Form.Item>
      </StyledRow>

      <StyledRow isMobile={isMobile}>
        <Form.Item name="sendEmail" valuePropName="checked">
          <StyledSingleCheckbox
            style={{
              marginTop: 10,
            }}
          >
            {t('modal.notifyEmail')}
          </StyledSingleCheckbox>
        </Form.Item>

        {sendEmail && (
          <Form.Item
            name="sendEmailTo"
            rules={[
              {
                required: true,
                message: t('modal.selectAtLeastOneEmail'),
              },
            ]}
          >
            <MultipleSelect
              placeholder={t('modal.selectEmail')}
              options={(users || []).map(user => ({
                label: user.email,
                value: user.email,
              }))}
            />
          </Form.Item>
        )}
      </StyledRow>
    </>
  );
};

export default EditableFields;
