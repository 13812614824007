import { API } from 'aws-amplify';
import { ALERT_API } from '@src/api';
import { GeneratedEventsQueryParams } from './types';
import { AssetEventGetListResponseDto } from '@asset-manager/types';

export async function fetchGeneratedEvents(
  tenantId: string,
  filters: GeneratedEventsQueryParams,
) {
  const params = new URLSearchParams();

  if (filters.alertIds) params.append('alertIds', filters.alertIds.join(','));
  if (filters.limit) params.append('limit', filters.limit);
  if (filters.skip) params.append('skip', filters.skip);
  if (filters.text) params.append('text', filters.text);
  if (filters.order) params.append('order', filters.order);
  if (filters.sort) params.append('sort', filters.sort);
  if (filters.endDate) params.append('endDate', filters.endDate);
  if (filters.startDate) params.append('startDate', filters.startDate);
  if (filters.severities) params.append('severities', filters.severities.join(','));
  if (filters.assetIds) params.append('assetIds', filters.assetIds.join(','));
  if (filters.plantIds) params.append('plantIds', filters.plantIds.join(','));
  if (filters.lineIds) params.append('lineIds', filters.lineIds.join(','));
  if (filters.departmentIds) params.append('departmentIds', filters.departmentIds.join(','));

  return (await API.get(
    ALERT_API,
    `/tenant/${tenantId}/events?${params.toString()}`,
    {},
  )) as AssetEventGetListResponseDto;
}
