import Button from '@components/atoms/button';
import Icon from '@components/atoms/icon';
import Header from '@components/organisms/header';
import AddAlertModal from '@components/organisms/modals/modal-add-alert/modal-add-alert';
import { lightTheme } from '@theme/light';
import { ConfigProvider } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import AlertsSetTab from './alerts-set-tab';
import { StyledTabs } from './alerts.components';
import GeneratedEventsTab from './generated-events-tab';
import { useTranslation } from 'react-i18next';
import { UnitsEnum } from '@asset-manager/types';
import { ruleStatusValues } from '@components/organisms/modals/modal-add-alert/modal-add-alert.types';

export const mapDbUnitsToRuleConditionValueUnit: {
  [key in UnitsEnum]: string;
} = {
  [UnitsEnum.KWATT_HOUR]: ruleStatusValues.CONSUMPTION,
  [UnitsEnum.TEMP_CELSIUS]: ruleStatusValues.TEMPERATURE,
  [UnitsEnum.MILLIMETERS_PER_SECOND]: ruleStatusValues.VIBRATION,
  [UnitsEnum.TEMP_FAHRENHEIT]: ruleStatusValues.TEMPERATURE,
};

const Body = styled.div``;

export default function Alerts() {
  const [newAlertModalOpen, setNewAlertModalOpen] = useState(false);

  const { t } = useTranslation();

  const isTablet = window.innerWidth < 768;

  return (
    <ConfigProvider theme={lightTheme}>
      <div>
        <Header
          title={t('alerts.alerts')}
          extra={
            <Button
              centerIcon
              icon={<Icon name="emergency_home" color="currentcolor" />}
              onClick={() => setNewAlertModalOpen(true)}
            >
              {!isTablet && t('alerts.newRule')}
            </Button>
          }
        />
        <Body>
          <StyledTabs
            tabBarGutter={8}
            defaultActiveKey="0"
            items={[
              {
                key: '0',
                label: t('alerts.storicalAlerts'),
                children: <GeneratedEventsTab />,
              },
              {
                key: '1',
                label: t('general.rules'),
                children: (
                  <AlertsSetTab
                    setNewAlertModalOpen={setNewAlertModalOpen}
                    isRule
                  />
                ),
              },
            ]}
          />
        </Body>
        {newAlertModalOpen && (
          <AddAlertModal
            open={newAlertModalOpen}
            onCancel={() => {
              setNewAlertModalOpen(false);
            }}
          />
        )}
      </div>
    </ConfigProvider>
  );
}
