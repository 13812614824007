import { AssetGetAssetResponseDto } from '@asset-manager/common/dist/asset';
import { useGetAssets } from '@src/api';
import { assetFilterPlant } from '@store/asset/assetFilterPlantOptions';
import { assetsFilterLineState } from '@store/asset/assetsFiltersLineState';
import { assetsState } from '@store/asset/assetsState';
import { selectedAssetIdState } from '@store/asset/selectedAssetIdState';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { t } from 'i18next';
import { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';

export function useAssets() {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [assets, setAssets] = useRecoilState(assetsState);
  const [selectedAssetId, setSelectedAssetId] =
    useRecoilState(selectedAssetIdState);
  const [assetFiltersLineState, setAssetsFiltersLineState] = useRecoilState(
    assetsFilterLineState,
  );

  const [assetFilters] = useRecoilState(assetFilterPlant);

  const {
    data: assetsData,
    isLoading: isLoadingAssets,
    isFetching: isFetchingAssets,
    isError: isErrorAssets,
    // refetch,
  } = useGetAssets(selectedTenantId, assetFilters.name);

  // useEffect(() => {
  //   refetch().catch(e => console.error(e));
  // }, [assetFilters]);

  useEffect(() => {
    if (!isLoadingAssets) {
      setAssets(assetsData?.items || []);
      const filters = assetsData?.filters;
      const newAssetFilters = [
        {
          label: t('assets.asset'),
          value: 'assets',
          children: filters?.assets?.map((asset: any) => ({
            label: asset.name,
            value: asset.id,
          })),
        },
        {
          label: t('general.department'),
          value: 'department',
          children: filters?.departments?.map((dep: any) => ({
            label: dep.name,
            value: dep.id,
          })),
        },
        {
          label: t('general.line'),
          value: 'lines',
          children: filters?.lines?.map((r: any) => ({
            label: r.name,
            value: r.id,
          })),
        },
        {
          label: t('general.establishment'),
          value: 'plants',
          children: filters?.plants?.map((r: any) => ({
            label: r.name,
            value: r.id,
          })),
        },
        {
          label: t('general.type'),
          value: 'type',
          children: [
            {
              label: t('general.machine'),
              value: 'machine',
            },
            {
              label: t('general.monitron'),
              value: 'monitron',
            },
          ],
        },
      ];
      if (
        JSON.stringify(newAssetFilters) !==
        JSON.stringify(assetFiltersLineState)
      )
        setAssetsFiltersLineState(newAssetFilters);
    }
  }, [
    assetFiltersLineState,
    assetsData,
    isLoadingAssets,
    setAssets,
    setAssetsFiltersLineState,
  ]);

  const selectedAsset = useMemo(() => {
    if (selectedAssetId) {
      return assets.find(
        (i: AssetGetAssetResponseDto) => i.id === selectedAssetId,
      );
    }

    return null;
  }, [assets, selectedAssetId]);

  const { data: assetsNoFilter } = useGetAssets(selectedTenantId, '', true);

  return {
    assets,
    assetsNoFilter: (assetsNoFilter?.items || []) as AssetGetAssetResponseDto[],
    selectedAsset,
    count: assetsData?.count,
    setSelectedAssetId,
    filters: assetsData?.filters,
    isErrorAssets,
    isLoadingAssets,
    isFetchingAssets,
  };
}
