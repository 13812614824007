import { Form, Input } from 'antd';
import AuthPage from '../components/AuthPage';
import {
  ButtonSubmit,
  Section,
  StyledForm,
  Subtitle,
  Title,
} from '../components/common';
import { useAmplify } from '../hooks';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { NotificationContext } from '@src/App';

export default function ResetPasswordPage() {
  const { forgotPasswordSubmit } = useAmplify();

  const [form] = Form.useForm<{ email: string }>();

  const { toast } = useContext(NotificationContext);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const {
    state: { email },
  } = useLocation();

  const onFinish = (fields: any) => {
    setLoading(true);
    forgotPasswordSubmit(email, fields.code, fields.password)
      .then(() => {
        toast?.success({
          message: 'Password reimpostata con successo',
          placement: 'topRight',
        });
        navigate('/sign-in', { replace: true });
      })
      .catch(err => {
        console.error(err);
        toast?.error({
          message: 'Non è stato possibile reimpostare la password',
          placement: 'topRight',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!email) {
    return <Navigate to="/sign-in" />;
  }

  return (
    <AuthPage>
      <Section>
        <Title>Controlla la tua mail </Title>
        <Subtitle>
          Abbiamo mandato un codice di verifica all'indirizzo email che ci hai
          fornito. Inseriscilo qui sotto per reimpostare la tua password.
        </Subtitle>
        <StyledForm
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            label="Codice di verifica"
            name="code"
            rules={[
              {
                required: true,
                message: 'inserisci il codice ricevuto via mail',
              },
            ]}
          >
            <Input placeholder="Codice di verifica" />
          </Form.Item>
          <Form.Item
            label="Nuova password"
            name="password"
            rules={[
              {
                required: true,
                message: 'Inserisci una nuova password',
              },
            ]}
          >
            <Input.Password placeholder="Nuova password" />
          </Form.Item>
          <Form.Item
            label="Conferma password"
            name="confirm-password"
            rules={[
              {
                required: true,
                message: 'Inserisci di nuovo la  password',
              },
              {
                validator: async (_, value) => {
                  if (value !== form.getFieldValue('password')) {
                    return Promise.reject(
                      new Error('Le due password non coincidono'),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password placeholder="Conferma password" />
          </Form.Item>

          <Form.Item style={{ width: '100%' }}>
            <ButtonSubmit
              dark
              variant="primary"
              htmlType="submit"
              disabled={loading}
            >
              Invia
            </ButtonSubmit>
          </Form.Item>
        </StyledForm>
      </Section>
    </AuthPage>
  );
}
