import { Pie, PieConfig } from '@ant-design/plots';
import { t } from 'i18next';
import { useMemo } from 'react';

interface Props extends Partial<PieConfig> {}

const PieChart = ({ ...props }: Props) => {
  const config = useMemo(
    () => ({
      appendPadding: 10,
      angleField: 'value',
      colorField: 'type',
      color: ({ type }: any) => {
        if (type === t('report.healthy')) {
          return '#5cdb7b';
        }
        return '#ff9b9b';
      },
      radius: 1,
      innerRadius: 0.6,
      label: {
        content: '{value}% ',
        style: {
          opacity: 0.6,
          fontSize: 11,
        },
      },
      interactions: [
        {
          type: 'element-selected',
        },
        {
          type: 'element-active',
        },
      ],
      statistic: {
        title: false,
        content: {
          style: {
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          content: '',
        },
      },
    }),
    [],
  );

  return (
    <>
      {/* @ts-ignore*/}
      <Pie {...config} {...props} />
    </>
  );
};

export default PieChart;
