import { Tooltip as TooltipAntd, TooltipProps } from 'antd';
import { createGlobalStyle } from 'styled-components';
import React, { useRef, useEffect, useState } from 'react'; // Import React and useRef
import Icon from '../icon';
import { stopPropagationHelper } from '@src/utils';

const TooltipGlobalStyle = createGlobalStyle`
  .ant-tooltip {
    --antd-arrow-background-color: rgba(40, 40, 42, 0.8);
    cursor: pointer;
  }
  .ant-tooltip .ant-tooltip-inner{
    background-color: rgba(40, 40, 42, 0.8);
    font-family: 'Satoshi-Variable';
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    z-index: 9999;
  }
`;

export interface Props {
  title: string;
}

export const InfoIcon = ({ space }: { space: boolean }) => {
  return (
    <Icon
      name="info"
      style={{
        fontSize: 16,
        paddingLeft: space ? 8 : 0,
      }}
    />
  );
};

function useTooltipState() {
  const tooltipRef = useRef(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    const scrollableDiv = document.querySelector('.ant-drawer-body');
    const handleScroll = () => {
      setTooltipOpen(false);
    };

    const handleWindowScroll = () => {
      setTooltipOpen(false);
    };

    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);

  return { tooltipRef, tooltipOpen, setTooltipOpen };
}

export default function Tooltip({ ...props }: TooltipProps) {
  const { tooltipRef, tooltipOpen, setTooltipOpen } = useTooltipState();

  const handleTooltipOpenChange = (open: boolean) => {
    setTooltipOpen(open);
  };

  return (
    <span onClick={stopPropagationHelper}>
      <TooltipGlobalStyle />
      <TooltipAntd
        title={props.title}
        open={tooltipOpen}
        onOpenChange={handleTooltipOpenChange}
        ref={tooltipRef}
        {...props}
      />
    </span>
  );
}
