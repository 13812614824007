import { initializeApp, FirebaseApp } from 'firebase/app';
import { getAnalytics, Analytics, logEvent } from 'firebase/analytics';
import { firebaseConfig } from '@src/config/analytics';

class AnalyticsClass {
  private app: FirebaseApp;
  private analytics: Analytics;

  constructor() {
    // Initialize Firebase
    this.app = initializeApp(firebaseConfig);
    // Initialize Analytics and get a reference to the service
    this.analytics = getAnalytics(this.app);
  }

  logEvent = (eventName: string, eventParams: any = {}) => {
    logEvent(this.analytics, eventName, eventParams);
  };
}

const analytics = new AnalyticsClass();

export default analytics;
