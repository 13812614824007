import { useQuery } from 'react-query';
import { fetchTenantById } from '@src/api/tenant/fetch-tenant-by-id';
import { GET_TENANT_BY_ID_QUERY_KEY } from '@src/api';
import { fetchConfig } from '@src/config/http';

export function useGetTenantById(id?: string) {
  return useQuery([GET_TENANT_BY_ID_QUERY_KEY, id], () => fetchTenantById(id), {
    ...(fetchConfig as any),
  });
}
