import { useQuery } from 'react-query';
import { fetchAssetSensors, GET_ASSET_SENSORS_QUERY_KEY } from '@src/api';
import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { selectedAssetIdState } from '@store/asset/selectedAssetIdState';
import { fetchConfig } from '@src/config/http';

export function useGetAssetSensors() {
  const [tenantId] = useRecoilState(selectedTenantIdState);
  const [assetId] = useRecoilState(selectedAssetIdState);

  return useQuery(
    [GET_ASSET_SENSORS_QUERY_KEY, tenantId, assetId],
    () => fetchAssetSensors(tenantId, assetId),
    { ...(fetchConfig as any) },
  );
}
