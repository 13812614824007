import { API } from 'aws-amplify';
import { REPORT_API, ReportQueryParams } from '@src/api';

export async function fetchAssetReport(
  tenantId: string,
  assetId: string,
  filters: ReportQueryParams,
) {
  if (tenantId && assetId) {
    const f = {
      startDate: String(filters.startDate),
      endDate: String(filters.endDate),
      stopsWithLengthMoreThan: '0',
    };

    const qs = filters ? `?${new URLSearchParams(f).toString()}` : '';
    return await API.get(
      REPORT_API,
      `/tenant/${tenantId}/asset/${assetId}${qs}`,
      {},
    );
  }

  return [];
}
