import { fontSize } from '@src/globalStyle';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface Prop extends HTMLAttributes<HTMLDivElement> {
  size?: string;
  fontSize?: string;
}

// const Test = styled.div.attrs(props => ({
//   size: props.size ?? '1em',
// }))`
//   width: ${props => props.size};
// `;

const StyledCircleAcronyms = styled.div<{
  size: Prop['size'];
  fontSize: Prop['fontSize'];
}>`
  user-select: none;
  width: ${props => props.size};
  height: ${props => props.size};

  border-radius: 50%;
  background: white;

  display: flex;
  justify-content: center;
  align-items: center;

  font-family: 'Satoshi-Variable';
  font-weight: 700;
  /* ${fontSize.m} */
  font-size: ${props => props.fontSize};

  color: #373737;

  //transition: color 0.3s, background 0.3s;
`;

export default function CircleAcronyms({
  size = '40px',
  fontSize = '1em',
  ...props
}: Prop) {
  return <StyledCircleAcronyms size={size} fontSize={fontSize} {...props} />;
}
