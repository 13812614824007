import { GetReportTenantMappedResponseDto } from '@asset-manager/types';
import { REPORT_API, ReportQueryParams } from '@src/api';
import { IReportExtraFilterState } from '@store/report/report-extra-filter-state';
import { API } from 'aws-amplify';
// import { mock } from './mock';

export async function fetchTenantReport(
  id: string,
  filters: ReportQueryParams & IReportExtraFilterState,
): Promise<GetReportTenantMappedResponseDto | undefined> {
  let qs = new URLSearchParams();
  if (filters?.departmentIds)
    qs.append('departmentIds', filters.departmentIds.join(','));
  if (filters?.plantIds) qs.append('plantIds', filters.plantIds.join(','));
  // if (filters?.types) qs.append('types', filters.types.join(','));
  if (filters?.lineIds) qs.append('lineIds', filters.lineIds.join(','));
  if (filters?.assets) qs.append('assets', filters.assets.join(','));
  if (filters.endDate) qs.append('endDate', filters.endDate);
  if (filters.startDate) qs.append('startDate', filters.startDate);
  if (filters.stopsWithLengthMoreThan)
    qs.append('stopsWithLengthMoreThan', filters.stopsWithLengthMoreThan);
  if (filters.subEventType) qs.append('subEventType', filters.subEventType);
  if (filters.rules) qs.append('rules', filters.rules); // Todo, fare il join con la virgola.
  if (filters?.types) qs.append('types', filters.types);


  if (!id) return;
  // return await Promise.resolve(mock);
  return await API.get(REPORT_API, `/tenant/${id}/map?${qs}`, {});
}
