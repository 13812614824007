import { useQuery } from 'react-query';
import { GET_ASSETS_QUERY_KEY } from '@src/api';
import { fetchAssets } from '@src/api/asset';
// import { useEffect } from 'react';
import { fetchConfig } from '@src/config/http';
import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { AssetGetAssetResponseDto } from '@asset-manager/types';

export function useGetAssetsAutocomplete(text?: string) {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);

  const filter = {
    name: text || '',
  };

  const query = useQuery(
    [GET_ASSETS_QUERY_KEY, 'autocomplete', selectedTenantId, text],
    () => {
      return fetchAssets(selectedTenantId, filter);
    },
    { ...(fetchConfig as any) },
  );

  return {
    assets: (query.data?.items || []) as AssetGetAssetResponseDto[],
  };
}
