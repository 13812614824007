import {
  AlertDurationModeEnum,
  AlertGetAlertResponseDto,
  AlertSeverityEnumDto,
  AlertUpdateRuleRequestDto,
  AssetStageEnum,
  LogicalOperatorEnum,
} from '@asset-manager/types';
import { PrecisionManufacturingIcon } from '@assets';
import Button from '@components/atoms/button';
import DateTimeFrameSelectModalDetail from '@components/atoms/date-time-frame-select-modal-detail';
import EmptyState from '@components/atoms/empty-state';
import Error from '@components/atoms/error';
import Icon from '@components/atoms/icon';
import Modal, { Footer, ModalProp } from '@components/atoms/modal';
import Select from '@components/atoms/select';
import { GeneratedEventsByAlertRangePickerNode } from '@components/organisms/range-picker-node/range-picker-node';
import { useScreenType } from '@hooks';
import { useGetTenantEventSubTypes } from '@src/api';
import { useAlertDetails } from '@src/api/alert/use-alert-details';
import { useGeneratedEventsByAlert } from '@src/api/alert/use-generated-events-by-alert';
import { useUpdateAlert } from '@src/api/alert/use-update-alert';
import { useUpdateEvent } from '@src/api/event/use-update-event';
import { NotificationContext } from '@src/App';
import { fontSize } from '@src/globalStyle';
import { StyledPagination } from '@src/routes/Assets/components/drawer-contents/alert-drawer-content/alert-drawer-content';
import { currentAlertIdState } from '@store/alert/alert-filter-state';
import { selectedAssetIdState } from '@store/asset';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { device, size } from '@theme/breakpoints';
import { darkTheme } from '@theme/dark';
import {
  Col,
  ConfigProvider,
  Form,
  Input,
  Row,
  Select as SelectAntd,
  Tabs,
  Typography,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import styled, { css } from 'styled-components';
import EditableFields from '../modal-add-alert/modal-add-alert-editable-fields';
import { StyledSpin } from '../modal-add-alert/modal-add-alert.components';
import {
  datePeriodSelect,
  datePeriodSelectOptions,
  durationSelect,
  FormFields,
  initialValues,
  ruleConditionsOptions,
  ruleConjunctionsOptions,
  statusSelect,
  statusSelectOptions,
  timePeriodSelect,
  timePeriodSelectOptions,
} from '../modal-add-alert/modal-add-alert.types';
import { StyledSelectNewCategory } from '../modal-detail-stop/modal-detail-stop';
import { optionsColor } from '../modal-detail-stop/modal-detail-stop.config';
import {
  NewCategory,
  SingleOption,
  SingleOptionIcon,
} from '../modal-detail-stop/modal-detail-stop.style';
import { PlusCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { useCreateEventSubType } from '@src/api/tenant/create-event-sub-type';

export enum AlertDurationTypeEnum {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}
dayjs.extend(require('dayjs/plugin/utc'));

const { Option } = SelectAntd;
const StyledInput = styled(Input)`
  font-size: 14px;
  width: 11.4rem;
  max-width: 100%;
  height: 44px;

  // under 675px width 100%
  @media (max-width: 675px) {
    width: 100%;
  }
`;
const dayMap = {
  0: 'Domenica',
  1: 'Lunedì',
  2: 'Martedì',
  3: 'Mercoledì',
  4: 'Giovedì',
  5: 'Venerdì',
  6: 'Sabato',
};

const StyledModal = styled(Modal)`
  .ant-modal-close {
    right: 16px;
  }

  @media (max-width: ${size.tablet}) {
    .ant-modal {
      height: -webkit-fill-available;
      max-width: 100vw;
    }
    .ant-modal-content {
      height: 100vh;
      top: 0;
      overflow: auto;
      margin: -15px;
    }
    .ant-modal-centered::before {
      content: unset;
    }
    .ant-modal-footer {
      display: none;
    }
  }

  @media ${device.tablet} {
    position: relative;
    padding: 24px 16px;
  }
`;
const EditIconContainer = styled(Button)`
  padding: 4px 16px;
  display: flex;

  border: 1px solid #fff;
`;

const StyledParagraph = styled.p`
  margin-top: 8px;
  margin-bottom: 16px;
`;

const Divider = styled.div`
  height: 1px;
  background-color: #373737;
`;

const StyledTitle = styled.h3`
  ${fontSize.m}
  margin-block: 16px !important;
`;

const StyledRule = styled.div`
  display: flex;
  column-gap: 16px;

  p {
    margin: 0;
  }

  > p:first-child {
    color: #919191;
  }

  > p:last-child {
    font-weight: 700;
  }
`;

const StyledTabs = styled(Tabs)<{ hideNav?: boolean }>`
  padding-bottom: 24px;

  ${({ hideNav }) =>
    hideNav &&
    css`
      .ant-tabs-nav {
        display: none;
      }
    `}

  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-nav-list {
    width: calc(
      100% - 0.1px
    ); // TODO: non mi piace quando si allarga la sezione
    /* width: 329px; //100%; */
  }
  .ant-tabs-tab {
    border-radius: 8px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    flex: 1;
    justify-content: center;
    border: none !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
    padding-top: 16px;
    background: #1c1c1e;

    &:before {
      border: none;
    }
  }
`;

const RuleRecap = ({ title, value }: { title: string; value: string }) => {
  return (
    <StyledRule>
      <p>{title}</p>
      <p>{value}</p>
    </StyledRule>
  );
};

const getInitialValues = (alert?: AlertGetAlertResponseDto | null) => {
  if (!alert) return initialValues;

  const hasTimeIntervals = alert.intervals.find(interval => interval.startHour);
  const hasDateIntervals = alert.intervals.find(interval => interval.startDate);

  const values: FormFields = {
    subTypeInfoName: alert.subTypeInfoName || '',
    subTypeInfoId: alert.subTypeInfoId || '',
    textTemplate: alert.textTemplate,
    name: alert.name,
    message: alert.textTemplate,
    priority: alert.severity as AlertSeverityEnumDto,
    statusSelect: alert.stage?.in
      ? statusSelectOptions.IN
      : statusSelectOptions.NOT_IN,
    statusOption: alert.stage?.stage || undefined,
    assets: alert.assets.map(asset => asset.name),
    durationSelect: alert.duration.mode,
    duration: alert.duration.duration.toString(),
    days: alert.inDay.dayOfWeek || [],
    timePeriodSelect: hasTimeIntervals
      ? timePeriodSelectOptions.INTERVAL
      : timePeriodSelectOptions.ANY,
    intervals: hasTimeIntervals
      ? alert.intervals.map(interval => ({
          startTime: dayjs()
            .set('hour', Number(interval.startHour?.split(':')[0]) || 0)
            .set('minute', Number(interval.startHour?.split(':')[1]) || 0),
          endTime: dayjs()
            .set('hour', Number(interval.endHour?.split(':')[0]) || 0)
            .set('minute', Number(interval.endHour?.split(':')[1]) || 0),
        }))
      : [],
    datePeriodSelect: hasDateIntervals
      ? datePeriodSelectOptions.DURING
      : datePeriodSelectOptions.ANY,
    datePeriod: hasDateIntervals
      ? [dayjs(hasDateIntervals.startDate), dayjs(hasDateIntervals.endDate)]
      : null,
    rules: alert.expressions.map(items => {
      if (items.stage) {
        return {
          ruleStatus: items.stage.in
            ? statusSelectOptions.IN
            : statusSelectOptions.NOT_IN,
          ruleCondition: ruleConditionsOptions[0].value,
          ruleConditionValue: '',
          statusOption: items.stage.stage,
        };
      } else if (items.value) {
        return {
          ruleStatus: statusSelectOptions.IN,
          ruleCondition:
            items.value.mode === AlertDurationModeEnum.MORE
              ? ruleConditionsOptions[0].value
              : ruleConditionsOptions[1].value,
          ruleConditionValue: items?.value?.value?.toString() || '',
          statusOption: undefined,
          ruleConjunction:
            items.logicalOperator === LogicalOperatorEnum.OR
              ? ruleConjunctionsOptions[1].value
              : ruleConjunctionsOptions[0].value,
        };
      } else {
        return {
          ruleStatus: statusSelectOptions.IN,
          ruleCondition: ruleConditionsOptions[0].value,
          ruleConditionValue: '',
          statusOption: AssetStageEnum.STOP,
        };
      }
    }),
    sendEmail: alert.sendEmail,
    sendEmailTo: alert.sendEmailTo || [],
  };
  return values;
};

interface Prop extends ModalProp {
  open: boolean;
  isRule?: boolean;
  onCancel: () => void;
  event?: object;
}
export default function AlertDetailsModal({
  open,
  onCancel,
  event,
  onOk,
  isRule,
  ...props
}: Prop) {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const { t } = useTranslation();
  const [, setCurrentAlertId] = useRecoilState(currentAlertIdState);
  const { data: currentAlertDetails, isFetching, isError } = useAlertDetails();
  console.log('AlertDetailsModal event', event);
  const [activeKey, setActiveKey] = useState(isRule ? 'rules' : 'cause');

  const [isEditing, toggleIsEditing] = useReducer(state => {
    setActiveKey('rules');
    return !state;
  }, !!isRule);
  const [tabs, setTabs] = useState<any[]>([]);

  useEffect(() => {
    const tmpTabs = isRule
      ? []
      : [
          {
            key: 'cause',
            label: t('general.cause'),
            children: (
              <CauseAlertTab
                onOk={onOk || (() => {})}
                currentAlertDetails={currentAlertDetails}
                event={event as { id?: string }}
                isFetching={isFetching}
                isError={isError}
                isEditing={isEditing}
                onCancel={onCancel}
                tenantId={selectedTenantId}
              />
            ),
          },
        ];
    tmpTabs.push({
      key: 'rules',
      label: t('general.rules'),
      children: (
        <AlertDetailsTab
          currentAlertDetails={currentAlertDetails}
          isFetching={isFetching}
          isError={isError}
          isEditing={isEditing}
          onCancel={onCancel}
        />
      ),
    });

    tmpTabs.push({
      key: 'history',
      label: t('alerts.storicalAlerts'),
      children: <GeneratedAlertsTab></GeneratedAlertsTab>,
    });

    setTabs(tmpTabs);
  }, [
    currentAlertDetails,
    event,
    isEditing,
    isError,
    isFetching,
    isRule,
    onCancel,
    onOk,
    selectedTenantId,
    t,
  ]);

  return (
    <StyledModal
      dark
      title={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginRight: 40,
            flexWrap: 'wrap',
            gap: 8,
          }}
        >
          {isEditing ? (
            <>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'unset',
                  flexWrap: 'wrap',
                  gap: 8,
                }}
              >
                {!isRule && (
                  <Button
                    variant="grey-E3"
                    onClick={toggleIsEditing}
                    style={{
                      marginRight: 12,
                      width: 32,
                      height: 32,
                      padding: 4,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Icon name="arrow_back_ios_new" />
                  </Button>
                )}

                <span>{t('modal.editRule')}</span>
              </div>
            </>
          ) : (
            <>
              <span>{currentAlertDetails?.name}</span>
              <EditIconContainer variant="grey-E3" onClick={toggleIsEditing}>
                <Icon name="edit" />
                <span style={{ marginLeft: 8 }}>{t('modal.editRule')}</span>
              </EditIconContainer>
            </>
          )}
        </div>
      }
      bigTitle
      lowerCloseIcon
      open={open}
      okText="Salve e attiva"
      onCancel={() => {
        setCurrentAlertId(null);
        onCancel && onCancel();
      }}
      centered
      width={640}
      footer={null}
      {...props}
    >
      <ConfigProvider theme={darkTheme}>
        <StyledTabs
          hideNav={isEditing || isError}
          activeKey={activeKey}
          onChange={key => setActiveKey(key)}
          items={tabs}
          type="card"
          centered
        />
      </ConfigProvider>
    </StyledModal>
  );
}

const AlertDetailsTab = ({
  currentAlertDetails,
  isFetching,
  isError,
  isEditing,
  onCancel,
}: {
  currentAlertDetails?: AlertGetAlertResponseDto | null;
  isFetching: boolean;
  isError: boolean;
  isEditing: boolean;
  onCancel: () => void;
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<FormFields>();

  const { toast } = useContext(NotificationContext);
  const [, setCurrentAlertId] = useRecoilState(currentAlertIdState);

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        const body: AlertUpdateRuleRequestDto = {
          inDay: {
            dayOfWeek: values.days,
          },
          intervals: [],
          runUntil: dayjs().add(1, 'year').format(),
          active: true,
          sendEmail: values.sendEmail,
          sendEmailTo: values.sendEmailTo,
        };

        const dateInterval: { startDate?: string; endDate?: string } = {};
        let hasDateInterval = false;

        if (
          values.datePeriodSelect === datePeriodSelectOptions.DURING &&
          values.datePeriod
        ) {
          dateInterval.startDate = dayjs(values.datePeriod[0])
            .startOf('day')
            .utc()
            .format();
          dateInterval.endDate = dayjs(values.datePeriod[1])
            .endOf('day')
            .utc()
            .format();
          hasDateInterval = true;
        }

        if (values.timePeriodSelect === timePeriodSelectOptions.INTERVAL) {
          values.intervals.forEach(interval => {
            body.intervals?.push({
              ...dateInterval,
              startHour:
                typeof interval.startTime === 'string'
                  ? interval.startTime
                  : interval.startTime.format('HH:mm'),
              endHour:
                typeof interval.endTime === 'string'
                  ? interval.endTime
                  : interval.endTime.format('HH:mm'),
            });
          });
        } else if (hasDateInterval) {
          body.intervals?.push(dateInterval);
        }

        return updateAlert.mutate({ body });
      })
      .catch(err => {
        console.error(err);
      });
  };

  const updateAlert = useUpdateAlert({
    onSuccess: () => {
      toast?.success({
        message: t('alert.editedAlertSuccess'),
        placement: 'topRight',
      });
      setCurrentAlertId(null);
      onCancel && onCancel();
    },
    onError: () => {
      toast?.error({
        message: t('alert.errorEditAlert'),
        placement: 'topRight',
      });
    },
  });
  const initialValues = getInitialValues(currentAlertDetails);

  const matchedRule = initialValues.rules?.find(rule => rule.ruleConjunction);
  const matchedOption = ruleConjunctionsOptions.find(
    option => option.value === matchedRule?.ruleConjunction,
  );

  const greaterRule = initialValues.rules?.find(rule => rule.ruleCondition);
  const greaterOption = ruleConditionsOptions.find(
    option => option.value === greaterRule?.ruleCondition,
  );

  const durationLabel =
    Number(initialValues.duration) > 1
      ? t('general.minutes')
      : t('general.minute');

  if (isFetching) {
    return <StyledSpin size="large" />;
  }

  if (isError) {
    return (
      <Error
        style={{
          color: '#fff',
          marginTop: 32,
        }}
        subtitle={t('alert.editedAlertSuccess')}
      />
    );
  }

  return (
    <>
      <StyledParagraph>{currentAlertDetails?.description}</StyledParagraph>
      <Divider />

      <div>
        <StyledTitle>{t('alerts.generalAlertCondition')}</StyledTitle>
        <RuleRecap
          title={t('alerts.generateWhen')}
          value={initialValues.assets?.join(', ') || ''}
        />
        {initialValues.rules?.[0]?.statusOption ? (
          <RuleRecap
            title={
              statusSelect().find(
                el => el.value === initialValues.rules?.[0]?.ruleStatus,
              )?.label || ''
            }
            value={`${initialValues.rules?.[0]?.statusOption}` || ''}
          />
        ) : null}
        <RuleRecap
          title={
            matchedOption
              ? `${matchedOption.label()}${t('alerts.hasCharacteristics')}`
              : ''
          }
          value={''}
        />
        {initialValues?.rules?.map(rule => {
          if (rule.ruleConditionValue) {
            return (
              <RuleRecap
                title={greaterOption ? greaterOption.label : ''}
                value={rule.ruleConditionValue + ' '}
              />
            );
          }
          return null;
        })}
        <RuleRecap
          title={
            durationSelect().find(
              el => el.value === initialValues.durationSelect,
            )?.label || ''
          }
          value={`${initialValues.duration} ${durationLabel}` || ''}
        />
        {!isEditing && (
          <>
            <RuleRecap
              title={
                datePeriodSelect(t).find(
                  el => el.value === initialValues.datePeriodSelect,
                )?.label || ''
              }
              value={
                initialValues.datePeriod
                  ? `${initialValues.datePeriod[0].format(
                      'YYYY-MM-DD',
                    )} > ${initialValues.datePeriod[1].format('YYYY-MM-DD')} `
                  : ''
              }
            />
            <RuleRecap
              title={
                timePeriodSelect().find(
                  el => el.value === initialValues.timePeriodSelect,
                )?.label || ''
              }
              value={
                initialValues.intervals
                  ? initialValues.intervals
                      .map(
                        interval =>
                          `${t('general.fromLowerCase')} ${dayjs(
                            interval.startTime,
                          ).format('HH:mm')} ${t(
                            'general.toLowerCase',
                          )} ${dayjs(interval.endTime).format('HH:mm')}`,
                      )
                      .join(', ')
                  : ''
              }
            />
            <RuleRecap
              title={t('modal.rangeDays')}
              value={
                initialValues.days?.length === 7
                  ? t('general.all')
                  : initialValues.days?.map(day => dayMap[day]).join(', ') || ''
              }
            />
            {initialValues?.sendEmailTo?.[0] && (
              <RuleRecap
                title={t('general.notifyTo')}
                value={
                  (initialValues?.sendEmailTo &&
                    initialValues.sendEmailTo
                      ?.map(email => email)
                      .join(', ')) ||
                  ''
                }
              />
            )}
          </>
        )}
      </div>

      {isEditing && (
        <>
          <Form
            name="edit-alert"
            wrapperCol={{ span: 24 }}
            initialValues={initialValues}
            form={form}
            autoComplete="off"
            layout="horizontal"
          >
            <EditableFields form={form} initialValues={initialValues} />
          </Form>
          <Footer>
            <Button
              type="button"
              variant="outline-white"
              onClick={onCancel}
              key="back"
            >
              {t('general.cancel')}
            </Button>
            <Button
              type="button"
              variant="grey"
              key="submit"
              onClick={onFinish}
            >
              {t('general.saveAndActive')}
            </Button>
          </Footer>
        </>
      )}
    </>
  );
};

const GeneratedAlertRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 48px;
  padding-block: 16px;
  border-bottom: 1px solid #373737;
`;

const GeneratedAlertCell = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const GeneratedAlertRow = ({
  date,
  time,
  assetName,
}: {
  date: string;
  time: string;
  assetName: string;
}) => {
  return (
    <GeneratedAlertRowContainer>
      <GeneratedAlertCell>
        <Icon
          name="event"
          style={{
            fontSize: 16,
          }}
          color="#919191"
        />
        <Typography.Text style={{ color: '#919191' }}>{date}</Typography.Text>
      </GeneratedAlertCell>
      <GeneratedAlertCell>
        <Icon
          name="schedule"
          style={{
            fontSize: 16,
          }}
          color="#919191"
        />
        <Typography.Text style={{ color: '#919191' }}>{time}</Typography.Text>
      </GeneratedAlertCell>
      <GeneratedAlertCell>
        <PrecisionManufacturingIcon style={{ color: '#919191' }} />
        <Typography.Text style={{ color: '#919191' }}>
          {assetName}
        </Typography.Text>
      </GeneratedAlertCell>
    </GeneratedAlertRowContainer>
  );
};

const GeneratedAlertsTab = () => {
  const { t } = useTranslation();
  const { isMobile } = useScreenType();
  const {
    generatedEvents,
    isError,
    isLoading,
    pageNumber,
    pageSize,
    updateFilters,
  } = useGeneratedEventsByAlert();

  if (isLoading) {
    return <StyledSpin size="large" />;
  }

  if (isError) {
    return (
      <Error
        fillDimensions
        subtitle={t('alerts.errorFetchingGeneratedAlerts')}
        style={{
          color: '#fff',
          marginTop: 32,
        }}
      />
    );
  }

  return (
    <>
      <Row
        style={{
          // display: 'flex',
          // flexDirection: 'row',
          marginBlock: 24,
          alignItems: 'center',
          gap: 8,
        }}
      >
        {isMobile ? (
          <DateTimeFrameSelectModalDetail />
        ) : (
          <GeneratedEventsByAlertRangePickerNode
            style={{ flexBasis: !isMobile ? '50%' : '100%' }}
          />
        )}

        <Col style={{ flexGrow: 1 }}>
          <Typography.Paragraph
            style={{ color: '#919191', margin: 0, textAlign: 'center' }}
          >
            {t('alerts.numberOfGeneratedAlerts')}
            <span
              style={{
                color: '#fff',
                fontWeight: 700,
                fontSize: 24,
                lineHeight: '32px',
                marginLeft: 12,
              }}
            >
              {generatedEvents?.count}
            </span>
          </Typography.Paragraph>
        </Col>
      </Row>
      {!generatedEvents || !generatedEvents.items.length ? (
        <EmptyState
          title={t('alerts.noAlertGenerated')}
          subtitle={t('alerts.noAlertGeneratedDescription')}
          dark
          fillDimensions
          style={{ maxWidth: 302, margin: 'auto' }}
          image={
            <Icon
              name="emergency_home"
              style={{ fontSize: 48 }}
              color="#919191"
            />
          }
        />
      ) : (
        <div style={{ marginBottom: 32 }}>
          {[...generatedEvents.items].map(item => (
            <GeneratedAlertRow
              // title={item.alertName || ''}
              // severity={item.alertSeverity}
              date={format(new Date(item.createdAt), 'dd/MM/yyyy')}
              time={format(new Date(item.createdAt), 'HH:mm')}
              assetName={item.assetName || ''}
              // description={item.description}
            />
          ))}

          <StyledPagination
            defaultCurrent={1}
            current={pageNumber}
            total={generatedEvents?.count}
            pageSize={pageSize}
            showSizeChanger={false}
            // showLessItems
            onChange={page =>
              updateFilters({
                page,
              })
            }
          />
        </div>
      )}
    </>
  );
};

const StyledRow = styled.div<{
  isMobile: boolean;
  paddingTop?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '208px 1fr')};
  ${({ paddingTop }) =>
    paddingTop &&
    css`
      > :first-child {
        padding-top: 2px;
      }
    `}
  column-gap: 20px;
`;

const LabelCol = styled(Col)`
  font-family: 'Satoshi-Variable';
  font-weight: 500;
  ${fontSize.s}
  color: #fff;
  flex: 0 1 208px;
  margin-top: 10px;
`;

const CauseAlertTab = ({
  onCancel,
  currentAlertDetails,
  isFetching,
  isError,
  event,
  onOk,
  tenantId,
}: {
  event?: any;
  onCancel?: () => void;
  currentAlertDetails?: AlertGetAlertResponseDto | null;
  isFetching: boolean;
  isError: boolean;
  isEditing: boolean;
  onOk: () => void;
  tenantId: string;
}) => {
  const { data: subTypes } = useGetTenantEventSubTypes();
  const [selectedCategory, setSelectedCategory] = useState<
    string | string[] | undefined
  >(
    subTypes?.items?.find((item: any) => {
      return item?.name === event.subTypeName;
    })?.key,
  );

  const [isNewCategory, setIsNewCategory] = useState<boolean>(false);
  const [newCategoryColor, setNewCategoryColor] = useState<string>('HIGH');
  const [newCategoryName, setNewCategoryName] = useState<string>('');

  const createEventSubType = useCreateEventSubType();
  const callbackCreateEventSubType = useCallback(() => {
    createEventSubType.mutate(
      {
        key: newCategoryName,
        name: newCategoryName,
        severity: newCategoryColor,
        tenantId: tenantId,
      },
      {
        onSuccess: () => {
          setSelectedCategory(newCategoryName);
        },
      },
    );
  }, [createEventSubType, newCategoryColor, newCategoryName, tenantId]);
  const [assetIdRecoil] = useRecoilState(selectedAssetIdState);
  const isMobile = window.innerWidth < 1024;
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const { toast } = useContext(NotificationContext);
  const [, setCurrentAlertId] = useRecoilState(currentAlertIdState);

  const [selectedTenantId] = useRecoilState(selectedTenantIdState);

  // Functions that handle the save of the cause and the note of the alert.
  const onClickSaveCause = async () => {
    form
      .validateFields()
      .then(values => {
        const body: AlertUpdateRuleRequestDto = {
          subTypeInfoId: selectedCategory as string,
          textTemplate: values.notes,
          inDay: { dayOfWeek: initialValues.days },
          runUntil: dayjs().add(1, 'year').format(),
          active: true,
          sendEmail: initialValues.sendEmail,
          sendEmailTo: initialValues.sendEmailTo,
          intervals: [],
        };

        const dateInterval: { startDate?: string; endDate?: string } = {};
        let hasDateInterval = false;

        if (
          initialValues.datePeriodSelect === datePeriodSelectOptions.DURING &&
          initialValues.datePeriod
        ) {
          dateInterval.startDate = dayjs(initialValues.datePeriod[0])
            .startOf('day')
            .utc()
            .format();
          dateInterval.endDate = dayjs(initialValues.datePeriod[1])
            .endOf('day')
            .utc()
            .format();
          hasDateInterval = true;
        }

        if (
          initialValues.timePeriodSelect === timePeriodSelectOptions.INTERVAL
        ) {
          initialValues?.intervals?.forEach((interval: any) => {
            body.intervals?.push({
              ...dateInterval,
              startHour:
                typeof interval.startTime === 'string'
                  ? interval.startTime
                  : interval.startTime.format('HH:mm'),
              endHour:
                typeof interval.endTime === 'string'
                  ? interval.endTime
                  : interval.endTime.format('HH:mm'),
            });
          });
        } else if (hasDateInterval) {
          body.intervals?.push(dateInterval);
        }

        return updateEvent.mutate(
          {
            assetId: event.assetId || assetIdRecoil,
            tenantId: selectedTenantId,
            body: {
              description: values.notes,
              subTypeInfoId: selectedCategory as string,
            },
            eventId: event.id,
          },
          {
            onSuccess: () => {
              // we don't need to do this, there is a popup already

              // toast?.success({
              //   message: t('alert.editedAlertSuccess'),
              //   placement: 'topRight',
              // });
              onOk();
              setCurrentAlertId(null);
              onCancel && onCancel();
            },
            onError: () => {
              toast?.error({
                message: t('alert.errorEditAlert'),
                placement: 'topRight',
              });
            },
          },
        );
      })
      .catch(err => console.error(err));
  };

  const updateEvent = useUpdateEvent();

  const initialValues = getInitialValues(currentAlertDetails);

  if (isFetching) {
    return <StyledSpin size="large" />;
  }

  if (isError) {
    return (
      <Error
        style={{
          color: '#fff',
          marginTop: 32,
        }}
        subtitle={t('alert.editedAlertSuccess')}
      />
    );
  }

  return (
    <div style={{ paddingTop: 24 }}>
      <Form
        name="add-cause"
        wrapperCol={{ span: 24 }}
        autoComplete="off"
        form={form}
      >
        <Col>
          <StyledRow isMobile={isMobile}>
            <LabelCol>{t('cause.cause')}</LabelCol>

            <Form.Item
              name="cause"
              initialValue={
                subTypes?.items?.find((item: any) => {
                  return item?.name === event.subTypeName;
                })?.key
              }
            >
              <Select
                defaultValue={selectedCategory}
                showSearch={true}
                placeholder={t('cause.addCause')}
                onChange={setSelectedCategory}
                suffixIcon={
                  <PlusCircleOutlined
                    onClick={() => setIsNewCategory(!isNewCategory)}
                  />
                }
                options={[
                  ...(subTypes?.items?.map((item: any) => ({
                    label: item.name,
                    value: item.key,
                  })) || []),
                  { label: t('cause.addCause'), value: 'new_category' },
                ]}
              />
              {isNewCategory && (
                <NewCategory size={8} id="id-modal-detail-stop-new-category">
                  <StyledInput
                    prefix={
                      <SearchOutlined
                        style={{
                          color: '#909090',
                        }}
                      />
                    }
                    size="large"
                    placeholder={t('general.nameCategory')}
                    width={'100%'}
                    onChange={e => setNewCategoryName(() => e.target.value)}
                  />
                  <StyledSelectNewCategory
                    size="large"
                    placeholder={undefined}
                    options={undefined}
                    defaultValue={newCategoryColor}
                    onChange={setNewCategoryColor}
                    suffixIcon={
                      <Icon
                        name="expand_more"
                        color={darkTheme ? '#fff' : '#1C1B1F'}
                        style={{ fontSize: 16 }}
                      />
                    }
                  >
                    {optionsColor.map((item, index) => (
                      <Option value={item.value} label={item.label} key={index}>
                        <SingleOption>
                          <SingleOptionIcon
                            name="radio_button_checked"
                            color={item.color}
                            aria-label={item.label}
                          />
                        </SingleOption>
                      </Option>
                    ))}
                  </StyledSelectNewCategory>
                  <Button onClick={callbackCreateEventSubType}>
                    {t('general.add')}
                  </Button>
                </NewCategory>
              )}
            </Form.Item>
          </StyledRow>
          <StyledRow isMobile={isMobile}>
            <LabelCol>{t('cause.notes')}</LabelCol>
            <Form.Item initialValue={event.description} name="notes">
              <TextArea rows={6} placeholder={t('cause.addNotes')} />
            </Form.Item>
          </StyledRow>
        </Col>
      </Form>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'end',
        }}
      >
        <Button
          type="button"
          variant="grey"
          key="submit"
          onClick={onClickSaveCause}
        >
          {t('cause.saveCauseAndNote')}
        </Button>
      </div>
    </div>
  );
};
