import { CSSProperties, HTMLAttributes } from 'react';
import classNames from 'classnames';
import { Property } from 'csstype';

export interface Props extends HTMLAttributes<HTMLSpanElement> {
  className?: string;
  name: string;
  color?: Property.Color;
  style?: CSSProperties;
  title?: string;
}

export default function Icon({
  name,
  className,
  color = '#FFFFFF',
  style,
  title,
  ...props
}: Props) {
  return (
    <span
      className={classNames('material-symbols-outlined', className)}
      style={{ ...style, color }}
      {...props}
      title={title}
    >
      {name}
    </span>
  );
}
