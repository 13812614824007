import { API } from 'aws-amplify';
import { ALERT_API } from '@src/api';
import { AlertGetAlertResponseDto } from '@asset-manager/types';

export async function fetchAlertDetails(
  tenantId: string,
  alertId: string | null,
) {
  if (!tenantId || !alertId) {
    return null;
  }
  return (await API.get(
    ALERT_API,
    `/tenant/${tenantId}/${alertId}`,
    {},
  )) as AlertGetAlertResponseDto;
}
