import { mediaQuery, useMediaQuery } from '@src/hooks/useMediaQuery';
import { siderMenuCollapsibleState, siderMenuVisibilityState } from '@store/general/layout';
import { useEffect, useLayoutEffect } from 'react';
import { useRecoilState } from 'recoil';

export function useLayout() {
  const [collapsed, setCollapsed] = useRecoilState(siderMenuCollapsibleState);
  const [visible, setVisible] = useRecoilState(siderMenuVisibilityState);

  const isLaptop = useMediaQuery('laptop');

  // useEffect(() => {
  //   setVisible(isLaptop);
  //   setCollapsed(isLaptop);
  // }, [isLaptop, setVisible, setCollapsed]);

  useLayoutEffect(() => {
    mediaQuery((width: any) => {
      const isLaptop2 = width >= 1024;
      setVisible(isLaptop2);
      setCollapsed(isLaptop2);
    });
  }, [setCollapsed, setVisible]);

  return {
    collapsed,
    setCollapsed,
    visible,
    setVisible,
  };
}
