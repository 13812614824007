import RangePicker from '@components/atoms/range-picker';
import { useTimeframeSelector } from '@hooks';
import {
  eventsByAlertIdFiltersState,
  generatedEventsByAssetFiltersState,
  generatedEventsFiltersState,
} from '@store/alert/generated-events-filter-state';
import { assetFilterDateState } from '@store/asset/assetFilterDateState';
import { noteFiltersState } from '@store/note/note-filters-state';
import { reportFiltersState } from '@store/report/reportFiltersState';
import dayjs from 'dayjs';
import { useRecoilState } from 'recoil';

dayjs.extend(require('dayjs/plugin/utc'));
// @ts-ignore
const utcDayjs = dayjs.utc;

interface RangePickerProps {
  data: {
    startDate: string;
    endDate: string;
  };
  onSelect: (e: any) => void;
  additionalAction?: () => void;
}

export const RangePickerNode = ({
  data,
  onSelect,
  ...props
}: RangePickerProps) => {
  return (
    <RangePicker
      value={[utcDayjs(+data.startDate * 1000), utcDayjs(+data.endDate * 1000)]}
      onChange={e => {
        props.additionalAction?.();
        onSelect(e);
      }}
      disabledDate={(date: dayjs.Dayjs) => dayjs().isBefore(date, 'day')}
      {...props}
    />
  );
};

export const ReportRangePickerNode = ({ ...props }) => {
  const { onSelectedDatesRangeChange } = useTimeframeSelector();

  const [reportFilter] = useRecoilState(reportFiltersState);

  return (
    <RangePickerNode
      data={{
        startDate: reportFilter.startDate,
        endDate: reportFilter.endDate,
      }}
      onSelect={onSelectedDatesRangeChange}
      {...props}
    />
  );
};

export const DashboardRangePickerNode = ({ ...props }) => {
  const { onSelectedDatesRangeChangeDashboard } = useTimeframeSelector();
  const [assetFilterDate] = useRecoilState(assetFilterDateState);

  return (
    <RangePickerNode
      data={{
        startDate: assetFilterDate.startDate,
        endDate: assetFilterDate.endDate,
      }}
      onSelect={onSelectedDatesRangeChangeDashboard}
      {...props}
    />
  );
};

export const NotesRangePickerNode = ({ ...props }) => {
  const { onSelectedNotesDatesRangeChange } = useTimeframeSelector();

  const [noteFilter] = useRecoilState(noteFiltersState);

  return (
    <RangePickerNode
      data={{
        startDate: noteFilter.startDate,
        endDate: noteFilter.endDate,
      }}
      onSelect={onSelectedNotesDatesRangeChange}
      {...props}
    />
  );
};

export const GeneratedEventsRangePickerNode = ({ ...props }) => {
  const { onSelectedGeneratedEventsDatesRangeChange } = useTimeframeSelector();

  const [generatedEventsFilter] = useRecoilState(generatedEventsFiltersState);

  return (
    <RangePickerNode
      data={{
        startDate: generatedEventsFilter.startDate,
        endDate: generatedEventsFilter.endDate,
      }}
      onSelect={onSelectedGeneratedEventsDatesRangeChange}
      {...props}
    />
  );
};

export const GeneratedEventsByAlertRangePickerNode = ({ ...props }) => {
  const { onSelectedGeneratedEventsByAlertDatesRangeChange } =
    useTimeframeSelector();

  const [generatedEventsFilter] = useRecoilState(eventsByAlertIdFiltersState);

  return (
    <RangePickerNode
      data={{
        startDate: generatedEventsFilter.startDate,
        endDate: generatedEventsFilter.endDate,
      }}
      onSelect={onSelectedGeneratedEventsByAlertDatesRangeChange}
      {...props}
    />
  );
};

export const GeneratedEventsByAssetRangePickerNode = ({ ...props }) => {
  const { onSelectedGeneratedEventsByAssetDatesRangeChange } =
    useTimeframeSelector();
  const [generatedEventsFilter] = useRecoilState(
    generatedEventsByAssetFiltersState,
  );
  return (
    <RangePickerNode
      data={{
        startDate: generatedEventsFilter.startDate,
        endDate: generatedEventsFilter.endDate,
      }}
      onSelect={onSelectedGeneratedEventsByAssetDatesRangeChange}
      {...props}
    />
  );
};
