import EmptyStateChart from '@components/atoms/empty-state/empty-state-chart';
import { TimeFrame } from '@components/atoms/select/select';
import Slider from '@components/atoms/slider';
import CardChart from '@components/molecules/card/card-title-extra';
import {
  tooltip_domStyles,
  TooltipHeatmap,
} from '@components/molecules/charts/components/chart-tooltip';
import Legend from '@components/molecules/charts/components/legend';
import Heatmap, {
  HeatmapConfigType,
} from '@components/molecules/charts/heatmap';
import { ConfigType } from '@components/molecules/charts/heatmap/heatmap';
import { HeathMapData } from '@src/routes/Report/Report';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  data?: HeathMapData[];
  defaultValue: [number, number];
  chartType?: HeatmapConfigType;
  timeFrame?: TimeFrame;
}

const convertTIME = (time: string) => {
  try {
    if (!time) return '';
    const sliced = time.slice(0, -3);
    if (!sliced) return '';
    const splitted = sliced?.split(' ');
    if (!splitted || splitted.length < 2) return '';
    return splitted[1] + ':00';
  } catch (e) {
    return '';
  }
};

export default function CardAssetChart({
  data = [],
  defaultValue,
  chartType = HeatmapConfigType.BAR,
}: Props) {
  const { t } = useTranslation();
  const [values, setValues] = useState<Props['defaultValue']>(defaultValue);

  const minutesOfInterval = useMemo(() => {
    const interval = Array.from(new Set(data?.map(r => convertTIME(r.Time))));
    try {
      if (interval[interval.length - 1] !== '00:00') {
        const lastTime = interval[interval.length - 1];
        const lastTimeSplit = Number(lastTime?.split(':')[0]);
        if (lastTimeSplit < 23) {
          interval.push(`${lastTimeSplit + 1}:00`);
        } else interval.push('00:00');
      }
    } catch (e) {
      console.log(e);
    }
    return interval;
  }, [data]);

  const [minutesOfRangeInterval, setMinutesOfRangeInterval] = useState<
    string[]
  >(minutesOfInterval.slice(values[0], values[1]));

  useEffect(() => {
    setMinutesOfRangeInterval(minutesOfInterval.slice(values[0], values[1]));
  }, [data, minutesOfInterval, values]);

  const formatterSliderTooltip = useCallback(
    (value?: number) => {
      if (typeof value === 'number') {
        return minutesOfInterval[value];
      }
      return value;
    },
    [minutesOfInterval],
  );

  const onChangeSlider = useCallback(
    (value: [number, number]) => {
      setValues(value);
      setMinutesOfRangeInterval(
        Array.from(new Set(data.map(i => i.Time))).slice(values[0], values[1]),
      );
    },
    [data, values],
  );

  const chartHeight = useMemo(() => {
    //@ts-ignore
    const typeIds = [...new Set(data.map(i => i.Name))].length;
    return typeIds * 40;
  }, [data]);

  const tooltip = useMemo(
    () => ({
      showMarkers: false,
      customContent: (title: any, data: any) => {
        title =
          chartType === HeatmapConfigType.BAR && title
            ? title?.split(', ')[1]
            : title;
        return (
          <TooltipHeatmap title={title} data={data} configType={chartType} />
        );
      },
      domStyles: tooltip_domStyles,
    }),
    [chartType],
  );

  const dataFiltered = useMemo(() => {
    return data.filter((r, i) => {
      if (chartType !== ConfigType.BAR) return true;

      return minutesOfRangeInterval.includes(convertTIME(r.Time));
    });
  }, [data, chartType, minutesOfRangeInterval]);

  const [dataBar] = useState(data);

  const heatmap: JSX.Element = useMemo(() => {
    if (chartType === HeatmapConfigType.BAR && dataBar.length) {
      // DEPRECATED
      return (
        <Heatmap
          autoFit
          xField={chartType === 'BAR' ? 'Time' : undefined}
          height={chartHeight}
          data={dataFiltered}
          xAxis={{
            label: {
              formatter: (val: any) => {
                try {
                  if (chartType === 'BAR') return val?.split(', ')[1];
                  return val;
                } catch (e) {
                  return val;
                }
              },
            },
          }}
          configType={chartType === 'BAR' ? 'BAR' : 'SQUARE'}
          tooltip={tooltip}
        />
      );
    }

    return (
      <Heatmap
        autoFit
        height={chartHeight}
        data={data}
        configType={chartType}
        tooltip={tooltip}
      />
    );
  }, [chartHeight, chartType, data, dataBar.length, dataFiltered, tooltip]);

  return (
    <CardChart
      title={t('assets.totalConsumptionPerAsset')}
      titleTooltip={t('assets.uptimePercentage')}
      data-cypress="assets-chart"
    >
      {data.length ? (
        <>
          {heatmap}
          {chartType === ConfigType.BAR ? (
            <Slider
              tooltip={{ formatter: formatterSliderTooltip }}
              range={{ draggableTrack: true }}
              min={0}
              max={minutesOfInterval.length - 1}
              defaultValue={defaultValue}
              onChange={onChangeSlider}
              style={{ marginTop: 16 }}
            />
          ) : null}
        </>
      ) : (
        <EmptyStateChart style={{ height: 200 }} />
      )}
    </CardChart>
  );
}
