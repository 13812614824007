import { darkTheme } from '@theme/dark';
import { lightTheme } from '@theme/light';
import { ConfigProvider, Modal as ModalAntd, ModalProps } from 'antd';
import { ReactNode } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

import Button from '../button';
import Icon from '../icon';

export interface Prop extends ModalProps {
  dark?: boolean;
  light?: boolean;
  children?: ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
  data?: any;
  bigTitle?: boolean;
  lowerCloseIcon?: boolean;
}

const ModalGlobalStyle = createGlobalStyle<{ dark: boolean }>`
  .ant-modal {

    .ant-modal-title {
      font-size: 20px;
      font-weight: 700;
      color: ${props =>
    !!props.dark ? '#fff' : '#373737'}; // NB: #373737 non verificato
    }
    // FIX for safari
    .ant-modal-close{
      width: unset;
      height: unset;
    }
  }

  
`;

export const Footer = styled.div`
  display: flex;
  padding-top: 12px;
  gap: 24px;
  button {
    flex-grow: 1;
  }
`;

const StyledModalLight = styled(ModalAntd) <{
  bigTitle?: boolean;
  lowerCloseIcon?: boolean;
}>`
  ${({ lowerCloseIcon }) =>
    lowerCloseIcon &&
    css`
      .ant-modal-close {
        top: 24px;
        right: 24px;
      }
    `}
  ${({ bigTitle }) =>
    bigTitle &&
    css`
      .ant-modal-title {
        font-size: 24px;
        line-height: 32px;
        padding-bottom: 16px;
      }
    `}
    .ant-modal-content {
      background-color: white;
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.06);
      max-width: 320px;
      margin: 0 auto;
    }
    .ant-modal-header {
      background-color: white;
      margin-bottom: 24px;
      .ant-modal-title {
        color: #000
      }
    }
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: #373737;
      margin-bottom: 24px;
      .ant-input {
        background-color: white;
        color: #000
      }
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
      
      div {
        gap: 8px;
        display: flex;
        width: 100%;
        height: 44px;
      }

      button {
        background: transparent;
        flex: 1;
        margin: 0;
      }

      button:nth-child(1) {
        padding: 10px;
      }

      button:nth-child(2) {
        background: #E8684A;
        box-shadow: none;
        border: none;
        color: white
      }
    }
`;

const StyledModal = styled(ModalAntd) <{
  bigTitle?: boolean;
  lowerCloseIcon?: boolean;
}>`
  .ant-modal-content {
    padding: 16px;
    padding-top: 24px;
  }
  ${({ lowerCloseIcon }) =>
    lowerCloseIcon &&
    css`
      .ant-modal-close {
        top: 24px;
        right: 24px;
      }
    `}
  ${({ bigTitle }) =>
    bigTitle &&
    css`
      .ant-modal-title {
        font-size: 24px;
        line-height: 32px;
        padding-bottom: 16px;
      }
    `}
`;

export default function Modal({
  dark,
  light,
  children,
  onCancel,
  bigTitle,
  lowerCloseIcon,
  ...props
}: Prop) {
  return (
    <ConfigProvider theme={!!dark ? darkTheme : lightTheme}>
      <ModalGlobalStyle dark={!!dark} />
      {
        light ?
          <StyledModalLight bigTitle={bigTitle}
            lowerCloseIcon={lowerCloseIcon}
            centered
            closable={false}
            onCancel={onCancel}
            {...props}>
            {children}
          </StyledModalLight> : <StyledModal
            bigTitle={bigTitle}
            lowerCloseIcon={lowerCloseIcon}
            centered
            closeIcon={
              <Button
                center
                shape="square"
                variant="grey-E3"
                // icon={<Icon name="close" />}
                onClick={onCancel}
              >
                <Icon name="close" />
              </Button>
            }
            onCancel={onCancel}
            {...props}
          >
            {children}
          </StyledModal>
      }

    </ConfigProvider>
  );
}
