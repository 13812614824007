import { UseQueryOptions } from 'react-query/types/react/types';
import { minToMs } from '@src/utils';

export const fetchConfig: Omit<UseQueryOptions, 'queryKey' | 'queryFn'> = {
  retry: false,
  retryOnMount: false,
  refetchOnWindowFocus: false,
  refetchIntervalInBackground: true,
  refetchInterval: minToMs(5),
};
