import Select from '@components/atoms/select';
import { device } from '@theme/breakpoints';
import styled from 'styled-components';

export const WrapDateTimeFrameSelect = styled.div`
  @media ${device.laptop} {
    width: auto;
  }
  .ant-select {
    margin-top: 0 !important;
    @media ${device.laptop} {
      width: 302px !important;
    }
  }
`;

export const StyledSelect = styled(Select)`
  .ant-select-selector {
    border: 0px !important;
  }
`;

export const InputWrapper = styled.div<{ isMobile?: boolean }>`
  border: 1px solid rgb(200, 200, 200);
  border-radius: 6px;
  ${props => (props.isMobile ? 'width: 100%;' : '')};
`;

export const LabelWrapper = styled.div`
  color: #7a7a7a;
  padding: 4px 11px;
  font-size: 11px;
`;
