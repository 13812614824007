import { fontSize } from '@src/globalStyle';
import { lightTheme } from '@theme/light';
import { Cascader as CascaderAntd, CascaderProps } from 'antd';
import { BaseOptionType } from 'antd/lib/cascader';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import Button from '../button';
import Icon from '../icon';
import { options } from './cascader.mock';
import { t } from 'i18next';

export type Props = CascaderProps<BaseOptionType> & {
  customTrigger?: boolean;
  onConfirm: (filter: any) => void;
  onClear?: () => void;
  onReset?: () => void;
  filter?: any;
  setFilter?: Dispatch<SetStateAction<{}>>;
};

const { SHOW_CHILD } = CascaderAntd;

export interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

const CascaderGlobalStyle = createGlobalStyle<{ optionsFirstLevel: number }>`
    .ant-cascader-dropdown {
        border-color: #C8C8C8 !important; // TODO: HIGHT va in conflitto col dropdown della select

        ul.ant-cascader-menu {
            max-height: 200px;
            overflow-y: auto;
            height: 200px; // Imposta un'altezza fissa

            &:first-of-type .ant-cascader-checkbox {
                display: none;
            }

            .ant-cascader-menu-item {
                padding-top: 8px;
                padding-bottom: 8px;

                &-content {
                    font-weight: 500;
                    padding-right: 10px;
                }
            }

            .ant-cascader-checkbox {
                &-checked .ant-cascader-checkbox-inner,
                &:not(.ant-cascader-checkbox-disabled):hover .ant-cascader-checkbox-inner {
                    background-color: ${lightTheme.components.Checkbox.colorPrimary};
                    border-color: ${lightTheme.components.Checkbox.colorBorder};
                }

                .ant-cascader-checkbox-inner {
                    border: 2px solid ${lightTheme.components.Checkbox.colorPrimaryBorder};
                }
            }
        }
    }
`;
const StyledCascader = styled(CascaderAntd)`
  width: 100%;
`;

const DropdownCtas = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
  border-top: 1px solid #c8c8c8;

  button {
    width: auto;
  }
`;

const IconFilter = styled(Icon)`
  font-size: 14px;
`;

const NumberFilters = styled.span`
  font-family: 'Satoshi-Variable';
  font-weight: 400;
  ${fontSize.s} /* fontSize: 'calc(1rem - 2px)', */ padding-left: 8px;
  // make the span the same width with or without the number
  /* width: 15px; */
`;

export default function Cascader({
  filter,
  setFilter,
  onReset,
  ...props
}: Props) {
  const [, setText] = useState('Unselect');
  const [hasChanged, setHasChanged] = useState(false);
  const [valueForReset, setValueForReset] = useState<undefined | []>(undefined);

  useEffect(() => {
    return () => {
      setHasChanged(false);
      // onClickReset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = useCallback(
    (value: string[][], selectedOptions: Option[]) => {
      setHasChanged(true);
      const tmpFilter = {};
      for (let i = 0; i < value.length; i++) {
        // @ts-ignore
        if (!tmpFilter[value[i][0]]) tmpFilter[value[i][0]] = [];
        // @ts-ignore
        tmpFilter[value[i][0]].push(value[i][1]);
      }
      if (setFilter) {
        setFilter(() => tmpFilter);
      }
      setText(() => selectedOptions.map(o => o.label).join(', '));
    },
    [setFilter],
  );
  const [open, setOpen] = useState(false);

  const numberOfFilters = useMemo(() => {
    let count = 0;
    if (!!filter) {
      for (const [key, value] of Object.entries(filter)) {
        // @ts-ignore
        count += value.length;
      }
    }
    return count;
  }, [filter]);

  const onClickOk = useCallback(() => {
    props?.onConfirm(filter);
    setOpen(false);
  }, [filter, props]);

  const onClickReset = useCallback(() => {
    if (onReset) {
      onReset();
    }
    setHasChanged(false);
    // @ts-ignore
    setText('Unselect');
    if (setFilter) {
      setFilter({});
    }
    setValueForReset([]);
    setOpen(false);
    // wait for the value to be reset
    setTimeout(() => {
      setValueForReset(() => undefined);
      props?.onClear && props?.onClear();
    }, 10);
  }, [onReset, props, setFilter]);

  const dropdownRender = useCallback(
    (menus: React.ReactNode) => (
      <div>
        {menus}
        {/* <DropdownDivider /> */}
        <DropdownCtas>
          <Button
            onMouseDown={e => e.preventDefault()}
            variant="ghost-black"
            disabled={numberOfFilters === 0}
            onClick={onClickReset}
            data-cypress="clear-button"
          >
            {t('general.reset')}
          </Button>
          <Button
            disabled={!hasChanged && numberOfFilters === 0}
            variant="grey"
            onClick={onClickOk}
            data-cypress="ok-button"
          >
            {(t('general.ok') as string).toUpperCase()}
          </Button>
        </DropdownCtas>
      </div>
    ),
    [hasChanged, numberOfFilters, onClickOk, onClickReset],
  );

  const onMouseDown = useCallback(
    (e: React.MouseEvent) => e.preventDefault(),
    [],
  );

  const onButtonClick = useCallback((e: any) => {
    setOpen(!open);
    e.target.focus();
  }, []);

  const translatedOptions = useMemo(
    () =>
      props.options?.map(el => {
        return { ...el, label: t(el.label) };
      }),
    [props.options],
  );

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CascaderGlobalStyle optionsFirstLevel={options?.length || 0} />
      <StyledCascader
        onMouseDown={onMouseDown}
        //@ts-ignore
        onChange={onChange}
        multiple
        maxTagCount="responsive"
        value={valueForReset}
        showCheckedStrategy={SHOW_CHILD}
        dropdownRender={dropdownRender}
        open={open}
        {...props}
        options={translatedOptions}
      >
        <span>
          <Button
            size="m"
            onBlur={() => {
              setOpen(false);
              props?.onConfirm(filter);
            }}
            active={numberOfFilters > 0}
            variant={'outline-grey'}
            onClick={onButtonClick}
            icon={<IconFilter name="filter_list" color="currentColor" />}
            style={{
              lineHeight: '26px',
            }}
            data-cypress="filters-cascader"
          >
            {/* Filter {text} */}
            {t('general.filters')}
            {numberOfFilters > 0 && (
              <NumberFilters>{numberOfFilters}</NumberFilters>
            )}
          </Button>
        </span>
      </StyledCascader>
    </div>
  );
}
