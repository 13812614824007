import {
  AlertDurationModeEnum,
  AlertSeverityEnumDto,
  AssetStageEnum,
  DayOfWeekEnum,
} from '@asset-manager/types';
import { consumptionMapping } from '@src/utils/mapping.utils';
import { capitalizeFirstLetter } from '@src/utils/string.utils';
import { Dayjs } from 'dayjs';
import i18next from 'i18next';
enum AlertDurationTypeEnum {
  MINUTES = 'MINUTES',
  HOURS = 'HOURS',
  DAYS = 'DAYS',
}

export const priorityOptions = () => [
  { label: i18next.t('general.normal'), value: AlertSeverityEnumDto.LOW },
  { label: i18next.t('general.high'), value: AlertSeverityEnumDto.HIGH },
];

export enum statusSelectOptions {
  IN = 1,
  NOT_IN = 0,
  HAS_VALUE = 2,
}

export const statusSelect = () => [
  { label: i18next.t('general.inStateOf'), value: statusSelectOptions.IN },
  {
    label: i18next.t('general.notInStateOf'),
    value: statusSelectOptions.NOT_IN,
  },
  {
    label: i18next.t('general.hasValueOf'),
    value: statusSelectOptions.HAS_VALUE,
  },
];

export const statusOptions = [
  {
    label: capitalizeFirstLetter(consumptionMapping.LOW),
    value: AssetStageEnum.STOP,
  },
  {
    label: capitalizeFirstLetter(consumptionMapping.MEDIUM),
    value: AssetStageEnum.IDLE,
  },
  {
    label: capitalizeFirstLetter(consumptionMapping.HIGH),
    value: AssetStageEnum.WORKING,
  },
];

export const statusOptionsGeneric = [
  { label: 'Alarm', value: AssetStageEnum.STOP },
  { label: 'Warning', value: AssetStageEnum.IDLE },
  { label: 'Normal', value: AssetStageEnum.WORKING },
];

export const durationSelect = () => [
  {
    label: i18next.t('general.forMoreThan'),
    value: AlertDurationModeEnum.MORE,
  },
  {
    label: i18next.t('general.forLessThan'),
    value: AlertDurationModeEnum.LESS,
  },
];

export enum datePeriodSelectOptions {
  DURING = 'during',
  ANY = 'any',
}

export const datePeriodSelect = (t: (key: string) => string) => [
  {
    label: t('general.duringPeriod'),
    value: datePeriodSelectOptions.DURING,
  },
  { label: t('general.anyData'), value: datePeriodSelectOptions.ANY },
];

export enum timePeriodSelectOptions {
  INTERVAL = 'interval',
  ANY = 'any',
}

export const timePeriodSelect = () => [
  {
    label: i18next.t('general.inInterval'),
    value: timePeriodSelectOptions.INTERVAL,
  },
  { label: i18next.t('general.anyHour'), value: timePeriodSelectOptions.ANY },
];

export const daysOptionsDefault = () => [
  { label: i18next.t('general.monday'), value: 1, disabled: false },
  { label: i18next.t('general.tuesday'), value: 2, disabled: false },
  { label: i18next.t('general.wednesday'), value: 3, disabled: false },
  { label: i18next.t('general.thursday'), value: 4, disabled: false },
  { label: i18next.t('general.friday'), value: 5, disabled: false },
  { label: i18next.t('general.saturday'), value: 6, disabled: false },
  { label: i18next.t('general.sunday'), value: 0, disabled: false },
];

export enum ruleStatusValues {
  CONSUMPTION = 'consumption',
  TEMPERATURE = 'temperature',
  VIBRATION = 'vibration',
}

export const ruleStatusValuesOptions = [
  { label: 'Consumo', value: ruleStatusValues.CONSUMPTION },
  { label: 'Temperatura', value: ruleStatusValues.TEMPERATURE },
  { label: 'Vibrazione', value: ruleStatusValues.VIBRATION },
];

export enum ruleConjunctions {
  AND = 'AND',
  OR = 'OR',
}

export const ruleConjunctionsOptions = [
  {
    label: () => i18next.t('general.furthermore'),
    value: ruleConjunctions.AND,
  },
  { label: () => i18next.t('general.or'), value: ruleConjunctions.OR },
];

export enum ruleConditions {
  GREATER_THAN = 'MORE',
  LESS_THAN = 'LESS',
  THRESHOLD = 'THRESHOLD',
}

export const ruleConditionsOptions = [
  {
    label: 'general.inThreshold',
    value: ruleConditions.THRESHOLD,
  },
  {
    label: 'general.greaterThan',
    value: ruleConditions.GREATER_THAN,
  },
  { label: 'general.lessThan', value: ruleConditions.LESS_THAN },
];

export const ruleConditionValueUnit: { [key in ruleStatusValues]: string } = {
  [ruleStatusValues.CONSUMPTION]: 'kWh',
  [ruleStatusValues.TEMPERATURE]: '°C',
  [ruleStatusValues.VIBRATION]: 'mm/s',
};

export const mapRuleConditionValueUnitToDbUnits: {
  [key in ruleStatusValues]: string;
} = {
  [ruleStatusValues.CONSUMPTION]: 'KWATT_HOUR',
  [ruleStatusValues.TEMPERATURE]: 'TEMP_CELSIUS',
  [ruleStatusValues.VIBRATION]: 'MILLIMETERS_PER_SECOND',
};

export type FormFields = {
  textTemplate?: string;
  subTypeInfoName?: string;
  subTypeInfoId?: string;
  name: string;
  message: string;
  datePeriodSelect: datePeriodSelectOptions;
  datePeriod: [Dayjs, Dayjs] | null;
  timePeriodSelect: timePeriodSelectOptions;
  duration: string;
  durationSelect: AlertDurationModeEnum;
  priority: AlertSeverityEnumDto;
  statusOption?: AssetStageEnum;
  statusSelect: statusSelectOptions;
  intervals: Array<{ startTime: Dayjs | string; endTime: Dayjs | string }>;
  days: Array<DayOfWeekEnum>;
  assets: Array<string>;
  // rules: Array<AlertExpressionDto>;
  rules: Array<{
    ruleConjunction?: ruleConjunctions;
    ruleStatus: statusSelectOptions;
    ruleStatusValue?: ruleStatusValues;
    ruleCondition?: ruleConditions;
    ruleConditionValue: string;
    statusOption?: AssetStageEnum;
  }>;
  sendEmail: boolean;
  sendEmailTo: string[];
};

export const initialValues: Partial<FormFields> = {
  priority: AlertSeverityEnumDto.LOW,
  statusSelect: statusSelectOptions.IN,
  statusOption: AssetStageEnum.STOP,
  durationSelect: AlertDurationModeEnum.MORE,
  datePeriodSelect: datePeriodSelectOptions.ANY,
  timePeriodSelect: timePeriodSelectOptions.ANY,
  days: daysOptionsDefault().map(option => option.value),
  message: '',
  rules: [
    {
      ruleStatus: statusSelectOptions.HAS_VALUE,
      // ruleCondition: ruleConditions.GREATER_THAN, // ruleConditionsOptions[1].value,
      ruleConditionValue: '',
    },
  ],
};
