import { Space } from 'antd';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';

type Item = {
  label: string;
  color: string;
};

interface Props extends HTMLAttributes<HTMLDivElement> {
  data: Item[];
  colorTitle?: string;
  center?: boolean;
}

const LegendSpace = styled<any>(Space)`
  width: 100%;
  ${props => props.center && 'justify-content: center;'}
`;

const LegendItem = styled.div`
  display: inline-flex;
  align-items: center;
  font-family: 'Satoshi-Variable';
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #373737; // #FFFFFF for light theme
`;
const LegendCircle = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: ${({ color }) => color};
`;

export default function Legend({ data, ...props }: Props) {
  return (
    <LegendSpace size={16} {...props}>
      {data.map((item, index) => (
        <LegendItem
          key={index}
          style={{ color: props.colorTitle || undefined }}
        >
          <LegendCircle color={item.color} />
          {item.label}
        </LegendItem>
      ))}
    </LegendSpace>
  );
}
