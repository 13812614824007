import Icon from '@components/atoms/icon';
import { Select as SelectAntd, SelectProps } from 'antd';
import { useEffect, useLayoutEffect, useState } from 'react';

import { options } from './modal-detail-stop.config';
import {
  SingleOption,
  SingleOptionIcon,
  StyledSelect,
} from './modal-detail-stop.style';
import { useTenant } from '@src/modules/asset';
import { useGetTenantEventSubTypes } from '@src/api';
import { useTranslation } from 'react-i18next';

const { Option } = SelectAntd;

type SelectStopCategoryValue = string | string[] | undefined;
type SelectStopCategoryProps = {
  defaultValue: SelectStopCategoryValue;
  withNewCategory?: boolean;
  onChange: (value: SelectStopCategoryValue) => void;
};
const newCategoryKey = Math.random().toString(36).substring(7);

export default function SelectStopCategory({
  defaultValue,
  onChange,
  withNewCategory,
  ...props
}: SelectStopCategoryProps & SelectProps): JSX.Element {
  const [showNewCategory, setShowNewCategory] = useState(false);
  // q: what is the opposite of show in css?
  // a: hide
  // q: in what property?
  // a: opacity

  useTenant();
  const { t } = useTranslation()
  const { data: subTypes } = useGetTenantEventSubTypes();
  const darkTheme = true;
  const [selectedCategory, setSelectedCategory] = useState<
    string | string[] | undefined
  >(() => defaultValue);
  const onSearch = (value: string) => {
    console.log('search:', value);
  };
  useLayoutEffect(() => {
    setSelectedCategory(() => defaultValue);
  }, [defaultValue]);
  useEffect(() => {
    setSelectedCategory(() => defaultValue);
  }, [defaultValue]);
  useEffect(() => {
    onChange(selectedCategory);
  }, [selectedCategory, onChange]);

  const COLORS = {
    LOW: '#18CD4B',
    MEDIUM: '#F6BD16',
    HIGH: '#E8684A',
    PURPLE: '#8C54FF',
    BLUE: '#1890FF',
    WHITE: '#FFFFFF',
    BROWN: '#D9BFA5',
    PINK: '#FF85A5',
    GRAY: '#D9D9D9',
  };

  return (
    <>
      <StyledSelect
        dark
        size="large"
        style={{ width: '100%' }}
        placeholder={
          selectedCategory
            ? options.find(item => item.value === selectedCategory)?.label
            : t('general.addCategory')
        }
        onChange={setSelectedCategory}
        onSearch={onSearch}
        value={selectedCategory}
        suffixIcon={
          <Icon
            name="expand_circle_down"
            color={darkTheme ? '#fff' : '#1C1B1F'}
            style={{ fontSize: 16 }}
          />
        }
        options={undefined}
        showSearch
        defaultValue={selectedCategory}
        optionLabelProp="label"
        optionFilterProp="children"
        filterOption={(input, option) =>
          (String(option?.label) ?? '')
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        {...props}
      >
        {subTypes?.items.map((item: any, index: number) => (
          <Option value={item.key} label={item.name} key={index}>
            <SingleOption>
              <SingleOptionIcon
                name={'radio_button_checked'}
                color={
                  COLORS[item.severity as 'LOW' | 'MEDIUM' | 'HIGH'] ||
                  COLORS.LOW
                }
                aria-label={item.name}
              />
              {item.name}
            </SingleOption>
          </Option>
        ))}
        {withNewCategory && (
          <Option value={'new_category'} label={t('general.newCategory')} key={-1}>
            <SingleOption
              onClick={() => {
                setShowNewCategory(() => true);
              }}
            >
              <SingleOptionIcon
                color={
                  selectedCategory === 'new_category'
                    ? 'rgb(55, 55, 55)'
                    : 'white'
                }
                name={'add_circle'}
                aria-label={t('general.newCategory')}
              />
              {t('general.newCategory')}
            </SingleOption>
          </Option>
        )}
      </StyledSelect>
    </>
  );
}
