import { useQuery } from 'react-query';
import {
  ASSET_REPORT_TEMPERATURE_STATE_QUERY,
  fetchAssetTemperatureStateReport,
  ReportQueryParams,
} from '@src/api';
import { fetchConfig } from '@src/config/http';
import { minToMs } from '@src/utils';

export function useAssetTemperatureStateReport(
  tenantId: string,
  assetId: string,
  filters: ReportQueryParams,
  assetType: string,
) {
  return useQuery(
    [ASSET_REPORT_TEMPERATURE_STATE_QUERY, tenantId, assetId, filters],
    () => fetchAssetTemperatureStateReport(tenantId, assetId, filters),
    {
      ...(fetchConfig as any),
      enabled: !!tenantId && !!assetId && assetType === 'monitron',
      refetchInterval: minToMs(5),
    },
  );
}
