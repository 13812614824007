import Button from '@components/atoms/button';
import Icon from '@components/atoms/icon';
import Tooltip from '@components/atoms/tooltip';
import { fontSize } from '@src/globalStyle';
import { useTenantSlimReport } from '@src/modules/tenant';
import { device } from '@theme/breakpoints';
import { darkTheme } from '@theme/dark';
import { ConfigProvider, Spin } from 'antd';
import React, { HTMLAttributes, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled, { createGlobalStyle, css } from 'styled-components';

import Overview from '../overview';
import { buildOverviewList } from './company-preview.utils';
import DateTimeFrameSelectDashboard from '@components/atoms/date-time-frame-select-dashboard';
import { stopPropagationHelper } from '@src/utils';
import { assetFilterDateState } from '@store/asset/assetFilterDateState';
import { useTenant } from '@src/modules/asset';
import { useTranslation } from 'react-i18next';
import { formatTime } from '@src/utils/date.utils';

interface Props extends HTMLAttributes<HTMLDivElement> {
  compact: boolean;
  onClickArrow?: () => void;
}

// type ContainerProps = Pick<Props, 'compact'>;

const CompanyPreviewGlobalStyle = createGlobalStyle`
  .ant-spin-nested-loading {
    width: 100%;
    @media (max-width: 1357px) {
      overflow: hidden;
      border-radius: 12px;
    }
  }
`;

const Container = styled.div<{ compact: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  /* width: 912px; */
  width: 100%;
  background: #1c1c1e;
  border-radius: 12px;
  gap: 8px;
  min-height: 96px; // il loader cambia altezza

  /* @media (min-width: 370px) {
    height: 92px;
  }

  @media (min-width: 450px) {
    gap: 64px;
  } */

  @media ${device.laptop} {
    padding: 16px 24px;
  }

  ${({ compact }) =>
    compact &&
    css`
      .info-header {
        display: none;
      }
    `};

  .info-content-icon {
    font-size: 24px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;

  @media ${device.laptop} {
    width: unset;
  }
`;

const DataInfo = styled.div<{ compact: boolean }>`
  display: flex;
  flex-direction: ${props => (props.compact ? 'column' : 'row')};
  align-items: ${props => (props.compact ? 'flex-start' : 'center')};
  gap: 8px;
  flex-grow: 1;

  @media ${device.laptop} {
    gap: 64px;
  }
`;

const Title = styled.span`
  ${fontSize.m};
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;
  position: relative;
`;

const Time = styled.span`
  ${fontSize.label};
  font-weight: 700;
  text-transform: uppercase;
  color: #919191;
  min-width: 100px;
  text-align: center;
`;

const WrapDateTimeFrameSelect = styled.div`
  width: 100%;
  @media ${device.laptop} {
    width: auto;
  }
  .ant-select {
    margin-top: 0 !important;
    @media ${device.laptop} {
      width: 302px !important;
    }
  }
`;

const dateRangeToValueSelected = (from: string, to: string) => {
  // convert from to dd/mm/yy

  return `${new Date(Number(from) * 1000).toLocaleString('it-IT', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })} - ${new Date(Number(to) * 1000).toLocaleString('it-IT', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
  })}`;
};

const IconArrow = styled(Icon)`
  cursor: pointer;
  /* padding: 15px;
  transform: translateX(15px); */
`;

export default function CompanyPreview({
  compact,
  onClickArrow,
  ...props
}: Props) {
  // retrieve tenant informations and use them
  const { tenant } = useTenant();
  const { t } = useTranslation();

  // const ID_RANGE_PICKER_NODE = 'range-picker-node';
  const { tenantReport, isReportLoading, isFetching } = useTenantSlimReport();
  const [reportFilters] = useRecoilState(assetFilterDateState);
  const [valueSelected, setValueSelected] = useState<string>(
    dateRangeToValueSelected(reportFilters.startDate, reportFilters.endDate),
  );
  useEffect(() => {
    setValueSelected(() =>
      dateRangeToValueSelected(reportFilters.startDate, reportFilters.endDate),
    );
  }, [reportFilters]);
  const ref = React.createRef<HTMLSpanElement>();

  return (
    <ConfigProvider theme={darkTheme}>
      <CompanyPreviewGlobalStyle />
      <Spin size={'large'} spinning={!isReportLoading && isFetching}>
        <Container
          onClick={(e: React.MouseEvent): void => {
            const target = e.target as HTMLElement;
            if (target.matches('.ant-select-selection-item')) return;
            if (ref && ref.current && ref.current.contains(target)) return;
            if (typeof onClickArrow === 'function') {
              onClickArrow();
            }
          }}
          style={{
            cursor: typeof onClickArrow === 'function' ? 'pointer' : 'auto',
          }}
          compact={compact}
          {...props}
        >
          <DataInfo compact={compact}>
            <StyledDiv>
              <Title>
                overview
                <Tooltip
                  title={
                    'Dati aggregati riferiti all’insieme degli asset connessi.'
                  }
                >
                  <Icon
                    name="info"
                    style={{
                      fontSize: 16,
                      position: 'absolute',
                      paddingLeft: 8,
                    }}
                  />
                </Tooltip>
              </Title>

              <WrapDateTimeFrameSelect onClick={stopPropagationHelper}>
                <DateTimeFrameSelectDashboard />
              </WrapDateTimeFrameSelect>
            </StyledDiv>

            <Overview
              isLoading={isReportLoading}
              tenantType={tenant?.type}
              list={buildOverviewList(
                compact,
                tenantReport?.uptime,
                compact ? 0 : tenantReport?.uptimeVariation,
                tenantReport?.totalConsumption,
                // @ts-ignore
                tenantReport?.totalConsumptionUnitOfMeasure ?? 'kWh',
                tenantReport?.alertsCount ?? 0,
                formatTime(Number(tenantReport?.totalTimeInNoData), t),
                '',
                tenantReport?.assetsCount ?? 0,
              )}
              listGutter={window.innerWidth < 1024 ? [16, 16] : [60, 60]}
              infoContentSmall={window.innerWidth < 1024}
            />
          </DataInfo>

          {/* TODO: provide correct design based on tenant type */}
          {typeof onClickArrow === 'function' && tenant?.type !== 'GENERIC' && (
            <Button
              onClick={onClickArrow}
              center
              shape="square"
              variant="black"
            >
              <IconArrow name="chevron_right" color="white" />
            </Button>
          )}
        </Container>
      </Spin>
    </ConfigProvider>
  );
}
