// noinspection CssInvalidMediaFeature

import Info from '@components/molecules/info';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import { size } from '@theme/breakpoints';
import { Col, Row as RowAntd, Spin } from 'antd';
import { Gutter } from 'antd/es/grid/row';
import styled, { css } from 'styled-components';
import { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';

export interface Props {
  list?: Array<OverviewListItem | null>;
  listSpan?: number;
  listStyle?: React.CSSProperties;
  colStyle?: React.CSSProperties;
  listGutter?: Gutter | [Gutter, Gutter];
  split?: string; // 4n+1
  onClick?: () => void;
  infoContentSmall?: boolean;
  isLoading?: boolean;
  style?: React.CSSProperties;
  tenantType?: string;
}

export type OverviewListItem = {
  title: string;
  info: string;
  icon?: string;
  iconComponent?: React.ReactNode;
  iconColor?: string;
  value: string;
  label: string;
  labelColor?: string;
  footer?: string;
  titleColor?: string;
  titleAdditionalInfo?: string;
};

const Container = styled.div<{ split: Props['split'] }>`
  ${({ split }) =>
    split &&
    css`
      @media (min-width: ${size.laptop}) {
        // border-left: 1px solid #373737;
      }
    `}
`;

const Row = styled(RowAntd)`
  /* margin-top: 10px; // perché */
`;

const StyledSpin = styled(Spin)`
  @media (max-width: 1023px) {
    padding: 10px;
  }
`;

export default function Overview({
  split,
  list,
  listGutter,
  listStyle,
  colStyle,
  listSpan,
  onClick,
  infoContentSmall,
  isLoading = false,
  style,
  tenantType,
}: Props) {
  const isLaptop = useMediaQuery('laptop');
  const { t } = useTranslation();

  const styleCol: CSSProperties = {};
  if (split?.length) {
    styleCol.display = 'flex';
    styleCol.justifyContent = 'start';
    styleCol.alignItems = 'left';
    styleCol.flexDirection = 'column';
  }

  if (tenantType === 'GENERIC') {
    return null;
  }

  return (
    <Container split={split} onClick={onClick || undefined} style={style}>
      {isLoading ? (
        <StyledSpin size={isLaptop ? 'large' : 'small'} />
      ) : (
        list && (
          <Row gutter={listGutter} justify="space-between" style={listStyle}>
            {list.map((item, index) => (
              <>
                <Col
                  key={index}
                  span={listSpan}
                  style={{
                    ...styleCol,
                    ...colStyle,
                  }}
                >
                  {item !== null ? (
                    <Info
                      title={t(item.title)}
                      info={t(item.info)}
                      icon={item.icon}
                      iconComponent={item.iconComponent}
                      iconColor={item.iconColor}
                      value={item.value}
                      label={item.label}
                      labelColor={item.labelColor}
                      contentSmall={infoContentSmall}
                      titleColor={item.titleColor}
                      footer={item.footer}
                      titleAdditionalInfo={item.titleAdditionalInfo}
                    />
                  ) : null}
                </Col>
                {!!(index !== list.length - 1 && split?.length) ? (
                  <div style={{ borderRight: '1px solid #373737' }} />
                ) : null}
              </>
            ))}
          </Row>
        )
      )}
    </Container>
  );
}
