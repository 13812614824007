import { CascaderOption } from '.';

export const list1 = [
  {
    value: 'zhejiang',
    label: 'Zhejiang',
    children: [
      {
        value: 'hangzhou',
        label: 'Hangzhou',
      },
    ],
  },
  {
    value: 'jiangsu',
    label: 'Jiangsu',
    children: [
      {
        value: 'nanjing',
        label: 'Nanjing',
      },
    ],
  },
];

export const list2 = [
  {
    label: 'Light',
    value: 'light',
    children: new Array(20)
      .fill(null)
      .map((_, index) => ({ label: `Number ${index}`, value: index })),
  },
];

export const list3 = [
  {
    label: 'Bamboo',
    value: 'bamboo',
    children: [
      {
        label: 'Little',
        value: 'little',
        children: [
          {
            label: 'Toy Fish',
            value: 'fish',
          },
          {
            label: 'Toy Cards',
            value: 'cards',
          },
          {
            label: 'Toy Bird',
            value: 'bird',
          },
        ],
      },
    ],
  },
];

export const list = [
  {
    label: 'Stabilimento',
    value: 'stabilization',
    children: [
      {
        label: 'Torino',
        value: 'turin',
      },
      {
        label: 'Milano',
        value: 'milan',
      },
    ],
  },
  {
    label: 'Tipo',
    value: 'type',
    children: [
      {
        label: 'Macchina',
        value: 'little',
      },
    ],
  },
];

export const options: CascaderOption[] = [...list];
