import { AssetGetAssetResponseDto } from '@asset-manager/common/dist/asset';
import Divider from '@components/atoms/divider';
import EmptyState from '@components/atoms/empty-state';
import Icon from '@components/atoms/icon';
import CardOverview from '@components/molecules/card-overview';
import Overview, { OverviewListItem } from '@components/organisms/overview';
import { useGetAssetSensors } from '@src/api/asset/use-get-asset-sensors';
import { useWindowWidth } from '@src/hooks/useMediaQuery';
import { device } from '@theme/breakpoints';
import { darkTheme } from '@theme/dark';
import { Col, ConfigProvider, Row } from 'antd';
import { format } from 'date-fns';
import styled from 'styled-components';

import { RowExtraOverview } from '../common';
import DrawerSection from '../section/section';
import { buildOverviewList } from './sensors-drawer-content.utils';
import { useMemo } from 'react';
import { t } from 'i18next';
import { semaphoreMapStatus } from '@components/atoms/tag/tag';

const Container = styled.div``;

interface Props {
  item?: AssetGetAssetResponseDto;
  fullscreen: boolean;
}

const ContainerOverview = styled.div<{ fullscreen: boolean }>`
  padding: 16px 0;

  @media ${device.laptop} {
    margin: ${props => (props.fullscreen ? '0 80px' : '0')};
  }
`;

export default function SensorsDrawerContent({ item, fullscreen }: Props) {
  const buildOverviewListForBrain = (
    item: any,
  ): Array<OverviewListItem | null> => {
    const list: Array<OverviewListItem | null> = [];
    if (!item.mqttBrain) return list;
    const { mqttBrain } = item;
    if (mqttBrain.serialNumber) {
      list.push({
        icon: '',
        value: mqttBrain.serialNumber,
        info: '',
        label: '',
        title: t('sensor.serial'),
      });
    } else list.push(null);
    if (mqttBrain.firmwareVersion) {
      list.push({
        icon: '',
        value: mqttBrain.firmwareVersion,
        info: '',
        label: '',
        title: t('sensor.firmware'),
      });
    } else list.push(null);
    if (mqttBrain.productionDate) {
      list.push({
        icon: '',
        value: mqttBrain.productionDate,
        info: '',
        label: '',
        title: t('sensor.productionDate'),
      });
    } else list.push(null);
    return list;
  };
  const windowWidth = useWindowWidth();
  const subtitleDrawer = useMemo(
    () =>
      item &&
      item.updatedAt &&
      `${t('general.updatedAt')} ${format(new Date(item.updatedAt), 'HH:mm')}`,
    [item],
  );
  const { data } = useGetAssetSensors();
  const isMobile = useMemo(
    () => windowWidth && windowWidth < 1024,
    [windowWidth],
  );

  const overviewListBrain = useMemo(() => {
    if (!data) return [];
    return buildOverviewListForBrain(data);
  }, [data]);
  const buildAdditionalInfo = (
    item: any,
  ): Array<{ title: string; value: string }> => {
    const additionalInfo = [];

    if (item.fullScale)
      additionalInfo.push({
        title: t('sensor.fullScale'),
        value: item.fullScale,
      });
    if (item.serialNumber)
      additionalInfo.push({
        title: t('sensor.fullScale'),
        value: item.serialNumber,
      });
    if (item.productionDate)
      additionalInfo.push({
        title: t('sensor.productionDate'),
        value: item.productionDate,
      });

    return additionalInfo;
  };

  return (
    <Container>
      <DrawerSection
        style={{ marginTop: 32 }}
        title={(t('sensor.sensorLocalStatus') as string).toLocaleUpperCase()}
        subtitle={subtitleDrawer}
        extra={
          <RowExtraOverview justify="center" align="middle" fadeIn={fullscreen}>
            <ConfigProvider theme={darkTheme}></ConfigProvider>
          </RowExtraOverview>
        }
      >
        <ContainerOverview fullscreen={fullscreen}>
          {fullscreen && overviewListBrain && overviewListBrain.length > 0 && (
            <Overview
              // TODO LOADER
              isLoading={false}
              split={fullscreen ? '3n+1' : ''}
              list={overviewListBrain}
              listSpan={isMobile ? 12 : fullscreen ? undefined : 12}
              listGutter={[fullscreen ? 60 : 16, 16]}
              style={{ marginBottom: 50 }}
            />
          )}
          <Overview
            // TODO LOADER
            isLoading={false}
            split={fullscreen ? '3n+1' : ''}
            list={buildOverviewList(item?.rssiSignal, item?.batteryLevel)}
            listSpan={isMobile ? 12 : fullscreen ? 8 : 12}
            listGutter={[fullscreen ? 60 : 16, 16]}
            style={{ marginBottom: 16 }}
          />
        </ContainerOverview>
      </DrawerSection>

      <Divider dark />

      {/* TODO: re enable sensor list */}

      <DrawerSection title={(t('sensor.sensors') as string).toUpperCase()}>
        {data && data.items ? (
          <Row gutter={[fullscreen ? 16 : 0, 16]}>
            {data?.items.map((item: any, index: any) => (
              <Col key={index} span={isMobile ? 24 : fullscreen ? 8 : 24}>
                <CardOverview
                  key={index}
                  title={item.name}
                  subtitle={''}
                  status={item.state === 'ON' ? 'default' : 'error'}
                  statusTag={
                    item.state === 'ON'
                      ? 'success'
                      : item.state === 'OFF'
                      ? 'error'
                      : 'warning'
                  }
                  labelTag={item.state}
                  // list={item.list}
                  infoDirection="row"
                  additionalInfo={buildAdditionalInfo(item)}
                  chosenMapStatus={semaphoreMapStatus}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <EmptyState
            dark
            style={{ height: 200, width: fullscreen ? '200px' : undefined }}
            image={
              <Icon
                name="emergency_home"
                style={{ fontSize: 48 }}
                color="currentColor"
              />
            }
            title={t('sensor.noSensorFound')}
          />
        )}
      </DrawerSection>
    </Container>
  );
}
