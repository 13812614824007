import { useQuery } from 'react-query';
import { GET_ASSETS_QUERY_KEY } from '@src/api';
import { fetchAssets } from '@src/api/asset';
import { useRecoilState } from 'recoil';
import { assetFilterPlant } from '@store/asset/assetFilterPlantOptions';
import { fetchConfig } from '@src/config/http';
import { assetFilterDateState } from '@store/asset/assetFilterDateState';

export function useGetAssets(
  id?: string,
  searchParam?: string,
  avoidFilter?: boolean,
) {
  const [filter] = useRecoilState(assetFilterPlant);
  const [reportFilters] = useRecoilState(assetFilterDateState);

  const query = useQuery(
    [GET_ASSETS_QUERY_KEY, id, searchParam, filter, reportFilters, avoidFilter],
    () => {
      if (avoidFilter) return fetchAssets(id, undefined, undefined);
      return fetchAssets(id, filter, reportFilters);
    },
    { ...(fetchConfig as any) },
  );

  return query;
}
