import { atom } from 'recoil';

export interface AssetFilterPlantOptions {
  name?: string;
  limit?: string;
  skip?: string;
  departments?: string[];
  lines?: string[];
  plants?: string[];
  types?: string[];
  assets?: string[];
}

export const defaultAssetFilterState = {
  name: '',
  skip: '0',
  limit: '10',
} as AssetFilterPlantOptions

export const assetFilterPlant = atom({
  key: 'assetsFilterState',
  default: defaultAssetFilterState
});


