import { useQuery } from 'react-query';
import {
  fetchTenantSlimReport,
  ReportQueryParams,
  TENANT_SLIM_REPORT_QUERY_KEY,
} from '@src/api';
import { fetchConfig } from '@src/config/http';
import { minToMs } from '@src/utils';

export function useTenantSlimReport(id: string, filters: ReportQueryParams) {
  return useQuery(
    [TENANT_SLIM_REPORT_QUERY_KEY, id, filters],
    () => fetchTenantSlimReport(id, filters),
    {
      ...(fetchConfig as any),
      enabled: !!id,
      refetchInterval: minToMs(5),
    },
  );
}
