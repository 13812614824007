import { device, size } from '@theme/breakpoints';
import { Row } from 'antd';
import styled, { css } from 'styled-components';

export const StylizedTable = styled.div<{ isSafari: string }>`
  width: 100%;
  /* overflow: auto; */

  .ant-table-wrapper .ant-table-thead > tr > th {
    border-bottom: none;
  }

  .ant-table-wrapper
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan])::before {
    display: none;
  }

  .ant-pagination .ant-pagination-item-active {
    background-color: #fbfbfb !important;
    border-color: #fbfbfb;
  }

  .ant-pagination .ant-pagination-prev,
  .ant-pagination .ant-pagination-next {
    height: 44px;
  }

  .ant-table-filter-column {
    justify-content: flex-start;
    align-items: center;

    .ant-table-column-title {
      flex: initial;
    }
  }

  .ant-table-wrapper .ant-table-filter-trigger {
    background: #d9d9d9;
    color: #7a7a7a;
    width: 24px;
    height: 24px;
    padding: 0;
    justify-content: center;

    .material-symbols-outlined {
      font-size: 16px;
    }
  }

  /* .ant-table-fixed {
    table-layout: fixed;
  } */

  .ant-table-tbody > tr > td {
    // altrimenti le colonne non assumono la width settata
    word-wrap: break-word;
    word-break: break-all;
    // altrimenti fa un brutto effetto con i bordi convessi
    border-top-color: transparent !important;
  }

  .ant-pagination {
    font-weight: 700; // il tema considera solo la pagina selezionata
  }

  .ant-pagination .ant-pagination-item {
    background-color: #e3e3e3;
    width: 44px;
    height: 44px;
    line-height: 44px;
  }

  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: #fafafa; //inherit;
  }

  .ant-table-wrapper .ant-table-tbody > tr.ant-table-row > td {
    background-color: #fbfbfb;
  }

  .ant-table-wrapper table {
    /* flex: 1; */
    border-spacing: 0 4px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    background-color: #ececec !important;
  }

  .ant-table-row {
    td {
      transition: none !important;
    }

    td:first-child {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    td:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  tbody tr {
    cursor: pointer;
  }

  @media ${device.laptopM} {
    tbody tr.enh-selected-row {
      position: relative;
      :after {
        content: '';
        position: absolute;
        /* top: 0; */
        height: calc(100%);
        height: 100%; //77px; // come la riga
        width: 17px; //22px;
        background-color: #1c1c1e;

        /*
          // TEST per safari
          right: -16px;
          top: -5px;
          background-color: #1c1c1e;
          background-image: url('https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png');
          background-size: contain;
          background: url('http://localhost:3000/assets/images/EnhancersBlack.png');
          // png 64 stond
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAAEoCAYAAADcwDZBAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARHSURBVHgB7dlPThNhGIDx75u2CUKBxlWTFjIuXVlPAEfwCNxAbgA30Bu4dqU3gBvoDeySnZUVCe2M8wmNYwO1f+bZPb+E0k6HzZM3b2dKCJL0pH4/z7MgTLsdDEwqyzAyMKowMOzEwJDBIB/F6A4GFefp0cCck/RgYMBgcHSW1kN6bmBEvJg/awc1ajg8TnHz+esY1Jh0a9xuFz/qx1wRDen18l6rVVwtHndFNKTbLT+F2mqYc4Ib8LB3y3dPvecO3tLjh9rlc++7IjaUdu7eXvGhenq27DwneAPpe4bqVvjL/GZiGQOvIU1tt1u8D0tWwiIDr6AWNn2B01vnbw28xHCYn1arIH1ps3bYOQOHhwnt9UJvNpuNiiLmWRbeVP/uSZddG0Wti9VlRhmE8UYDZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEGhhkYZmCYgWEx6I+8Mp2GvCzDKISi+oknMZZ52JKBl6iaj+7vi/NtYht4RYNBfhZCebFuaAOvaTg8vqx+Xax6voE30O/neatVXq0yzV5FbODmZjzudOLbGOPX/53bCtrIZDK5u7399fng4DBtgdPnzjPwlqrI18siG7gBKfL+/uGrGNM19L/cwQ3pdLLzsozjxeNeRTQo3ZhMp8W3+jFXRIOqD76bxX3simhYu519rHbxZP7aCW5YunyrpvhFeJxiJxhQn2InGFCfYicYk10HsY6Ojn+6IkDV3d1rVwQq+25gUJbNxq4I0O7uyzsnGLSz8/eOTpDfNdlxnV1OOvcAAAAASUVORK5CYII=');
          // png 64
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAABNCAYAAACi/ZQPAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACrSURBVHgB7djBCcMwDAVQOU4CPdTt0SBRNEI2aDfpCM2GGaEjdIUccnYrF7qBXAj8DwadnmV8+0R7CjNPHTVJ9wjknJyz9v34ct24osMwLnV22VhEbvb8q42znfMXFrm8qUEafR5gwIABAwYMGDBgwIABAwYMGDBgwIABA/4P7FJMM+tEVOYQ6O4K/2JNusZYFrtAG5T/auV/eUZyzrata0qngztck9KR9pcPyggRy5dbS+0AAAAASUVORK5CYII=');
          //  png 4*
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAAE0CAYAAACo1PShAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARoSURBVHgB7dlNThNhHIDxd6ZTgnxpXDWZQsYTUE8AR/AI3EBuUG6gN3DtSj0B3EBvYJfsrLCBpHR8X6TaNFD7MY+r55dA2+l08+SfPzMlBEl6VKdTVXkQpihGBibled4zMOjurj40MCjPs2MDQ8qy7NV1cAdz8rf3v4MQaT3cPwY1riz3T9J6SM8NDIjT2//zPKhR3e5BfzK9SRbUmE68Ny6Kje/Tx5zghryI2u2N89njBm7Izs7uh+nVMFEErS3t3fjw5rH33MFreoh79tT7TvCK0s7d3t59F5+ezDvPCV5B+p4hz1ufHtu5swy8hDS1Ozt76TuGs0U/Y+AFTIU9TS+X+ayB5+h2u8fxSvYorBB2wsDh94Qmt7d3cbdmVZ6Hw7hf02XXSlGnZfEyow7CeCcHMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwLAs6F4VjUajqq7zXnyZfo6yLFRhTQaeoyyrGHp8GtaIbeAFleX+SczVXza0gZfU7R6cxYf+oucbeAWdTlW1WuPzRabZq4gVXF4OBu12/jrO5+d/ndsKWslwOLy5uvr5cW/vedoCx0+dZ+A1xcgX8yIbuAEp8u7u3qssy3qz77mDG9Jut07rOgxmj3sV0aB0Y5Jl46/Tx1wRDbq+Hl7O7mNXRMOKIn8fH4aT105ww9LlW5ziZ+Fhip1gwPQUO8GA6Sl2gjHjiyBW/ObthxMMijceXwwMarXCNwOD6roeeBUB2tp6eeMEgzY3/97RSZL+r/TfZ//IgYpiVP0CkAxuorsykYwAAAAASUVORK5CYII=');
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFgAAAE0CAYAAACo1PShAAAACXBIWXMAACxLAAAsSwGlPZapAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAARoSURBVHgB7dlNThNhHIDxd6ZTgnxpXDWZQsYTUE8AR/AI3EBuUG6gN3DtSj0B3EBvYJfsrLCBpHR8X6TaNFD7MY+r55dA2+l08+SfPzMlBEl6VKdTVXkQpihGBibled4zMOjurj40MCjPs2MDQ8qy7NV1cAdz8rf3v4MQaT3cPwY1riz3T9J6SM8NDIjT2//zPKhR3e5BfzK9SRbUmE68Ny6Kje/Tx5zghryI2u2N89njBm7Izs7uh+nVMFEErS3t3fjw5rH33MFreoh79tT7TvCK0s7d3t59F5+ezDvPCV5B+p4hz1ufHtu5swy8hDS1Ozt76TuGs0U/Y+AFTIU9TS+X+ayB5+h2u8fxSvYorBB2wsDh94Qmt7d3cbdmVZ6Hw7hf02XXSlGnZfEyow7CeCcHMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwDADwwwMMzDMwLAs6F4VjUajqq7zXnyZfo6yLFRhTQaeoyyrGHp8GtaIbeAFleX+SczVXza0gZfU7R6cxYf+oucbeAWdTlW1WuPzRabZq4gVXF4OBu12/jrO5+d/ndsKWslwOLy5uvr5cW/vedoCx0+dZ+A1xcgX8yIbuAEp8u7u3qssy3qz77mDG9Jut07rOgxmj3sV0aB0Y5Jl46/Tx1wRDbq+Hl7O7mNXRMOKIn8fH4aT105ww9LlW5ziZ+Fhip1gwPQUO8GA6Sl2gjHjiyBW/ObthxMMijceXwwMarXCNwOD6roeeBUB2tp6eeMEgzY3/97RSZL+r/TfZ//IgYpiVP0CkAxuorsykYwAAAAASUVORK5CYII=');
          // svg base 64
          background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjIiIGhlaWdodD0iNzciIHZpZXdCb3g9IjAgMCAyMiA3NyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNiA2QzE5LjMxMzcgNiAyMiAzLjMxMzcxIDIyIDBWNzZDMjIgNzIuNjg2MyAxOS4zMTM3IDcwIDE2IDcwSDBWNkgxNlpNMjIgNzZWNzdIMjEuOTE3QzIxLjk3MTYgNzYuNjc0OCAyMiA3Ni4zNDA3IDIyIDc2WiIgZmlsbD0iIzFDMUMxRSIvPgo8L3N2Zz4K');
          background-image: url('../../assets/images/Union.png');
          background: url('/assets/images/EnhancersBlack.png') #1c1c1e right center;
          background-size: 150%;
        */

        ${({ isSafari }) =>
          isSafari &&
          isSafari === 'true' &&
          css`
            height: 56px;
          `};

        // https://stackoverflow.com/questions/16348489/is-there-a-way-to-apply-styles-to-safari-only
        // not work
        /* @media not all and (min-resolution: 0.001dpcm) {
          @media {
            .safari_only {
              height: 56px;
              background-color: red;
            }
          }
        } */
      }
      /* :hover {
        :after{
          height: 100%;
        }
        td {
          &:last-child {
            :after {
              top: 100%;
            }
          }
        }
      } */
    }
  }

  tr.enh-selected-row td {
    background-color: #1c1c1e !important;
    border: none !important;
    z-index: 1;

    & > span:not([class^='ant-tag']) {
      color: white;
    }

    @media ${device.laptopM} {
      &:last-child {
        background-color: #1c1c1e !important;
        border-bottom-right-radius: 0 !important;
        border-top-right-radius: 0 !important;
        background-clip: border-box !important;

        &:before,
        &:after {
          // TODO su safari nascondo le curve perché renderizzate male
          ${({ isSafari }) =>
            isSafari &&
            isSafari === 'true' &&
            css`
              display: none;
            `};
          content: '';
          position: absolute;
          top: -19px;
          right: -17px;
          left: 0;
          height: 20px;
          rotate: 180deg;
          background: radial-gradient(
              farthest-side at bottom right,
              transparent 95%,
              #1c1c1e 100%
            )
            0/20px no-repeat;
        }

        :after {
          top: calc(100% - 1px);
          background: radial-gradient(
              farthest-side at top right,
              transparent 95%,
              #1c1c1e 100%
            )
            0/20px no-repeat;
        }
      }
    }
  }
`;

export const AssetLabel = styled.span<{ dark: boolean }>`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${props => (props.dark ? '#ffffff' : '#373737')};
`;

export const AssetValue = styled.span<{ dark: boolean }>`
  font-weight: 700;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: ${props => (props.dark ? '#919191' : '#7a7a7a')};
`;

export const ItemsFound = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #7a7a7a;
  margin-left: 8px;

  @media (max-width: ${size.laptop}) {
    display: none;
  }
`;

export const RowPreview = styled(Row)`
  width: 100%;
  margin-bottom: 32px;
  position: relative;
  .cssid-company-preview {
    &.selected {
      @media ${device.laptopM} {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .cssid-company-preview > div:first-child {
    align-items: flex-start;
  }

  .cssid-company-preview > div > div:nth-child(2) > div {
    row-gap: 0px !important;
  }
`;

export const ConnectorCompanyPreview = styled.div<{ spinning: string }>`
  @media ${device.laptopM} {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;

    position: absolute;
    top: 0;
    right: -18px;
    height: calc(100%);
    width: 18px;
    background-color: #1c1c1e;

    // NB: stessi valori dello spin di antd
    opacity: ${({ spinning }) => (spinning === 'true' ? 0.5 : 1)};
    transition: opacity 0.3s;
    /* ${({ spinning }) =>
      spinning &&
      spinning === 'true' &&
      css`
        opacity: 0.5;
      `} */

    :before,
    :after {
      content: '';
      position: absolute;
      top: -19px;
      right: 0px;
      left: 0;
      height: 20px;
      rotate: 180deg;
      background: radial-gradient(
          farthest-side at bottom right,
          transparent 95%,
          #1c1c1e 100%
        )
        0/20px no-repeat;
    }

    :before {
      top: -16px;
    }

    :after {
      top: calc(100% - 4px);
      background: radial-gradient(
          farthest-side at top right,
          transparent 95%,
          #1c1c1e 100%
        )
        0/20px no-repeat;
    }
  }
`;

export const RowFilters = styled(Row)`
  width: 100%;
  margin: 0 0 24px;
  gap: 8px;
  align-items: center;

  .ant-input-affix-wrapper {
    height: 44px;
    flex-grow: 1;
    width: min-content;

    @media ${device.laptop} {
      width: auto;
      flex-grow: 0;
    }
  }

  .ant-input-affix-wrapper-focused {
    flex: 1;
    & + span {
      display: none;
    }
  }
`;
