import { AlertSeverityEnumDto } from '@asset-manager/types';
import EmptyState from '@components/atoms/empty-state';
import Icon from '@components/atoms/icon';
import MenuPopover from '@components/molecules/menu-popover';
import PlayIcon from '@src/assets/icons/PlayIcon';
import StopIcon from '@src/assets/icons/StopIcon';
import TrashIcon from '@src/assets/icons/TrashIcon';
import { device } from '@theme/breakpoints';
import { Row, Table, Tabs } from 'antd';

import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  .ant-tabs-ink-bar {
    display: none;
  }

  .ant-tabs-nav::before {
    border: none;
  }

  .ant-tabs-nav-list {
    flex-grow: 1;
  }

  .ant-tabs-tab {
    flex-grow: 1;
    background-color: #e3e3e3;
    display: flex;
    justify-content: center;
    padding-block: 4px;
    border-radius: 6px;
    font-weight: 700;
    color: #373737;
  }

  .ant-tabs-tab-active {
    background-color: #fff;
  }

  .ant-tabs-tab-btn {
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }
`;

export const RowFilters = styled(Row)`
  width: 100%;
  margin: 0 0 24px;
  gap: 8px;
  align-items: center;

  .ant-input-affix-wrapper {
    height: 44px;
    flex-grow: 1;
    width: min-content;

    @media ${device.laptop} {
      width: auto;
      flex-grow: 0;
    }
  }

  .ant-input-affix-wrapper-focused {
    flex: 1;
    & + span {
      display: none;
    }
  }

  .action-containers {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: #7a7a7a;

    .action-buttons {
      display: flex;
      gap: 8px;
    }
  }
`;

export const StyledTable = styled(Table)`
  .ant-table {
    max-width: 100vw;
    overflow-x: auto;
  }
  .ant-table-thead .ant-table-cell {
    background-color: transparent;
    border: none;
  }

  .ant-table-thead .ant-table-cell::before {
    display: none;
  }

  .ant-table-row {
    cursor: pointer;
    .ant-table-cell:nth-child(1) {
      max-width: 250px;
    }
  }

  .ant-table-row
    > .ant-table-cell
    > .ant-checkbox-wrapper
    > .ant-checkbox-checked
    .ant-checkbox-inner {
    background-color: #1c1b1f !important;
    border-color: #1c1b1f;
  }

  .ant-table-row
    > .ant-table-cell
    > .ant-checkbox-wrapper
    > .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
    border-color: #1c1b1f;
  }

  .ant-table-tbody .ant-table-row:not(:last-child) .ant-table-cell {
    border-bottom: 2px solid #ececec;
  }

  .ant-table-tbody .ant-table-row:not(:first-child) .ant-table-cell {
    border-top: 2px solid #ececec;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell {
    background-color: #fbfbfb;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell:first-child {
    border-radius: 6px 0 0 6px;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell:last-child {
    border-radius: 0 6px 6px 0;
  }

  .ant-pagination-item {
    font-weight: 700;
    background-color: #e3e3e3;
    border: none;
    width: 44px;
    height: 44px;
  }

  .ant-pagination-item-active {
    background-color: #fbfbfb;
  }

  .ant-pagination-item a {
    line-height: 44px;
  }

  .ant-pagination-next,
  .ant-pagination-prev {
    width: 44px;
    height: 44px;
  }

  .ant-empty {
  }

  .ant-pagination-options {
    display: flex;
    align-items: center;
  }
`;

export const BoldCell = styled.span`
  font-weight: 700;
`;

export const ErrorOrEmptyContainer = styled.div`
  background-color: #fff;
  padding-block: 64px;

  > div {
    max-width: 302px;
    margin: auto;
  }
`;

export const TableEmptyState = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) => {
  return (
    <ErrorOrEmptyContainer>
      <EmptyState
        title={title}
        subtitle={subtitle}
        image={
          <Icon
            name="emergency_home"
            color="#373737"
            style={{ fontSize: 48 }}
          />
        }
      />
    </ErrorOrEmptyContainer>
  );
};

export const renderDescriptionOrName = (
  dataIndices: string | string[],
  rowData: any,
) => {
  if (rowData && rowData.alertName) {
    return <BoldCell>{rowData?.alertName}</BoldCell>;
  } else if (rowData && rowData.description) {
    return <BoldCell>{rowData?.description}</BoldCell>;
  } else {
    return <BoldCell>{rowData?.name}</BoldCell>;
  }
};

export const renderBoldCell = (text: string) => <BoldCell>{text}</BoldCell>;

export const renderSeverityIcon = (
  severity: AlertSeverityEnumDto,
  t: (key: string) => string,
) => {
  return (
    <div
      style={{
        borderRadius: '50%',
        backgroundColor: '#28282A',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 32,
        height: 32,
      }}
    >
      <Icon
        name="emergency_home"
        style={{
          fontSize: 20,
        }}
        title={
          severity === AlertSeverityEnumDto.HIGH
            ? t('general.high')
            : t('general.normal')
        }
        color={severity === AlertSeverityEnumDto.HIGH ? '#E8684A' : '#F6BD16'}
      />
    </div>
  );
};

export const renderAlertMenu = ({
  onDelete,
  onOpenDetails,
  onActive,
  onDisactivate,
  active,
  t,
}: {
  onDelete: () => void;
  onOpenDetails: () => void;
  onActive: () => void;
  onDisactivate: () => void;
  active: boolean;
  t: (key: string) => string;
}) => {
  const menuItems = [
    {
      label: t('general.seeDetails'),
      onClick: onOpenDetails,
      icon: <Icon name="visibility" color="#1C1B1F" style={{ fontSize: 16 }} />,
    },
    !active
      ? {
          label: t('general.active'),
          onClick: onActive,
          icon: (
            <Icon name="play_circle" color="#1C1B1F" style={{ fontSize: 16 }} />
          ),
        }
      : {
          label: t('general.secondUnactive'),
          onClick: onDisactivate,
          icon: (
            <Icon name="stop_circle" color="#1C1B1F" style={{ fontSize: 16 }} />
          ),
        },
    {
      label: t('general.delete'),
      onClick: onDelete,
      icon: <Icon name="delete" color="#E8684A" style={{ fontSize: 16 }} />,
    },
  ];

  return <MenuPopover menuItems={menuItems} />;
};

export const renderEventMenu = ({
  onDelete,
  onOpenDetails,
  t,
}: {
  onDelete: () => void;
  onOpenDetails: () => void;
  t: (key: string) => string;
}) => {
  const menuItems = [
    {
      label: t('general.seeDetails'),
      onClick: onOpenDetails,
      icon: <Icon name="visibility" color="#1C1B1F" style={{ fontSize: 16 }} />,
    },
    {
      label: t('general.delete'),
      onClick: onDelete,
      icon: <Icon name="delete" color="#E8684A" style={{ fontSize: 16 }} />,
    },
  ];

  return <MenuPopover menuItems={menuItems} />;
};
