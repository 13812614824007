import { GET_ASSET_NOTES_QUERY_KEY } from '@asset-manager/api';
import { queryClient } from '@src/App';
import { useMutation } from 'react-query';
import { createNote, CreateNoteBody } from './create-note';
import analytics from '@src/utils/analytics';
import { useTenant } from '@src/modules/asset';
import { useAmplify } from '@src/modules/amplify';
import { format } from 'date-fns';

export function useCreateNote() {
  const { tenant } = useTenant();
  const { user } = useAmplify();

  return useMutation({
    mutationFn: (data: {
      tenantId: string;
      assetId: string;
      body: CreateNoteBody;
    }) => createNote(data.tenantId, data.assetId, data.body),
    onSettled: () => {
      queryClient.invalidateQueries(GET_ASSET_NOTES_QUERY_KEY);
    },
    onSuccess: () => {
      analytics.logEvent('create_note', {
        touchpoint: 'Astra web',
        tenant: tenant?.name,
        tenantId: tenant?.id,
        userId: user?.username,
        time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      });
    },
  });
}
