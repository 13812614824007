import { Layout } from 'antd';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { AppLayoutGlobalStyle } from './AppLayout';

export default function PublicAppLayout() {
  return (
    <Layout>
      <AppLayoutGlobalStyle />
      <Layout.Content>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
}
