import { Legend, SliderProps } from './common.interface';

// const baseConfigTooltip = {
//   customContent: (title: any, data: any) => {
//     return `<div>${title}...</div>`;
//   },
// };

export const baseConfigAxis = {
  label: {
    style: {
      fill: '#7A7A7A',
    },
  },
  // tickLine: {
  //   length: 100,
  // },
  // subTickLine: {
  //   count: 2,
  //   style: {
  //     fill: 'red',
  //     stroke: 'orange',
  //     color: 'green',
  //   },
  // },
  grid: {
    line: {
      style: {
        stroke: '#C8C8C8', //'#373737',
        lineWidth: 1,
        // lineDash: [4, 5],
        // strokeOpacity: 0.7,
        // shadowColor: 'black',
        // shadowBlur: 10,
        // shadowOffsetX: 5,
        // shadowOffsetY: 5,
        // cursor: 'pointer',
      },
    },
  },
};

export const baseConfigLegend = (dark?: boolean): Legend => ({
  // title: {
  //   text: 'title legend',
  // },
  layout: 'horizontal',
  // position: 'bottom',
  itemSpacing: 20,
  itemName: {
    // spacing: 10,
    // formatter: (text: string, item: /*ListItem*/ any, index: number) => {
    //   return text.toUpperCase();
    // },
    style: (item: any, index: number, items: any[]) => {
      return {
        fontSize: 14,
        fill: dark ? '#fff' : '#373737',
        fontWeight: 500,
        fontFamily: 'Satoshi-Variable',
      };
    },
  },
  marker: {
    symbol: 'circle',
    // style: {
    //   fill: 'orange',
    // },
  },
  // formatter: (text: string, item: /*ListItem*/ any, index: number) => {
  //   return text.toUpperCase();
  // },
  // background: {
  //   style: {
  //     fill: 'pink',
  //     stroke: 'red',
  //     path: 'blue',
  //   },
  // },
});

const backgroundHeight = 24;
const handlerHeight = 12;
const foregroundHeight = 4;

// const sliderHeight = backgroundHeight + handlerHeight + foregroundHeight;
// const handlerY = (backgroundHeight - handlerHeight) / 2;
const foregroundY = (backgroundHeight - foregroundHeight) / 2;

export const slider0: SliderProps = {
  start: 0, //0.1, // range[0]
  end: 1, //0.9, /
  trendCfg: {
    isArea: !true, // cond ? grafico : linea
  },
};

export const baseConfigSlider = {
  // minLimit: 1990,
  // maxLimit: 2000,
  start: 0, //0.1, // range[0]
  end: 1, //0.9, // range[1]
  height: backgroundHeight,
  trendCfg: {
    // data
    smooth: true,
    isArea: !true,
    // backgroundStyle: {
    //   fill: 'purple',
    //   stroke: 'green',
    //   lineWidth: 0,
    //   lineDash: [4, 4],
    // },
    // areaStyle: {
    //   fill: 'purple',
    //   stroke: 'red',
    //   lineWidth: 0,
    //   lineDash: [4, 4],
    // },
    // lineStyle: {
    //   fill: 'purple',
    //   stroke: 'yellow',
    //   lineWidth: 0,
    //   lineDash: [4, 4],
    // },
  },
  backgroundStyle: {
    fill: '#373737',
    stroke: '#373737',
    lineWidth: 1,
    radius: 4,
    opacity: 1,
    height: backgroundHeight,
    // width: 100,
  },
  foregroundStyle: {
    // è la linea in mezzo tra gli handler
    height: foregroundHeight,
    y: foregroundY,
    fill: '#7A7A7A',
    stroke: '#7A7A7A',
    lineWidth: 1,
    opacity: 0.3,
  },
  handlerStyle: {
    height: handlerHeight,
    width: handlerHeight,
    y: 0,
    highLightFill: '#FBFBFB',
    fill: '#FBFBFB',
    stroke: '#FBFBFB',
    opacity: 1,
    radius: handlerHeight / 2,
    cursor: 'pointer',
  },
  textStyle: {
    fill: '#919191',
    opacity: 0,
  },
};
