export const ButtonSizeValue = {
  s: {
    paddingVertical: 4,
    fontSize: 14,
  },
  m: {
    paddingVertical: 10,
    fontSize: 16,
  },
  l: {
    paddingVertical: 10,
    fontSize: 18,
  },
  large: {
    paddingVertical: 10,
    fontSize: 20,
  },
};

export type ButtonSize = 's' | 'm';
export type ButtonShape = 'square';

export type ButtonVariants =
  | 'outline-black'
  | 'outline-grey'
  | 'outline-white'
  | 'grey'
  | 'grey-E3'
  | 'grey-white'
  | 'white'
  | 'black'
  | 'ghost-black'
  | 'ghost-white'
  | 'fill-white'
  | 'transparent-dark'
  | 'danger';

interface ButtonColors {
  color: string;
  bgColor?: string;
  borderColor?: string;
}

export interface ButtonTheme {
  hasBorder?: boolean;
  default: ButtonColors;
  hover: ButtonColors;
  disabled?: ButtonColors;
  active?: ButtonColors;
}

export const color = {
  transparent: 'transparent',
  dark: '#373737',
  white: '#FFFFFF',
  white_3: 'rgba(255, 255, 255, 0.3)',
  greyE3: '#E3E3E3',
  greyEC: '#ECECEC',
  greyC8: '#C8C8C8',
  grey7A: '#7A7A7A',
  grey91: '#919191',
  ultraBlack: '#1C1C1E',
  error: '#E8684A',
  errorHvr: '#E25230',
};


export type themeConfigInterface = {
  [key in ButtonVariants]: ButtonTheme;
};
export const themeConfig: themeConfigInterface = {
  'outline-black': {
    hasBorder: true,
    default: {
      color: color.dark,
      borderColor: color.dark,
    },
    hover: {
      color: color.dark,
      bgColor: color.greyC8,
      borderColor: color.greyC8,
    },
    disabled: {
      color: color.dark,
      bgColor: color.white,
      borderColor: color.dark,
    },
  },
  'outline-grey': {
    hasBorder: true,
    default: {
      color: color.dark,
      bgColor: color.greyEC,
      borderColor: color.greyC8,
    },
    hover: {
      // TODO: designer
      color: color.dark,
      bgColor: color.greyC8,
      borderColor: color.greyC8,
    },
    active: {
      color: color.white,
      bgColor: color.ultraBlack,
      borderColor: color.ultraBlack,
    },
  },
  'outline-white': {
    hasBorder: true,
    default: {
      color: color.white,
      bgColor: color.transparent,
      borderColor: color.white,
    },
    hover: {
      // TODO: designer (bottone annulla nella modale)
      color: color.white,
      bgColor: color.dark,
      borderColor: color.white,
    },
  },
  'grey': {
    default: {
      color: color.dark,
      bgColor: color.greyE3,
    },
    hover: {
      color: color.dark,
      bgColor: color.greyC8,
    },
  },
  'grey-white': {
    default: {
      color: color.white,
      bgColor: color.dark,
    },
    hover: {
      color: color.dark,
      bgColor: color.white,
    },
  },
  'grey-E3': {
    default: {
      color: color.white,
      bgColor: color.dark,
    },
    hover: {
      color: color.white,
      bgColor: color.white_3,
    },
  },
  'white': {
    default: {
      color: color.dark,
      bgColor: color.white,
    },
    hover: {
      color: color.dark,
      bgColor: color.greyE3,
    },
  },
  'black': {
    default: {
      color: color.white,
      bgColor: color.ultraBlack,
    },
    hover: {
      color: color.white,
      bgColor: color.dark,
    },
  },
  'ghost-black': {
    default: {
      color: color.dark,
      bgColor: color.transparent,
    },
    hover: {
      color: color.grey7A,
      bgColor: color.transparent,
    },
  },
  'ghost-white': {
    default: {
      color: color.white,
    },
    hover: {
      color: color.grey91,
    },
  },
  'fill-white': {
    default: {
      color: color.dark,
    },
    hover: {
      color: color.grey91,
      bgColor: color.greyE3,
    },
  },
  'transparent-dark': {
    default: {
      color: color.dark,
      bgColor: color.transparent,
    },
    hover: {
      color: color.grey7A,
    },
  },
  'danger': {
    default: {
      color: color.white,
      bgColor: color.error,
    },
    hover: {
      color: color.white,
      bgColor: color.errorHvr,
    },
  },
};

// TODO: rivedere meglio ilts e l'uso effettivo per sovrascrivere ant con l' !important
// ${getBtn({ type: 'danger', theme: 'default' })}
export const getBtn = ({
  type,
  theme = 'default',
}: {
  type: ButtonVariants;
  theme: string;
}) => ``;
// `
//   color: ${themeConfig?[type]?[theme].color};
//   background-color : ${themeConfig?[type]?[theme].bgColor};
// `
