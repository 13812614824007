import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useAssetHeatMapSingleAsset as useAssetHeatMapAssetQuery } from '@src/api/asset/use-asset-heatmap-single-asset';
import { selectedAssetIdState } from '@store/asset/selectedAssetIdState';
import { GetReportAssetHeatMapResponseDto } from '@asset-manager/types';
import { assetFilterDateState } from '@store/asset/assetFilterDateState';

export interface IUseAssetHeatMapAsset {
  selectedTenantId: string;
  assetHeatMapReport?: GetReportAssetHeatMapResponseDto;
  isReportLoading: boolean;
}
export function useAssetHeatMapSingleAsset(): IUseAssetHeatMapAsset {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [selectedAssetId] = useRecoilState(selectedAssetIdState);
  const [assetFilterDate] = useRecoilState(assetFilterDateState);
  const { data: assetHeatMapReport, isLoading: isReportLoading } =
  useAssetHeatMapAssetQuery(
      selectedTenantId,
      selectedAssetId,
      assetFilterDate,
    );

  return {
    selectedTenantId,
    assetHeatMapReport,
    isReportLoading,
  };
}
