import CancelIcon from '@src/assets/icons/CancelIcon';
import { fontSize } from '@src/globalStyle';
import { device } from '@theme/breakpoints';
import {
  Checkbox,
  Col,
  Form,
  Radio,
  Select,
  SelectProps,
  Spin,
  Tag,
  TimePicker,
} from 'antd';
import styled, { css } from 'styled-components';

const commonText = css`
  font-family: 'Satoshi-Variable';
  font-weight: 500;
  ${fontSize.s}
  color: #fff;
`;

export const LabelCol = styled(Col)`
  ${commonText}
  flex: 0 1 208px;
  margin-top: 10px;
`;

export const StyledFormItem = styled(Form.Item)`
  color: #fff !important;

  .ant-form-item-control {
    width: 100%;
    flex-grow: 1;
  }
`;

export const StyledRow = styled.div<{
  isMobile: boolean;
  paddingTop?: boolean;
}>`
  display: grid;
  grid-template-columns: ${({ isMobile }) => (isMobile ? '1fr' : '208px 1fr')};
  ${({ paddingTop }) =>
    paddingTop &&
    css`
      > :first-child {
        padding-top: 2px;
      }
    `}
  column-gap: 20px;
`;

export const OperandsSelect = styled(Select)`
  width: fit-content;
  .ant-select-selector {
    border: none !important;
    background: transparent !important;
    padding-left: 0 !important;
  }

  @media ${device.tablet} {
    width: fit-content !important;
  }
`;

export const OperandsSelectAlt = styled(Select)`
  .ant-select-selector {
    border: none !important;
    background: transparent !important;
    padding-left: 0 !important;
  }
`;

export const StyledSelect = styled(Select)`
  svg {
    position: relative;
    top: 2px;
    right: -2px;
    width: 14px !important;
    height: 14px !important;
  }

  .ant-select-selection-overflow-item .ant-select-selection-item {
    color: #373737 !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    background: #fff !important;
  }
`;

export const StyledTag = styled(Tag)`
  margin-right: 3px;
  background: #fff;
  color: #373737;
  font-size: 14px;
  font-weight: 700;
  border-radius: 6px;
  padding: 4px 8px;
`;

export const MultipleSelect = ({ mode, ...props }: SelectProps) => {
  const maxTagCount = 2;
  return (
    <StyledSelect
      size="large"
      mode={mode || 'multiple'}
      maxTagCount={maxTagCount}
      maxTagPlaceholder={e => `+${e.length}`}
      menuItemSelectedIcon={false}
      {...(props as any)}
      tagRender={({ label, closable, onClose }) => (
        <StyledTag
          closable={closable}
          onClose={onClose}
          closeIcon={<CancelIcon />}
        >
          {label}
        </StyledTag>
      )}
    />
  );
};

export const StyledRadioGroup = styled(Radio.Group)`
  display: flex;
  gap: 8px;

  > .ant-radio-button-wrapper-in-form-item {
    border-radius: 6px;
    border: 1px solid #373737 !important;
    background: #373737;
    color: #fff;
    font-size: 14px;
    height: 44px;
    font-family: 'Satoshi-Variable';
    font-weight: 700;
    padding: 10px;
    display: flex;
    align-items: center;
  }

  > .ant-radio-button-wrapper-in-form-item:hover {
    background: rgba(255, 255, 255, 0.3);
  }

  > .ant-radio-button-wrapper-checked {
    background: #fff !important;
    color: #373737 !important;
  }

  > .ant-radio-button-wrapper-in-form-item::before {
    display: none;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #373737;
  margin-bottom: 16px;
`;

export const StyledCheckbox = styled(Checkbox)`
  width: 44px;
  height: 44px;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  background: #373737;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  :not(.ant-checkbox-wrapper-disabled):not(
      .ant-checkbox-wrapper-checked
    ):hover {
    background: rgba(255, 255, 255, 0.3);
  }

  .ant-checkbox {
    display: none;
  }

  span:last-child {
    padding: 10px;
    border-radius: 6px;
    flex-basis: 100%;
  }

  &.ant-checkbox-wrapper-checked {
    background-color: #fff;
    color: #373737 !important;
  }
`;

export const StyledSingleCheckbox = styled(Checkbox)`
  display: flex;
  column-gap: 8px;

  .ant-checkbox {
    margin-top: -3px;
  }

  .ant-checkbox-inner {
    width: 24px;
    height: 24px;
  }

  .ant-checkbox-inner::after {
    margin-left: 2px;
    border-color: #373737;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #fff !important;
    border-color: #fff;
  }
`;

export const CheckboxGroup = styled(Checkbox.Group)`
  flex-wrap: wrap;
  gap: 8px;

  > label {
    margin: 0 !important;
  }
`;

export const IntervalsLabel = styled.span`
  color: #fff;
  margin-top: 10px;
`;

export const IntervalsTimePicker = styled(TimePicker)`
  width: 100px;

  input {
    color: #fff !important;
  }
`;

export const FormErrorList = styled(Form.ErrorList)`
  color: #ff4d4f;
`;

export const StyledSpin = styled(Spin)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;
