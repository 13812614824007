import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { history, HistoryRouter } from './HistoryRouter';
import { useAmplify } from '../modules/amplify';

// routes
import Assets from './Assets/Assets';
import Notifications from './Notifications/Notifications';
import Sensors from './Sensors/Sensors';
import Stats from './Stats/Stats';
import User from './User/User';
import ErrorPage from './ErrorPage/ErrorPage';
import LoginPage from '../modules/amplify/pages/LoginPage';
import Report from './Report/Report';
import Help from './Help/Help';

// layouts
import PublicAppLayout from '../components/templates/PublicAppLayout';
import AppLayout from '../components/templates/AppLayout';
import SignUpPage from '../modules/amplify/pages/SignUpPage';
import ForgotPasswordPage from '../modules/amplify/pages/ForgotPasswordPage';
import CodeConfirmationPage from '../modules/amplify/components/ConfirmationCodeForm';
import { ReactNode, useEffect } from 'react';
import Alerts from './Alerts/Alerts';
import ResetPasswordPage from '@src/modules/amplify/pages/ResetPasswordPage';
import analytics from '@src/utils/analytics';
import { useTenant } from '@src/modules/asset';
import { format } from 'date-fns';
import i18n from '@src/i18n';

const ProtectedRoute: (props: {
  isAuthenticated: boolean;
  children: ReactNode;
}) => JSX.Element = ({ isAuthenticated, children }) => {
  const { isInitialStateLoading } = useAmplify();
  if (isInitialStateLoading) {
    return <></>;
  }
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return <>{children}</>;
};

export default function Router() {
  const { isAuthenticated } = useAmplify();
  const { tenant } = useTenant();

  const changeLanguage = (newLanguage: string) => {
    i18n.changeLanguage(newLanguage);
  };

  const language = navigator.language;

  useEffect(() => {
    if (!!tenant) {
      changeLanguage(tenant.language);
    } else {
      changeLanguage(language);
    }
  }, [tenant]);
  return (
    <HistoryRouter history={history}>
      <ScrollToTop />
      <PageViewAnalytics />
      <Routes>
        <Route path="/" element={<PublicAppLayout />}>
          {/*<Route path="/" element={<Home />} />*/}
          <Route path="/" element={<LoginPage />} />
          <Route path="/sign-in" element={<LoginPage />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/code-confirmation" element={<CodeConfirmationPage />} />
        </Route>

        <Route
          path="/"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <AppLayout />
            </ProtectedRoute>
          }
        >
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Assets />
              </ProtectedRoute>
            }
          />
          <Route
            path="/sensors"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Sensors />
              </ProtectedRoute>
            }
          />
          <Route
            path="/notifications"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Notifications />
              </ProtectedRoute>
            }
          />
          <Route
            path="/stats"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Stats />
              </ProtectedRoute>
            }
          />
          <Route
            path="/user"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <User />
              </ProtectedRoute>
            }
          />
          <Route
            path="/report"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Report />
              </ProtectedRoute>
            }
          />
          <Route
            path="/alerts"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Alerts />
              </ProtectedRoute>
            }
          />
          <Route
            path="/help"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Help />
              </ProtectedRoute>
            }
          />
        </Route>

        <Route
          path="/*"
          element={
            <ProtectedRoute isAuthenticated={isAuthenticated}>
              <ErrorPage />
            </ProtectedRoute>
          }
        />
      </Routes>
    </HistoryRouter>
  );
}

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const PageViewAnalytics = () => {
  const location = useLocation();
  const { tenant } = useTenant();
  const { user } = useAmplify();

  useEffect(() => {
    analytics.logEvent('page_view', {
      touchpoint: 'Astra web',
      page: location.pathname,
      tenant: tenant?.name,
      tenantId: tenant?.id,
      userId: user?.username,
      time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    });
  }, [location, tenant, user]);

  return null;
};
