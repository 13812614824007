import {
  AlertCreateRuleRequestDto,
  AlertUpdateRuleRequestDto,
  ReportUpdateThresholdItemDto,
} from '@asset-manager/types';
import Button from '@components/atoms/button';
import Modal, { Footer, ModalProp } from '@components/atoms/modal';
import { NotificationContext } from '@src/App';
import { useCreateAlert } from '@src/api/alert/use-create-alert';
import { useAssets, useTenant } from '@src/modules/asset';
import { darkTheme } from '@theme/dark';
import { Col, ConfigProvider, Form, Input } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import dayjs from 'dayjs';
import { useContext, useState } from 'react';
import {
  datePeriodSelectOptions,
  FormFields,
  initialValues,
  mapRuleConditionValueUnitToDbUnits,
  priorityOptions,
  ruleConditions,
  ruleStatusValues,
  statusSelectOptions,
  timePeriodSelectOptions,
} from './modal-add-alert.types';
import {
  Divider,
  LabelCol,
  MultipleSelect,
  StyledFormItem,
  StyledRadioGroup,
  StyledRow,
} from './modal-add-alert.components';
import Rules from './modal-add-alert-rules';
import EditableFields from './modal-add-alert-editable-fields';
import { useRecoilState } from 'recoil';
import { selectedAssetIdState } from '@store/asset';
import { AlertDurationTypeEnum } from '../modal-alert-details/modal-alert-details';
import { useGetAssetsAutocomplete } from '@src/api/asset/use-get-assets-autocomplete';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { fetchAssetThresholds } from '@src/api/report/use-fetch-asset-thresholds';

dayjs.extend(require('dayjs/plugin/utc'));

interface Props extends ModalProp {}

const StyledModal = styled(Modal)`
  max-width: calc(100vw - 0px);
`;

const AddAlertModal = ({ open, onCancel, ...props }: Props) => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 1024;

  const [form] = Form.useForm<FormFields>();
  const { selectedTenantId } = useTenant();
  const [assetId] = useRecoilState(selectedAssetIdState);

  const [assetAutocompleteValue, setAssetAutocompleteValue] = useState('');
  const { assets: existingAssets } = useGetAssetsAutocomplete(
    assetAutocompleteValue,
  );
  const [thresholds, setThresholds] = useState<ReportUpdateThresholdItemDto[]>(
    [],
  );

  const { toast } = useContext(NotificationContext);

  const createAlert = useCreateAlert();

  const handleCreateAlert = (body: AlertUpdateRuleRequestDto) => {
    createAlert.mutate(
      { body },
      {
        onSuccess: () => {
          toast?.success({
            message: t('alerts.createdAlertSuccess'),
            placement: 'topRight',
          });
          onCancel && onCancel();
        },
        onError: () => {
          toast?.error({
            message: t('alerts.errorCreationAlert'),
            placement: 'topRight',
          });
        },
      },
    );
  };

  const onFinish = async () => {
    form
      .validateFields()
      .then(values => {
        console.log("values", values);
        const body: AlertCreateRuleRequestDto = {
          name: values.name,
          expressions: [],
          textTemplate: values.message,
          severity: values.priority,
          assets: values.assets,
          duration: {
            duration: Number(values.duration),
            mode: values.durationSelect,
            type: AlertDurationTypeEnum.MINUTES,
          },
          inDay: {
            dayOfWeek: values.days,
          },
          intervals: [],
          runUntil: dayjs().add(1, 'year').format(),
          active: true,
          sendEmail: values.sendEmail,
          sendEmailTo: values.sendEmailTo,
        };

        for (const rule of values.rules) {
          const obj = {
            logicalOperator: rule.ruleConjunction,
            ...({
              value: {
                value: Number(rule?.ruleConditionValue) || 0,
                mode: rule?.ruleCondition,
                unit: mapRuleConditionValueUnitToDbUnits[
                  rule?.ruleStatusValue ?? ruleStatusValues.CONSUMPTION
                ],
                ...(rule?.ruleCondition === ruleConditions.THRESHOLD && {
                  stage: {
                    stageId: rule?.ruleConditionValue,
                    mode: ruleConditions.THRESHOLD,
                    in: true,
                  }
                })
              },
            }),
          };
          if (Object.values(obj).filter(r => !!r).length > 0)
            // @ts-ignore
            // TODO: remove when types are fixed
            body.expressions?.push(obj);
        }

        const dateInterval: { startDate?: string; endDate?: string } = {};
        let hasDateInterval = false;

        if (
          values.datePeriodSelect === datePeriodSelectOptions.DURING &&
          values.datePeriod
        ) {
          dateInterval.startDate = dayjs(values.datePeriod[0])
            .utc()
            .startOf('day')
            .format();
          dateInterval.endDate = dayjs(values.datePeriod[1])
            .utc()
            .endOf('day')
            .format();
          hasDateInterval = true;
        }

        if (values.timePeriodSelect === timePeriodSelectOptions.INTERVAL) {
          values.intervals.forEach(interval => {
            body.intervals?.push({
              ...dateInterval,
              startHour:
                typeof interval.startTime === 'string'
                  ? interval.startTime
                  : interval.startTime.format('HH:mm'),
              endHour:
                typeof interval.endTime === 'string'
                  ? interval.endTime
                  : interval.endTime.format('HH:mm'),
            });
          });
        } else if (hasDateInterval) {
          body.intervals?.push(dateInterval);
        }
        console.log("body", body);
        return handleCreateAlert(body);
      })
      .catch(err => {
        console.error(err);
      });
  };

  // Questa funzione serve per impostare i valori delle select di "condtion" in base all'asset ed alle soglie.
  const onValuesChange = (changedValues: any, allValues: FormFields) => {
    if (changedValues?.assets?.length === 1) {
      fetchAssetThresholds(changedValues?.assets[0], selectedTenantId).then((res) => {
        if (res?.items?.length) {
          const rules = form.getFieldValue('rules');
          const updatedRules = rules.map((r: any) => ({
            ...r,
            ruleCondition: !r.ruleConditionValue ? ruleConditions.THRESHOLD : r.ruleCondition,
          }))
          setThresholds(res?.items || []);
          form.setFieldsValue({ rules: updatedRules });
        }
      })
    } else if (changedValues?.assets?.length === 0 || changedValues?.assets?.length > 1) {
      const rules = form.getFieldValue('rules');
      const updatedRules = rules.map((r: any) => ({
        ...r,
        ruleCondition: r.ruleCondition === ruleConditions.THRESHOLD ? ruleConditions.GREATER_THAN : r.ruleCondition,
        ruleConditionValue: r.ruleCondition === ruleConditions.THRESHOLD ? '' : r.ruleConditionValue,
      }))
      setThresholds([]);
      form.setFieldsValue({ rules: updatedRules });
    }
  };

  return (
    <StyledModal
      dark
      title={t('alerts.setAlert')}
      bigTitle
      lowerCloseIcon
      open={open}
      // @ts-ignore
      onOk={onFinish}
      okText={t('general.saveAndActive')}
      onCancel={() => {
        onCancel && onCancel();
      }}
      centered
      width={640}
      footer={
        <Footer>
          <>
            <Button
              type="button"
              variant="outline-white"
              onClick={onCancel}
              key="back"
            >
              {t('general.cancel')}
            </Button>
            <Button
              type="button"
              variant="grey"
              key="submit"
              onClick={onFinish}
            >
              {t('general.saveAndActive')}
            </Button>
          </>
        </Footer>
      }
      {...props}
    >
      <ConfigProvider theme={darkTheme}>
        <Form
          name="add-alert"
          wrapperCol={{ span: 24 }}
          initialValues={initialValues}
          form={form}
          autoComplete="off"
          layout="horizontal"
          onValuesChange={onValuesChange}
        >
          <Col>
            <StyledRow isMobile={isMobile}>
              <LabelCol>{t('alerts.alertName')}</LabelCol>
              <StyledFormItem
                name="name"
                rules={[
                  {
                    required: true,
                    message: t('alerts.nameAlertMandatory'),
                  },
                ]}
              >
                <Input
                  placeholder={t('general.addMandatoryName')}
                  size="large"
                />
              </StyledFormItem>
            </StyledRow>
            <StyledRow isMobile={isMobile}>
              <LabelCol>{t('general.message')}</LabelCol>
              <Form.Item name="message">
                <TextArea placeholder={t('general.addNonMandatoryMessage')} />
              </Form.Item>
            </StyledRow>
            <StyledRow isMobile={isMobile}>
              <LabelCol>{t('general.priority')}</LabelCol>
              <Form.Item name="priority">
                <StyledRadioGroup
                  options={priorityOptions()}
                  optionType="button"
                  buttonStyle="solid"
                  size="large"
                />
              </Form.Item>
            </StyledRow>

            <Divider />

            <StyledRow isMobile={isMobile}>
              <LabelCol>{t('assets.generateAssetWhen')}</LabelCol>
              <Form.Item
                name="assets"
                rules={[
                  {
                    required: true,
                    message: t('assets.selectAtLeastOneAsset'),
                  },
                ]}
                initialValue={assetId ? [assetId] : undefined}
              >
                <MultipleSelect
                  placeholder={t('assets.selectAsset')}
                  options={existingAssets.map(asset => ({
                    label: asset.name,
                    value: asset.id,
                  }))}
                  onKeyUp={e => {
                    // @ts-ignore
                    setAssetAutocompleteValue(e.target.value);
                  }}
                  optionFilterProp="label"
                  // onSelect={(e) => setSelectedAssetsIds((prev) => [...prev, e])}
                  // onDeselect={(e) => setSelectedAssetsIds((prev) => prev.filter((id) => id !== e))}
                />
              </Form.Item>
            </StyledRow>

            <Rules form={form} initialValues={initialValues} thresholds={thresholds} />

            <EditableFields form={form} initialValues={initialValues} />
          </Col>
        </Form>
      </ConfigProvider>
    </StyledModal>
  );
};

export default AddAlertModal;
