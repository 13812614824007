import { useQuery } from 'react-query';
import { TENANT_API } from '@src/api';
import { fetchConfig } from '@src/config/http';
import { API } from 'aws-amplify';
import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useEffect } from 'react';

export const GET_TENANT_EVENT_SUB_TYPES_QUERY_KEY = 'getTenantEventSubTypes';
async function fetchTenantEventSubTypes(id?: string) {
  if (id) return await API.get(TENANT_API, `/${id}/event/subType`, {});
  return;
}
export function useHook(id?: string) {
  const query = useQuery(
    GET_TENANT_EVENT_SUB_TYPES_QUERY_KEY,
    async () => await fetchTenantEventSubTypes(id),
    {
      retry: true,
      ...(fetchConfig as any),
    },
  );

  return query;
}

export function useGetTenantEventSubTypes() {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);

  const { data, isLoading, isFetching, refetch } = useHook(selectedTenantId);

  // when current tenant is changed, refetch data
  useEffect(() => {
    refetch().catch(() => console.log('error'));
  }, [refetch, selectedTenantId]);

  return {
    selectedTenantId,
    data,
    isLoading,
    refetch,
    isFetching,
  };
}
