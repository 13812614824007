import Divider from '@components/atoms/divider';
import EmptyState from '@components/atoms/empty-state';
import Icon from '@components/atoms/icon';
import Overview from '@components/organisms/overview';
import { useWindowWidth } from '@src/hooks/useMediaQuery';
import { useTenantSlimReport } from '@src/modules/tenant';
import { Row, Spin } from 'antd';
import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowProps,
} from 'react-virtualized';
import { Loader, RowExtraOverview } from '../common';
import DrawerSection from '../section/section';
import { overviewList } from './tenant-drawer-content.utils';
import { format } from 'date-fns';
import { DashboardRangePickerNode } from '@components/organisms/range-picker-node/range-picker-node';
import DateTimeFrameSelectDashboard from '@components/atoms/date-time-frame-select-dashboard';
import { useTenant } from '@src/modules/asset';
import { useTranslation } from 'react-i18next';
import { AssetEventGetAssetEventResponseDto } from '@asset-manager/types';
import { fetchGeneratedEvents } from '@src/api/alert/fetch-generated-events';
import CardAlerts from '@components/molecules/card-alerts';
import { useRecoilState } from 'recoil';
import { currentAlertIdState } from '@store/alert/alert-filter-state';
import AlertDetailsModal from '@components/organisms/modals/modal-alert-details';
import { queryClient } from '@src/App';
import { TENANT_SLIM_REPORT_QUERY_KEY } from '@src/api';
import { useGeneratedEvents } from '@src/api/alert/use-generated-events';

dayjs.extend(require('dayjs/plugin/utc'));

// @ts-ignore
const utcDayjs = dayjs.utc;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .stops {
    div {
      //-ms-overflow-style: none;
      //scrollbar-width: none;
      //&::-webkit-scrollbar {
      //  display: none;
      //}

      /* ===== Scrollbar CSS ===== */
      /* Firefox */
      * {
        scrollbar-width: auto;
        scrollbar-color: #7a7a7a #1c1c1e;
      }

      /* Chrome, Edge, and Safari */
      *::-webkit-scrollbar {
        width: 16px;
      }

      *::-webkit-scrollbar-track {
        background: #1c1c1e;
      }

      *::-webkit-scrollbar-thumb {
        background-color: #7a7a7a;
        border-radius: 10px;
        border: 3px solid #1c1c1e;
      }
    }
  }
`;

interface TenantDrawerContentProps {
  fullscreen: boolean;
}

const StyledCardAlerts = styled(CardAlerts)`
  cursor: pointer;
`;

const showRangePickerNode = (windowWidth: number, fullscreen: boolean) => {
  const isLaptop = windowWidth && windowWidth >= 1024 && windowWidth < 1358;
  const isLaptopM = windowWidth && windowWidth >= 1358;
  if (isLaptopM) return fullscreen;
  return isLaptop;
};

export default function TenantDrawerContent({
  fullscreen,
}: TenantDrawerContentProps) {
  const { t } = useTranslation();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth && windowWidth < 1024;
  const [isUpdated, setIsUpdated] = useState<number>(0);
  const { selectedTenantId } = useTenant();
  const { tenantReport } = useTenantSlimReport();
  const [items, setItems] = useState<AssetEventGetAssetEventResponseDto[]>([]);
  const [count, setCount] = useState<number>(0);
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);

  const [, setCurrentAlertId] = useRecoilState(currentAlertIdState);

  useEffect(() => {
    (async () => {
      setIsReportLoading(true);
      try {
        const response = await fetchGeneratedEvents(selectedTenantId, {
          limit: '1000',
          startDate: utcDayjs()
            .startOf('day')
            .subtract(365, 'days')
            .unix()
            .toString(),
          endDate: utcDayjs().endOf('day').unix().toString(),
          skip: '0',
          sort: 'createdAt',
          order: 'desc',
        });

        setItems(response.items);
        setCount(response.count);
      } catch {
        setItems([]);
        setCount(0);
      }
      setIsReportLoading(false);
    })();
  }, [selectedTenantId, isUpdated]);

  const [currentEventId, setCurrentEventId] = useState<any>('');
  const [isAlertDetailsModalOpen, setIsAlertDetailsModalOpen] =
    useState<boolean>(false);

  const showModal = useCallback(
    (alertId: string, eventId?: any) => {
      console.log('AlertId: ', alertId);
      if (eventId) setCurrentEventId(eventId);
      setCurrentAlertId(alertId);
      setIsAlertDetailsModalOpen(true);
    },
    [setCurrentAlertId],
  );

  const {
    getGeneratedEvents: generatedEvents,
    pageNumber,
    pageSize,
    updateFilters,
  } = useGeneratedEvents();
  const onCancelAlertDetailsModal = useCallback(() => {
    setIsAlertDetailsModalOpen(false);
  }, []);

  const showRangePicker = showRangePickerNode(windowWidth || 0, fullscreen);

  const alertsCache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        // keyMapper: index => items?.[index],
      }),
    [],
  );

  // alerts key hack for react virtualized
  const listRef = useRef<List>(null);

  useEffect(() => {
    setTimeout(() => {
      listRef.current?.forceUpdate();
      listRef.current?.forceUpdateGrid();
      listRef.current?.recomputeRowHeights();
      alertsCache.clearAll();
    }, 500);
  }, [alertsCache, fullscreen, items]);
  // alerts key hack for react virtualized - END

  const rowRenderer = useCallback(
    ({ key, index, isScrolling, isVisible, style, parent }: ListRowProps) => {
      const item = items?.[index];
      if (!item) return null;

      const createdAt = new Date(item.createdAt);
      const startTime = item.startTime ? new Date(item.startTime) : null;
      const endTime = item.endTime ? new Date(item.endTime) : new Date();

      // get date in format dd/mm/yyyy  it locale
      const date = createdAt.toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        minute: '2-digit',
        hour: '2-digit',
      });

      // Duration is calculated as the difference between the start and end time, in string format HH:mm
      const duration =
        startTime && endTime
          ? dayjs(endTime).diff(dayjs(startTime), 'minutes')
          : 0;

      return (
        <CellMeasurer
          key={key}
          cache={alertsCache}
          columnIndex={0}
          rowIndex={index}
          parent={parent}
        >
          <div style={style}>
            <StyledCardAlerts
              description={item.description}
              eventId={item.id}
              tenantId={selectedTenantId}
              assetId={item.assetId || ''}
              assetName={item.assetName || ''}
              alertName={item.alertName || ''}
              alertSeverity={(item as any).alertSeverity}
              subTypeSeverity={(item as any).subTypeSeverity}
              subTypeName={(item as any).subTypeName}
              date={date.split(',')[0]}
              time={date.split(',')[1].replaceAll(' ', '')}
              duration={duration}
              onClick={() => showModal((item as any).alertId, item)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </CellMeasurer>
      );
    },
    [alertsCache, items, selectedTenantId, showModal],
  );

  return (
    <Container>
      {fullscreen && (
        <DrawerSection
          title={t('general.overview')}
          titleTooltip={t('assets.aggregateAssetsData')}
          subtitle={
            tenantReport?.lastUpdate &&
            `${t('general.updatedAt')} ${format(
              new Date(tenantReport.lastUpdate),
              'HH:mm',
            )}`
          }
          extra={
            <RowExtraOverview fadeIn={fullscreen}>
              {showRangePicker ? (
                <DashboardRangePickerNode />
              ) : (
                <DateTimeFrameSelectDashboard />
              )}
            </RowExtraOverview>
          }
        >
          {isReportLoading ? null : (
            <Overview
              // isLoading={isReportLoading}
              list={overviewList(
                `${tenantReport?.alertsCount ?? 0}`,
                `${tenantReport?.uptime ?? 0}`,
                tenantReport?.uptimeVariation ?? 0,
                // sum all consumption
                `${tenantReport?.totalConsumption ?? 0}`,
                // @ts-ignore
                `${tenantReport?.totalConsumptionUnitOfMeasure ?? ''}`,
                `${tenantReport?.totalTimeInNoData ?? ''}`,
              )}
              listSpan={isMobile ? 12 : undefined}
              split={!isMobile ? '3' : ''}
              listGutter={[16, 16]}
            />
          )}
        </DrawerSection>
      )}

      {isReportLoading ? (
        <Loader>
          <Spin size="large" />;
        </Loader>
      ) : (
        <>
          {fullscreen && <Divider dark />}
          <DrawerSection
            title={(t('alerts.alerts') as string).toUpperCase()}
            titleTooltip={t('alerts.list')}
            style={{ flex: 1, display: 'flex', flexDirection: 'column' }}
          >
            {items?.length ? (
              <div
                className="stops"
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Row style={{ flex: 1 }}>
                  <AutoSizer>
                    {({ height, width }) => {
                      return (
                        <List
                          width={width}
                          height={height}
                          rowCount={Math.min(count, 1000) || 0}
                          rowHeight={alertsCache.rowHeight}
                          rowRenderer={rowRenderer}
                          ref={listRef}
                        />
                      );
                    }}
                  </AutoSizer>
                </Row>
              </div>
            ) : (
              <EmptyState
                dark
                style={{
                  height: 200,
                  width: fullscreen ? '200px' : undefined,
                }}
                image={
                  <Icon
                    name="emergency_home"
                    style={{ fontSize: 48 }}
                    color="currentColor"
                  />
                }
                title={t('alerts.noAlertFound')}
              />
            )}
          </DrawerSection>
        </>
      )}
      {isAlertDetailsModalOpen && (
        <AlertDetailsModal
          event={currentEventId}
          open={isAlertDetailsModalOpen}
          onCancel={onCancelAlertDetailsModal}
          onOk={() => {
            queryClient
              .invalidateQueries(TENANT_SLIM_REPORT_QUERY_KEY)
              .catch(e => console.log('Error', e.message));
            setIsUpdated(isUpdated + 1);
            generatedEvents.refetch().catch(() => console.log('Refetched'));
          }}
        />
      )}
    </Container>
  );
}
