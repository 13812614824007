import { Column } from '@ant-design/plots';
import { AreaConfig } from '@ant-design/plots';
import Slider from '@components/atoms/slider';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { CommonChart } from '../common.interface';
import { dataMapping, plotComponents } from './config';

import dayjs from 'dayjs';
import { debounce } from 'lodash';
import {
  GetReportAssetPointResponseDto,
  ReportUpdateThresholdItemDto,
} from '@asset-manager/types';
import { filterUnique } from '@src/utils/array';

dayjs.extend(require('dayjs/plugin/utc'));
const utcDayjs = dayjs.utc;

interface Props extends CommonChart, Partial<AreaConfig> {
  unit?: string;
  dateRange: number;
  thresholds?: ReportUpdateThresholdItemDto[];
  assetId?: string;
}

const Component = ({ dark, ...props }: Props) => {
  const [minMax, setMinMax] = useState<[number, number]>([
    0,
    props?.data?.length ? filterUnique(props?.data, 'time').length - 1 : 0,
  ]);
  const [range, setRange] = useState([0, minMax[1]]);
  const data = useMemo(() => props?.data ?? [], [props?.data]);
  const dataTimeGrouped = useMemo(() => filterUnique(data, 'time'), [data]);

  const config = useCallback(
    (dark: boolean): AreaConfig | any => ({
      ...dataMapping,
      ...plotComponents(dark, props.unit),
      ...(props?.thresholds?.length
        ? {
            color: (d: GetReportAssetPointResponseDto) => {
              const status = d?.status?.split('_')[0];
              const sortedThresholds = [...(props?.thresholds || [])];
              sortedThresholds.sort((a, b) => b.value - a.value);
              const foundThreshold = sortedThresholds.find(
                threshold => threshold.id === status,
              );
              return foundThreshold?.hexColor || '#e6e6e6';
            },
          }
        : { color: () => 'lightblue' }),
      animation: false,
    }),
    [props?.thresholds, props.unit],
  );

  const areaData = useMemo(
    () =>
      data.filter(
        r =>
          r.time >= dataTimeGrouped[range[0]]?.time &&
          r.time <= dataTimeGrouped[range[1]]?.time,
      ),
    [data, dataTimeGrouped, range],
  );

  const sliderFormatter = useCallback(
    (value?: number): ReactNode => {
      try {
        if (value === undefined || value === null) return value;
        const d = dataTimeGrouped[value];

        if (!dataTimeGrouped) return value;
        return utcDayjs(d.time).subtract(2, 'hours').format('DD/MM');
      } catch (e) {
        return value;
      }
    },
    [dataTimeGrouped],
  );

  useEffect(() => {
    const max = filterUnique(data, 'time').length - 1 ?? 0;
    setMinMax([0, max]);
    setRange([0, max]);
  }, [data]);

  const debounceChange = useMemo(() => debounce(setRange, 300), []);

  return (
    <>
      <Column
        key={`${minMax[1]}_${data?.[0]?.time}`}
        className="cssid--area"
        renderer="svg"
        {...config(!!dark)}
        slider={false}
        {...props}
        data={areaData}
      />
      <div style={{ marginLeft: 16 }}>
        <Slider
          dark
          key={`${minMax[1]}_${data?.[0]?.time}`}
          tooltip={{
            formatter: sliderFormatter,
          }}
          range={{ draggableTrack: true }}
          min={minMax[0]}
          max={minMax[1]}
          defaultValue={[0, data ? minMax[1] : 0]}
          onChange={debounceChange}
          style={{ marginTop: 16 }}
        />
      </div>
    </>
  );
};

export const StackedColumn = Component;
