import { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CancelSvg = (props: SVGProps<SVGSVGElement>) => (
  <svg width={14} height={14} fill="none" {...props}>
    <path
      fill="#373737"
      d="m4.6 10.334 2.4-2.4 2.4 2.4.933-.933-2.4-2.4 2.4-2.4-.933-.934-2.4 2.4-2.4-2.4-.933.934 2.4 2.4-2.4 2.4.933.933ZM7 13.667a6.489 6.489 0 0 1-2.6-.525 6.728 6.728 0 0 1-2.117-1.425A6.727 6.727 0 0 1 .86 9.601 6.488 6.488 0 0 1 .333 7c0-.923.175-1.79.526-2.6a6.728 6.728 0 0 1 1.424-2.117c.6-.6 1.306-1.075 2.117-1.425A6.495 6.495 0 0 1 7 .334c.922 0 1.789.175 2.6.525.811.35 1.517.825 2.117 1.425.6.6 1.074 1.306 1.424 2.117.35.81.526 1.677.526 2.6 0 .922-.175 1.789-.526 2.6a6.728 6.728 0 0 1-1.424 2.116c-.6.6-1.306 1.075-2.117 1.425a6.489 6.489 0 0 1-2.6.525Zm0-1.333c1.489 0 2.75-.517 3.783-1.55 1.034-1.033 1.55-2.294 1.55-3.783 0-1.49-.516-2.75-1.55-3.784C9.75 2.184 8.49 1.667 7 1.667c-1.489 0-2.75.517-3.783 1.55-1.034 1.034-1.55 2.295-1.55 3.784 0 1.489.516 2.75 1.55 3.783C4.25 11.817 5.51 12.334 7 12.334Z"
    />
  </svg>
);

const CancelIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CancelSvg} {...props} />
);

export default CancelIcon;
