import { Dayjs } from 'dayjs';
import 'dayjs/locale/it';
import { useRecoilState } from 'recoil';
import { reportFiltersState } from '@store/report/reportFiltersState';
import { useTimeframeSelector } from '@hooks';
import { TimeFrame } from '@components/atoms/select/select';
import { useCallback, useEffect, useState } from 'react';
import { t } from 'i18next';
import {
  InputWrapper,
  LabelWrapper,
  StyledSelect,
} from '@src/routes/Report/components/helpers';
export type AntdRangeValue = [Dayjs | null, Dayjs | null] | null;
export default function DateTimeFrameSelect({
  showRangePicker,
  fullscreen,
  style,
  dark,
}: {
  showRangePicker?: boolean;
  fullscreen?: boolean;
  style?: any;
  dark?: boolean;
}) {
  const [filters] = useRecoilState(reportFiltersState);
  const { onSelectedDatesSelectChange } = useTimeframeSelector();
  const [dateRange, set_dateRange] = useState(
    filters.dateRange || TimeFrame.today,
  );

  useEffect(() => {
    if (filters.dateRange) {
      set_dateRange(filters.dateRange);
    }
  }, [filters.dateRange]);

  const onChange = useCallback(
    async (value: string) => {
      onSelectedDatesSelectChange(value as TimeFrame);
    },
    [onSelectedDatesSelectChange],
  );
  return (
    <InputWrapper>
      <LabelWrapper>
        {(t('general.period') as string).toUpperCase()}
      </LabelWrapper>
      <StyledSelect
        size="small"
        style={{
          width: '100%',
          maxWidth: 160,
          marginRight: 0,
          paddingLeft: 5,
          marginTop: showRangePicker || !fullscreen ? 16 : 0,
          ...style,
        }}
        defaultValue={dateRange}
        value={dateRange}
        options={[
          {
            label: t('general.today'),
            value: TimeFrame.today,
          },
          {
            label: t('general.thisWeek'),
            value: TimeFrame.thisWeek,
          },
          {
            label: t('general.lastWeek'),
            value: TimeFrame.lastWeek,
          },
          {
            label: t('general.thisMonth'),
            value: TimeFrame.thisMonth,
          },
          {
            label: t('general.lastMonth'),
            value: TimeFrame.lastMonth,
          },
          {
            label: t('general.thisQuarter'),
            value: TimeFrame.thisQuarter,
          },
          // {
          //   label: t('general.lastYear'),
          //   value: TimeFrame.lastYear,
          // },
        ]}
        onChange={onChange}
      />
    </InputWrapper>
  );
}
