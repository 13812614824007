import { OverviewListItem } from '.';

export const mock: OverviewListItem[] = [
  { title: 'Macchine', info: '', icon: '', value: '32', label: '' },
  {
    title: 'Sensori connessi',
    info: '',
    icon: '',
    value: '96',
    label: '/96',
  },
  {
    title: 'Uptime medio',
    info: "La percentuale di tempo in cui l'asset non è in stato di stop.",
    icon: '',
    iconColor: '#18CD4B',
    value: '67%',
    label: '+3.1%',
    labelColor: '#18CD4B',
  },
  {
    title: 'Consumo',
    info: 'Consumo totale per il periodo selezionato',
    icon: 'trending_up',
    iconColor: '#E8684A',
    value: '385',
    label: 'kWh',
  },
  { title: 'Ore lavorate', info: '', icon: '', value: '9.3', label: 'kWh' },
  {
    title: 'Autonomia stimata',
    info: '',
    icon: 'battery_low',
    iconColor: '#F6BD16',
    value: '4',
    label: 'gg',
  },
  {
    title: 'Segnale',
    info: '',
    icon: 'podcasts',
    iconColor: '#18CD4B',
    value: '',
    label: 'Buono',
  },
  {
    title: 'Corrente',
    info: '',
    icon: 'electrical_services',
    iconColor: '#F6BD16',
    value: '48',
    label: 'A',
  },
];
