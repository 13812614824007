import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const TemperatureSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill={props.fill}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={22}
      viewBox="0 0 56 56"
    >
      <g>
        <path d="M25.5,38c-2.7,0-4.9,2.2-4.9,4.9c0,2.7,2.2,4.9,4.9,4.9c2.7,0,4.9-2.2,4.9-4.9C30.4,40.2,28.2,38,25.5,38z M25.5,45.8   c-1.6,0-2.9-1.3-2.9-2.9s1.3-2.9,2.9-2.9s2.9,1.3,2.9,2.9S27.1,45.8,25.5,45.8z" />
        <path d="M30,33.8V7.4c0-1.2-0.5-2.3-1.3-3.1C27.8,3.5,26.7,3,25.5,3c-2.4,0-4.4,2-4.4,4.4v26.4c-3.5,1.7-5.6,5.2-5.6,9.1   C15.4,48.5,20,53,25.5,53c5.6,0,10.1-4.5,10.1-10.1C35.6,39,33.4,35.5,30,33.8z M25.5,51c-4.5,0-8.1-3.6-8.1-8.1c0-3.3,2-6.2,5-7.5   c0.4-0.2,0.6-0.5,0.6-0.9V7.4c0-1.3,1.1-2.4,2.4-2.4c0.7,0,1.3,0.3,1.7,0.7C27.7,6.2,28,6.8,28,7.4v27c0,0.4,0.2,0.8,0.6,0.9   c3.1,1.3,5,4.2,5,7.5C33.6,47.4,30,51,25.5,51z" />
        <path d="M32.6,9.9h6.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-6.9c-0.6,0-1,0.4-1,1S32.1,9.9,32.6,9.9z" />
        <path d="M32.6,16h3.5c0.6,0,1-0.4,1-1s-0.4-1-1-1h-3.5c-0.6,0-1,0.4-1,1S32.1,16,32.6,16z" />
        <path d="M39.6,20.1h-6.9c-0.6,0-1,0.4-1,1s0.4,1,1,1h6.9c0.6,0,1-0.4,1-1S40.1,20.1,39.6,20.1z" />
        <path d="M36.1,26.2h-3.5c-0.6,0-1,0.4-1,1s0.4,1,1,1h3.5c0.6,0,1-0.4,1-1S36.6,26.2,36.1,26.2z" />
      </g>
    </svg>
  );
};

const TemperatureIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={() => <TemperatureSvg fill={props.fill} />} {...props} />
);

export default TemperatureIcon;
