import { API } from 'aws-amplify';
import { ASSET_API } from '@src/api';

export async function deleteEvent(
  tenantId?: string,
  assetId?: string,
  eventId?: string,
) {
  if (tenantId && assetId && eventId) {
    return API.del(
      ASSET_API,
      `/tenant/${tenantId}/${assetId}/event/${eventId}`,
      {},
    );
  }

  return [];
}
