import { NotificationContext } from '@src/App';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import {
  GET_ASSET_ALERTS_QUERY_KEY,
  GET_ASSET_ALERTS_TABLE_QUERY_KEY,
} from '../consts';
import { deleteAlert } from './delete-alert';
import { useContext } from 'react';

export function useDeleteAlert(
  params?: UseMutationOptions<any, any, any, any>,
) {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);

  const { toast } = useContext(NotificationContext);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (alertId: string) => deleteAlert(selectedTenantId, alertId),
    onSettled: () => {
      queryClient.invalidateQueries(GET_ASSET_ALERTS_QUERY_KEY);
      queryClient.invalidateQueries(GET_ASSET_ALERTS_TABLE_QUERY_KEY);
    },

    onSuccess: () => {
      toast?.success({
        message: 'Alert eliminato con successo',
        placement: 'topRight',
      });
    },
    onError: () => {
      toast?.error({
        message: "Errore durante l'eliminazione dell'alert",
        placement: 'topRight',
      });
    },

    ...params,
  });
}
