import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    debug: true,
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      it: {
        translation: require('./locales/it.json')
      },
      es: {
        translation: require('./locales/es.json')
      }
    }
  });

export default i18n;