import React, { useCallback } from 'react';
import Card, { CardProps } from '@components/molecules/card';
import Tag, { TagType } from '@components/atoms/tag';
import { useTranslation } from 'react-i18next';
import { useDeleteEvent } from '@src/api/event/use-delete-event';
import styled from 'styled-components';
import { fontSize } from '@src/globalStyle';
import { Col, Row as RowAntd, Space, Tooltip } from 'antd';
import Icon from '@components/atoms/icon';
import { AlertSeverityEnumDto } from '@asset-manager/types';
import { title } from 'process';

export interface Props extends CardProps {
  eventId: string;
  tenantId?: string;
  assetId: string;
  description?: string;
  assetName: string;
  alertSeverity: AlertSeverityEnumDto;
  alertName: string;
  date?: string;
  time?: string;
  subTypeSeverity?: TagType;
  subTypeName?: string;
  duration?: number | string;
  onUpdate?: () => void;
}

// ${fontSize.m}
const Title = styled.h3`
  font-weight: 700;
  font-size: 16px;
  color: white;
  line-height: 24px;
  margin-bottom: 8px;
`;

const Text = styled.span<{ bold?: boolean; fs?: any }>`
  ${({ fs }: any) => fs ?? fontSize.xs};
  font-weight: 500;
  color: ${({ color }) => color ?? '#919191'};
`;

const Row = styled(RowAntd)``;

const RowHeader = styled(Row)`
  margin-bottom: 8px;
`;

const RowInfo = styled(Row)`
  display: flex;
  flex-direction: column;
`;

const ColumnInfo = styled(Col)<{ justifyContent?: string }>`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  justify-content: ${props => props.justifyContent || undefined};
  gap: 8px;
`;

const ColumnActions = styled(ColumnInfo)`
  align-items: flex-end;
`;

const CardA = styled(Card)`
  min-height: 90px;
  width: calc(100% - 8px);
  padding: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  :hover {
    cursor: pointer;
    background-color: #373737;
  }

  .content {
    display: flex;
    justify-content: space-between;
  }
`;

const InfoIcon = styled(Icon)<any>`
  font-size: 16px;
  margin-right: 6px;
`;

const DateItem = styled.div`
  display: flex;
`;

const TextItem = styled.div`
  display: flex;
`;

const CardTag = styled(Tag)`
  padding: 3px 12px;
`;
const CardAlerts = ({
  eventId,
  tenantId,
  assetId,
  assetName,
  onUpdate,
  alertName,
  alertSeverity,
  date,
  time,
  subTypeSeverity,
  subTypeName,
  duration,
  description,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const deleteEvent = useDeleteEvent();
  const getStatusTagFromSubType = useCallback(
    (
      value: 'LOW' | 'MEDIUM' | 'HIGH',
    ): 'error' | 'warning' | 'success' | 'processing' => {
      if (value === 'HIGH') return 'error';
      if (value === 'MEDIUM') return 'warning';
      if (value === 'LOW') return 'success';
      if (value === 'BLUE') return 'processing';
      return value;
    },
    [],
  );

  return (
    <CardA status="basic" {...props}>
      <div className="content">
        <ColumnInfo justifyContent={'space-between'}>
          <RowInfo>
            {alertName && <Title>{alertName}</Title>}{' '}
            <Space size={8} style={{ marginTop: 8 }}>
              {!!date && (
                <DateItem>
                  <InfoIcon color="#919191" name="event" />
                  <Text>{date}</Text>
                </DateItem>
              )}
              {!!time && (
                <DateItem>
                  <InfoIcon color="#919191" name="schedule" />
                  <Text>{time}</Text>
                </DateItem>
              )}
              {!!assetName && (
                <>
                  <Tooltip color="#373737" title={t('alerts.generatedFrom')}>
                    {/* InfoIcon di Tooltip non piace */}
                    {/* <InfoIcon space={!!title} /> */}
                    <TextItem>
                      <InfoIcon
                        color="#919191"
                        name="precision_manufacturing"
                      />
                      <Text>{assetName}</Text>
                    </TextItem>
                  </Tooltip>
                </>
              )}
            </Space>
          </RowInfo>
        </ColumnInfo>

        <ColumnActions style={{ display: 'flex' }}>
          <Row>
            <Space
              size={8}
              style={{ marginTop: 4, marginRight: 7, marginBottom: 10 }}
            >
              {description && description?.length > 0 ? (
                <Icon style={{ fontSize: 20 }} name="chat" color={'#4786FF'} />
              ) : null}
              <Icon
                style={{
                  fontSize: 20,
                  // make it center
                  alignContent: 'center',
                }}
                name="emergency_home"
                title={
                  alertSeverity === AlertSeverityEnumDto.HIGH
                    ? t('general.high')
                    : t('general.normal')
                }
                color={
                  alertSeverity === AlertSeverityEnumDto.HIGH
                    ? '#E8684A'
                    : '#F6BD16'
                }
              />
            </Space>
          </Row>
        </ColumnActions>
      </div>

      {(!!duration || !!subTypeSeverity || !!subTypeName) && (
        <Row
          style={{
            marginTop: 20,
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <TextItem>
            {!!duration && (
              <>
                <Tooltip color="#373737" title={t('alerts.duration')}>
                  {' '}
                  <InfoIcon
                    color="#919191"
                    name="timer"
                    style={{ lineHeight: '24px' }}
                  />
                  <Text color="#ffffff" fs={fontSize.s}>
                    {Number(duration) + ' ' + t('general.min')}
                  </Text>
                </Tooltip>
              </>
            )}
          </TextItem>
          {(!!subTypeSeverity || !!subTypeName) && (
            <CardTag
              dark
              size="label"
              label={subTypeName}
              status={getStatusTagFromSubType(subTypeSeverity as any)}
            />
          )}
        </Row>
      )}
    </CardA>
  );
};

export default CardAlerts;
