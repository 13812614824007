import { Amplify, Auth } from 'aws-amplify';
import {
  ALERT_API,
  ASSET_API,
  FAQ_API,
  NOTE_API,
  REPORT_API,
  SENSOR_API,
  TENANT_API,
} from '@src/api';
import { Service } from '@asset-manager/api';

async function useCustomHeader() {
  return {
    Authorization: `Bearer ${(await Auth.currentSession())
      .getAccessToken()
      .getJwtToken()}`,
  };
}

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_SRP_AUTH',
  },

  API: {
    endpoints: [
      {
        name: ASSET_API,
        endpoint: process.env.REACT_APP_AMPLIFY_ASSET_API,
        custom_header: useCustomHeader,
      },
      {
        name: TENANT_API,
        endpoint: process.env.REACT_APP_AMPLIFY_TENANT_API,
        custom_header: useCustomHeader,
      },
      {
        name: REPORT_API,
        endpoint: process.env.REACT_APP_AMPLIFY_REPORT_API,
        custom_header: useCustomHeader,
      },
      {
        name: NOTE_API,
        endpoint: process.env.REACT_APP_AMPLIFY_NOTE_API,
        custom_header: useCustomHeader,
      },
      {
        name: SENSOR_API,
        endpoint: process.env.REACT_APP_AMPLIFY_SENSOR_API,
        custom_header: useCustomHeader,
      },
      {
        name: Service.user,
        endpoint: process.env.REACT_APP_AMPLIFY_USER_API,
        custom_header: useCustomHeader,
      },
      {
        name: FAQ_API,
        endpoint: process.env.REACT_APP_AMPLIFY_FAQ_API,
        custom_header: useCustomHeader,
      },
      {
        name: ALERT_API,
        endpoint: process.env.REACT_APP_AMPLIFY_ALERT_API,
        custom_header: useCustomHeader,
      },
    ],
  },
});
