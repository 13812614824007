import { t } from 'i18next';
import Icon from '../icon';
import EmptyState from './empty-state';

export default function EmptyStateChart({ ...props }) {
  return (
    <EmptyState
      image={
        <Icon name="area_chart" style={{ fontSize: 48 }} color="currentColor" />
      }
      title={t('general.noDataRevealed')}
      subtitle={
        props?.subtitle ||
        t('general.controlAssociationAsset')
      }
      {...props}
    />
  );
}
