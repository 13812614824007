import { useQuery } from 'react-query';
import { GET_ASSET_DETAILS_QUERY_KEY, fetchAsset } from '@src/api';
import { fetchConfig } from '@src/config/http';
import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { selectedAssetIdState } from '@store/asset';

export function useGetAsset() {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [selectedAssetId] = useRecoilState(selectedAssetIdState);

  const query = useQuery(
    [GET_ASSET_DETAILS_QUERY_KEY, selectedTenantId, selectedAssetId],
    () => {
      return fetchAsset(selectedTenantId, selectedAssetId);
    },
    { ...(fetchConfig as any) },
  );

  return query;
}
