import { Form, Input, Row } from 'antd';
import AuthPage from '../components/AuthPage';
import {
  Title,
  Section,
  ButtonSubmit,
  Subtitle,
  StyledForm,
  ButtonLink,
} from '../components/common';
import { useAmplify } from '../hooks';
import { useContext, useState } from 'react';
import { NotificationContext } from '@src/App';
import { Link, useNavigate } from 'react-router-dom';

export default function ForgotPasswordPage() {
  const { forgotPassword } = useAmplify();
  const { toast } = useContext(NotificationContext);
  const navigate = useNavigate();

  const [form] = Form.useForm<{ email: string }>();

  const [isLoading, setIsLoading] = useState(false);

  const onAskForgotPassword = (fields: any) => {
    setIsLoading(true);
    forgotPassword(fields.email)
      .then(() => {
        navigate('/reset-password', {
          state: { email: fields.email },
        });
      })
      .catch(err => {
        console.error(err);
        toast?.error({
          message: 'Non è stato possibile inviare la mail di recupero password',
          placement: 'topRight',
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <AuthPage>
      <Section>
        <Title>Password dimenticata</Title>
        <Subtitle>
          Inserisci il tuo indirizzo email e ti invieremo un codice per
          aggiornare la password
        </Subtitle>
        <StyledForm
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onAskForgotPassword}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <Form.Item
            // noStyle
            label="Email"
            name="email"
            rules={[
              { required: true, message: 'Email is required' },
              {
                type: 'email',
                message: 'Email is not valid',
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <Input placeholder="Email" />
          </Form.Item>

          <Form.Item style={{ width: '100%' }}>
            <ButtonSubmit
              dark
              variant="primary"
              htmlType="submit"
              disabled={isLoading}
            >
              Invia
            </ButtonSubmit>
          </Form.Item>
        </StyledForm>
      </Section>
      <Row justify="space-between" align="middle">
        <Link to="/sign-in">
          <ButtonLink>Torna al login</ButtonLink>
        </Link>
      </Row>
    </AuthPage>
  );
}
