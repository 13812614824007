import { atom } from 'recoil';

// export const siderMenuState = atom({
//   key: 'siderMenuState',
//   default: false,
// });

export const siderMenuVisibilityState = atom({
  key: 'siderMenuVisibilityState',
  default: true,
});

export const siderMenuCollapsibleState = atom({
  key: 'siderMenuCollapsibleState',
  default: true,
});


export const clickedSamePageSiderMenuState = atom({
  key: 'clickedSamePageSiderMenuState',
  default: false,
});


export const closeOrContractDrawerState = atom({
  key: 'closeOrContractDrawerState',
  default: false,
});