import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { GET_AUTHORS_QUERY_KEY } from '@src/api';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { fetchConfig } from '@src/config/http';
import { fetchAuthors } from './fetch-authors';
import { noteAuthorsFiltersState } from '@store/note/note-author-filters-state';

export function useAuthors() {
  const [tenantId] = useRecoilState(selectedTenantIdState);
  const [filters] = useRecoilState(noteAuthorsFiltersState);

  return useQuery(
    [GET_AUTHORS_QUERY_KEY, tenantId, filters],
    () => fetchAuthors(tenantId, filters),
    {
      ...(fetchConfig as any),
      enabled: !!tenantId,
    },
  );
}
