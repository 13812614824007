import { API } from 'aws-amplify';
import { NOTE_API } from '@src/api';

export interface CreateNoteBody {
  title?: string;
  description?: string;
  eventId?: string;
  alertId?: string;
}

export async function createNote(
  tenantId?: string,
  assetId?: string,
  body?: CreateNoteBody,
) {
  if (tenantId && assetId && body) {
    return API.post(NOTE_API, `/tenant/${tenantId}/asset/${assetId}`, { body });
  }

  return [];
}
