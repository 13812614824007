import { API } from 'aws-amplify';
import { TENANT_API, useGetTenantEventSubTypes } from '@src/api';
import { CreateTenantSubTypeDto } from '@asset-manager/types';
import { useMutation } from 'react-query';
import analytics from '@src/utils/analytics';
import { useTenant } from '@src/modules/asset';
import { useAmplify } from '@src/modules/amplify';
import { format } from 'date-fns';

export function useCreateEventSubType() {
  const { refetch: refetchTenantEventSubTypes } = useGetTenantEventSubTypes();
  const { tenant } = useTenant();
  const { user } = useAmplify();
  return useMutation({
    // TODO: remove description after be deploy
    mutationFn: (params: CreateTenantSubTypeDto & { tenantId: string }) => {
      const { tenantId, ...body } = params;
      return API.post(TENANT_API, `/${tenantId}/event/subType`, {
        body: body,
      });
    },
    onSettled: () => {
      refetchTenantEventSubTypes().catch(() => console.log('error'));
    },
    onSuccess: data => {
      console.log('sending stop_classification event', data);
      analytics.logEvent('stop_classification', {
        touchpoint: 'Astra web',
        tenant: tenant?.name,
        userId: user?.username,
        tenantId: tenant?.id,
        time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      });
    },
  });
}
