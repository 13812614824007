import Button from '@components/atoms/button';
import { Form } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  body {
    background-color: #1c1c1e;
  }
`;

export const StyledForm = styled(Form)`
  width: 320px;
  margin-top: 32px;
  .ant-form-item .ant-form-item-label > label {
    display: none; // così prende il margin del form
  }

  input[type='text'],
  input[type='email'],
  input[type='password'] {
    padding-top: 10px;
    padding-bottom: 10px;
    /* &::placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #7a7a7a;
  } */
  }

  .ant-input-affix-wrapper {
    padding: 0 11px;
  }

  // NICE TO HAVE: how can i override this style?
  /* input:-internal-autofill-selected {
    appearance: menulist-button;
    background-image: none !important;
    background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
    color: fieldtext !important;
  } */
`;

export const Section = styled.div`
  width: 320px;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
`;

export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  margin: 0;
  padding-top: 8px;
`;

export const Text = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #7a7a7a;
`;

export const ButtonLink = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  margin: 0;
  color: white !important;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const ButtonSubmit = styled(Button)`
  margin-top: 32px;
  width: 100% !important;
`;
