import { API } from 'aws-amplify';
import { REPORT_API } from '@src/api';
import {  ReportUpdateThresholdsDto } from '@asset-manager/types';

export async function createThresholds(
  tenantId?: string,
  assetId?: string,
  body?: ReportUpdateThresholdsDto,
) {
  if (tenantId && assetId && body) {
    return API.post(REPORT_API, `/mqtt-connector/${tenantId}/${assetId}/thresholds`, {
      body,
    });
  }

  return [];
}
