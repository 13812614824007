import styled, { css } from 'styled-components';
import { ModalProps, Row, Spin, Tag as AntTag } from 'antd';
import Tag from '@components/atoms/tag';
import { Note as INote, useAssetsNotes } from '@src/api';
import Col from 'antd/lib/grid/col';
import Button from '@components/atoms/button';
import Error from '@components/atoms/error';
import Icon from '@components/atoms/icon';
import Textarea from '@components/atoms/textarea';
import { useContext, useEffect, useRef, useState } from 'react';
import ConfigProvider from 'antd/lib/config-provider';
import { darkTheme } from '@theme/dark';
import DrawerSection from '../section/section';
import { NotesRangePickerNode } from '@components/organisms/range-picker-node';
import { format } from 'date-fns';
import { ContractContentIcon } from '@assets';
import { useDeleteNote } from '@src/api/note/use-delete-note';
import { useCreateNote } from '@src/api/note/use-create-note';
import { useRecoilState } from 'recoil';
import { selectedAssetIdState } from '@store/asset';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import EmptyState from '@components/atoms/empty-state';
import { NotificationContext } from '@src/App';
import useInfiniteScrolling from '@src/hooks/use-infinite-scrolling';
import ConfirmModal from '@components/molecules/confirm-modal';
import Modal from '@components/atoms/modal';
import Select from '@components/atoms/select';
import { useAuthors } from '@src/api/note/use-authors';
import { noteFiltersState } from '@store/note/note-filters-state';
import DateTimeFrameSelectNoteDrawerContent from '@components/atoms/date-time-frame-select-note-drawer-content';
import { size } from '@theme/breakpoints';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const Container = styled.div``;

const calculateDuration = (startDate: string, endDate: string) => {
  const diff = Math.abs(
    new Date(startDate).getTime() - new Date(endDate).getTime(),
  );
  const days = Math.floor(diff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((diff / (1000 * 60)) % 60);

  let duration = '';

  if (days > 0) {
    const daysLabel = days === 1 ? 'giorno' : 'giorni';
    duration += `${days} ${daysLabel}`;
  }

  if (hours > 0) {
    duration.length > 0 && (duration += ' ');
    const hoursLabel = hours === 1 ? 'ora' : 'ore';
    duration += `${hours} ${hoursLabel}`;
  }

  const minutesLabel = minutes === 1 ? 'minuto' : 'minuti';
  duration.length > 0 && (duration += ' ');
  duration += `${minutes} ${minutesLabel}`;

  return duration;
};

const NoteLabel = styled.span<{
  size: 'small' | 'extra-small';
  rightMargin?: boolean;
  leftMargin?: boolean;
}>`
  color: #919191;
  ${({ size }) =>
    size === 'small'
      ? css`
          font-size: 14px;
        `
      : css`
          font-size: 12px;
        `}
  ${({ rightMargin }) =>
    rightMargin &&
    css`
      margin-right: 8px;
    `}
  ${({ leftMargin }) =>
    leftMargin &&
    css`
      margin-left: 16px;
    `}
`;

const NoteContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #373737;
  padding-block: 16px;
`;

const NoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoteDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 6px;
  padding: 8px 16px;
  background-color: #322c1d;
  margin-top: 12px;
`;

const NoteDetail = styled.div<{ fullscreen?: boolean; marginLeft?: boolean }>`
  display: inline-flex;
  ${({ fullscreen }) =>
    fullscreen
      ? css`
          flex-direction: row;
          align-items: center;
        `
      : css`
          flex-direction: column;
          align-items: flex-start;
        `}
  ${({ marginLeft }) =>
    marginLeft &&
    css`
      margin-left: 12px;
    `}
`;

const NoteActions = styled.div`
  display: flex;
  gap: 8px;
`;

const NoteBody = styled.div<{
  compact?: boolean;
}>`
  padding-block: 12px;
  ${({ compact }) =>
    compact &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;

interface NoteProps {
  note: INote;
  isExpanded?: boolean;
  onExpand?: () => void;
  fullscreen?: boolean;
}

const Note = ({ note, isExpanded, onExpand, fullscreen }: NoteProps) => {
  const { t } = useTranslation()
  const bodyRef = useRef<HTMLDivElement>(null);
  const [showExpand, setShowExpand] = useState(false);

  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [selectedAssetId] = useRecoilState(selectedAssetIdState);

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);

  const { toast } = useContext(NotificationContext);

  const deleteNote = useDeleteNote({
    onSuccess: () => {
      toast?.success({
        message: t('notes.noteDeletedSuccessfully'),
        placement: 'topRight',
      });
      setIsConfirmDeleteModalOpen(false);
    },
    onError: () => {
      toast?.error({
        message: t('notes.noteDeleteError'),
        placement: 'topRight',
      });
      setIsConfirmDeleteModalOpen(false);
    },
  });

  useEffect(() => {
    if (bodyRef.current) {
      setShowExpand(bodyRef.current.offsetWidth < bodyRef.current.scrollWidth);
    }
  }, [bodyRef]);

  return (
    <NoteContainer>
      <NoteHeader>
        <NoteLabel size="small">
          {format(new Date(note.createdAt), 'dd-MM-yyyy HH:mm')}
        </NoteLabel>
        <NoteActions>
          {showExpand && (
            <Button
              center
              shape="square"
              variant="grey-E3"
              shapeSize={32}
              onClick={onExpand}
            >
              {isExpanded ? (
                <ContractContentIcon width={24} height={24} />
              ) : (
                <Icon
                  name="expand_content"
                  color="currenColor"
                  style={{ fontSize: 24, fontWeight: 700 }}
                />
              )}
            </Button>
          )}
          <Button
            center
            shape="square"
            variant="grey-E3"
            shapeSize={32}
            onClick={() => setIsConfirmDeleteModalOpen(true)}
          >
            <Icon name={'delete'} />
          </Button>
        </NoteActions>
      </NoteHeader>
      {note.event && (
        <NoteDetailsContainer>
          <Tag
            dark
            size="label"
            label={note.event.type}
            status="warning"
            showBorder
            hideIcon
          />
          <div>
            <NoteDetail fullscreen={fullscreen}>
              <NoteLabel size="extra-small" rightMargin>
                {t('general.date')}
              </NoteLabel>
              <span>{format(new Date(note.createdAt), 'yyyy-MM-dd')}</span>
            </NoteDetail>
            <NoteDetail fullscreen={fullscreen} marginLeft>
              <NoteLabel size="extra-small" rightMargin>
                {t('general.hour')}
              </NoteLabel>
              <span>{format(new Date(note.createdAt), 'HH:mm')}</span>
            </NoteDetail>
            <NoteDetail fullscreen={fullscreen} marginLeft>
              <NoteLabel size="extra-small" rightMargin>
                {t('general.duration')}
              </NoteLabel>
              <span>
                {calculateDuration(note.event.startDate, note.event.endDate)}
              </span>
            </NoteDetail>
          </div>
        </NoteDetailsContainer>
      )}
      <NoteBody ref={bodyRef} compact={!isExpanded}>
        {note.description}
      </NoteBody>
      <AntTag
        color="#202735"
        style={{ display: 'inline-flex', alignItems: 'center' }}
      >
        <Icon
          name="message"
          style={{ fontSize: 16, marginRight: 10 }}
          color={'#4786FF'}
        />
        <span style={{ color: '#4786FF' }}>
          {note.creator.name} {note.creator.surname}
        </span>
      </AntTag>
      <ConfirmModal
        title={t('notes.noteDeletionAlert')}
        description={t('notes.noteDeleteDeleteQuestion')}
        open={isConfirmDeleteModalOpen}
        onCancel={() => setIsConfirmDeleteModalOpen(false)}
        onConfirm={() => {
          deleteNote.mutate({
            tenantId: selectedTenantId,
            assetId: selectedAssetId,
            noteId: note.id,
          });
        }}
        dark
        confirmLoading={deleteNote.isLoading}
      />
    </NoteContainer>
  );
};

const StyledSpin = styled(Spin) <{ fullscreen?: boolean }>`
  ${({ fullscreen }) =>
    !fullscreen &&
    css`
      margin-top: 32px;
    `}
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledModal = styled(Modal)`
  @media (max-width: ${size.tablet}) {
    .ant-modal {
      height: -webkit-fill-available;
      max-width: 100vw;
    }
    .ant-modal-content {
      height: 100vh;
      top: 0;
      overflow: auto;
      margin: -15px;
    }
    .ant-modal-centered::before {
      content: unset;
    }
    .ant-modal-footer {
      position: absolute;
      bottom: 12px;
      width: 90%;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

interface AddNoteModalProps extends ModalProps {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmLoading: boolean;
  value: string;
  onChange: (value: string) => void;
}

const AddNoteModal = ({
  open,
  onConfirm,
  confirmLoading,
  onCancel,
  value,
  onChange,
  ...props
}: AddNoteModalProps) => {
  return (
    <StyledModal
      dark
      title={t('notes.addNote')}
      open={open}
      // @ts-ignore
      onOk={onConfirm}
      okText={t('notes.addNote')}
      confirmLoading={confirmLoading}
      onCancel={onCancel}
      centered
      {...props}
    >
      <Textarea
        dark
        style={{
          height: 208,
        }}
        placeholder={t('notes.insertNote')}
        onChange={e => onChange(e.target.value)}
        value={value}
      />
    </StyledModal>
  );
};

interface NoteAuthorSelectProps {
  onChangeAuthor: (value: any) => void;
}

const NoteAuthorSelect = ({ onChangeAuthor }: NoteAuthorSelectProps) => {
  const { data: authors } = useAuthors();
  const [, setNoteFilters] = useRecoilState(noteFiltersState);

  return (
    <Select
      dark
      size="large"
      style={{ marginTop: 16 }}
      defaultValue={''}
      options={[
        {
          label: t('notes.allAuthors'),
          value: '',
        },
        ...(authors?.items || []).map(author => ({
          label: `${author.name} ${author.surname}`,
          value: author.id,
        })),
      ]}
      onSelect={(value, option) => {
        if (option) {
          const name = option.label;
          onChangeAuthor(name);
          setNoteFilters(filters => ({
            ...filters,
            userId: value,
          }));
        }
      }}
    />
  );
};

export default function NoteDrawerContent({
  fullscreen,
}: {
  fullscreen?: boolean;
}) {
  const _fullscreen = fullscreen && window.innerWidth >= 768;
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [selectedAssetId] = useRecoilState(selectedAssetIdState);

  const { toast } = useContext(NotificationContext);

  const createNote = useCreateNote();

  const handleCreateNote = () => {
    createNote.mutate(
      {
        tenantId: selectedTenantId,
        assetId: selectedAssetId,
        body: {
          title: '',
          description: newNote,
        },
      },
      {
        onSuccess: () => {
          setNewNote('');
          toast?.success({
            message: t('notes.noteCreatedSuccessfully'),
            placement: 'topRight',
          });
          setIsAddNoteModalOpen(false);
        },
        onError: () => {
          toast?.error({
            message: t('notes.errorCreationNote'),
            placement: 'topRight',
          });
        },
      },
    );
  };

  const { data, isLoading, isError, fetchNextPage } = useAssetsNotes();

  const [newNote, setNewNote] = useState('');

  const [expandedNoteIndex, setExpandedNoteIndex] = useState<number>();

  const ref = useRef<HTMLDivElement>(null);
  const infiniteScrollBottomRef = useRef<HTMLDivElement>(null);
  const [heightOffsetFromTop, setHeightOffsetFromTop] = useState<number>(0);
  const [selectedAuthorValue, setSelectedAuthorValue] = useState('');

  const { isLoading: isInfiniteScrollingLoading } = useInfiniteScrolling(
    fetchNextPage,
    infiniteScrollBottomRef,
  );

  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState(false);

  const handleAuthorSelect = (value: string) => {
    setSelectedAuthorValue(value);
  };

  useEffect(() => {
    if (ref.current) {
      setHeightOffsetFromTop(ref.current.getBoundingClientRect().top);
    }
  }, []);

  return (
    <ConfigProvider theme={darkTheme}>
      <Container ref={ref}>
        <Row
          wrap={false}
          style={{
            flexDirection: _fullscreen ? 'row' : 'column',
            minHeight: `calc(100vh - ${heightOffsetFromTop}px - 24px)`,
            position: 'relative',
          }}
        >
          <Col
            flex={_fullscreen ? '0 0 302px' : ''}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              paddingTop: 16,
            }}
          >
            {_fullscreen ? (
              <NotesRangePickerNode />
            ) : (
              <DateTimeFrameSelectNoteDrawerContent />
            )}

            <NoteAuthorSelect onChangeAuthor={handleAuthorSelect} />

            {_fullscreen && (
              <DrawerSection style={{ marginTop: 'auto' }} title={t('notes.newNote')}>
                <Textarea
                  dark
                  style={{
                    height: 208,
                  }}
                  placeholder={t('notes.insertNote')}
                  onChange={e => setNewNote(e.target.value)}
                  value={newNote}
                />
                <Button
                  style={{ marginTop: 16 }}
                  disabled={!newNote.length}
                  onClick={handleCreateNote}
                >
                  {t('notes.addNote')}
                </Button>
              </DrawerSection>
            )}
          </Col>
          <Col
            style={
              _fullscreen
                ? {
                  maxWidth: 'calc(100% - 302px)',
                  flexGrow: 1,
                  paddingLeft: 32,
                  overflowY: 'scroll',
                  height: `calc(100vh - ${heightOffsetFromTop}px - 24px)`,
                }
                : {
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  flexGrow: 1,
                }
            }
          >
            {isLoading ? (
              <StyledSpin size="large" fullscreen={_fullscreen} />
            ) : isError ? (
              <EmptyState
                title={t('notes.noNewNote')}
                subtitle={t('notes.addNoteDescription')}
                dark
                fillDimensions
                image={
                  <Icon
                    name="message"
                    style={{ fontSize: 48 }}
                    color="#919191"
                  />
                }
              />
            ) : data && data.pages.some(page => page.length > 0) ? (
              <div style={{ flexGrow: 1 }}>
                {data.pages.map(page =>
                  page.map((note, i) => (
                    <Note
                      key={note.id}
                      note={note}
                      isExpanded={expandedNoteIndex === i}
                      onExpand={() => {
                        if (expandedNoteIndex === i) {
                          setExpandedNoteIndex(undefined);
                        } else {
                          setExpandedNoteIndex(i);
                        }
                      }}
                      fullscreen={_fullscreen}
                    />
                  )),
                )}
              </div>
            ) : (
              <EmptyState
                title={
                  selectedAuthorValue === 'Tutti gli autori' ||
                    !selectedAuthorValue
                    ? t('notes.noNewNoteAdded')
                    : `${t('notes.noNewNoteAddedByAuthor')} ${selectedAuthorValue}`
                }
                subtitle={t('notes.addNoteDescription')}
                dark
                fillDimensions
                image={
                  <Icon
                    name="message"
                    style={{ fontSize: 48 }}
                    color="#919191"
                  />
                }
              />
            )}
            <Row justify={'center'} ref={infiniteScrollBottomRef}>
              {isInfiniteScrollingLoading && !isLoading && (
                <Spin style={{ paddingTop: 16 }} />
              )}
            </Row>
          </Col>
          {!_fullscreen && (
            <>
              <Button
                style={{
                  position: 'sticky',
                  bottom: 16,
                }}
                onClick={() => setIsAddNoteModalOpen(true)}
                icon={
                  <Icon
                    name="add_circle"
                    style={{ fontSize: 16 }}
                    color="#373737"
                  />
                }
              >
                {t('notes.addNote')}
              </Button>
              <AddNoteModal
                open={isAddNoteModalOpen}
                value={newNote}
                onChange={setNewNote}
                onConfirm={handleCreateNote}
                confirmLoading={createNote.isLoading}
                onCancel={() => {
                  setIsAddNoteModalOpen(false);
                }}
              />
            </>
          )}
        </Row>
      </Container>
    </ConfigProvider>
  );
}
