import { device } from '@theme/breakpoints';
import { css } from 'styled-components';

// TODO rem
export const fontSize = {
  l: css`
    // similar to h4
    font-size: 20px;
    line-height: 24px;
  `,
  m: css`
    // similar to h5
    font-size: 16px;
    line-height: 24px;
  `,
  s: css`
    // similar to h3
    font-size: 14px;
    line-height: 24px;
  `,
  xs: css`
    font-size: 12px;
    line-height: 16px;
  `,
  h1: css`
    font-size: 32px;
    line-height: 40px;

    @media ${device.laptop} {
      font-size: 40px;
      line-height: 48px;
    }
  `,
  h2: css`
    // similar to h3 - bis
    font-size: 24px;
    line-height: 32px;
  `,
  label: css`
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.03em;
  `,
};

export const utils = {
  ellipsis: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  hiddenScrollbar: css`
    /* Hide scrollbar for IE, Edge and Firefox */
    & {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }
    }
  `,
  xAxisWindows: css`
    div[data-chart-source-type='G2Plot'] {
      path#-axis-line + g {
        text {
        }
      }
      text[id|='-axis-label'] {
      }
    }
  `,
  hotfixXaxisWindows: css`
    div.cssid--heatmap:not(.cssid--square),
    div.cssid--area {
      svg g:nth-of-type(1) g:nth-of-type(2) g:nth-of-type(1) g:nth-of-type(1) {
        transform: translateY(-1px);
      }
    }
  `,
  customScrollbar: css`
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
      scrollbar-width: auto;
      scrollbar-color: #7a7a7a #1c1c1e;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      width: 16px;
    }

    *::-webkit-scrollbar-track {
      background: #1c1c1e;
    }

    *::-webkit-scrollbar-thumb {
      background-color: #7a7a7a;
      border-radius: 10px;
      border: 3px solid #1c1c1e;
    }
  `,
};
