import { Dayjs } from 'dayjs';
import 'dayjs/locale/it';
import { useRecoilState } from 'recoil';
import { useTimeframeSelector } from '@hooks';
import { TimeFrame } from '@components/atoms/select/select';
import Select from '@components/atoms/select';
import { useCallback, useEffect, useState } from 'react';
import { noteFiltersState } from '@store/note/note-filters-state';
import { useTranslation } from 'react-i18next';
export type AntdRangeValue = [Dayjs | null, Dayjs | null] | null;
export default function DateTimeFrameSelectNoteDrawerContent({
  showRangePicker,
  fullscreen,
  style,
}: {
  showRangePicker?: boolean;
  fullscreen?: boolean;
  style?: any;
}) {
  const { t } = useTranslation();
  const [filters] = useRecoilState(noteFiltersState);

  const { onSelectDatesSelectChangeNoteDrawerContent } = useTimeframeSelector();
  const [dateRange, set_dateRange] = useState(
    filters.dateRange || TimeFrame.today,
  );

  useEffect(() => {
    if (filters.dateRange) {
      set_dateRange(filters.dateRange);
    }
  }, [filters.startDate]);

  const onChange = useCallback(
    async (value: string) => {
      onSelectDatesSelectChangeNoteDrawerContent(value as TimeFrame);
      set_dateRange(value);
    },
    [onSelectDatesSelectChangeNoteDrawerContent],
  );

  return (
    <Select
      dark
      size="large"
      style={{
        width: '100%',
        marginRight: 0,
        marginTop: showRangePicker || !fullscreen ? 16 : 0,
        ...style,
      }}
      defaultValue={dateRange}
      value={dateRange}
      options={[
        {
          label: t('general.today'),
          value: TimeFrame.today,
        },
        {
          label: t('general.thisWeek'),
          value: TimeFrame.thisWeek,
        },
        {
          label: t('general.lastWeek'),
          value: TimeFrame.lastWeek,
        },
        {
          label: t('general.thisMonth'),
          value: TimeFrame.thisMonth,
        },
        {
          label: t('general.lastMonth'),
          value: TimeFrame.lastMonth,
        },
        // {
        //      label: t('general.thisYear'),
        //      value: TimeFrame.thisYear,
        // },
        // {
        //      label: t('general.lastYear'),
        //      value: TimeFrame.lastYear,
        // },
      ]}
      onChange={onChange}
    />
  );
}
