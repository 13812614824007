import Header from '@components/organisms/header';
import { t } from 'i18next';

export default function Sensors() {
  return (
    <div>
      <Header title={t('general.sensors')} />
    </div>
  );
}
