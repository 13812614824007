import { Select as SelectAntd, SelectProps } from 'antd';
import styled, { createGlobalStyle } from 'styled-components';

import Icon from '../icon';
import { useTranslation } from 'react-i18next';

const StyledSelect = styled<any>(SelectAntd)`
  /* width: 100%; */

  &.ant-select-lg {
    .ant-select-selector {
      // lg base è altp 40, con questo sale a 44
      height: 44px !important;
      /* padding: 6px 11px !important; */
    }
  }

  .ant-select-selection-item {
    font-weight: 700;
    ${props => props.oneLiner && 'line-height: 42px !important;'}
  }
  /* .ant-select-item-option-content { } */
`;

const SuffixIcon = styled(Icon)`
  font-size: 16px;
`;

const SelectGlobalStyle = createGlobalStyle<{ dark: Prop['dark'] }>`

.ant-select-dropdown {
  padding: 16px;
  width: fit-content !important;
  content: ${props => String(props.dark)};
  border: 1px solid ${props => (!!props.dark ? '#373737' : '#C8C8C8')};
  max-width: -webkit-fill-available;

  .ant-select-item-option {
    width: fit-content;

  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled){
    &, &:hover {
      width: fit-content;
    }
  }

  .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
    background-color:${props => (!!props.dark ? '#FFFFFF' : '#E3E3E3')};
    color: #373737;
    font-weight: 700;
    width: fit-content;
  }
}

`;

interface Prop extends SelectProps {
  dark?: boolean;
  oneLiner?: boolean;
}

export enum TimeFrame {
  today = 'today',
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
  thisYear = 'thisYear',
  thisQuarter = 'thisQuarter',
  lastYear = 'lastYear',
}

export default function Select({ dark, oneLiner, ...props }: Prop) {
  const { t } = useTranslation();
  return (
    <>
      <SelectGlobalStyle dark={dark} />
      {/* NICE: label */}
      {/* TODO: ts */}
      {/* @ts-ignore */}
      <StyledSelect
        oneLiner={oneLiner}
        placeholder={t('general.selectOption')}
        defaultValue={'today'}
        suffixIcon={
          <SuffixIcon name="expand_more" color={dark ? '#fff' : '#1C1B1F'} />
        }
        options={[
          { value: TimeFrame.today, label: t('general.today') },
          { value: TimeFrame.thisWeek, label: t('general.thisWeek') },
          { value: TimeFrame.lastWeek, label: t('general.lastWeek') },
          { value: TimeFrame.thisMonth, label: t('general.thisMonth') },
          { value: TimeFrame.lastMonth, label: t('general.lastMonth') },
          { value: TimeFrame.thisQuarter, label: t('general.thisQuarter') },
          { value: TimeFrame.thisYear, label: t('general.thisYear') },
          { value: TimeFrame.lastYear, label: t('general.lastYear') },
        ]}
        {...props}
      />
    </>
  );
}
