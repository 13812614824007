import { useQuery } from 'react-query';
import { API } from 'aws-amplify';
import { USER_API } from '../consts';
import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { UserGetListResponseDto } from '@asset-manager/types';

async function getUsers(tenantId: string, text?: string) {
  const qs = new URLSearchParams({
    limit: '100',
    text: text || '',
  });
  return (
    (await API.get(
      USER_API,
      `/tenant/${tenantId}?${qs.toString()}`,
      {},
    )) as UserGetListResponseDto
  ).items;
}

export function useUsers(text?: string) {
  const [tenantId] = useRecoilState(selectedTenantIdState);

  return useQuery({
    queryKey: ['users', text],
    queryFn: () => getUsers(tenantId, text),
  });
}
