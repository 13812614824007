import { ReportQueryParams } from '@src/api';
import dayjs from 'dayjs';
import { atom } from 'recoil';

dayjs.extend(require('dayjs/plugin/utc'));

// @ts-ignore
const utcDayjs = dayjs.utc;
export const assetFilterDateState = atom({
  key: 'assetFilterDateState',
  default: {
    startDate: utcDayjs().startOf('day').unix().toString(),
    endDate: utcDayjs().endOf('day').unix().toString(),
    stopsWithLengthMoreThan: '0',
    aggregationWindowInMinutes: '0',
    dateRange: 'today',
  } as ReportQueryParams & { dateRange: string },
});
