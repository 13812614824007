import { fontSize } from '@src/globalStyle';
import { device } from '@theme/breakpoints';
import { HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import Icon from '../icon';
import { t } from 'i18next';

export interface Prop extends HTMLAttributes<HTMLDivElement> {
  dark?: boolean;
  children?: ReactNode;
  title?: string;
  subtitle?: string;
  fillDimensions?: boolean;
  smallIcon?: boolean;
}

const StyledError = styled.div<{
  dark: string;
  fillDimensions?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: ${props => (props.dark === 'true' ? '#fff' : '#373737')};
  ${props =>
    props.fillDimensions &&
    css`
      width: 100%;
      height: 100%;
      align-self: center;
    `}
`;

const WarningIcon = styled(Icon)<{ small?: boolean }>`
  font-size: ${({ small }) => (small ? '56px' : '96px')} !important;
  @media ${device.laptop} {
    font-size: 160px;
  }
`;

const Title = styled.h3`
  font-family: 'Technor-Variable';
  font-weight: 700;
  ${fontSize.h1}
  margin-bottom: 24px;
  text-align: center;
`;

const Subtitle = styled.h4`
  font-family: 'Satoshi-Variable';
  font-weight: 500;
  ${fontSize.m}
  line-height: 20px;
  text-align: center;
`;

export default function Error({
  dark = false,
  children,
  title = t('general.error'),
  subtitle,
  smallIcon,
  fillDimensions,
  ...props
}: Prop) {
  return (
    <StyledError
      dark={dark.toString()}
      fillDimensions={fillDimensions}
      {...props}
    >
      <WarningIcon name="warning" color="#E8684A" small={smallIcon} />
      <div>
        <Title>{title}</Title>
        {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
      </div>
      {children}
    </StyledError>
  );
}
