import EmptyState from '@components/atoms/empty-state';
import Icon from '@components/atoms/icon';
import Slider from '@components/atoms/slider';
import {
  tooltip_domStyles,
  TooltipHeatmap,
} from '@components/molecules/charts/components/chart-tooltip';
import Heatmap, {
  HeatmapConfigType,
} from '@components/molecules/charts/heatmap';
import { minutesInDay } from '@components/molecules/charts/utils';
import { HeathMapData } from '@src/routes/Report/Report';
import { t } from 'i18next';
import { useMemo, useState } from 'react';

// type Item = {
//   Time: string;
//   Name: string;
//   Value: number;
// };

export interface Props {
  data?: HeathMapData[];
  defaultValue: [number, number];
  configType?: HeatmapConfigType;
  hasLimitedPalette?: boolean;
  tooltip?: any;
}

const roundMinutes = (date: string): string => {
  const hours = date.split(':')[0];
  const minutes = date.split(':')[1];

  const roundedMinutes = Math.round(parseInt(minutes) / 5) * 5;
  return `${hours}:${('0' + roundedMinutes).slice(-2)}`;
};

export default function DataDrawerContentHeatmap({
  data = [],
  defaultValue,
  configType = HeatmapConfigType.BAR,
  hasLimitedPalette,
  tooltip,
}: Props) {
  const mapValues = data.map(r => roundMinutes(r.TimeHHMM as string));
  const allMinutes = minutesInDay(5);
  const minutesOfInterval = allMinutes.filter(r => mapValues.includes(r));
  if (minutesOfInterval[minutesOfInterval.length - 1] === '00:00')
    minutesOfInterval.pop();

  const heatmapHeight = useMemo(
    () => Math.round(new Set(data.map(i => i.Name)).size * 30),
    [data],
  );

  const formatterSliderTooltip = (value?: number) => {
    if (typeof value === 'number') {
      return minutesOfInterval[value];
    }
    return value;
  };

  const onChangeSlider = (value: [number, number]) => {
    const check = (a: number) => a >= 0 && a <= minutesOfInterval.length;
    if (check(value[0]) && check(value[1]) && value[0] !== value[1]) {
      setDataBar(() => {
        let isRange = false;
        const result = [];
        const from = minutesOfInterval[value[0]];
        const to = minutesOfInterval[value[1]];

        for (const item of data) {
          if (item.TimeHHMM === from) isRange = true;
          if (isRange) result.push(item);
          if (item.TimeHHMM === to) isRange = false;
        }
        return result;
      });
    }
  };

  const [dataBar, setDataBar] = useState(data);

  const tooltipToShow = useMemo(
    () => ({
      showMarkers: false,
      customContent: tooltip
        ? tooltip.customContent
        : (title: any, data: any) => {
            return (
              <TooltipHeatmap
                title={title}
                data={data}
                configType={configType}
              />
            );
          },
      domStyles: tooltip ? tooltip_domStyles : tooltip_domStyles,
    }),
    [configType, tooltip],
  );

  const filteredData = useMemo(
    () => data,
    // .filter(el => el.Time !== '00:00'),
    [data],
  );

  if (filteredData.length === 0) {
    return (
      <EmptyState
        style={{ height: 200 }}
        image={
          <Icon
            name="emergency_home"
            style={{ fontSize: 48 }}
            color="currentColor"
          />
        }
        title={t('general.noArgumentFound')}
      />
    );
  }

  return (
    <>
      {/* DEPRECATED */}
      {configType === HeatmapConfigType.BAR && dataBar.length ? (
        <Heatmap
          autoFit
          xField={'TimeHHMM'}
          height={75}
          data={dataBar}
          configType={configType}
          tooltip={tooltipToShow}
        />
      ) : null}

      {configType === HeatmapConfigType.SQUARE && (
        <Heatmap
          height={heatmapHeight}
          data={filteredData}
          configType={configType}
          tooltip={tooltipToShow}
          hasLimitedPalette={hasLimitedPalette}
        />
      )}

      {/* TODO: slider for both */}
      {configType === HeatmapConfigType.BAR && dataBar.length ? (
        <Slider
          key={configType}
          dark
          tooltip={{ formatter: formatterSliderTooltip }}
          range={{ draggableTrack: true }}
          min={0}
          max={minutesOfInterval.length - 1}
          defaultValue={[0, Number.MAX_VALUE]}
          onChange={onChangeSlider}
          containerProps={{
            style: {
              marginTop: 16,
            },
          }}
        />
      ) : null}
    </>
  );
}
