import { Layout, Spin } from 'antd';
import { Navigate } from 'react-router-dom';

import AuthPage from '../components/AuthPage';
import NewPasswordForm from '../components/NewPasswordForm';
import SignInForm from '../components/SignInForm';
import { useAmplify } from '../hooks';

export default function LoginPage() {
  const {
    signIn,
    newPasswordRequired,
    completeNewPassword,
    isAuthenticated,
    loginError,
  } = useAmplify();

  const onSignIn = (values: any) => {
    signIn(values.email, values.password);
  };

  const onSignInFailed = (e: any) => {
    console.log('Failed:', e);
  };

  const onNewPassword = (values: any) => {
    completeNewPassword(values.password);
  };

  const onNewPasswordFailed = (e: any) => {
    console.log('Failed:', e);
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  if (isAuthenticated === undefined) {
    return (
      <Layout
        style={{
          alignContent: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Spin size="large" />
      </Layout>
    );
  }

  return (
    <AuthPage>
      {newPasswordRequired ? (
        <NewPasswordForm
          onFinish={onNewPassword}
          onFinishFailed={onNewPasswordFailed}
        />
      ) : (
        <SignInForm
          onFinish={onSignIn}
          onFinishFailed={onSignInFailed}
          loginError={loginError}
        />
      )}
    </AuthPage>
  );
}
