import { ConfigProvider } from 'antd';
import GlobalCSS from './common';
import { darkTheme } from '@theme/dark';
import styled from 'styled-components';
import { ReactNode } from 'react';
import { device } from '@theme/breakpoints';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  min-height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: white;
  padding: 20px;
  background-color: #1c1c1e;
  @media ${device.laptop} {
    padding: 32px;
  }
`;

const Logo = styled.div`
  height: 128px;
  width: 128px;
  margin-bottom: 72px;
  pointer-events: none;
  user-select: none;
`;

interface Props {
  children?: ReactNode;
}

export default function AuthPage({ children }: Props) {
  return (
    <ConfigProvider theme={darkTheme}>
      <GlobalCSS />
      <StyledContainer>
        <Logo>
          <svg
            width="128"
            height="128"
            viewBox="0 0 128 128"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_489_16198)">
              <path
                d="M68.8398 63.38C68.8398 70.98 74.9998 77.16 82.6198 77.16C90.2398 77.16 96.3998 71 96.3998 63.38C96.3998 55.76 90.2198 49.6 82.6198 49.6C75.0198 49.6 68.8398 55.76 68.8398 63.38Z"
                fill="#0057FF"
              />
              <path
                d="M26.7595 83.32C19.1595 83.32 12.9795 89.48 12.9795 97.1C12.9795 104.7 19.1395 110.88 26.7595 110.88C34.3595 110.88 40.5395 104.72 40.5395 97.1C40.5395 89.48 34.3795 83.32 26.7595 83.32Z"
                fill="#0057FF"
              />
              <path
                d="M101.299 83.2C93.4395 83.16 92.1395 88.46 82.6595 88.42C73.1794 88.38 71.9394 83.06 64.0795 83.02C56.4795 82.98 50.2795 89.12 50.2395 96.72C50.1995 104.32 56.3395 110.52 63.9395 110.56C71.7994 110.6 73.0995 105.3 82.5794 105.34C92.0595 105.38 93.3195 110.7 101.159 110.74C108.759 110.78 114.959 104.64 114.999 97.04C115.039 89.44 108.899 83.24 101.299 83.2Z"
                fill="#0057FF"
              />
              <path
                d="M57.3998 70.12C61.2398 63.26 57.2398 59.56 61.8998 51.3C66.5398 43.02 71.7998 44.52 75.6398 37.66C79.3598 31.02 76.9998 22.64 70.3798 18.92C63.7398 15.2 55.3598 17.56 51.6398 24.18C47.7998 31.04 51.7998 34.74 47.1398 43C42.4998 51.28 37.2398 49.78 33.3998 56.64C29.6798 63.28 32.0398 71.66 38.6598 75.38C45.2798 79.1 53.6598 76.74 57.3998 70.12Z"
                fill="#0057FF"
              />
            </g>
            <defs>
              <clipPath id="clip0_489_16198">
                <rect
                  width="102.4"
                  height="102.4"
                  fill="white"
                  transform="translate(12.7998 12.8)"
                />
              </clipPath>
            </defs>
          </svg>
        </Logo>
        {children}
      </StyledContainer>
    </ConfigProvider>
  );
}
