// https://gw.alipayobjects.com/os/bmw-prod/b21e7336-0b3e-486c-9070-612ede49284e.json
export const mock_italy = [
  {
    country: 'italy',
    date: 1965,
    value: 1390.5,
  },
  {
    country: 'italy',
    date: 1966,
    value: 1469.5,
  },
  {
    country: 'italy',
    date: 1967,
    value: 1521.7,
  },
  {
    country: 'italy',
    date: 1968,
    value: 1615.9,
  },
  {
    country: 'italy',
    date: 1969,
    value: 1703.7,
  },
  {
    country: 'italy',
    date: 1970,
    value: 1767.8,
  },
  {
    country: 'italy',
    date: 1971,
    value: 1806.2,
  },
  {
    country: 'italy',
    date: 1972,
    value: 1903.5,
  },
  {
    country: 'italy',
    date: 1973,
    value: 1986.6,
  },
  {
    country: 'italy',
    date: 1974,
    value: 1952,
  },
  {
    country: 'italy',
    date: 1975,
    value: 1910.4,
  },
  {
    country: 'italy',
    date: 1976,
    value: 2015.8,
  },
  {
    country: 'italy',
    date: 1977,
    value: 2074.7,
  },
  {
    country: 'italy',
    date: 1978,
    value: 2092.7,
  },
  {
    country: 'italy',
    date: 1979,
    value: 2123.8,
  },
  {
    country: 'italy',
    date: 1980,
    value: 2068.3,
  },
  {
    country: 'italy',
    date: 1981,
    value: 2018,
  },
  {
    country: 'italy',
    date: 1982,
    value: 1951.5,
  },
  {
    country: 'italy',
    date: 1983,
    value: 1941.1,
  },
  {
    country: 'italy',
    date: 1984,
    value: 2046.2,
  },
  {
    country: 'italy',
    date: 1985,
    value: 2053.1,
  },
  {
    country: 'italy',
    date: 1986,
    value: 2060.7,
  },
  {
    country: 'italy',
    date: 1987,
    value: 2130.8,
  },
  {
    country: 'italy',
    date: 1988,
    value: 2223.5,
  },
  {
    country: 'italy',
    date: 1989,
    value: 2275.9,
  },
  {
    country: 'italy',
    date: 1990,
    value: 2280.7,
  },
  {
    country: 'italy',
    date: 1991,
    value: 2282,
  },
  {
    country: 'italy',
    date: 1992,
    value: 2319.7,
  },
  {
    country: 'italy',
    date: 1993,
    value: 2366.6,
  },
  {
    country: 'italy',
    date: 1994,
    value: 2420.2,
  },
  {
    country: 'italy',
    date: 1995,
    value: 2466.9,
  },
  {
    country: 'italy',
    date: 1996,
    value: 2547.4,
  },
  {
    country: 'italy',
    date: 1997,
    value: 2569,
  },
  {
    country: 'italy',
    date: 1998,
    value: 2585.2,
  },
  {
    country: 'italy',
    date: 1999,
    value: 2633.8,
  },
  {
    country: 'italy',
    date: 2000,
    value: 2699.4,
  },
  {
    country: 'italy',
    date: 2001,
    value: 2640.1,
  },
  {
    country: 'italy',
    date: 2002,
    value: 2687.7,
  },
  {
    country: 'italy',
    date: 2003,
    value: 2700.7,
  },
  {
    country: 'italy',
    date: 2004,
    value: 2759.4,
  },
  {
    country: 'italy',
    date: 2005,
    value: 2775.6,
  },
  {
    country: 'italy',
    date: 2006,
    value: 2761.9,
  },
  {
    country: 'italy',
    date: 2007,
    value: 2809.5,
  },
  {
    country: 'italy',
    date: 2008,
    value: 2759.4,
  },
  {
    country: 'italy',
    date: 2009,
    value: 2632.5,
  },
  {
    country: 'italy',
    date: 2010,
    value: 2720.7,
  },
  {
    country: 'italy',
    date: 2011,
    value: 2722.9,
  },
  {
    country: 'italy',
    date: 2012,
    value: 2665.1,
  },
  {
    country: 'italy',
    date: 2013,
    value: 2738.3,
  },
  {
    country: 'italy',
    date: 2014,
    value: 2766.8,
  },
  {
    country: 'italy',
    date: 2015,
    value: 2739.7,
  },
  {
    country: 'italy',
    date: 2016,
    value: 2761.9,
  },
  {
    country: 'italy',
    date: 2017,
    value: 2772.8,
  },
];
const mock_spain = [
  {
    country: 'spain',
    date: 1965,
    value: 109.2,
  },
  {
    country: 'spain',
    date: 1966,
    value: 115.7,
  },
  {
    country: 'spain',
    date: 1967,
    value: 120.5,
  },
  {
    country: 'spain',
    date: 1968,
    value: 128,
  },
  {
    country: 'spain',
    date: 1969,
    value: 134.4,
  },
  {
    country: 'spain',
    date: 1970,
    value: 142.2,
  },
  {
    country: 'spain',
    date: 1971,
    value: 157.5,
  },
  {
    country: 'spain',
    date: 1972,
    value: 169.5,
  },
  {
    country: 'spain',
    date: 1973,
    value: 186.3,
  },
  {
    country: 'spain',
    date: 1974,
    value: 195.5,
  },
  {
    country: 'spain',
    date: 1975,
    value: 198,
  },
  {
    country: 'spain',
    date: 1976,
    value: 211.7,
  },
  {
    country: 'spain',
    date: 1977,
    value: 223.8,
  },
  {
    country: 'spain',
    date: 1978,
    value: 236.5,
  },
  {
    country: 'spain',
    date: 1979,
    value: 251.8,
  },
  {
    country: 'spain',
    date: 1980,
    value: 262.9,
  },
  {
    country: 'spain',
    date: 1981,
    value: 262.7,
  },
  {
    country: 'spain',
    date: 1982,
    value: 265.9,
  },
  {
    country: 'spain',
    date: 1983,
    value: 268.3,
  },
  {
    country: 'spain',
    date: 1984,
    value: 278.3,
  },
  {
    country: 'spain',
    date: 1985,
    value: 285.2,
  },
  {
    country: 'spain',
    date: 1986,
    value: 304.2,
  },
  {
    country: 'spain',
    date: 1987,
    value: 315.4,
  },
  {
    country: 'spain',
    date: 1988,
    value: 324.6,
  },
  {
    country: 'spain',
    date: 1989,
    value: 329.9,
  },
  {
    country: 'spain',
    date: 1990,
    value: 331.1,
  },
  {
    country: 'spain',
    date: 1991,
    value: 339.7,
  },
  {
    country: 'spain',
    date: 1992,
    value: 355.8,
  },
  {
    country: 'spain',
    date: 1993,
    value: 368.8,
  },
  {
    country: 'spain',
    date: 1994,
    value: 390.9,
  },
  {
    country: 'spain',
    date: 1995,
    value: 408.3,
  },
  {
    country: 'spain',
    date: 1996,
    value: 425.8,
  },
  {
    country: 'spain',
    date: 1997,
    value: 448.2,
  },
  {
    country: 'spain',
    date: 1998,
    value: 465.5,
  },
  {
    country: 'spain',
    date: 1999,
    value: 463.7,
  },
  {
    country: 'spain',
    date: 2000,
    value: 476.1,
  },
  {
    country: 'spain',
    date: 2001,
    value: 477.7,
  },
  {
    country: 'spain',
    date: 2002,
    value: 483.5,
  },
  {
    country: 'spain',
    date: 2003,
    value: 489.3,
  },
  {
    country: 'spain',
    date: 2004,
    value: 515.5,
  },
  {
    country: 'spain',
    date: 2005,
    value: 533.6,
  },
  {
    country: 'spain',
    date: 2006,
    value: 564,
  },
  {
    country: 'spain',
    date: 2007,
    value: 587,
  },
  {
    country: 'spain',
    date: 2008,
    value: 605.8,
  },
  {
    country: 'spain',
    date: 2009,
    value: 596.8,
  },
  {
    country: 'spain',
    date: 2010,
    value: 632.5,
  },
  {
    country: 'spain',
    date: 2011,
    value: 658.9,
  },
  {
    country: 'spain',
    date: 2012,
    value: 676.5,
  },
  {
    country: 'spain',
    date: 2013,
    value: 692,
  },
  {
    country: 'spain',
    date: 2014,
    value: 697.7,
  },
  {
    country: 'spain',
    date: 2015,
    value: 701.1,
  },
  {
    country: 'spain',
    date: 2016,
    value: 696.8,
  },
  {
    country: 'spain',
    date: 2017,
    value: 700.6,
  },
];
const mock_uk = [
  {
    country: 'uk',
    date: 1965,
    value: 1058.1,
  },
  {
    country: 'uk',
    date: 1966,
    value: 1089.7,
  },
  {
    country: 'uk',
    date: 1967,
    value: 1121.7,
  },
  {
    country: 'uk',
    date: 1968,
    value: 1196.6,
  },
  {
    country: 'uk',
    date: 1969,
    value: 1285.5,
  },
  {
    country: 'uk',
    date: 1970,
    value: 1369,
  },
  {
    country: 'uk',
    date: 1971,
    value: 1406.2,
  },
  {
    country: 'uk',
    date: 1972,
    value: 1472.7,
  },
  {
    country: 'uk',
    date: 1973,
    value: 1558,
  },
  {
    country: 'uk',
    date: 1974,
    value: 1535.5,
  },
  {
    country: 'uk',
    date: 1975,
    value: 1519.3,
  },
  {
    country: 'uk',
    date: 1976,
    value: 1606.9,
  },
  {
    country: 'uk',
    date: 1977,
    value: 1632.4,
  },
  {
    country: 'uk',
    date: 1978,
    value: 1687.5,
  },
  {
    country: 'uk',
    date: 1979,
    value: 1749.6,
  },
  {
    country: 'uk',
    date: 1980,
    value: 1706.4,
  },
  {
    country: 'uk',
    date: 1981,
    value: 1661.4,
  },
  {
    country: 'uk',
    date: 1982,
    value: 1630.2,
  },
  {
    country: 'uk',
    date: 1983,
    value: 1645.2,
  },
  {
    country: 'uk',
    date: 1984,
    value: 1686.9,
  },
  {
    country: 'uk',
    date: 1985,
    value: 1779.4,
  },
  {
    country: 'uk',
    date: 1986,
    value: 1811.3,
  },
  {
    country: 'uk',
    date: 1987,
    value: 1849.7,
  },
  {
    country: 'uk',
    date: 1988,
    value: 1870,
  },
  {
    country: 'uk',
    date: 1989,
    value: 1875,
  },
  {
    country: 'uk',
    date: 1990,
    value: 1853.3,
  },
  {
    country: 'uk',
    date: 1991,
    value: 1844.6,
  },
  {
    country: 'uk',
    date: 1992,
    value: 1814.1,
  },
  {
    country: 'uk',
    date: 1993,
    value: 1805.3,
  },
  {
    country: 'uk',
    date: 1994,
    value: 1791.3,
  },
  {
    country: 'uk',
    date: 1995,
    value: 1836.2,
  },
  {
    country: 'uk',
    date: 1996,
    value: 1896.1,
  },
  {
    country: 'uk',
    date: 1997,
    value: 1896.4,
  },
  {
    country: 'uk',
    date: 1998,
    value: 1918.8,
  },
  {
    country: 'uk',
    date: 1999,
    value: 1907.7,
  },
  {
    country: 'uk',
    date: 2000,
    value: 1932.1,
  },
  {
    country: 'uk',
    date: 2001,
    value: 1959.2,
  },
  {
    country: 'uk',
    date: 2002,
    value: 1954.8,
  },
  {
    country: 'uk',
    date: 2003,
    value: 1991.6,
  },
  {
    country: 'uk',
    date: 2004,
    value: 2025.4,
  },
  {
    country: 'uk',
    date: 2005,
    value: 2037.4,
  },
  {
    country: 'uk',
    date: 2006,
    value: 2056.4,
  },
  {
    country: 'uk',
    date: 2007,
    value: 2041.7,
  },
  {
    country: 'uk',
    date: 2008,
    value: 2038.5,
  },
  {
    country: 'uk',
    date: 2009,
    value: 1932.1,
  },
  {
    country: 'uk',
    date: 2010,
    value: 2001.1,
  },
  {
    country: 'uk',
    date: 2011,
    value: 1949.1,
  },
  {
    country: 'uk',
    date: 2012,
    value: 1944.3,
  },
  {
    country: 'uk',
    date: 2013,
    value: 1934,
  },
  {
    country: 'uk',
    date: 2014,
    value: 1871.2,
  },
  {
    country: 'uk',
    date: 2015,
    value: 1908.7,
  },
  {
    country: 'uk',
    date: 2016,
    value: 1934.6,
  },
  {
    country: 'uk',
    date: 2017,
    value: 1969.5,
  },
];
const mock_germany = [
  {
    country: 'germany',
    date: 1965,
    value: 593.3,
  },
  {
    country: 'germany',
    date: 1966,
    value: 630.9,
  },
  {
    country: 'germany',
    date: 1967,
    value: 663.2,
  },
  {
    country: 'germany',
    date: 1968,
    value: 687.8,
  },
  {
    country: 'germany',
    date: 1969,
    value: 719,
  },
  {
    country: 'germany',
    date: 1970,
    value: 754.8,
  },
  {
    country: 'germany',
    date: 1971,
    value: 791.9,
  },
  {
    country: 'germany',
    date: 1972,
    value: 832.3,
  },
  {
    country: 'germany',
    date: 1973,
    value: 875.1,
  },
  {
    country: 'germany',
    date: 1974,
    value: 923.3,
  },
  {
    country: 'germany',
    date: 1975,
    value: 969,
  },
  {
    country: 'germany',
    date: 1976,
    value: 1006.5,
  },
  {
    country: 'germany',
    date: 1977,
    value: 1051.4,
  },
  {
    country: 'germany',
    date: 1978,
    value: 1094.2,
  },
  {
    country: 'germany',
    date: 1979,
    value: 1127.1,
  },
  {
    country: 'germany',
    date: 1980,
    value: 1150.1,
  },
  {
    country: 'germany',
    date: 1981,
    value: 1174.5,
  },
  {
    country: 'germany',
    date: 1982,
    value: 1204,
  },
  {
    country: 'germany',
    date: 1983,
    value: 1229,
  },
  {
    country: 'germany',
    date: 1984,
    value: 1274.6,
  },
  {
    country: 'germany',
    date: 1985,
    value: 1257,
  },
  {
    country: 'germany',
    date: 1986,
    value: 1282,
  },
  {
    country: 'germany',
    date: 1987,
    value: 1318,
  },
  {
    country: 'germany',
    date: 1988,
    value: 1341.5,
  },
  {
    country: 'germany',
    date: 1989,
    value: 1332.5,
  },
  {
    country: 'germany',
    date: 1990,
    value: 1350.3,
  },
  {
    country: 'germany',
    date: 1991,
    value: 1308.9,
  },
  {
    country: 'germany',
    date: 1992,
    value: 1233.1,
  },
  {
    country: 'germany',
    date: 1993,
    value: 1121,
  },
  {
    country: 'germany',
    date: 1994,
    value: 1012.2,
  },
  {
    country: 'germany',
    date: 1995,
    value: 951.2,
  },
  {
    country: 'germany',
    date: 1996,
    value: 920,
  },
  {
    country: 'germany',
    date: 1997,
    value: 878.4,
  },
  {
    country: 'germany',
    date: 1998,
    value: 871.7,
  },
  {
    country: 'germany',
    date: 1999,
    value: 881.3,
  },
  {
    country: 'germany',
    date: 2000,
    value: 888.5,
  },
  {
    country: 'germany',
    date: 2001,
    value: 905.5,
  },
  {
    country: 'germany',
    date: 2002,
    value: 904,
  },
  {
    country: 'germany',
    date: 2003,
    value: 924.3,
  },
  {
    country: 'germany',
    date: 2004,
    value: 938.7,
  },
  {
    country: 'germany',
    date: 2005,
    value: 942.3,
  },
  {
    country: 'germany',
    date: 2006,
    value: 978.6,
  },
  {
    country: 'germany',
    date: 2007,
    value: 989.8,
  },
  {
    country: 'germany',
    date: 2008,
    value: 998.1,
  },
  {
    country: 'germany',
    date: 2009,
    value: 926.8,
  },
  {
    country: 'germany',
    date: 2010,
    value: 967.8,
  },
  {
    country: 'germany',
    date: 2011,
    value: 1006,
  },
  {
    country: 'germany',
    date: 2012,
    value: 1014.1,
  },
  {
    country: 'germany',
    date: 2013,
    value: 989.2,
  },
  {
    country: 'germany',
    date: 2014,
    value: 987,
  },
  {
    country: 'germany',
    date: 2015,
    value: 960.7,
  },
  {
    country: 'germany',
    date: 2016,
    value: 972,
  },
  {
    country: 'germany',
    date: 2017,
    value: 978,
  },
];
const mock_ireland = [
  {
    country: 'ireland',
    date: 1965,
    value: 48.3,
  },
  {
    country: 'ireland',
    date: 1966,
    value: 50.4,
  },
  {
    country: 'ireland',
    date: 1967,
    value: 52.7,
  },
  {
    country: 'ireland',
    date: 1968,
    value: 55.6,
  },
  {
    country: 'ireland',
    date: 1969,
    value: 58.5,
  },
  {
    country: 'ireland',
    date: 1970,
    value: 61.5,
  },
  {
    country: 'ireland',
    date: 1971,
    value: 64.9,
  },
  {
    country: 'ireland',
    date: 1972,
    value: 70.6,
  },
  {
    country: 'ireland',
    date: 1973,
    value: 77.4,
  },
  {
    country: 'ireland',
    date: 1974,
    value: 82.3,
  },
  {
    country: 'ireland',
    date: 1975,
    value: 82.1,
  },
  {
    country: 'ireland',
    date: 1976,
    value: 93,
  },
  {
    country: 'ireland',
    date: 1977,
    value: 105.7,
  },
  {
    country: 'ireland',
    date: 1978,
    value: 111,
  },
  {
    country: 'ireland',
    date: 1979,
    value: 130.5,
  },
  {
    country: 'ireland',
    date: 1980,
    value: 126.5,
  },
  {
    country: 'ireland',
    date: 1981,
    value: 137.9,
  },
  {
    country: 'ireland',
    date: 1982,
    value: 152.8,
  },
  {
    country: 'ireland',
    date: 1983,
    value: 167.1,
  },
  {
    country: 'ireland',
    date: 1984,
    value: 188.9,
  },
  {
    country: 'ireland',
    date: 1985,
    value: 200.8,
  },
  {
    country: 'ireland',
    date: 1986,
    value: 209.8,
  },
  {
    country: 'ireland',
    date: 1987,
    value: 224.5,
  },
  {
    country: 'ireland',
    date: 1988,
    value: 238.5,
  },
  {
    country: 'ireland',
    date: 1989,
    value: 251.5,
  },
  {
    country: 'ireland',
    date: 1990,
    value: 260,
  },
  {
    country: 'ireland',
    date: 1991,
    value: 271.7,
  },
  {
    country: 'ireland',
    date: 1992,
    value: 296.4,
  },
  {
    country: 'ireland',
    date: 1993,
    value: 304.7,
  },
  {
    country: 'ireland',
    date: 1994,
    value: 340.3,
  },
  {
    country: 'ireland',
    date: 1995,
    value: 352.4,
  },
  {
    country: 'ireland',
    date: 1996,
    value: 363.9,
  },
  {
    country: 'ireland',
    date: 1997,
    value: 381.3,
  },
  {
    country: 'ireland',
    date: 1998,
    value: 387.7,
  },
  {
    country: 'ireland',
    date: 1999,
    value: 395,
  },
  {
    country: 'ireland',
    date: 2000,
    value: 414.9,
  },
  {
    country: 'ireland',
    date: 2001,
    value: 435.6,
  },
  {
    country: 'ireland',
    date: 2002,
    value: 459.4,
  },
  {
    country: 'ireland',
    date: 2003,
    value: 479.3,
  },
  {
    country: 'ireland',
    date: 2004,
    value: 517.1,
  },
  {
    country: 'ireland',
    date: 2005,
    value: 553.7,
  },
  {
    country: 'ireland',
    date: 2006,
    value: 582.6,
  },
  {
    country: 'ireland',
    date: 2007,
    value: 618.2,
  },
  {
    country: 'ireland',
    date: 2008,
    value: 657.1,
  },
  {
    country: 'ireland',
    date: 2009,
    value: 677.2,
  },
  {
    country: 'ireland',
    date: 2010,
    value: 714.3,
  },
  {
    country: 'ireland',
    date: 2011,
    value: 740.9,
  },
  {
    country: 'ireland',
    date: 2012,
    value: 771.1,
  },
  {
    country: 'ireland',
    date: 2013,
    value: 795.3,
  },
  {
    country: 'ireland',
    date: 2014,
    value: 823.1,
  },
  {
    country: 'ireland',
    date: 2015,
    value: 848.3,
  },
  {
    country: 'ireland',
    date: 2016,
    value: 869.7,
  },
  {
    country: 'ireland',
    date: 2017,
    value: 897.2,
  },
];
const mock_sweden = [
  {
    country: 'sweden',
    date: 1965,
    value: 60.6,
  },
  {
    country: 'sweden',
    date: 1966,
    value: 63.3,
  },
  {
    country: 'sweden',
    date: 1967,
    value: 64,
  },
  {
    country: 'sweden',
    date: 1968,
    value: 67.4,
  },
  {
    country: 'sweden',
    date: 1969,
    value: 68.9,
  },
  {
    country: 'sweden',
    date: 1970,
    value: 74.7,
  },
  {
    country: 'sweden',
    date: 1971,
    value: 81.2,
  },
  {
    country: 'sweden',
    date: 1972,
    value: 86.3,
  },
  {
    country: 'sweden',
    date: 1973,
    value: 92.9,
  },
  {
    country: 'sweden',
    date: 1974,
    value: 97.6,
  },
  {
    country: 'sweden',
    date: 1975,
    value: 103.3,
  },
  {
    country: 'sweden',
    date: 1976,
    value: 112.4,
  },
  {
    country: 'sweden',
    date: 1977,
    value: 118.4,
  },
  {
    country: 'sweden',
    date: 1978,
    value: 123.1,
  },
  {
    country: 'sweden',
    date: 1979,
    value: 134.4,
  },
  {
    country: 'sweden',
    date: 1980,
    value: 144.8,
  },
  {
    country: 'sweden',
    date: 1981,
    value: 161.5,
  },
  {
    country: 'sweden',
    date: 1982,
    value: 172.7,
  },
  {
    country: 'sweden',
    date: 1983,
    value: 177.5,
  },
  {
    country: 'sweden',
    date: 1984,
    value: 183.7,
  },
  {
    country: 'sweden',
    date: 1985,
    value: 190.7,
  },
  {
    country: 'sweden',
    date: 1986,
    value: 195.1,
  },
  {
    country: 'sweden',
    date: 1987,
    value: 201.2,
  },
  {
    country: 'sweden',
    date: 1988,
    value: 215.7,
  },
  {
    country: 'sweden',
    date: 1989,
    value: 216.3,
  },
  {
    country: 'sweden',
    date: 1990,
    value: 223.3,
  },
  {
    country: 'sweden',
    date: 1991,
    value: 223,
  },
  {
    country: 'sweden',
    date: 1992,
    value: 226.3,
  },
  {
    country: 'sweden',
    date: 1993,
    value: 227.2,
  },
  {
    country: 'sweden',
    date: 1994,
    value: 233.9,
  },
  {
    country: 'sweden',
    date: 1995,
    value: 243.4,
  },
  {
    country: 'sweden',
    date: 1996,
    value: 250.1,
  },
  {
    country: 'sweden',
    date: 1997,
    value: 255.1,
  },
  {
    country: 'sweden',
    date: 1998,
    value: 259.1,
  },
  {
    country: 'sweden',
    date: 1999,
    value: 267.2,
  },
  {
    country: 'sweden',
    date: 2000,
    value: 273.4,
  },
  {
    country: 'sweden',
    date: 2001,
    value: 283.8,
  },
  {
    country: 'sweden',
    date: 2002,
    value: 287.1,
  },
  {
    country: 'sweden',
    date: 2003,
    value: 300.6,
  },
  {
    country: 'sweden',
    date: 2004,
    value: 323.2,
  },
  {
    country: 'sweden',
    date: 2005,
    value: 326.5,
  },
  {
    country: 'sweden',
    date: 2006,
    value: 332.8,
  },
  {
    country: 'sweden',
    date: 2007,
    value: 346.9,
  },
  {
    country: 'sweden',
    date: 2008,
    value: 368.7,
  },
  {
    country: 'sweden',
    date: 2009,
    value: 373.4,
  },
  {
    country: 'sweden',
    date: 2010,
    value: 386.9,
  },
  {
    country: 'sweden',
    date: 2011,
    value: 385.6,
  },
  {
    country: 'sweden',
    date: 2012,
    value: 399.8,
  },
  {
    country: 'sweden',
    date: 2013,
    value: 410.6,
  },
  {
    country: 'sweden',
    date: 2014,
    value: 425.1,
  },
  {
    country: 'sweden',
    date: 2015,
    value: 429.4,
  },
  {
    country: 'sweden',
    date: 2016,
    value: 438,
  },
  {
    country: 'sweden',
    date: 2017,
    value: 449.5,
  },
];
const mock_finland = [
  {
    country: 'finland',
    date: 1965,
    value: 441.6,
  },
  {
    country: 'finland',
    date: 1966,
    value: 482.9,
  },
  {
    country: 'finland',
    date: 1967,
    value: 506.1,
  },
  {
    country: 'finland',
    date: 1968,
    value: 544.1,
  },
  {
    country: 'finland',
    date: 1969,
    value: 619.8,
  },
  {
    country: 'finland',
    date: 1970,
    value: 704.9,
  },
  {
    country: 'finland',
    date: 1971,
    value: 771.4,
  },
  {
    country: 'finland',
    date: 1972,
    value: 817.9,
  },
  {
    country: 'finland',
    date: 1973,
    value: 885.1,
  },
  {
    country: 'finland',
    date: 1974,
    value: 902.2,
  },
  {
    country: 'finland',
    date: 1975,
    value: 936.1,
  },
  {
    country: 'finland',
    date: 1976,
    value: 983.2,
  },
  {
    country: 'finland',
    date: 1977,
    value: 1037.3,
  },
  {
    country: 'finland',
    date: 1978,
    value: 1106.2,
  },
  {
    country: 'finland',
    date: 1979,
    value: 1157.6,
  },
  {
    country: 'finland',
    date: 1980,
    value: 1168,
  },
  {
    country: 'finland',
    date: 1981,
    value: 1175,
  },
  {
    country: 'finland',
    date: 1982,
    value: 1186.8,
  },
  {
    country: 'finland',
    date: 1983,
    value: 1240.7,
  },
  {
    country: 'finland',
    date: 1984,
    value: 1326.7,
  },
  {
    country: 'finland',
    date: 1985,
    value: 1395.9,
  },
  {
    country: 'finland',
    date: 1986,
    value: 1456.5,
  },
  {
    country: 'finland',
    date: 1987,
    value: 1538,
  },
  {
    country: 'finland',
    date: 1988,
    value: 1650.5,
  },
  {
    country: 'finland',
    date: 1989,
    value: 1740.4,
  },
  {
    country: 'finland',
    date: 1990,
    value: 1812.8,
  },
  {
    country: 'finland',
    date: 1991,
    value: 1896.9,
  },
  {
    country: 'finland',
    date: 1992,
    value: 1984.5,
  },
  {
    country: 'finland',
    date: 1993,
    value: 2088.9,
  },
  {
    country: 'finland',
    date: 1994,
    value: 2204.3,
  },
  {
    country: 'finland',
    date: 1995,
    value: 2306.8,
  },
  {
    country: 'finland',
    date: 1996,
    value: 2413.2,
  },
  {
    country: 'finland',
    date: 1997,
    value: 2487,
  },
  {
    country: 'finland',
    date: 1998,
    value: 2481,
  },
  {
    country: 'finland',
    date: 1999,
    value: 2577.9,
  },
  {
    country: 'finland',
    date: 2000,
    value: 2671.9,
  },
  {
    country: 'finland',
    date: 2001,
    value: 2759.7,
  },
  {
    country: 'finland',
    date: 2002,
    value: 2901.2,
  },
  {
    country: 'finland',
    date: 2003,
    value: 3145.5,
  },
  {
    country: 'finland',
    date: 2004,
    value: 3445.8,
  },
  {
    country: 'finland',
    date: 2005,
    value: 3724.3,
  },
  {
    country: 'finland',
    date: 2006,
    value: 3944,
  },
  {
    country: 'finland',
    date: 2007,
    value: 4195.2,
  },
  {
    country: 'finland',
    date: 2008,
    value: 4310.8,
  },
  {
    country: 'finland',
    date: 2009,
    value: 4411.1,
  },
  {
    country: 'finland',
    date: 2010,
    value: 4696.1,
  },
  {
    country: 'finland',
    date: 2011,
    value: 4951.1,
  },
  {
    country: 'finland',
    date: 2012,
    value: 5118.2,
  },
  {
    country: 'finland',
    date: 2013,
    value: 5269.9,
  },
  {
    country: 'finland',
    date: 2014,
    value: 5382.9,
  },
  {
    country: 'finland',
    date: 2015,
    value: 5472.4,
  },
  {
    country: 'finland',
    date: 2016,
    value: 5585.5,
  },
  {
    country: 'finland',
    date: 2017,
    value: 5743.6,
  },
];

export const mock_all = [
  ...mock_italy,
  ...mock_spain,
  ...mock_uk,
  ...mock_germany,
  ...mock_ireland,
  ...mock_sweden,
  ...mock_finland,
];

export const mixed_list = [
  ...mock_italy.slice(0, 30),
  ...mock_spain.slice(30, 53),
];

// {time:0, value: 0, status: idel|on|off}
export const mixed_list2 = [
  ...mock_italy.slice(0, 18).map(i => ({ ...i, country: 'error' })),
  ...mock_italy.slice(18, 35).map(i => ({ ...i, country: 'idle' })),
  ...mock_italy.slice(35, 53).map(i => ({ ...i, country: 'work' })),
  ...mock_italy.map(i => ({
    ...i,
    country: 'error2',
    date: i.date + 53,
  })),
];

export const mixed_list2a = [
  ...mock_italy,
  ...mock_italy.map(i => ({ ...i, date: i.date + 53, value: 0 })),
  ...mock_spain.map(i => ({ ...i, date: i.date + 53 })),
  ...mock_italy.map(i => ({ ...i, date: i.date + 53 + 53 })),
];

export const test = [
  { country: 'italy', date: 1993, value: 2366.6, test: 'f' },
  { country: 'italy', date: 1994, value: 2420.2, test: 'f' },
  { country: 'italy', date: 1995, value: 2420.2, test: 't' },
  // { country: 'spain', date: 1995, value: 408.3 },
  { country: 'italy', date: 1996, value: 425.8, test: 't' },
];
