import styled, { css } from 'styled-components';
import {
  ConfigProvider,
  Input,
  Pagination,
  Spin,
  Tabs,
  Typography,
} from 'antd';
import { darkTheme } from '@theme/dark';
import { AutoSizer, List } from 'react-virtualized';
import { Alert, ToggleAlertBody } from '@src/api/alert/types';
import Icon from '@components/atoms/icon';
import Button from '@components/atoms/button';
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import AddAlertModal from '@components/organisms/modals/modal-add-alert/modal-add-alert';
import { AlertSeverityEnumDto } from '@asset-manager/types';
import {
  useAssetsGeneratedEvents,
  useGeneratedEventsPage,
} from '@src/api/alert/use-generated-events';
import { format } from 'date-fns';
import { useAssetAlerts } from '@src/api/alert/use-asset-alerts';
import { useDeleteAlert } from '@src/api/alert/use-delete-alert';
import { UseMutationResult } from 'react-query';
import {
  GeneratedEventsByAssetRangePickerNode,
  GeneratedEventsRangePickerNode,
} from '@components/organisms/range-picker-node/range-picker-node';
import EmptyState from '@components/atoms/empty-state';
import Error from '@components/atoms/error';
import { utils } from '@src/globalStyle';
import { useRecoilState } from 'recoil';
import {
  alertFiltersState,
  currentAlertIdState,
} from '@store/alert/alert-filter-state';
import ChartColumn from '@components/molecules/charts/column/chart-column';
import { generatedEventsByAssetFiltersState } from '@store/alert/generated-events-filter-state';
import { useGeneratedEventsReport } from '@src/api/alert/use-generated-events-report';
import dayjs from 'dayjs';
import { device } from '@theme/breakpoints';
import AlertDetailsModal from '@components/organisms/modals/modal-alert-details';
import ConfirmModal from '@components/molecules/confirm-modal';
import { useToggleAlert } from '@src/api/alert/use-toggle-alert';
import { t } from 'i18next';
import { queryClient } from '@src/App';
import { GET_GENERATED_EVENTS_QUERY_KEY } from '@src/api';
import CardAlerts from '@components/molecules/card-alerts';
import { useTenantSlimReport } from '@src/modules/tenant/hooks';
import MenuPopover from '@components/molecules/menu-popover';

const Container = styled.div``;

const FullscreenContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 32px;
`;

const FullscreenContentContainer = styled.div`
  display: grid;
  column-gap: 32px;

  @media ${device.tablet} {
    grid-template-columns: 2fr 1fr;
  }
`;

const Title = styled(Typography.Title)`
  color: white !important;
  margin-top: 32px !important;
`;

const LeftColumn = styled.div`
  position: relative;
  padding-top: 32px;
`;

const StyledInput = styled(Input)`
  margin-bottom: 0;
  border-radius: 30px;
  border: none;
  max-width: 208px;
`;

const StyledDiv = styled.div`
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  height: 44px;
`;

const StyledButton = styled(Button)`
  height: 47px;
  margin-top: 16px;
  background-color: transparent;
  border: none;
  color: white;
`;

const InputIcon = styled(Icon)`
  font-size: 16px;
  margin-right: 4px;
  color: #373737 !important;
`;

const StyledCardAlerts = styled(CardAlerts)`
  cursor: pointer;
`;

const FullscreenContent = ({
  onaddAlertClick,
  onOpenAlertDetails,
}: {
  onaddAlertClick: () => void;
  onOpenAlertDetails: (event: object) => void;
}) => {
  const [heightOffsetFromTop, setHeightOffsetFromTop] = useState<number>(0);
  const [activeTab, setActiveTab] = useState('0');
  // const [search, setSearch] = useState('');

  const [filters, setAlertFilters] = useRecoilState(alertFiltersState);

  const setSearch = (value: string) => {
    setAlertFilters({
      ...filters,
      text: value,
    });
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setHeightOffsetFromTop(ref.current.getBoundingClientRect().top);
    }
  }, []);

  const [generatedEventsFilter] = useRecoilState(
    generatedEventsByAssetFiltersState,
  );

  const { data: eventsReport, isLoading, isError } = useGeneratedEventsReport();

  const chartData = useMemo(() => {
    if (!eventsReport) return [];
    const data: {
      date: string;
      value: number;
      type: 'HIGH' | 'LOW';
    }[] = [];
    eventsReport.high.forEach(event => {
      data.push({
        date: dayjs(event.date).format('YYYY-MM-DD'),
        value: event.value,
        type: 'HIGH',
      });
    });
    eventsReport.low.forEach(event => {
      data.push({
        date: dayjs(event.date).format('YYYY-MM-DD'),
        value: event.value,
        type: 'LOW',
      });
    });
    return data;
  }, [eventsReport]);

  return (
    <FullscreenContainer>
      <StyledTabs
        defaultActiveKey="0"
        items={[
          {
            key: '0',
            label: t('alerts.storicalAlerts'),
            children: (
              <FullscreenContentContainer>
                <div style={{ position: 'relative', height: '60vh' }}>
                  <GeneratedEvents
                    compact={false}
                    openAlertDetailsModal={onOpenAlertDetails}
                  />
                </div>
                {window.innerWidth >= 768 && (
                  <LeftColumn
                    ref={ref}
                    style={{
                      height: `180px`,
                    }}
                  >
                    <GeneratedEventsByAssetRangePickerNode
                      style={{ width: '100%' }}
                    />
                    <Title level={4}>{t('alerts.storicalAlerts')}</Title>
                    {isLoading ? (
                      <StyledSpin style={{ height: 150 }} />
                    ) : isError ? (
                      <Error smallIcon />
                    ) : !chartData?.length ? (
                      <EmptyState
                        title={t('general.noDataRevealed')}
                        subtitle={t('general.controlAssociationAsset')}
                        image={
                          <Icon
                            name="area_chart"
                            style={{ fontSize: 48 }}
                            color="#919191"
                          />
                        }
                        dark
                        style={{
                          maxWidth: 200,
                          marginInline: 'auto',
                          marginBlock: 16,
                        }}
                      />
                    ) : (
                      <ChartColumn
                        data={chartData}
                        startDate={
                          Number(generatedEventsFilter.startDate) * 1000
                        }
                        endDate={Number(generatedEventsFilter.endDate) * 1000}
                      />
                    )}
                  </LeftColumn>
                )}
              </FullscreenContentContainer>
            ),
          },
          {
            key: '1',
            label: t('general.rules'),
            children: (
              <>
                <StyledDiv>
                  <StyledInput
                    prefix={<InputIcon name="search" />}
                    placeholder={t('general.search')}
                    size="large"
                    value={filters.text || ''}
                    onChange={e => setSearch(e.target.value)}
                  />
                </StyledDiv>
                <div
                  style={{
                    position: 'relative',
                    minHeight: '100px',
                  }}
                >
                  <AlertsSet
                    compact={true}
                    openModal={onaddAlertClick}
                    openAlertDetailsModal={onOpenAlertDetails}
                    isRule
                  />
                </div>
              </>
            ),
          },
        ]}
        onTabClick={key => {
          setActiveTab(key);
        }}
      />
      {activeTab === '1' && (
        <div
          style={{
            backgroundColor: '#1C1C1E',
            position: 'sticky',
            bottom: 0,
            textAlign: 'left',
            height: 76,
          }}
        >
          <Button
            style={{
              height: 47,
              marginTop: 16,
              backgroundColor: 'transparent',
              border: 'none',
              color: 'white',
            }}
            onClick={onaddAlertClick}
            centerIcon
            icon={<Icon name="add_circle" style={{ fontSize: 16 }} />}
          >
            {t('alerts.addRule')}
          </Button>
        </div>
      )}
    </FullscreenContainer>
  );
};

const StyledTabs = styled(Tabs)<{ compact?: boolean }>`
  .ant-tabs-tab .ant-tabs-tab-btn {
    color: #919191 !important;
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
  }

  .ant-tabs-ink-bar {
    background-color: white !important;
  }

  .ant-tabs-nav-list {
    border-bottom: 1px solid rgb(55, 55, 55);
  }

  .ant-tabs-ink-bar-animated {
    width: 50% !important;
  }

  ${({ compact }) =>
    !compact &&
    css`
      flex: 1;

      .ant-tabs-content {
        height: 100%;
      }

      .ant-tabs-tabpane {
        height: 100%;
      }
    `}
`;

const GeneratedAlertRowContainer = styled.div`
  padding-block: 16px;
  padding: 8px;
  border-bottom: 1px solid #373737;
  cursor: pointer;

  &:hover {
    background-color: #373737;
  }
`;

const GeneratedAlertRowHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  h3 {
    margin: 0;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
`;

const GeneratedAlertRowDetailsContainer = styled.div`
  display: flex;
  column-gap: 16px;

  padding-bottom: 8px;
`;

const GeneratedAlertRowDetails = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  font-size: 12px;
  lint-height: 16px;
  color: #919191;
`;

const GeneratedAlertRowContent = styled.p`
  margin: 0;
`;

const GeneratedAlertRow = ({
  title,
  severity,
  date,
  time,
  description,
  onClick,
  alertItem: item,
}: {
  title: string;
  severity?: string;
  date: string;
  time: string;
  description: string;
  onClick: () => void;
  alertItem: any;
}) => {
  const createdAt = new Date(item.createdAt);
  const startTime = item.startTime ? new Date(item.startTime) : null;
  const endTime = item.endTime ? new Date(item.endTime) : null;
  const date2 = createdAt.toLocaleDateString('it-IT', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    minute: '2-digit',
    hour: '2-digit',
  });

  const duration =
    startTime && endTime
      ? dayjs.utc(dayjs(endTime).diff(dayjs(startTime))).format('HH:mm')
      : '';

  return (
    <StyledCardAlerts
      description={item.description}
      eventId={item.id}
      assetId={item.assetId || ''}
      assetName={item.assetName || ''}
      alertName={item.alertName || ''}
      alertSeverity={(item as any).alertSeverity}
      subTypeSeverity={(item as any).subTypeSeverity}
      subTypeName={(item as any).subTypeName}
      date={date2.split(',')[0]}
      time={date2.split(',')[1].replaceAll(' ', '')}
      duration={duration}
      onClick={onClick}
    />
  );

  // DEPRECATED

  // return (
  //   <GeneratedAlertRowContainer role="button" onClick={onClick}>
  //     <GeneratedAlertRowHeader>
  //       <h3>{title}</h3>
  //       <div>
  //         <SeverityIcon severity={severity} />
  //       </div>
  //     </GeneratedAlertRowHeader>
  //     <GeneratedAlertRowDetailsContainer>
  //       <GeneratedAlertRowDetails>
  //         <Icon
  //           name="event"
  //           style={{
  //             fontSize: 16,
  //           }}
  //           color="#919191"
  //         />
  //         <span>{date}</span>
  //       </GeneratedAlertRowDetails>
  //       <GeneratedAlertRowDetails>
  //         <Icon
  //           name="schedule"
  //           style={{
  //             fontSize: 16,
  //           }}
  //           color="#919191"
  //         />
  //         <span>{time}</span>
  //       </GeneratedAlertRowDetails>
  //     </GeneratedAlertRowDetailsContainer>
  //     <GeneratedAlertRowContent>{description}</GeneratedAlertRowContent>
  //   </GeneratedAlertRowContainer>
  // );
};

const CollapsedContent = ({
  onaddAlertClick,
  onOpenAlertDetails,
}: {
  onaddAlertClick: () => void;
  onOpenAlertDetails: (id: object) => void;
}) => {
  const [heightOffsetFromTop, setHeightOffsetFromTop] = useState<number>(0);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setHeightOffsetFromTop(ref.current.getBoundingClientRect().top);
    }
  }, []);

  return (
    <ConfigProvider theme={darkTheme}>
      <div
        ref={ref}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: `calc(100vh - 24px - ${heightOffsetFromTop}px)`,
        }}
      >
        <StyledTabs
          defaultActiveKey="0"
          items={[
            {
              key: '0',
              label: t('alerts.storicalAlerts'),
              children: (
                <GeneratedEvents
                  compact={true}
                  openAlertDetailsModal={onOpenAlertDetails}
                />
              ),
            },
            {
              key: '1',
              label: t('general.rules'),
              children: (
                <AlertsSet
                  compact={true}
                  openModal={onaddAlertClick}
                  openAlertDetailsModal={onOpenAlertDetails}
                />
              ),
            },
          ]}
          onTabClick={() => {
            ref?.current?.click();
          }}
        />
        <Button
          style={{
            position: 'sticky',
            bottom: 16,
          }}
          onClick={onaddAlertClick}
          icon={
            <Icon name="add_circle" style={{ fontSize: 16 }} color="#373737" />
          }
          centerIcon
        >
          {t('alerts.addRule')}
        </Button>
      </div>
    </ConfigProvider>
  );
};

export const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  .ant-pagination-prev button,
  .ant-pagination-next button,
  .ant-pagination-item > a,
  .ant-pagination-item-ellipsis,
  .anticon-double-right,
  .anticon-double-left {
    color: #fff !important;
  }

  .ant-pagination-item-active > a {
    color: #373737 !important;
  }

  .ant-pagination-item:not(.ant-pagination-item-active):hover > a {
    background-color: #373737 !important;
    border-radius: 6px;
  }
`;

const GeneratedEvents = ({
  compact,
  openAlertDetailsModal,
}: {
  compact: boolean;
  openAlertDetailsModal: (id: object) => void;
}) => {
  const {
    data: generatedEvents,
    isError,
    isLoading,
  } = useAssetsGeneratedEvents();

  const { pageSize, page, updatePage } = useGeneratedEventsPage();

  const [, setCurrentAlertId] = useRecoilState(currentAlertIdState);

  if (isLoading) {
    return <StyledSpin size="large" />;
  }

  if (isError) {
    return <Error fillDimensions />;
  }

  return (
    <>
      {compact && (
        <GeneratedEventsRangePickerNode
          style={{ width: '100%', marginTop: 16 }}
        />
      )}
      {!generatedEvents || !generatedEvents.items.length ? (
        <EmptyState
          title={t('alerts.noAlertGenerated')}
          subtitle={t('alerts.noAlertGeneratedDescription')}
          dark
          fillDimensions
          style={{ maxWidth: 302, margin: 'auto' }}
          image={
            <Icon
              name="emergency_home"
              style={{ fontSize: 48 }}
              color="#919191"
            />
          }
        />
      ) : (
        <div
          style={{ paddingBottom: 32, marginTop: 8, width: 'calc(100% + 8px)' }}
        >
          {[...generatedEvents.items].map(item => (
            <GeneratedAlertRow
              title={item.alertName || ''}
              severity={item.alertSeverity}
              date={format(new Date(item.createdAt), 'dd/MM/yyyy')}
              time={format(new Date(item.createdAt), 'HH:mm')}
              description={item.description}
              alertItem={item}
              onClick={() => {
                if (!item.alertId) return;
                setCurrentAlertId(item.alertId);
                openAlertDetailsModal(item);
              }}
            />
          ))}

          <StyledPagination
            defaultCurrent={1}
            current={page}
            total={generatedEvents?.count}
            pageSize={pageSize}
            showSizeChanger={false}
            showLessItems
            onChange={updatePage}
          />
        </div>
      )}
    </>
  );
};

const StyledAlertRowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-block: 16px;
  border-bottom: 1px solid #373737;
  cursor: pointer;
  padding-left: 16px;
  padding-right: 16px;

  &:hover {
    background-color: #373737;
  }
`;

const AlertName = styled.span`
  flex-grow: 1;
  margin-left: 16px;
`;

const AlertIconContainer = styled.div<{ severity: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ severity }) =>
    severity === 'HIGH' ? '#442B27' : '#322C1D'};
`;

const SeverityIcon = ({ severity }: { severity?: string }) => {
  if (!severity) {
    return null;
  }
  return (
    <AlertIconContainer severity={severity}>
      <Icon
        name="emergency_home"
        style={{
          fontSize: 16,
        }}
        color={severity === AlertSeverityEnumDto.HIGH ? '#E8684A' : '#F6BD16'}
      />
    </AlertIconContainer>
  );
};

const rowRenderer =
  (
    alerts: Alert[],
    deleteAlert: UseMutationResult<any, any, string, any>,
    closeMenu: () => void,
    setCurrentAlert: (alert: Alert) => void,
    getCurrentAlertRule: (alert: Alert) => void,
    selectedTenantId: string,
    isRule: boolean,
  ) =>
  ({ key, index, style }: any) => {
    const alert = alerts[index];

    const onModify = (e: any) => {
      if (
        e.target.classList.contains('pop-over-custom') ||
        e.target.closest('.pop-over-custom')
      ) {
        return;
      }
      setCurrentAlert(alert);
    };

    const onClickSingleAlertRule = () => {
      getCurrentAlertRule(alert);
    };

    const menuItems = [];

    if (alert?.active) {
      menuItems.push({
        label: t('general.secondUnactive'),
        onClick: onClickSingleAlertRule,
      });
    }

    menuItems.push({
      label: t('general.delete'),
      onClick: () => {
        deleteAlert.mutate(alert.id);
        closeMenu();
      },
    });

    if (isRule)
      return (
        <StyledAlertRowContainer
          key={key}
          style={style}
          role="button"
          onClick={onModify}
        >
          <SeverityIcon severity={alert.severity} />
          <AlertName>{alert.name}</AlertName>
          <MenuPopover menuItems={menuItems} dark />
        </StyledAlertRowContainer>
      );

    return (
      <StyledAlertRowContainer
        key={key}
        style={style}
        role="button"
        onClick={onModify}
      >
        {/* <SeverityIcon severity={alert.severity} />
        <AlertName>{alert.name}</AlertName>
        <MenuPopover menuItems={menuItems} dark /> */}
      </StyledAlertRowContainer>
    );
  };

const StyledSpin = styled(Spin)`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledAutosizer = styled(AutoSizer)`
  ${utils.customScrollbar}

  div > span:nth-child(2) {
    display: block;
    width: 50px;
    white-space: pre;
  }
`;

const AlertsSet = ({
  compact,
  openModal,
  openAlertDetailsModal,
  isRule,
}: {
  compact?: boolean;
  isRule?: boolean;
  openModal: () => void;
  openAlertDetailsModal: (id: object) => void;
}) => {
  const { data: alerts, isLoading, isError } = useAssetAlerts();
  const { selectedTenantId } = useTenantSlimReport();
  const [, setCurrentAlertId] = useRecoilState(currentAlertIdState);

  const deleteAlert = useDeleteAlert();
  const toggleAlert = useToggleAlert();

  const ref = useRef<HTMLDivElement>(null);

  const [heightOffsetFromTop, setHeightOffsetFromTop] = useState<number>(0);

  const [isConfirmDeactivateModalOpen, setIsConfirmDeactivateModalOpen] =
    useState(false);

  const [alertIds, setAlertIds] = useState('');

  useLayoutEffect(() => {
    if (ref.current) {
      setHeightOffsetFromTop(ref.current.getBoundingClientRect().top);
    }
  }, [ref]);

  const closeMenu = () => ref.current?.click();

  let content;

  if (isLoading) {
    content = <StyledSpin size="large" />;
  } else if (isError) {
    content = <Error fillDimensions={compact} />;
  } else if (!alerts || !alerts.pages.flatMap(el => el).length) {
    content = (
      <EmptyState
        title={t('alerts.noAlertSet')}
        subtitle={t('alerts.setNewAlert')}
        dark
        fillDimensions={compact}
        style={{
          maxWidth: 302,
          margin: 'auto',
          marginTop: !compact ? 32 : -100,
        }}
        image={
          <Icon
            name="emergency_home"
            style={{ fontSize: 48 }}
            color="#919191"
          />
        }
      />
    );
  } else {
    content = (
      <div
        style={{
          height: '600px',
        }}
      >
        <StyledAutosizer>
          {({ width, height }: any) => {
            return (
              <List
                className="list"
                height={height}
                rowCount={alerts.pages.flatMap(el => el).length}
                rowHeight={65}
                rowRenderer={rowRenderer(
                  alerts.pages.flatMap(el => el),
                  deleteAlert,
                  closeMenu,
                  (alert: Alert) => {
                    setCurrentAlertId(alert.id);
                    closeMenu();
                    openAlertDetailsModal({});
                  },
                  (singleAlert: Alert) => {
                    setAlertIds(singleAlert.id);
                    setIsConfirmDeactivateModalOpen(true);
                    ref.current?.click();
                  },
                  selectedTenantId,
                  !!isRule,
                )}
                width={width}
                // style={{
                //   overflowY: 'scroll',
                // }}
              />
            );
          }}
        </StyledAutosizer>
        <ConfirmModal
          title={t('alerts.deactivate')}
          description={t('alerts.deactivateAlertConfirmation')}
          secondDescription={t('alerts.noMoreAlertNotification')}
          open={isConfirmDeactivateModalOpen}
          onCancel={() => setIsConfirmDeactivateModalOpen(false)}
          onConfirm={() => {
            const body: ToggleAlertBody = {
              alertIds: [alertIds],
              enable: false,
            };
            toggleAlert.mutate({ body });
            ref.current?.click();
            setIsConfirmDeactivateModalOpen(false);
          }}
          confirmLoading={toggleAlert.isLoading}
          firstButtonText={t('general.cancel')}
          secondButtonText={t('general.secondUnactive')}
          light
        />
      </div>
    );
  }

  return (
    <>
      {!compact && <Title level={4}>{t('general.rules')}</Title>}
      <div ref={ref} style={{ marginTop: 16 }}></div>
      {content}
    </>
  );
};

export default function AlertDrawerContent({
  fullscreen,
}: {
  fullscreen?: boolean;
}) {
  const [newAlertModalOpen, setNewAlertModalOpen] = useState(false);

  const [alertDetailsModalOpen, setAlertDetailsModalOpen] = useState(false);
  const [currentEventId, setCurrentEventId] = useState<object | undefined>(
    undefined,
  );
  return (
    <Container>
      {fullscreen ? (
        <FullscreenContent
          onaddAlertClick={() => setNewAlertModalOpen(true)}
          onOpenAlertDetails={(event: object) => {
            setAlertDetailsModalOpen(() => {
              console.log('current event: ' + event);
              setCurrentEventId(() => event);
              return true;
            });
          }}
        />
      ) : (
        <CollapsedContent
          onaddAlertClick={() => setNewAlertModalOpen(true)}
          onOpenAlertDetails={(id: object) => {
            setAlertDetailsModalOpen(() => {
              console.log('current event id: ' + id);
              setCurrentEventId(() => id);
              return true;
            });
          }}
        />
      )}
      {newAlertModalOpen && (
        <AddAlertModal
          open={newAlertModalOpen}
          onCancel={() => {
            setNewAlertModalOpen(false);
          }}
        />
      )}

      {alertDetailsModalOpen && (
        <AlertDetailsModal
          open={alertDetailsModalOpen}
          onCancel={() => {
            setAlertDetailsModalOpen(false);
          }}
          onOk={() => {
            queryClient
              .invalidateQueries(GET_GENERATED_EVENTS_QUERY_KEY)
              .catch(e => console.log('Error', e.message));
          }}
          event={currentEventId}
        />
      )}
    </Container>
  );
}
