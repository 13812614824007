export enum consumptionMapping {
  WORK = 'high',
  IDLE = 'medium',
  STOP = 'low',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export enum previousConsumptionMapping {
  WORK = 'work',
  IDLE = 'idle',
  STOP = 'stop',
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}

export const consumptionColorMapping = {
  [consumptionMapping.HIGH]: '#2972FF', // previous '#18CD4B'
  [consumptionMapping.MEDIUM]: '#8BB2FF', // previous '#F6BD16'
  [consumptionMapping.LOW]: '#C5D9FF', // previous '#E8684A'
};
