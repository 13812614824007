import { ConfigProvider } from 'antd';
import locale from 'antd/locale/it_IT';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RecoilRoot } from 'recoil';
import Router from './routes/Router';
import { lightTheme } from '@theme/light';
import { createContext } from 'react';
import useNotification from 'antd/es/notification/useNotification';
import { NotificationInstance } from 'antd/es/notification/interface';

export const queryClient = new QueryClient();

export const NotificationContext = createContext<{
  toast?: NotificationInstance;
}>({});

function App() {
  const [toast, contextHolder] = useNotification();
  return (
    <ConfigProvider theme={lightTheme} locale={locale}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <RecoilRoot>
          <NotificationContext.Provider value={{ toast }}>
            {contextHolder}
            <Router />
          </NotificationContext.Provider>
        </RecoilRoot>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
