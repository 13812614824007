import { atom } from 'recoil';
import dayjs from 'dayjs';
import {
  GeneratedEventsByAssetQueryParams,
  GeneratedEventsQueryParams,
} from '@src/api/alert/types';
import {
  AlertSeverityEnumDto,
  AssetEventGetListRequestDtoEventType,
} from '@asset-manager/types';

dayjs.extend(require('dayjs/plugin/utc'));

const utcDayjs = dayjs.utc;

export const generatedEventsByAssetFiltersState = atom({
  key: 'generatedEventsByAssetFiltersState',
  default: {
    startDate: utcDayjs().startOf('day').subtract(30, 'days').unix().toString(),
    endDate: utcDayjs().endOf('day').unix().toString(),
    limit: '6',
    skip: '0',
    sort: 'createdAt',
    order: 'desc',
    eventType: AssetEventGetListRequestDtoEventType.ALERT,
  } as GeneratedEventsByAssetQueryParams,
});

export const EventsDefaultSort = 'createdAt' as const;
export const EventsDefaultOrder = 'desc' as const;

export const generatedEventsDefaultSeverities = [
  AlertSeverityEnumDto.HIGH,
  AlertSeverityEnumDto.MEDIUM,
  AlertSeverityEnumDto.LOW,
];

export const generatedEventsFiltersState = atom({
  key: 'generatedEventsFiltersState',
  default: {
    startDate: utcDayjs()
      .startOf('day')
      .subtract(365, 'days')
      .unix()
      .toString(),
    endDate: utcDayjs().endOf('day').unix().toString(),
    limit: '10',
    skip: '0',
    sort: EventsDefaultSort,
    order: EventsDefaultOrder,
  } as GeneratedEventsQueryParams,
});

export const eventsByAlertIdFiltersState = atom({
  key: 'eventsByAlertIdFiltersState',
  default: {
    startDate: utcDayjs()
      .startOf('day')
      .subtract(365, 'days')
      .unix()
      .toString(),
    endDate: utcDayjs().endOf('day').unix().toString(),
    limit: '10',
    skip: '0',
    sort: EventsDefaultSort,
    order: EventsDefaultOrder,
  } as GeneratedEventsQueryParams & { dateRange: string },
});
