/**
 * NB: it's important for a quick copy-paste of the configuration on the online platform
 * https://ant.design/theme-editor
 * https://ant.design/docs/react/customize-theme#api
 * https://ant.design/docs/react/customize-theme#maptoken
 * nvm use 18.12.1
 */

// prettier-ignore
export const commonTheme = {
  "token": {
    "fontFamily": "Satoshi-Variable"
    // "fontSize": 14.1

    // "colorSuccessBg": "red",
    // "fontSizeHeading5": 16,
    // "colorPrimary": "white",

    // "primaryColor": "red",
    // "infoColor": "red",
    // "successColor": "red",
    // "processingColor": "red",
    // "errorColor": "red",
    // "warningColor": "red",
  },
  "components": {
    "Slider": {
      "handleLineWidth": 0,
      "dotSize": 12
    },
    "Cascader": {
      "paddingSM": 16, // 12 // ai lati della stringa
      "paddingXXS": 0, // 11 // contenitore dropdown
      "fontFamily": "Satoshi-Variable",
      "fontSize": 14,
      "lineHeight": 24 / 14, // 1.5714285714285714
    },
    "Select": {
      "fontFamily": "Satoshi-Variable",
      "fontSize": 14,
      "fontSizeLG": 14, //16
      // "fontWeightStrong": 700, // 600
      "lineHeight": 24 / 14, // 1.5714285714285714
      "controlOutline": "transparent"
    },
    "Pagination": {
      // "borderRadius": 6,
      "fontFamily": "Satoshi-Variable",
      "fontSize": 16, // 14
      "fontWeightStrong": 700, // 600
      "lineHeight": 24 / 16, // 1.5714285714285714

    },
    "Typography": {
      "fontFamilyCode": "Satoshi-Variable",
      "sizeMarginHeadingVerticalEnd": 0 || '0.5em',
      "sizeMarginHeadingVerticalStart": 0 || '1.67em',
      // fontSize: 14,
      // fontSizeHeading1: 38,
      // fontSizeHeading2: 30,
      // fontSizeHeading3: 24,
      // fontSizeHeading4: 20,
      // fontSizeHeading5: 16,
      // lineHeight: 1.1,
      // lineHeightHeading1: 1.55,
    },
    "Table": {
      "fontFamilyCode": "Satoshi-Variable",
      "borderRadius": 6,
      "borderRadiusLG": 6,
    },
    "Tag": {
      "fontSize": 14,
      "lineHeight": 22 / 14,
      "borderRadiusSM": 20,
      "fontFamilyCode": "Satoshi-Variable",
    },
    "Tabs": {
      "padding": 4,
      "marginXXS": 16,
      // paddingLG: 24.1,
      // paddingSM: 12.1,
      // paddingXS: 8.1,
      // paddingXXS: 4.1,
    },
    "Button": {
      "controlHeight": 44, // 32
      "fontSize": 16, // 14
      "lineHeight": 24 / 16 // 1.5714285714285714
    //   "colorBgTextActive": "pink",
    //   "colorBgContainer": "red",
    //   "colorText": "yellow",
    //   "colorTextLightSolid": 'green',
    //   "colorBorder": 'green',
    },
    "Form": {
      "margin": 16,
      "marginXS": 8,
      "marginXXS": 4,
      "marginLG": 16, // 24
    },
    "Checkbox": {
      "fontSize": 14,
      "lineHeight": 24 / 14, //1.5714285714285714
      "controlInteractiveSize": 18 // 16
    },
    "Input": {
      "fontFamily": "Satoshi-Variable", //"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji'",
      "fontSize": 14, // 14,
      // "paddingLG": 10.1, // 24
      // "paddingSM": 10.2, // 12
      // "paddingXS": 10.3, // 8
      // "paddingXXS": 10.4, // 4
    },
    "DatePicker": {
      "fontFamily": "Satoshi-Variable", //"-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji'",
      "fontSize": 14, // 14,
      "controlHeight": 44,
      // "lineHeight": 1.5, // 1.5714285714285714
    },
    "TimePicker": {
      //
    }
  }
};
