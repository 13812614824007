export const list = [
  { label: 'Asset', value: 'Nome Macchina' },
  { label: 'Data', value: '10/02/2023' },
  { label: 'Ora', value: '15:30' },
  { label: 'Durata', value: '35 min' },
];

export const options = [
  {
    icon: 'radio_button_checked',
    color: '#E8684A',
    value: 'OFF',
    label: 'Stop',
  },
  // {
  //   icon: 'radio_button_checked',
  //   color: '#E8684A',
  //   value: 'OFF',
  //   label: 'Spento',
  // },
  {
    icon: 'radio_button_checked',
    color: '#E8684A',
    value: 'FAULT',
    label: 'Guasto',
  },
  // {
  //   icon: 'radio_button_checked',
  //   color: '#F6BD16',
  //   value: 'IDLE',
  //   label: 'Inattivo',
  // },
  {
    icon: 'radio_button_checked',
    color: '#F6BD16',
    value: 'SERVICE',
    label: 'Manutenzione',
  },
  // {
  //   icon: 'radio_button_checked',
  //   color: '#18CD4B',
  //   value: 'ON',
  //   label: 'Acceso',
  // },
  {
    icon: 'radio_button_checked',
    color: '#18CD4B',
    value: 'TOOLING',
    label: 'Attrezzaggio',
  },
  // {
  //   icon: 'add_circle',
  //   color: '#fff',
  //   value: 'new_category',
  //   label: 'Nuova categoria',
  // },
];

export const optionsColor = [
  {
    color: '#E8684A',
    value: 'HIGH',
    label: 'fault',
  },
  {
    color: '#F6BD16',
    value: 'MEDIUM',
    label: 'maintenance',
  },
  {
    color: '#18CD4B',
    value: 'LOW',
    label: 'tooling',
  },
  {
    color: '#4786FF',
    value: 'BLUE',
    label: 'blue',
  },
  // {
  //   color: '#C8C8C8',
  //   value: 'WHITE',
  //   label: 'default',
  // },
];
