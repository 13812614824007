import { API } from 'aws-amplify';
import { ALERT_API } from '@src/api';
import { ToggleAlertBody } from './types';

export async function toggleAlert(tenantId?: string, body?: ToggleAlertBody) {
     if (tenantId) {
          return API.put(ALERT_API, `/tenant/${tenantId}/toggle`, { body });
     }

     return [];
}
