import { useMutation, useQueryClient } from 'react-query';
import { postEventUpdate } from '@src/api/event/post-event-update';
import { ASSET_REPORT_QUERY_KEY } from '../consts';
import { useContext } from 'react';
import { NotificationContext } from '@src/App';

export function useUpdateEvent() {
  const queryClient = useQueryClient();
  const { toast } = useContext(NotificationContext);

  return useMutation({
    // TODO: remove description after be deploy
    mutationFn: (data: {
      tenantId: string;
      assetId: string;
      body: any;
      eventId: string;
    }) => postEventUpdate(data.tenantId, data.assetId, data.eventId, data.body),
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: [ASSET_REPORT_QUERY_KEY] });
      }, 1000);
    },
    onSuccess: () => {
      toast?.success({
        message: 'Evento modificato con successo',
        placement: 'topRight',
      });
    },
    onError: () => {
      toast?.error({
        message: "Errore durante la modifica dell'evento",
        placement: 'topRight',
      });
    },
  });
}
