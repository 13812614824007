import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useAssetConsumptionStateReport as useAssetConsumptionStateReportQuery } from '@src/api';
import { selectedAssetIdState } from '@store/asset/selectedAssetIdState';
import { GetReportAssetStateResponseDto } from '@asset-manager/types';
import { assetFilterDateState } from '@store/asset/assetFilterDateState';

export interface IUseAssetConsumptionStateReport {
  selectedTenantId: string;
  assetConsumptionStateReport?: GetReportAssetStateResponseDto;
  isReportLoading: boolean;
  assetConsumptionRefetch?: () => void;
}
export function useAssetConsumptionStateReport(
  type: string = 'machine',
): IUseAssetConsumptionStateReport {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [selectedAssetId] = useRecoilState(selectedAssetIdState);
  const [reportFilters] = useRecoilState(assetFilterDateState);

  const {
    data: assetConsumptionStateReport,
    isLoading: isReportLoading,
    refetch: assetConsumptionRefetch,
  } = useAssetConsumptionStateReportQuery(
    selectedTenantId,
    selectedAssetId,
    reportFilters,
    type,
  );

  return {
    selectedTenantId,
    assetConsumptionStateReport,
    isReportLoading,
    assetConsumptionRefetch,
  };
}
