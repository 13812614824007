import { API } from 'aws-amplify';
import { NOTE_API } from '@src/api';

export async function deleteNote(
  tenantId?: string,
  assetId?: string,
  noteId?: string,
) {
  if (tenantId && assetId && noteId) {
    return API.del(
      NOTE_API,
      `/tenant/${tenantId}/asset/${assetId}/${noteId}`,
      {},
    );
  }

  return [];
}
