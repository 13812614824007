interface FaqItem {
  title: string;
  description: string;
}

interface Faq {
  id: string;
  name: string;
  icon?: string;
  faqs: FaqItem[];
}

const faq: FaqItem = {
  title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  description:
    'Duis et velit viverra, posuere velit a, faucibus sem. Sed diam est, maximus at erat sed, fringilla congue leo. Nulla ex nisl, malesuada id diam ac, consectetur vehicula nunc. Etiam ipsum nisl, cursus porta suscipit tristique, luctus sollicitudin purus. Pellentesque volutpat congue libero sit amet viverra. Curabitur consequat eu odio et tristique. Mauris ac arcu ipsum. Cras vel fermentum dolor, eu scelerisque urna. Quisque enim leo, condimentum nec est quis, efficitur dignissim nisl. Nam nec augue in risus bibendum porttitor. Nunc non orci a dolor hendrerit convallis sed quis sapien. Nullam commodo nunc purus, nec commodo orci ultricies vel. Duis blandit faucibus tristique. Nam hendrerit porta mi, in pharetra ante gravida et. Praesent sed maximus enim.',
};

export function getRandomArbitrary(min: number, max: number) {
  return Math.random() * (max - min) + min;
}

export const data: Faq[] = [
  // {
  //   id: 'add-assets',
  //   name: 'Aggiunta assets',
  //   icon: 'precision_manufacturing',
  //   faqs: Array(Math.floor(getRandomArbitrary(0, 10)))
  //     .fill(null)
  //     .map(_ => ({ ...faq })),
  // },
  {
    id: 'sensors',
    name: 'Sensori',
    icon: 'podcasts',
    faqs: Array(Math.floor(getRandomArbitrary(0, 10)))
      .fill(null)
      .map(_ => ({ ...faq })),
  },
  {
    id: 'stops',
    name: 'Eventi assets',
    icon: 'build',
    faqs: Array(Math.floor(getRandomArbitrary(0, 10)))
      .fill(null)
      .map(_ => ({ ...faq })),
  },
  // {
  //   id: 'note',
  //   name: 'Note',
  //   icon: 'chat',
  //   faqs: Array(Math.floor(getRandomArbitrary(0, 10)))
  //     .fill(null)
  //     .map(_ => ({ ...faq })),
  // },
  {
    id: 'account',
    name: 'Account e anagrafiche',
    faqs: Array(Math.floor(getRandomArbitrary(0, 10)))
      .fill(null)
      .map(_ => ({ ...faq })),
  },
  //
  /*
  {
    id: 'technical-problems',
    name: 'Problemi tecnici',
    icon: 'settings',
    faqs: Array(Math.floor(getRandomArbitrary(0, 10)))
      .fill(null)
      .map(_ => ({ ...faq })),
  },
  {
    id: 'installations',
    name: 'Installazione macchine',
    icon: 'precision_manufacturing',
    faqs: Array(Math.floor(getRandomArbitrary(0, 10)))
      .fill(null)
      .map(_ => ({ ...faq })),
  },
  {
    id: 'alerts',
    name: 'Gestione alert',
    icon: 'emergency_home',
    faqs: Array(Math.floor(getRandomArbitrary(0, 10)))
      .fill(null)
      .map(_ => ({ ...faq })),
  },
  {
    id: 'report',
    name: 'Creazione Report',
    icon: 'area_chart',
    faqs: Array(Math.floor(getRandomArbitrary(0, 10)))
      .fill(null)
      .map(_ => ({ ...faq })),
  },
*/
].filter(i => i.faqs.length > 0);
