export function formatTime(hours: number, t: (p: string) => string) {
  const days = Math.floor(hours / 24);
  const remainingHours = Math.floor(hours % 24);
  const minutes = Math.floor((hours * 60) % 60);

  if (hours === 0 || isNaN(hours)) return '0';

  if (hours < 1) {
    return minutes + (minutes > 1 ? `${t('date.min')}` : `${t('date.min1')}`);
  }

  if (days > 0) {
    return (
      days +
      (days > 1 ? `${t('date.d')}` : `${t('date.d1')}`) +
      ' ' +
      remainingHours +
      (hours > 1 ? `${t('date.h')}` : `${t('date.h1')}`)
    );
  } else {
    return (
      remainingHours +
      (hours > 1 ? `${t('date.h')}` : `${t('date.h1')}`) +
      ' ' +
      minutes +
      (minutes > 1 ? `${t('date.min')}` : `${t('date.min1')}`)
    );
  }
}
