import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const utcDayjs = dayjs.utc;

export type DateRange = {
  startDate: string;
  endDate: string;
};

function formatToFixed(n: number): string {
  return n.toFixed(0);
}

export function getTodayTimeFrame(): DateRange {
  // start of day with dayjs
  const startDate = utcDayjs().startOf('day').unix();
  const endDate = utcDayjs().endOf('day').unix();

  return {
    startDate: formatToFixed(startDate),
    endDate: formatToFixed(endDate),
  };
}

export function getThisWeekTimeFrame(): DateRange {
  const startDate = utcDayjs().startOf('week').add(1, 'day');
  let endDate = utcDayjs().endOf('week').add(1, 'day');
  endDate = utcDayjs().isBefore(endDate) ? utcDayjs() : endDate;

  return {
    startDate: formatToFixed(startDate.unix()),
    endDate: formatToFixed(endDate.unix()),
  };
}

export function getLastWeekTimeFrame(): DateRange {
  const startDate = utcDayjs()
    .subtract(1, 'week')
    .startOf('week')
    .add(1, 'day')
    .unix();
  const endDate = utcDayjs()
    .subtract(1, 'week')
    .endOf('week')
    .add(1, 'day')
    .unix();

  return {
    startDate: formatToFixed(startDate),
    endDate: formatToFixed(endDate),
  };
}

export function getThisMonthTimeFrame(): DateRange {
  const startDate = utcDayjs().startOf('month');
  let endDate = utcDayjs().endOf('month');
  endDate = utcDayjs().isBefore(endDate) ? utcDayjs() : endDate;

  return {
    startDate: formatToFixed(startDate.unix()),
    endDate: formatToFixed(endDate.unix()),
  };
}

export function getLastMonthTimeFrame(): DateRange {
  const startDate = utcDayjs().subtract(1, 'month').startOf('month').unix();
  const endDate = utcDayjs().subtract(1, 'month').endOf('month').unix();

  return {
    startDate: formatToFixed(startDate),
    endDate: formatToFixed(endDate),
  };
}

export function getThisYearTimeFrame(): DateRange {
  const startDate = utcDayjs().startOf('year');
  let endDate = utcDayjs().endOf('year');
  endDate = utcDayjs().isBefore(endDate) ? utcDayjs() : endDate;

  return {
    startDate: formatToFixed(startDate.unix()),
    endDate: formatToFixed(endDate.unix()),
  };
}

export function getThisQuarterTimeFrame(): DateRange {
  const startDate = utcDayjs().subtract(84, 'days');
  let endDate = utcDayjs();
  endDate = utcDayjs().isBefore(endDate) ? utcDayjs() : endDate;

  return {
    startDate: formatToFixed(startDate.unix()),
    endDate: formatToFixed(endDate.unix()),
  };
}

export function getLastYearTimeFrame(): DateRange {
  const startDate = utcDayjs().subtract(1, 'year').startOf('year').unix();
  const endDate = utcDayjs().subtract(1, 'year').endOf('year').unix();

  return {
    startDate: formatToFixed(startDate),
    endDate: formatToFixed(endDate),
  };
}
