import { atom } from 'recoil';

export const generatedEventsExtraFilterState = atom({
  key: 'generatedEventsExtraFilterState',
  default: [
    {
      label: 'general.priority',
      value: 'severity',
      children: [
        {
          label: 'general.high',
          value: 'HIGH',
        },
        {
          label: 'general.normal',
          value: 'LOW',
        },
      ],
    },
    {
      label: 'assets.asset',
      value: 'assets',
      children: [],
    },
    {
      label: 'general.department',
      value: 'departments',
      children: [],
    },
    {
      label: 'general.establishment',
      value: 'plants',
      children: [],
    },
    {
      label: 'general.line',
      value: 'lines',
      children: [],
    },
  ],
});
