import { Button as AntdButton, ButtonProps } from 'antd';
import styled, { css, ThemeProvider } from 'styled-components';

const themeD = {
  'primary': {
    color: '#ffffff',
    bgColor: '#373737',
    border: '2px solid transparent',
    padding: '10px 16px',

    hoverColor: '#ffffff',
    hoverOutlineColor: 'transparent',
    hoverBgColor: '#373737',
    width: 'unset',
    height: 'unset',
  },
  'outlined': {
    bgColor: '#373737',
    color: '#ffffff',
    border: '2px solid #ffffff',
    padding: '10px 16px',

    hoverColor: 'black',
    hoverOutlineColor: 'transparent',
    hoverBgColor: '#C8C8C8',
    width: 'unset',
    height: 'unset',
  },
  'icon-dark': {
    bgColor: '#373737',
    border: '2px solid transparent',
    padding: '4px',

    hoverColor: '#ffffff',
    hoverOutlineColor: 'transparent',
    hoverBgColor: '#373737',
    width: 'unset',
    height: 'unset',
  },

  'link': {
    color: 'white', // to check
    bgColor: 'transparent',
    borderColor: 'transparent',

    hoverColor: '#ffffff',
    hoverOutlineColor: 'transparent',
    hoverBgColor: '#373737',
    width: 'unset',
    height: 'unset',
  },
};

const themeL = {
  'primary': {
    bgColor: '#E3E3E3',
    border: '2px solid transparent',
    padding: '10px 16px',

    hoverBgColor: '#d2d2d2',
    hoverOutlineColor: 'transparent',
    width: 'unset',
    height: 'unset',
  },
  'outlined': {
    bgColor: 'transparent',
    border: '2px solid #373737',
    padding: '10px 16px',

    hoverColor: '#ffffff',
    hoverOutlineColor: 'transparent',
    hoverBgColor: 'transparent',
    width: 'unset',
    height: 'unset',
  },
  'icon-dark': {
    bgColor: '#373737',
    border: '2px solid transparent',
    padding: '4px',
    color: 'white',

    hoverColor: '#ffffff',
    hoverOutlineColor: 'transparent',
    hoverBgColor: '#373737',
    width: '38px',
    height: '38px',
  },
  'link': {
    color: '#373737', // to check
    bgColor: 'transparent',
    border: 'none',

    hoverColor: '#7A7A7A',
    width: 'unset',
    height: 'unset',
  },
};

// export type variantBtn = keyof (typeof themeD & typeof themeL);
export type ButtonVariants = 'primary' | 'outlined' | 'icon-dark' | 'link';

export interface Props extends ButtonProps {
  dark?: boolean;
  variant?: ButtonVariants;
  smallSize?: boolean;
  middleSize?: boolean;
  width?: string;
  height?: string;
}

const StyledButton = styled(AntdButton)<Props>`
  width: ${props =>
    typeof props.width !== 'string'
      ? props.theme[props.variant || 'primary'].width
      : props.width}!important;     
  height: ${props =>
    typeof props.height !== 'string'
      ? props.theme[props.variant || 'primary'].height
      : props.height}!important;
  // TODO: move to global theme
  color: #373737;
  font-family: 'Satoshi-Variable', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 1em;

  background-color: ${({ theme, variant }) =>
    theme[variant || 'primary'].bgColor};
  border: ${({ theme, variant }) => theme[variant || 'primary'].border};
  padding: ${({ theme, variant }) => theme[variant || 'primary'].padding};

  /* .anticon {
    color: ${props => props.theme.color};
  } */

  /*
    TODO: all'interno del bottone, quando viene premuto, si forma un componente in absolute
    al cui interno c'è un div.ant-wavw 
    che al click fa un effetto intorno al bottone
    Non riesco a cambiargli colore.
  */
  .ant-wave {
    display: none;
  }
  
  :active, .ant-btn-default:not(:disabled):active {
    // non c'è nel theme
    /* color: inherit !important;
    border-color: inherit !important; */
    background-color: ${({ theme, variant }) =>
      theme[variant || 'primary'].hoverBgColor};  !important;
    border-color: ${props =>
      props.theme[props.variant || 'primary'].hoverOutlineColor} !important;
    color: ${({ theme, variant }) =>
      theme[variant || 'primary'].hoverColor};  !important;
  }  
  
  :hover {
    // non c'è nel theme
    /* color: inherit !important;
    border-color: inherit !important; */ 
    background-color: ${({ theme, variant }) =>
      theme[variant || 'primary'].hoverBgColor};  !important;
    border-color: ${props =>
      props.theme[props.variant || 'primary'].hoverOutlineColor} !important;
    color: ${({ theme, variant }) =>
      theme[variant || 'primary'].hoverColor};  !important;
  }
  :where(.css-dev-only-do-not-override-gf70ni).ant-btn-default:not(:disabled):hover {
    color: ${({ theme, variant }) =>
      theme[variant || 'primary'].hoverColor} !important;
  }
  :where(.css-dev-only-do-not-override-15l6f1m).ant-btn-default:not(:disabled):hover {
    color: ${({ theme, variant }) =>
      theme[variant || 'primary'].hoverColor} !important;
  }
  :where(.css-dev-only-do-not-override-15l6f1m).ant-btn-default {
    color: ${({ theme, variant }) =>
      theme[variant || 'primary'].color} !important;
  }
  /* &:active {
    color: ${props => props.theme.color} !important;
    border-color: ${props => props.theme.borderColor} !important;
  } */

  ${({ smallSize }) =>
    smallSize &&
    css`
      width: 32px !important;
      height: 32px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      &.ant-btn.ant-btn-icon-only > span {
        transform: none;
      }
    `}

   ${({ middleSize }) =>
     middleSize &&
     css`
       :where(.css-dev-only-do-not-override-gf70ni).ant-btn {
         width: 44px !important;
       }
     `}
`;

// col link c'è un problema di bordi
export default function Button({
  smallSize = false,
  middleSize = false,
  dark = false,
  ...props
}: Props) {
  return (
    <ThemeProvider theme={dark ? themeD : themeL}>
      {/* {type}-{dark ? 'D' : 'L'} */}
      <StyledButton
        smallSize={smallSize}
        {...props}
        width={props.width}
        height={props.height}
      />
    </ThemeProvider>
  );
}
