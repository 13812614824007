import { AlertSeverityEnumDto } from '@asset-manager/types';
import { alertExtraFilterState } from '@store/alert/alert-extra-filter-state';
import {
  alertDefaultSeverities,
  alertTableFiltersState,
} from '@store/alert/alert-filter-state';
import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useGetAssets } from '../asset';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';

export default function useAlertTableExtraFilters() {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);

  const [filters, setFilters] = useRecoilState(alertTableFiltersState);

  const [extraFiltersOptions, setExtraFiltersOptions] = useRecoilState(
    alertExtraFilterState,
  );

  const { data: assetsData, isSuccess: isSuccessAssets } =
    useGetAssets(selectedTenantId);

  useEffect(() => {
    if (isSuccessAssets) {
      setExtraFiltersOptions(prev => {
        const newExtraFilters = JSON.parse(JSON.stringify(prev));

        const assets = newExtraFilters.find((f: any) => f.value === 'assets');
        if (assets) {
          assets.children = assetsData.filters?.assets?.map((asset: any) => ({
            label: asset.name,
            value: asset.id,
          }));
        }

        const departments = newExtraFilters.find(
          (f: any) => f.value === 'departments',
        );
        if (departments) {
          departments.children = assetsData.filters?.departments?.map(
            (dep: any) => ({
              label: dep.name,
              value: dep.id,
            }),
          );
        }

        const plants = newExtraFilters.find((f: any) => f.value === 'plants');
        if (departments) {
          plants.children = assetsData.filters?.plants?.map((dep: any) => ({
            label: dep.name,
            value: dep.id,
          }));
        }

        const lines = newExtraFilters.find((f: any) => f.value === 'lines');
        if (departments) {
          lines.children = assetsData.filters?.lines?.map((dep: any) => ({
            label: dep.name,
            value: dep.id,
          }));
        }

        return newExtraFilters;
      });
    }
  }, [assetsData, isSuccessAssets, setExtraFiltersOptions]);

  const updateAlertsTableExtraFilters = useCallback(
    (extraFilters: Record<string, string[]>) => {
      const newFilters = { ...filters };

      if ('severity' in extraFilters) {
        newFilters.severities = extraFilters.severity as AlertSeverityEnumDto[];
      } else {
        newFilters.severities = alertDefaultSeverities;
      }

      if ('actives' in extraFilters) {
        newFilters.actives = extraFilters.actives as ('true' | 'false')[];
      } else {
        delete newFilters.actives;
      }

      if ('assets' in extraFilters) {
        newFilters.assetIds = extraFilters.assets;
      } else {
        delete newFilters.assetIds;
      }

      if ('plants' in extraFilters) {
        newFilters.plantIds = extraFilters.plants;
      } else {
        delete newFilters.plantIds;
      }

      if ('lines' in extraFilters) {
        newFilters.lineIds = extraFilters.lines;
      } else {
        delete newFilters.lineIds;
      }

      if ('departments' in extraFilters) {
        newFilters.departmentIds = extraFilters.departments;
      } else {
        delete newFilters.departmentIds;
      }

      setFilters(newFilters);
    },
    [filters, setFilters],
  );

  const updateAlertsTableInputSearch = useCallback(
    (text: string) => {
      setFilters(prev => {
        const newFilters = { ...prev };
        if (text.length === 0) {
          delete newFilters.text;
        } else {
          newFilters.text = text;
        }
        return newFilters;
      });
    },
    [setFilters],
  );

  return {
    extraFiltersOptions,
    updateAlertsTableInputSearch,
    updateAlertsTableExtraFilters,
  };
}
