import { GET_ASSET_NOTES_QUERY_KEY } from '@asset-manager/api';
import { queryClient } from '@src/App';
import { useMutation, UseMutationOptions } from 'react-query';
import { deleteNote } from './delete-note';

export function useDeleteNote(params?: UseMutationOptions<any, any, any, any>) {
  return useMutation({
    mutationFn: (data: { tenantId: string; assetId: string; noteId: string }) =>
      deleteNote(data.tenantId, data.assetId, data.noteId),
    onSettled: () => {
      queryClient.invalidateQueries(GET_ASSET_NOTES_QUERY_KEY);
    },
    ...params,
  });
}
