import { useMutation, useQueryClient } from 'react-query';
import { deleteEvent } from './event-http';
import { NotificationContext } from '@src/App';
import { useContext } from 'react';
import {
  ASSET_REPORT_QUERY_KEY,
  GET_GENERATED_EVENTS_QUERY_KEY,
} from '../consts';

export function useDeleteEvent() {
  const { toast } = useContext(NotificationContext);

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: {
      tenantId: string;
      assetId: string;
      eventId: string;
    }) => deleteEvent(data.tenantId, data.assetId, data.eventId),
    onSettled: () => {
      queryClient.invalidateQueries(ASSET_REPORT_QUERY_KEY);
      queryClient.invalidateQueries(GET_GENERATED_EVENTS_QUERY_KEY);
    },

    onSuccess: () => {
      toast?.success({
        message: 'Evento eliminato con successo',
        placement: 'topRight',
      });
    },
    onError: () => {
      toast?.error({
        message: "Errore durante l'eliminazione dell'evento",
        placement: 'topRight',
      });
    },
  });
}
