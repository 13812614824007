import { ThemeConfig } from 'antd';
import { commonTheme } from './common';

export const darkTheme = {
  "token": {
    ...commonTheme.token,
    "colorPrimary": "#7A7A7A"
  },
  "components": {
    ...commonTheme.components,
    "Spin": {
      "colorBgContainer": "#141414",
      "colorPrimary": "#4786ff", //"#1668dc",
      "colorText": "rgba(255, 255, 255, 0.85)",
      "colorTextDescription": "rgba(255, 255, 255, 0.45)"
    },
    "Modal": {
      "colorBgElevated": "#1c1c1e", // #1f1f1f
      "colorText": "#fff",
    },
    "Slider":{
      ...commonTheme.components.Slider,
      "colorBgContainer": "#141414",
      "colorBgElevated": "#FBFBFB", //"#1f1f1f",
      "colorBorderSecondary": "#303030",
      "colorFillContentHover": "rgba(255, 255, 255, 0.18)",
      "colorFillSecondary": "rgba(255, 255, 255, 0.12)",
      "colorFillTertiary": "#28282A", //"rgba(255, 255, 255, 0.08)",
      "colorPrimary": "#FBFBFB", //"#1668dc", // TODO: handle focus
      "colorPrimaryBorder": "#7A7A7A", //"#15325b",
      "colorPrimaryBorderHover": "#7A7A7A", //"#15417e",// TODO: bar focus
      "colorText": "rgba(255, 255, 255, 0.85)",
      "colorTextDescription": "rgba(255, 255, 255, 0.45)",
      "colorTextDisabled": "rgba(255, 255, 255, 0.25)",
    },
    "Select": {
      ...commonTheme.components.Select,
      "colorBorder": "#373737",
      "colorBgContainer": "#28282A",
      "colorBgElevated": "#28282A",
      "colorText": "#FFFFFF",
      "colorIcon": "#373737",
      "boxShadowSecondary": "0px 0px 8px rgba(0, 0, 0, 0.06)",
      "colorPrimaryHover": "#373737",
      "controlItemBgHover": "#373737",
      "controlItemBgActive": "#28282A",
      "colorTextQuaternary": "#FFFFFF",
      "colorTextPlaceholder": "#7A7A7A",
    },
    "Input": {
      ...commonTheme.components.Input,
      "colorBgContainer": "#28282A", //"#141414",
      "colorBgContainerDisabled": "rgba(255, 255, 255, 0.08)",
      "colorBorder": "#373737", //"#424242",
      "colorError": "#dc4446",
      "colorErrorBorderHover": "#7e2e2f",
      "colorErrorOutline": "rgba(238, 38, 56, 0.11)",
      "colorFillAlter": "rgba(255, 255, 255, 0.04)",
      "colorIcon": "rgba(255, 255, 255, 0.45)",
      "colorIconHover": "rgba(255, 255, 255, 0.85)",
      "colorPrimary": "#1668dc",
      "colorPrimaryActive": "#1668dc",
      "colorPrimaryHover": "#373737", //"#3c89e8",
      "colorText": "rgba(255, 255, 255, 0.85)",
      "colorTextDescription": "rgba(255, 255, 255, 0.45)",
      "colorTextDisabled": "rgba(255, 255, 255, 0.25)",
      "colorTextPlaceholder": "#7a7a7a", //"rgba(255, 255, 255, 0.25)",
      "colorTextQuaternary": "rgba(255, 255, 255, 0.25)",
      "colorTextTertiary": "rgba(255, 255, 255, 0.45)",
      "colorWarning": "#d89614",
      "colorWarningBorderHover": "#7c5914",
      "colorWarningOutline": "rgba(173, 107, 0, 0.15)",
      "controlOutline": "rgba(0, 60, 180, 0.15)",
    },
    "Form": {
      ...commonTheme.components.Form,
      "colorText": "rgba(0, 0, 0, 0.88)",
    },
    "Checkbox": {
      ...commonTheme.components.Checkbox,
      "colorBgContainer": "#141414", // TODO #1c1c1e è il bg della pagina // "#141414",
      "colorBgContainerDisabled": "rgba(255, 255, 255, 0.08)",
      "colorBorder": "#424242", //"#fff", // "#424242",
      "colorPrimary": "#1668dc", // non va bene "#1C1B1F" per l'hover e active
      "colorPrimaryBorder":"#15325b",
      "colorPrimaryHover": "#3c89e8", // #fff"
      "colorText": "#fff", //"rgba(255, 255, 255, 0.85)",
      "colorTextDisabled": "rgba(255, 255, 255, 0.25)",
      "colorWhite": "#fff"
    },
    "DatePicker": {
      ...commonTheme.components.DatePicker,
      "colorBgContainer": "#28282A", //"#141414",
      "colorBgContainerDisabled": "rgba(255, 255, 255, 0.08)",
      "colorBgElevated": "#1f1f1f",
      "colorBorder": "#424242",//"#fff", // "#424242",
      "colorError": "#dc4446",
      "colorErrorOutline": "rgba(238, 38, 56, 0.11)",
      "colorIcon": "#fff", //"rgba(255, 255, 255, 0.45)",
      "colorIconHover":"#7a7a7a",
      "colorPrimaryBorder": "#C7C7C7", //"#15325b",
      "colorPrimaryHover": "#C7C7C7", //"#3c89e8",
      "colorSplit":"#C7C7C7",
      "colorText": "#919191",
      "colorTextDescription": "#C7C7C7",
      "colorTextDisabled": "#919191",
      "colorTextHeading": "#919191",
      "colorTextLightSolid": "#C7C7C7",
      "colorTextPlaceholder": "#7a7a7a", //"rgba(255, 255, 255, 0.25)",
      "colorPrimary": "#7a7a7a", //"rgba(255, 255, 255, 0.25)",
      colorTextLabel: "#373737",
      "colorWarning": "#d89614",
      "colorWarningOutline": "rgba(173, 107, 0, 0.15)",
      "controlItemBgActive": "#111a2c",
      "controlItemBgHover": "rgba(255, 255, 255, 0.08)",

      "colorLink": "#C7C7C7",
      "colorLinkActive": "#C7C7C7",
      "colorLinkHover": "#C7C7C7",
    },
    "Tag": {
      ...commonTheme.components.Tag,
      "colorError": "#E8684A", // "#dc4446",
      "colorErrorBg": "#442B27", // "#2c1618",
      "colorErrorBorder":"transparent", // "#5b2526",
      "colorSuccess": "#18CD4B", // "#49aa19",
      "colorSuccessBg": "#1B3F27", // "#162312",
      "colorSuccessBorder":"transparent", // "#274916",
      "colorWarning": "#F6BD16", // "#d89614",
      "colorWarningBg": "#322C1D", // "#2b2111",
      "colorWarningBorder": "transparent" // "#594214"
    },
    "TimePicker": {
      ...commonTheme.components.TimePicker,
      "colorBgContainer": "#28282A", //"#141414",
      "colorBgContainerDisabled": "rgba(255, 255, 255, 0.08)",
      "colorBgElevated": "#1f1f1f",
      "colorBorder": "#424242",//"#fff", // "#424242",
      "colorError": "#dc4446",
      "colorErrorOutline": "rgba(238, 38, 56, 0.11)",
      "colorIcon": "#fff", //"rgba(255, 255, 255, 0.45)",
      "colorIconHover":"#7a7a7a",
      "colorPrimaryBorder": "#C7C7C7", //"#15325b",
      "colorPrimaryHover": "#C7C7C7", //"#3c89e8",
      "colorSplit":"#C7C7C7",
      "colorText": "#919191",
      "colorTextDescription": "#C7C7C7",
      "colorTextDisabled": "#919191",
      "colorTextHeading": "#919191",
      "colorTextLightSolid": "#C7C7C7",
      "colorTextPlaceholder": "#7a7a7a", //"rgba(255, 255, 255, 0.25)",
      "colorPrimary": "#7a7a7a", //"rgba(255, 255, 255, 0.25)",
      colorTextLabel: "#373737",
      "colorWarning": "#d89614",
      "colorWarningOutline": "rgba(173, 107, 0, 0.15)",
      "controlItemBgActive": "#111a2c",
      "controlItemBgHover": "rgba(255, 255, 255, 0.08)",

      "colorLink": "#C7C7C7",
      "colorLinkActive": "#C7C7C7",
      "colorLinkHover": "#C7C7C7",
    },
  },
};
