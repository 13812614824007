import { GetReportAssetStateResponseDto } from '@asset-manager/types';
import { REPORT_API, ReportQueryParams } from '@src/api';
import { API } from 'aws-amplify';

export async function fetchAssetTemperatureStateReport(
  tenantId: string,
  assetId: string,
  filters: ReportQueryParams,
): Promise<GetReportAssetStateResponseDto> {
  const qs = filters ? `?${new URLSearchParams(filters).toString()}` : '';

  return await API.get(
    REPORT_API,
    `/tenant/${tenantId}/asset/${assetId}/temperature/state/map${qs}`,
    {},
  );
}
