import { atom } from 'recoil';
import dayjs from 'dayjs';
import { AuthorsQueryParams } from '@src/api';

dayjs.extend(require('dayjs/plugin/utc'));

export const noteAuthorsFiltersState = atom({
  key: 'noteAuthorsFiltersState',
  default: {
    limit: '100',
    skip: '0',
    sort: 'username',
    order: 'asc',
  } as AuthorsQueryParams,
});
