import { AssetGetAssetResponseDto } from '@asset-manager/common/dist/asset';
import Button from '@components/atoms/button';
import Icon from '@components/atoms/icon';
import DrawerHeader from '@components/molecules/drawer-header';
import { useMediaQuery, useWindowWidth } from '@src/hooks/useMediaQuery';
import { useAssets, useTenant } from '@src/modules/asset';
import { useTenantReport } from '@src/modules/asset/hooks/useTenantReport';
import { assetsTableSelectedRowState } from '@store/asset';
import {
  clickedSamePageSiderMenuState,
  closeOrContractDrawerState,
} from '@store/general/layout';
import { darkTheme } from '@theme/dark';
import { ConfigProvider, Drawer, Tabs } from 'antd';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { ContractContentIcon } from '../../../assets/icons';
import {
  AlertsDrawerContent,
  DataDrawerContent,
  NotesDrawerContent,
  SensorsDrawerContent,
  TenantDrawerContent,
} from './drawer-contents';
import { t } from 'i18next';

const Header = styled.div`
  position: sticky;
  top: 0;
  background-color: #1c1c1e;
  z-index: 2;
  padding-top: 24px;
`;

const StyledTabs = styled(Tabs)<{ topTabs: number }>`
  padding-bottom: 24px;

  .ant-tabs-nav-operations {
    display: none !important;
  }
  .ant-tabs-nav-list {
    width: calc(
      100% - 0.1px
    ); // TODO: non mi piace quando si allarga la sezione
    /* width: 329px; //100%; */
  }
  .ant-tabs-tab {
    border-radius: 8px !important;
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    flex: 1;
    justify-content: center;
    border: none !important;
  }

  .ant-tabs-nav {
    margin-bottom: 0;
    position: sticky;
    /* top: 80px; */
    top: ${({ topTabs }) => (topTabs ? `${topTabs}px` : '80px')};
    padding-top: 16px;
    background: #1c1c1e;
    z-index: 2;

    &:before {
      border: none;
    }
  }
`;

const getTabItems = (item: AssetGetAssetResponseDto, fullscreen: boolean) => [
  {
    key: 'data',
    label: t('general.date'),
    children: <DataDrawerContent item={item} fullscreen={fullscreen} />,
  },
  ...(item.type === 'monitron'
    ? []
    : [
        {
          key: 'sensors',
          label: t('general.sensors'),
          children: (
            <SensorsDrawerContent item={item} fullscreen={fullscreen} />
          ),
        },
      ]),
  {
    key: 'notes',
    label: t('notes.notes'),
    children: <NotesDrawerContent fullscreen={fullscreen} />,
  },
  {
    key: 'alerts',
    label: t('alerts.alerts'),
    children: <AlertsDrawerContent fullscreen={fullscreen} />,
  },
];

interface Props {
  showDrawer: boolean;
  setShowDrawer: Function;
  tenantType?: string;
}

export default function DetailsDrawer({
  showDrawer,
  setShowDrawer,
  tenantType,
}: Props) {
  const isLaptopM = useMediaQuery('laptopM');
  const windowWidth = useWindowWidth();

  const { selectedAsset, setSelectedAssetId } = useAssets();
  const { tenants } = useTenant();
  const { tenantReport } = useTenantReport();

  const [clickedSamePage, setClickedSamePage] = useRecoilState(
    clickedSamePageSiderMenuState,
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [closeOrContractDrawer, setCloseOrContractDrawer] = useRecoilState(
    closeOrContractDrawerState,
  );

  const [_, setAssetsTableSelectedRow] = useRecoilState(
    assetsTableSelectedRowState,
  );

  // non intende la width in sè per sè ma solo se è stato allargato il drawer o meno
  const [fullscreen, setFullscreen] = useState(
    // false,
    !isLaptopM,
  );

  const [headerHeight, setHeaderHeight] = useState(0);

  const headerSelectedAssetRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    setHeaderHeight(headerSelectedAssetRef.current?.offsetHeight || 0);
  }, []);

  const onClickChangeSize = useCallback(() => {
    setFullscreen(fs => !fs);
    // document.body.classList.toggle('disable-scroll');
    if (fullscreen) {
      setCloseOrContractDrawer(true);
      document.body.classList.remove('disable-scroll');
    } else document.body.classList.add('disable-scroll');
  }, [fullscreen, setCloseOrContractDrawer]);

  const listener = useCallback(() => {
    setFullscreen(window.innerWidth < 1358);
  }, []);

  useEffect(() => {
    listener();
    window.addEventListener('focus resize', listener);
    return () => {
      document.body.classList.remove('disable-scroll');
      window.removeEventListener('focus resize', listener);
    };
  }, [listener]);

  useEffect(() => {
    if (clickedSamePage && fullscreen) {
      if (!(isLaptopM && fullscreen)) {
        onClickCloseIcon();
      }
      if (isLaptopM) {
        onClickChangeSize();
      }
      setClickedSamePage(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedSamePage, fullscreen, isLaptopM]);

  const widthDrawer = useMemo(() => {
    // al mobile +è stato aggiunto 1px per il position fixed del drawer
    if (windowWidth && windowWidth < 1024) return 'calc(100vw + 1px)';
    return fullscreen ? 'calc(100vw - 80px)' : 378;
  }, [fullscreen, windowWidth]);

  // TODO: refactor utilizzando headerStyle/title/extra

  const onClickCloseIcon = useCallback(() => {
    setCloseOrContractDrawer(true);
    if (
      // !isLaptopM
      window.innerWidth < 1358
    ) {
      setShowDrawer(false);
    }
    setTimeout(() => {
      // TODO da migliorare perchè si vede il cambio colore della riga sotto
      // senza quando chiudi vedi i dati del tenant
      setAssetsTableSelectedRow(null);
      setSelectedAssetId('');
    }, 500);
  }, [
    setAssetsTableSelectedRow,
    setCloseOrContractDrawer,
    setSelectedAssetId,
    setShowDrawer,
  ]);

  return (
    <ConfigProvider theme={darkTheme}>
      <Drawer
        width={tenantType === 'GENERIC' && !selectedAsset ? 0 : widthDrawer}
        open={showDrawer}
        // open
        placement="right"
        closable={false}
        getContainer={false}
        mask={false}
        style={{ backgroundColor: '#1C1C1E' }}
        bodyStyle={{
          padding: '0 16px',
          // padding: '24px 16px 24px',
          display: 'flex',
          flexDirection: 'column',
        }}
        contentWrapperStyle={{ position: 'fixed', boxShadow: 'none' }}
        // headerStyle={{  margin: '0 16px' }}
        // title={<p>title</p>}
        // extra={<Space><Button>Cancel</Button></Space>}
      >
        {selectedAsset ? (
          <>
            <Header ref={headerSelectedAssetRef}>
              <DrawerHeader
                title={selectedAsset?.name ?? t('general.machine')}
                actions={
                  <>
                    {/* {!(isLaptopM && fullscreen) && (
                  <Button
                    icon={
                      <MoreVerticalIcon
                        width={24}
                        height={24}
                        style={{ color: 'white' }}
                      />
                    }
                  />
                )} */}

                    {isLaptopM && (
                      <Button
                        center
                        shape="square"
                        variant="grey-E3"
                        shapeSize={32}
                        onClick={onClickChangeSize}
                      >
                        {fullscreen ? (
                          <ContractContentIcon width={24} height={24} />
                        ) : (
                          <Icon
                            name="expand_content"
                            color="currenColor"
                            style={{ fontSize: 24, fontWeight: 700 }}
                          />
                        )}
                      </Button>
                    )}

                    {!(isLaptopM && fullscreen) && (
                      <Button
                        data-cypress="drawer-close-btn"
                        center
                        shape="square"
                        variant="grey-E3"
                        shapeSize={32}
                        onClick={onClickCloseIcon}
                        // onClick={() => {
                        //   if (
                        //     // !isLaptopM
                        //     window.innerWidth < 1358
                        //   ) {
                        //     setShowDrawer(false);
                        //   }
                        //   setTimeout(() => {
                        //     // TODO da migliorare perchè si vede il cambio colore della riga sotto
                        //     // senza quando chiudi vedi i dati del tenant
                        //     setAssetsTableSelectedRow(null);
                        //     setSelectedAssetId('');
                        //   }, 500);
                        // }}
                      >
                        <Icon
                          name={'close'}
                          color="currentColor"
                          style={{
                            fontSize: 24,
                            fontWeight: 700,
                            lineHeight: 'normal',
                          }}
                        />
                      </Button>
                    )}
                  </>
                }
              />
            </Header>
            <StyledTabs
              topTabs={headerHeight}
              // style={{ marginTop: 16 }}
              defaultActiveKey="1"
              items={getTabItems(selectedAsset, fullscreen)}
              type="card"
              centered
            />
          </>
        ) : (
          <>
            <Header>
              <DrawerHeader
                title={tenants?.items?.[0]?.name ?? 'Mocked Tenant'}
                actions={
                  <>
                    {isLaptopM ? (
                      <Button
                        center
                        shape="square"
                        variant="grey-E3"
                        shapeSize={32}
                        onClick={onClickChangeSize}
                      >
                        {fullscreen ? (
                          <ContractContentIcon
                            width={24}
                            height={24}
                            fill="currentColor"
                          />
                        ) : (
                          <Icon
                            name={'expand_content'}
                            style={{ fontWeight: 700 }}
                            color="currentColor"
                          />
                        )}
                      </Button>
                    ) : (
                      <Button
                        center
                        shape="square"
                        variant="grey-E3"
                        shapeSize={32}
                        onClick={() => {
                          setShowDrawer(false);
                          setAssetsTableSelectedRow(null);
                          setSelectedAssetId('');
                        }}
                      >
                        <Icon
                          name="close"
                          style={{
                            fontSize: 24,
                            fontWeight: 700,
                            lineHeight: 'normal',
                          }}
                        />
                      </Button>
                    )}
                  </>
                }
              />
            </Header>
            <TenantDrawerContent fullscreen={fullscreen} />
          </>
        )}
      </Drawer>
    </ConfigProvider>
  );
}
