import Button from '@components/atoms/button';
import { ConfigProvider, Popover, PopoverProps } from 'antd';
import styled, { css } from 'styled-components';
import { MoreVerticalIcon } from '@assets';
import { darkTheme } from '@theme/dark';
import { lightTheme } from '@theme/light';

interface Props extends PopoverProps {
  menuItems: {
    label: string;
    icon?: React.ReactNode;
    onClick: () => void;
  }[];
  dark?: boolean;
}

const MenuContainer = styled.div<{ dark?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ dark }) => (dark ? css`` : css``)}
`;

export default function MenuPopover({ menuItems, dark, ...props }: Props) {
  return (
    <ConfigProvider theme={dark ? darkTheme : lightTheme}>
      <Popover
        color={dark ? '#373737' : 'rgb(239, 239, 239)'}
        arrow={false}
        content={
          <MenuContainer dark={dark}>
            {menuItems.map(item => (
              <Button
                key={item.label}
                variant={dark ? 'grey-E3' : 'fill-white'}
                onClick={e => {
                  e.stopPropagation();
                  item.onClick();
                }}
                size="s"
                icon={item.icon}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'start',
                }}
              >
                {item.label}
              </Button>
            ))}
          </MenuContainer>
        }
        placement="bottomRight"
        trigger="click"
        {...props}
      >
        <Button
          center
          shape="square"
          variant={dark ? 'grey-E3' : 'fill-white'}
          shapeSize={32}
          className="pop-over-custom"
        >
          <MoreVerticalIcon />
        </Button>
      </Popover>
    </ConfigProvider>
  );
}
