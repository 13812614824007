import Button from '@components/atoms/button';
import Modal, { Footer } from '@components/atoms/modal';
import { darkTheme } from '@theme/dark';
import { lightTheme } from '@theme/light';
import { ConfigProvider, ModalProps } from 'antd';
import styled from 'styled-components';
import { fontSize } from '@src/globalStyle';
interface Props extends ModalProps {
  title: string;
  description: string;
  secondDescription?: string;
  firstButtonText?: string;
  secondButtonText?: string;
  open: boolean;
  onConfirm: () => void;
  confirmLoading?: boolean;
  onCancel: () => void;
  dark?: boolean;
  light?: boolean;
}

const Subtitle = styled.span`
  ${fontSize.s};
  font-weight: 400;
  color: #7A7A7A;
  margin-top: 8px;
`;

export default function ConfirmModal({
  title,
  description,
  secondDescription,
  open,
  onConfirm,
  confirmLoading,
  onCancel,
  dark,
  light,
  firstButtonText,
  secondButtonText,
  ...props
}: Props) {
  return (
    <ConfigProvider theme={!!dark ? darkTheme : lightTheme}>
      <Modal
        light
        dark
        title={title}
        open={open}
        // @ts-ignore
        onOk={onConfirm}
        confirmLoading={confirmLoading}
        onCancel={onCancel}
        centered
        {...props}
        footer={light && (<Footer>
          <Button onClick={onCancel}>
            {firstButtonText ? firstButtonText : 'Annulla'}
          </Button>
          <Button onClick={onConfirm}>{secondButtonText ? secondButtonText : 'OK'
          }</Button>
        </Footer>)
        }
      >
        {description}
        {
          light && <Subtitle>
            {secondDescription}
          </Subtitle>
        }

      </Modal>
    </ConfigProvider>
  );
}
