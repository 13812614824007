import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { GET_GENERATED_EVENTS_QUERY_KEY } from '@src/api';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { fetchConfig } from '@src/config/http';
import { selectedAssetIdState } from '@store/asset';
import { fetchAssetGeneratedEvents } from './fetch-asset-generated-events';
import {
  EventsDefaultOrder,
  EventsDefaultSort,
  generatedEventsByAssetFiltersState,
  generatedEventsFiltersState,
} from '@store/alert/generated-events-filter-state';
import { fetchGeneratedEvents } from './fetch-generated-events';
import { GeneratedEventsSortOptions } from './types';

export function useAssetsGeneratedEvents() {
  const [tenantId] = useRecoilState(selectedTenantIdState);
  const [assetId] = useRecoilState(selectedAssetIdState);
  const [filters] = useRecoilState(generatedEventsByAssetFiltersState);

  return useQuery(
    [GET_GENERATED_EVENTS_QUERY_KEY, tenantId, assetId, filters],
    () => fetchAssetGeneratedEvents(tenantId, assetId, filters),
    {
      ...(fetchConfig as any),
      enabled: !!tenantId && !!assetId,
    },
  );
}

export function useGeneratedEventsPage() {
  const [filters, setFilters] = useRecoilState(
    generatedEventsByAssetFiltersState,
  );
  const pageSize = Number(filters.limit);
  const page = Number(filters.skip) / pageSize + 1;

  const updatePage = (page: number) => {
    setFilters({
      ...filters,
      skip: String((page - 1) * pageSize),
    });
  };

  return {
    pageSize,
    page,
    updatePage,
  };
}

export function useGeneratedEvents() {
  const [tenantId] = useRecoilState(selectedTenantIdState);
  const [filters, setFilters] = useRecoilState(generatedEventsFiltersState);

  const getGeneratedEvents = useQuery(
    [GET_GENERATED_EVENTS_QUERY_KEY, tenantId, filters],
    () => fetchGeneratedEvents(tenantId, filters),
    {
      ...(fetchConfig as any),
      enabled: !!tenantId,
    },
  );

  const pageSize = Number(filters.limit);
  const pageNumber = Number(filters.skip) / pageSize + 1;

  const updateFilters = ({
    page,
    limit,
    sort,
    order,
  }: {
    page: number;
    limit?: number;
    sort?: GeneratedEventsSortOptions;
    order?: 'asc' | 'desc';
  }) => {
    setFilters({
      ...filters,
      limit: limit?.toString() ?? filters.limit,
      skip: `${(page - 1) * Number(filters.limit)}`,
      sort: sort ?? EventsDefaultSort,
      order: order ?? EventsDefaultOrder,
    });
  };

  return {
    getGeneratedEvents,
    pageNumber,
    pageSize,
    updateFilters,
  };
}
