import { useMutation } from 'react-query';
import { API } from 'aws-amplify';
import { FAQ_API } from '@src/api';
import { NotificationContext } from '@src/App';
import { useContext } from 'react';

export function usePostAssistance() {
  const { toast } = useContext(NotificationContext);

  return useMutation({
    // TODO: remove description after be deploy
    mutationFn: async ({ message }: { message: string }) => {
      await API.post(FAQ_API, `/assistance`, {
        body: { message },
      });
    },
    onSuccess: () => {
      toast?.success({
        message: 'Messaggio inviato con successo!',
        placement: 'topRight',
      });
    },
    onError: () => {
      toast?.error({
        message: 'Errore! Messaggio non inviato.',
        placement: 'topRight',
      });
    },
  });
}
