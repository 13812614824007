import { useQuery } from 'react-query';
import { GET_ASSETS_QUERY_KEY } from '@src/api';
import { AssetGetAssetResponseDto } from '@asset-manager/common/dist/asset';
import { fetchAssets } from '@src/api/asset';
import { useRecoilState } from 'recoil';
import { assetFilterPlant } from '@store/asset/assetFilterPlantOptions';
import { selectedAssetIdState } from '@store/asset/selectedAssetIdState';
import { assetsState } from '@store/asset/assetsState';
import { fetchConfig } from '@src/config/http';
import { useMemo } from 'react';
import { assetFilterDateState } from '@store/asset/assetFilterDateState';

export function useGetAssetsWithPagination(id?: string, searchParam?: string) {
  const [filters, setFilters] = useRecoilState(assetFilterPlant);
  const [selectedAssetId, setSelectedAssetId] =
    useRecoilState(selectedAssetIdState);
  const [assets] = useRecoilState(assetsState);

  const [reportsfFilters] = useRecoilState(assetFilterDateState);

  const getAssets = useQuery(
    [GET_ASSETS_QUERY_KEY, id, searchParam, filters, reportsfFilters],
    () => fetchAssets(id, filters, reportsfFilters),
    { ...(fetchConfig as any) },
  );
  const pageSize = Number(filters.limit);
  const pageNumber = Number(filters.skip) / pageSize + 1;

  const updateFilters = ({ page, limit }: { page: number; limit?: number }) => {
    setFilters({
      ...filters,
      limit: limit?.toString() ?? filters.limit,
      skip: filters.limit ? `${(page - 1) * parseInt(filters.limit)}` : `0`,
    });
  };

  const selectedAsset = useMemo(() => {
    if (selectedAssetId) {
      return assets.find(
        (i: AssetGetAssetResponseDto) => i.id === selectedAssetId,
      );
    }

    return null;
  }, [assets, selectedAssetId]);

  return {
    getAssets,
    pageSize,
    pageNumber,
    updateFilters,
    selectedAsset,
    setSelectedAssetId,
  };
}
