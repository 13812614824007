import Card, { CardProps } from '@components/molecules/card';
import { fontSize } from '@src/globalStyle';
import { ReactNode } from 'react';
import { Row } from 'antd';

import styled from 'styled-components';
import Tooltip from '@components/atoms/tooltip';
import Icon from '@components/atoms/icon';

interface CardChartProps extends CardProps {
  title: string;
  titleTooltip?: string;
  children?: ReactNode;
  extra?: ReactNode;
}

const Title = styled.h2`
  font-family: 'Satoshi-Variable';
  font-weight: 700;
  ${fontSize.l}
  color: #000000;
  padding-top: 4px;
  padding-bottom: 8px;
`;

const InfoIcon = styled(Icon)`
  font-size: 16px;
  position: 'absolute';
`;

export default function CardChart({
  title,
  titleTooltip,
  extra,
  children,
  ...props
}: CardChartProps) {
  return (
    <Card status="white" {...props}>
      <Row
        justify={'space-between'}
        align={'middle'}
        style={{ marginBottom: 16 }}
      >
        <Title data-cypress="chart-title">
          {title}
          {!!titleTooltip && (
            <Tooltip title={titleTooltip}>
              {/* InfoIcon di Tooltip da provare */}
              {/* <InfoIcon space={!!title} /> */}
              <InfoIcon
                name="info"
                color="#7A7A7A"
                style={{
                  paddingLeft: !!title ? 8 : 0,
                }}
                data-cypress="chart-tooltip-icon"
              />
            </Tooltip>
          )}
        </Title>
        {!!extra && <Row>{extra}</Row>}
      </Row>
      {children}
    </Card>
  );
}
