import styled, { css } from 'styled-components';
import Button from '@components/atoms/button';
import Textarea from '@components/atoms/textarea';
import { SetStateAction, useCallback, useState } from 'react';
import { usePostAssistance } from '@src/api/faq';
import { useTranslation } from 'react-i18next';

const Card = styled.div`
  padding: 24px 16px 16px;
  background: #1c1c1e;
  border: 1px solid #373737;
  border-radius: 12px;
  margin-top: 16px;
`;

const commonText = css`
  font-family: 'Satoshi-Variable';
  color: #ffffff;
  display: block;
`;

const Title = styled.span`
  ${commonText}
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
`;

const Subtitle = styled(Title)`
  ${commonText}
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin: 8px 0 24px;
`;

const ConfirmButton = styled(Button)`
  margin-top: 16px;
  background-color: #fff;
  color: #373737;
  width: 100% !important;
`;

const StyledTextarea = styled(Textarea)`
  resize: none !important;
`;

export default function AssistanceCard() {
  const { t } = useTranslation()
  const [request, setRequest] = useState<string>('');
  const post = usePostAssistance();
  const onConfirm = () => {
    // todo: request
    post.mutate({ message: request });
    setRequest('');
  };
  const updateRequest = useCallback(
    (e: { target: { value: SetStateAction<string> } }) => {
      setRequest(e.target.value);
    },
    [request],
  );

  return (
    <Card>
      <Title>{t('help.noSolution')}</Title>
      <Subtitle>
        {t('help.contactedViaEmail')}
        {/*, oppure chiamaci al 800 900 888*/}
      </Subtitle>
      <StyledTextarea
        dark
        placeholder={t('help.writeMessage')}
        rows={5}
        value={request}
        onChange={updateRequest}
      />
      <ConfirmButton onClick={onConfirm}>{t('help.send')}</ConfirmButton>
    </Card>
  );
}
