import Icon from '@components/atoms/icon';
import { Form, FormItemProps, Input, Row } from 'antd';
import { useContext, useEffect, useState } from 'react';

import { ButtonLink, ButtonSubmit, Section, StyledForm, Title } from './common';
import { NotificationContext } from '@src/App';
import { Link } from 'react-router-dom';

interface SignInFormProps {
  // TODO: types
  onFinish: (values: any) => void;
  onFinishFailed: (e: any) => void;
  loginError?: string;
}

const defaultProp: FormItemProps = {
  validateStatus: '',
  help: '',
};

export default function SignInForm({
  onFinish,
  onFinishFailed,
  loginError,
}: SignInFormProps) {
  const [form] = Form.useForm();
  const [formHasError, setFormHasErrors] = useState(true);
  const [emailProp, setEmailProp] = useState<FormItemProps>(defaultProp);
  const [passwordProp, setPasswordProp] = useState<FormItemProps>(defaultProp);

  const { toast } = useContext(NotificationContext);

  useEffect(() => {
    // cognito USER_SRP_AUTH - PASSWORD_VERIFIER
    const loginErrorLabel = loginError?.toString();
    if (loginErrorLabel === 'UserNotFoundException: User does not exist.') {
      setEmailProp({
        validateStatus: 'error',
        help: 'Non abbiamo trovato nessun account con questa email. Riprova o crea un nuovo account.',
      });
    } else if (
      loginErrorLabel ===
      'NotAuthorizedException: Incorrect username or password.'
    ) {
      setPasswordProp({
        validateStatus: 'error',
        help: 'La password inserita non è corretta',
      });
    } else if (loginErrorLabel) {
      toast?.error({
        message: 'Errore inaspettato durante il login. Riprova più tardi.',
        placement: 'topRight',
      });
    }
    return () => {
      setEmailProp(defaultProp);
      setPasswordProp(defaultProp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginError]);

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setFormHasErrors(hasErrors);
  };

  return (
    <Section>
      <Title>Login</Title>
      <StyledForm
        form={form}
        onFieldsChange={handleFormChange}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        data-cypress="inputs"
      >
        <Form.Item
          // noStyle
          label="Email"
          name="email"
          rules={[
            {
              type: 'email',
              message: 'L’email inserita non è corretta',
            },
            { required: true, message: 'Email is required' },
          ]}
          {...emailProp}
        >
          <Input
            data-cypress="input-email"
            placeholder="Email"
          />
        </Form.Item>

        <Form.Item
          // noStyle
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Password is required' }]}
          {...passwordProp}
        >
          <Input.Password
            data-cypress="input-psw"
            placeholder="Password"
          />
        </Form.Item>

        <Row justify="space-between" align="middle">
          <Link to="/forgot-password">
            <ButtonLink data-cypress="forgot-psw">
              Password dimenticata?
            </ButtonLink>
          </Link>
        </Row>

        <Form.Item>
          <ButtonSubmit
            data-cypress="btn-submit"
            disabled={formHasError}
            htmlType="submit"
          >
            Accedi
          </ButtonSubmit>
        </Form.Item>
      </StyledForm>
      {/* <Row justify="center" align="middle">
        <Text style={{ marginRight: 8 }}>Non sei ancora registrato?</Text>
        <Link to="/sign-up">
          <ButtonLink>Crea un account</ButtonLink>
        </Link>
      </Row> */}
    </Section>
  );
}
