import Icon from '@components/atoms/icon';
import Modal from '@components/atoms/modal';
import Select from '@components/atoms/select';
import { fontSize } from '@src/globalStyle';
import { Space } from 'antd';
import styled, { css } from 'styled-components';

export const StyledModal = styled(Modal)<{ mobile: boolean }>`
  .ant-modal-body {
    margin-top: 36px;
  }

  ${({ mobile }) =>
    mobile &&
    css`
      width: 100vw;
      max-width: 100vw !important;
      height: 100vh;

      .ant-modal-content {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .ant-modal-body {
        flex: 1;
      }
    `}
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  font-weight: 500;
  ${fontSize.xs}
  letter-spacing: 0.03em;
  color: #919191;
`;

export const Value = styled.span`
  font-weight: 500;
  ${fontSize.m}
  color: #ffffff;
  margin-top: 4px;
`;

export const TitleField = styled.span`
  display: block;
  font-weight: 700;
  ${fontSize.l}
  color: #fff;
  padding-top: 1.7em;
  padding-bottom: 0.24375em;
`;

export const StyledSelect = styled(Select)`
  .ant-select-dropdown .ant-select-item {
    display: inline-flex;
    align-items: center;
  }
`;

export const SingleOption = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const SingleOptionIcon = styled(Icon)`
  margin-right: 10px;
  font-size: 13px;
`;

export const NewCategory = styled(Space)`
  margin: 8px 0;
`;

export const StyledSpaceRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 575px) {
    // equals xs - 1
    &#id-not-new-category {
      .cssid-modal-detail-stop-select {
        order: 1;
      }
      #id-modal-detail-stop-title {
        order: 0;
      }

      #id-modal-detail-stop-textarea {
        order: 2;
      }
    }
  }
`;
