import { useInfiniteQuery, useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import {
  GET_ASSET_ALERTS_QUERY_KEY,
  GET_ASSET_ALERTS_TABLE_QUERY_KEY,
} from '@src/api';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { fetchConfig } from '@src/config/http';
import {
  alertFiltersState,
  alertTableDefaultOrder,
  alertTableDefaultSort,
  alertTableFiltersState,
} from '@store/alert/alert-filter-state';
import { fetchAssetAlerts } from './fetch-asset-alerts';
import { selectedAssetIdState } from '@store/asset';
import { AlertSortOptions } from './types';

export function useAssetAlerts() {
  const [tenantId] = useRecoilState(selectedTenantIdState);
  const [assetId] = useRecoilState(selectedAssetIdState);
  const [filters] = useRecoilState(alertFiltersState);

  const filtersWithAssetId = {
    ...filters,
    assetIds: [assetId],
  };

  return useInfiniteQuery(
    [GET_ASSET_ALERTS_QUERY_KEY, tenantId, assetId, filters],
    async ({ pageParam = 0 }) => {
      return (await fetchAssetAlerts(tenantId, filtersWithAssetId, pageParam))
        .items;
    },
    {
      ...(fetchConfig as any),
      enabled: !!tenantId,
      getNextPageParam: (_lastPage, allPages) => {
        return allPages.length;
      },
    },
  );
}

export function useAlertsTable() {
  const [tenantId] = useRecoilState(selectedTenantIdState);
  const [filters, setFilters] = useRecoilState(alertTableFiltersState);

  const getAlertsTable = useQuery(
    [GET_ASSET_ALERTS_TABLE_QUERY_KEY, tenantId, filters],
    () => fetchAssetAlerts(tenantId, filters),
    {
      ...(fetchConfig as any),
      enabled: !!tenantId,
    },
  );

  function updateAlertsTableFilters({
    page,
    sort,
    order,
    limit,
  }: {
    page: number;
    sort?: AlertSortOptions;
    order?: 'asc' | 'desc';
    limit?: number;
  }) {
    setFilters({
      ...filters,
      skip: `${(page - 1) * Number(filters.limit)}`,
      sort: sort ?? alertTableDefaultSort,
      order: order ?? alertTableDefaultOrder,
      limit: limit?.toString() ?? filters.limit,
    });
  }

  const pageNumber = Number(filters.skip) / Number(filters.limit) + 1;
  const pageSize = Number(filters.limit);

  return {
    getAlertsTable,
    updateAlertsTableFilters,
    pageNumber,
    pageSize,
  };
}
