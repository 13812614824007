import { HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';

import {
  ButtonShape,
  ButtonSize,
  ButtonSizeValue,
  ButtonTheme,
  ButtonVariants,
  themeConfig,
} from './button.config';
import classNames from 'classnames';

export interface Props extends HTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariants;
  shape?: ButtonShape;
  shapeSize?: number;
  size?: ButtonSize;
  icon?: ReactNode;
  active?: boolean;
  centerIcon?: boolean;
  [x: string]: any; // hotfix per la prop showIcon nello storybook
}

interface StyledProps extends Props {
  enhTheme: ButtonTheme;
}

const StyledButton = styled.button<StyledProps>`
  font-family: 'Satoshi-Variable', sans-serif;
  font-size: ${({ size }) => ButtonSizeValue[size || 'm']?.fontSize}px;
  font-weight: 700;
  line-height: 24px;
  border-radius: 6px;
  cursor: pointer;
  padding: ${({ size }) => ButtonSizeValue[size || 'm'].paddingVertical}px 16px;
  color: ${({ enhTheme }) => enhTheme.default.color};
  background-color: ${({ enhTheme }) => enhTheme.default.bgColor};
  border: ${({ enhTheme }) =>
    enhTheme.hasBorder
      ? `1.5px solid ${enhTheme.default.borderColor}`
      : 'none'};
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ shape, shapeSize }) =>
    shape &&
    shapeSize &&
    css`
      width: ${shapeSize}px;
      height: ${shapeSize}px;
      padding: 0;
      margin-right: 0;
    `};

  ${({ center }) =>
    center &&
    css`
      display: inline-flex;
      justify-content: center;
      align-items: center;
    `};

  /*
    per un futura gestione di più forme..

    $ {({ variant }) => {
      switch (variant) {
        case primary: ...
        case secondary: ...
      }
    }}
  */

  &:not(:disabled):hover {
    color: ${({ enhTheme }) => enhTheme.hover.color};
    background-color: ${({ enhTheme }) => enhTheme.hover.bgColor};

    ${({ enhTheme }) =>
      enhTheme.hasBorder && enhTheme?.hover?.borderColor
        ? css`
            border-color: ${enhTheme.hover.borderColor};
          `
        : ''};
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;

    ${({ enhTheme }) =>
      enhTheme?.disabled?.bgColor
        ? css`
            background-color: ${enhTheme.disabled.bgColor};
          `
        : ''};

    ${({ enhTheme }) =>
      enhTheme?.disabled?.color
        ? css`
            color: ${enhTheme.disabled.color};
          `
        : ''};

    ${({ enhTheme }) =>
      enhTheme.hasBorder && enhTheme?.disabled?.borderColor
        ? css`
            border-color: ${enhTheme.disabled.borderColor};
          `
        : ''};
  }

  &.active {
    ${({ enhTheme }) =>
      enhTheme?.active?.bgColor
        ? css`
            background-color: ${enhTheme.active.bgColor};
          `
        : ''};

    ${({ enhTheme }) =>
      enhTheme?.active?.color
        ? css`
            color: ${enhTheme.active.color};
          `
        : ''};

    ${({ enhTheme }) =>
      enhTheme.hasBorder && enhTheme?.active?.borderColor
        ? css`
            border-color: ${enhTheme.active.borderColor};
          `
        : ''};
  }
`;

StyledButton.defaultProps = {
  variant: 'outline-black',
  size: 'm',
};

const IconButton = styled.span<{ hasMargin: boolean; center?: boolean }>`
  display: inline-flex;
  ${({ center }) => center && 'vertical-align: middle;'}
  ${({ hasMargin }) =>
    hasMargin &&
    css`
      margin-right: 10px;
    `};
`;

export default function Button({
  shape,
  shapeSize = 32,
  center,
  centerIcon,
  variant = 'outline-black',
  size = 'm',
  icon,
  active,
  ...props
}: Props) {
  return (
    <StyledButton
      className={classNames(size, active && 'active')}
      size={size}
      shape={shape}
      center={center}
      shapeSize={shapeSize}
      // enhTheme={themeConfig[variant]}
      enhTheme={themeConfig[variant] || themeConfig['outline-black']}
      {...props}
    >
      {!!icon && (
        <IconButton hasMargin={!!props.children} center={centerIcon}>
          {icon}
        </IconButton>
      )}
      {props.children}
    </StyledButton>
  );
}
