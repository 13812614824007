import { useQuery } from 'react-query';
import {
  ASSET_HEATMAP_REPORT_QUERY_KEY,
  fetchAssetHeatMapReport,
  ReportQueryParams,
} from '@src/api';
import { fetchConfig } from '@src/config/http';
import { minToMs } from '@src/utils';

export function useAssetHeatMapSingleAsset(
  tenantId: string,
  assetId: string,
  filters: ReportQueryParams,
) {
  return useQuery(
    [ASSET_HEATMAP_REPORT_QUERY_KEY, tenantId, assetId, filters],
    () => fetchAssetHeatMapReport(tenantId, assetId, filters),
    {
      ...(fetchConfig as any),
      enabled: !!tenantId && !!assetId,
      refetchInterval: minToMs(5),
    },
  );
}
