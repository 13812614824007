import { darkTheme } from '@theme/dark';
import { lightTheme } from '@theme/light';
import { ConfigProvider } from 'antd';
import styled from 'styled-components';
import TextArea, { TextAreaProps } from 'antd/es/input/TextArea';

export interface Props extends TextAreaProps {
  dark?: boolean;
}

const StyledTextArea = styled(TextArea)`
  font-weight: 500;
`;

export default function Textarea({ dark = false, ...props }: Props) {
  return (
    <ConfigProvider theme={dark ? darkTheme : lightTheme}>
      <StyledTextArea {...props} />
    </ConfigProvider>
  );
}
