import { GetReportAssetAlertResponseDto } from '@asset-manager/types';
import EmptyStateChart from '@components/atoms/empty-state/empty-state-chart';
import Select from '@components/atoms/select';
import CardChart from '@components/molecules/card/card-title-extra/card-title-extra';
import { Space, Spin } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { reportFiltersState } from '@store/report/reportFiltersState';
import dayjs from 'dayjs';
import { useGetTenantEventSubTypes } from '@src/api';
import { useTenant } from '@src/modules/asset';
import { useTranslation } from 'react-i18next';
import ChartColumn from '@components/molecules/charts/column/chart-column';
import { useAssetAlerts } from '@src/api/alert/use-asset-alerts';

export interface Props {
  data: GetReportAssetAlertResponseDto | undefined;
  dateRange: number;
}

interface SubEventType {
  key: string;
  label: string;
}

interface ConditionType {
  key?: string;
  label: string;
}

export default function CardAlertsNew({ data, dateRange }: Props) {
  const { t } = useTranslation();
  const [reportFilter, setReportFilters] = useRecoilState(reportFiltersState);
  useTenant();
  const { data: subTypes } = useGetTenantEventSubTypes();
  const { data: alerts, isLoading } = useAssetAlerts();

  // const { data: conditions } = getTenantEventConditions();

  const chartData = useMemo(() => {
    if (!data) return [];
    const values: {
      date: string;
      value: number;
      type: 'HIGH' | 'LOW';
    }[] = [];

    data?.high?.forEach(evt => {
      values.push({
        date: dayjs(evt.date).format('YYYY-MM-DD'),
        value: evt.value,
        type: 'HIGH',
      });
    });
    data?.low?.forEach(evt => {
      values.push({
        date: dayjs(evt.date).format('YYYY-MM-DD'),
        value: evt.value,
        type: 'LOW',
      });
    });

    return values;
  }, [data]);

  const changeAlertConditionFilter = useCallback(
    (value: any) => {
      console.log('value: ', value);
      setReportFilters(() => ({
        ...reportFilter,
        rules: `${value}`,
      }));
    },
    [reportFilter, setReportFilters],
  );
  const changeAlertCauseFilter = useCallback(
    (value: any) => {
      setReportFilters(() => ({
        ...reportFilter,
        subEventType: value.length > 0 ? `${value}` : undefined,
      }));
    },
    [reportFilter, setReportFilters],
  );

  const [subEventTypes, setSubEventTypes] = useState<SubEventType[]>([]);
  const [conditionTypes, setConditionTypes] = useState<ConditionType[]>([]);

  useEffect(() => {
    if (subTypes) {
      setSubEventTypes(() => [
        { value: '', label: t('general.allCauses') },
        ...((subTypes &&
          // @ts-ignore
          subTypes.items?.map(subType => ({
            value: subType.key,
            label: subType.name,
          }))) ||
          []),
      ]);
    }
  }, [subTypes]);

  useEffect(() => {
    const conditions = alerts?.pages?.flatMap(p => p) || [];
    if (conditions?.length) {
      setConditionTypes(() => [
        { value: '', label: t('general.allConditions') },
        ...(conditions
          ? conditions?.map(cond => ({ label: cond.name, value: cond.id }))
          : []),
      ]);
    }
  }, [alerts?.pages, t]);

  return (
    <CardChart
      title={t('alerts.alertsGenerated')}
      titleTooltip={t('alerts.overallAlerts')}
      style={{ marginBottom: 16 }}
      extra={
        <Space style={{ flexWrap: 'wrap' }} size={8}>
          <Select
            style={{ width: 180 }}
            size="large"
            defaultValue={reportFilter.subEventType || ''}
            options={subEventTypes}
            onChange={changeAlertCauseFilter}
            oneLiner
          />
          {isLoading ? (
            <Spin />
          ) : (
            <Select
              style={{ width: 180 }}
              size="large"
              oneLiner
              defaultValue={reportFilter.rules || ''}
              options={conditionTypes}
              onChange={changeAlertConditionFilter}
            />
          )}
        </Space>
      }
    >
      {chartData?.length ? (
        <ChartColumn
          height={278}
          autoFit
          data={chartData}
          startDate={Number(reportFilter.startDate) * 1000}
          endDate={Number(reportFilter.endDate) * 1000}
          // startDate={1705449600 * 1000}
          // endDate={1708127999 * 1000}
          legendLabelColor="#666666"
          darkSlider={false}
        />
      ) : (
        <EmptyStateChart
          style={{ height: 200 }}
          subtitle={t('stops.noStopDetected')}
        />
      )}
    </CardChart>
  );
}
