import { commonTheme } from './common';

export const lightTheme = {
  "token": {
    ...commonTheme.token,
    "colorBgContainer": "#f5f5f5",
    "colorBgLayout": "#f5f5f5",
    "colorPrimary": "#424242"
  },
  "components": {
    ...commonTheme.components,
    "Spin":{
      "colorBgContainer": "#ffffff",
      "colorPrimary": "#4786ff", //"#1677ff",
      "colorText": "rgba(0, 0, 0, 0.88)",
      "colorTextDescription": "rgba(0, 0, 0, 0.45)",
    },
    "Slider": {
      ...commonTheme.components.Slider,
      "colorBgContainer": "#ffffff",
      "colorBgElevated": "#ffffff",
      "colorBorderSecondary": "#f0f0f0",
      "colorFillContentHover": "rgba(0, 0, 0, 0.15)",
      "colorFillSecondary": "rgba(0, 0, 0, 0.06)",
      "colorFillTertiary": "#ECECEC", //"rgba(0, 0, 0, 0.04)",
      "colorPrimary": "#ffffff", //"#1677ff", // TODO: handle focus
      "colorPrimaryBorder": "#FBFBFB", //"#91caff",
      "colorPrimaryBorderHover": "#FBFBFB", //"#69b1ff",// TODO: bar focus
      "colorText": "rgba(0, 0, 0, 0.88)",
      "colorTextDescription": "rgba(0, 0, 0, 0.45)",
      "colorTextDisabled": "rgba(0, 0, 0, 0.25)",
    },
    "Cascader": {
      ...commonTheme.components.Cascader,
      "colorBgContainer": "#ECECEC", //"#ffffff",
      "colorBgContainerDisabled": "rgba(0, 0, 0, 0.04)",
      "colorBorder": "#d9d9d9",
      "colorHighlight": "#ff4d4f",
      "colorPrimary": "#1677ff",
      "colorPrimaryBorder": "#91caff",
      "colorPrimaryHover": "#4096ff",
      "colorSplit": "rgba(5, 5, 5, 0.06)",
      "colorText": "red", //"rgba(0, 0, 0, 0.88)",
      "colorTextDescription": "rgba(0, 0, 0, 0.45)",
      "colorTextDisabled": "rgba(0, 0, 0, 0.25)",
      "colorWhite": "#fff",
      "controlItemBgActive": "#E3E3E3", //"#e6f4ff",
      "controlItemBgHover": "#E3E3E3", //"rgba(0, 0, 0, 0.04)",
    },
    "Pagination": {
      ...commonTheme.components.Pagination,
      "colorBgContainer": "#E3E3E3",
      "colorText": "#373737", // rgba(0, 0, 0, 0.88)
      "colorBgTextActive": "inherit",
      "colorBorder": "#d9d9d9",
      "colorPrimary": "#373737",
      "colorPrimaryHover": "#373737",
    },
    "Select": {
      ...commonTheme.components.Select,
      "colorBorder": "#C8C8C8", //"#d9d9d9",
      "colorBgContainer": "#ECECEC",
      "colorBgElevated": "#ECECEC",
      "colorText": "#373737",
      "colorIcon": "#373737",
      "boxShadowSecondary": "0px 0px 8px rgba(0, 0, 0, 0.06)",
      "colorPrimaryHover": "#C8C8C8",
      "controlItemBgHover": "#E3E3E3",
      "controlItemBgActive": "#E3E3E3",
      "colorTextQuaternary": "#373737",
      "colorTextPlaceholder": "#7A7A7A",
    },
    "Table": {
      ...commonTheme.components.Table,
      "colorBgContainer": "transparent",//,"#ffffff",
      "colorTextHeading": "#7A7A7A",
      "colorText": "#373737"
    },
    "Menu": {
      "colorItemText": "#7A7A7A",
      "colorItemTextSelected": "#373737",
      "colorItemBgSelected": "#FFFFFF",
    },
    "Tabs": {
      ...commonTheme.components.Tabs,
      "colorPrimary": "#373737",
      "colorPrimaryActive": "#FFFFFF", // copy
      "colorBgContainer": "#FFFFFF",
      "colorPrimaryHover": "#FFFFFF", // copy
      "colorFillAlter": "#373737",
      "colorText": "#FFFFFF",
    },
    "Input": {
      ...commonTheme.components.Input,
      "colorBgContainer": "#ffffff",
      "colorBgContainerDisabled": "rgba(0, 0, 0, 0.04)",
      "colorBorder": "#d9d9d9",
      "colorError": "#ff4d4f",
      "colorErrorBorderHover": "#ffa39e",
      "colorErrorOutline": "rgba(255, 38, 5, 0.06)",
      "colorFillAlter": "rgba(0, 0, 0, 0.02)",
      "colorIcon": "rgba(0, 0, 0, 0.45)",
      "colorIconHover": "rgba(0, 0, 0, 0.88)",
      "colorPrimary": "#1677ff",
      "colorPrimaryActive": "#0958d9",
      "colorPrimaryHover": "#d9d9d9",//"transparent", //"#4096ff",
      "colorText": "rgba(0, 0, 0, 0.88)",
      "colorTextDescription": "rgba(0, 0, 0, 0.45)",
      "colorTextDisabled": "rgba(0, 0, 0, 0.25)",
      "colorTextPlaceholder": "rgba(0, 0, 0, 0.25)",
      "colorTextQuaternary": "rgba(0, 0, 0, 0.25)",
      "colorTextTertiary": "rgba(0, 0, 0, 0.45)",
      "colorWarning": "#faad14",
      "colorWarningBorderHover": "#ffd666",
      "colorWarningOutline": "rgba(255, 215, 5, 0.1)",
      "controlOutline": "rgba(5, 145, 255, 0.1)",
    },
    "Form": {
      ...commonTheme.components.Form,
      "colorText": "#373737"
    },
    "Checkbox":{
      ...commonTheme.components.Checkbox,
      "colorBgContainer": "transparent", //"#ffffff",
      "colorBgContainerDisabled": "rgba(0, 0, 0, 0.04)",
      "colorBorder": "#1C1B1F", //"#d9d9d9",
      "colorPrimary": "#1C1B1F", //"#1677ff",
      "colorPrimaryBorder": "#1C1B1F", //"#91caff",
      "colorPrimaryHover": "#E3E3E3", //"#4096ff",
      "colorText": "rgba(0, 0, 0, 0.88)",
      "colorTextDisabled": "rgba(0, 0, 0, 0.25)",
      "colorWhite": "#fff"
    },
    "Tag": {
      ...commonTheme.components.Tag,
      "colorError": "#91caff", // "#ff4d4f",
      "colorErrorBg": "#E3E3E3", // "#fff2f0",
      "colorErrorBorder": "transparent", // "#ffccc7",
      "colorSuccess": "#2972FF", // "#52c41a",
      "colorSuccessBg": "#E3E3E3", // "#f6ffed",
      "colorSuccessBorder": "transparent", // "#b7eb8f",
      "colorWarning": "#8BB2FF", // "#faad14",
      "colorWarningBg": "#E3E3E3", // "#fffbe6",
      "colorWarningBorder": "transparent" // "#ffe58f",
    },
    "TimePicker": {
      ...commonTheme.components.TimePicker
    },
  },
};
