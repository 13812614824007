import { NotificationContext } from '@src/App';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useRecoilState } from 'recoil';
import {
  GET_ASSET_ALERTS_QUERY_KEY,
  GET_ASSET_ALERTS_TABLE_QUERY_KEY,
} from '../consts';
import { useContext, useState } from 'react';
import { toggleAlert } from './toggle-alert';
import { ToggleAlertBody } from './types';

export function useToggleAlert(
  params?: UseMutationOptions<any, any, any, any>,
) {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const { toast } = useContext(NotificationContext);

  const queryClient = useQueryClient();

  const [isAlertEnabled, setIsAlertEnabled] = useState(false);

  const handleToggleAlert = async (data: { body: ToggleAlertBody }) => {
    setIsAlertEnabled(!data.body.enable);
    await toggleAlert(selectedTenantId, data.body);
  };

  return useMutation({
    mutationFn: handleToggleAlert,
    onSettled: () => {
      queryClient.invalidateQueries(GET_ASSET_ALERTS_QUERY_KEY);
      queryClient.invalidateQueries(GET_ASSET_ALERTS_TABLE_QUERY_KEY);
    },

    onSuccess: () => {
      const message = isAlertEnabled
        ? 'Alert attivato con successo'
        : 'Alert disattivato con successo';

      toast?.success({
        message,
        placement: 'topRight',
      });
    },
    onError: () => {
      const message = isAlertEnabled
        ? "Errore durante l'attivazione dell'alert"
        : "Errore durante la disattivazione dell'alert";

      toast?.error({
        message,
        placement: 'topRight',
      });
    },

    ...params,
  });
}
