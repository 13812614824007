import { atom } from 'recoil';

export interface IReportExtraFilterState {
  departmentIds?: string[];
  lineIds?: string[];
  plantIds?: string[];
  assets?: string[];
}

export const reportExtraFilterState = atom({
  key: 'reportExtraFilterState',
  default: {} as IReportExtraFilterState,
});
