import React, { SVGProps } from 'react';
import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const VibrationSvg = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      fill={props.fill}
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      width={22}
      height={22}
      viewBox="0 0 28.66 28.661"
    >
      <g>
        <polygon
          points="28.66,15.704 24.479,13.615 24.479,15.092 19.859,15.092 17.287,12.167 13.895,16.971 10.497,5.489 8.721,18.027
   6.361,11.294 4.139,15.092 0,15.092 0,16.315 4.839,16.315 6.081,14.197 9.228,23.171 10.919,11.23 13.439,19.739 17.393,14.141
   19.305,16.315 24.479,16.315 24.479,17.795 	"
        />
      </g>
    </svg>
  );
};

const VibrationIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={() => <VibrationSvg fill={props.fill} />} {...props} />
);

export default VibrationIcon;
