/*
TODO
buildOverviewList di company-preview.utils.ts e
overviewList di tenant-drawer-content.utils.tsx
andrebbero unificate
*/

export const buildOverviewList = (
  compact = true,
  uptime: any, // = 0,
  uptimeVariation = 0,
  totalConsumption: any, // = 0,
  totalConsumptionUnit = 'kWh',
  stops: number, // = 0,
  totalTimeInNoData: string,
  totalTimeInNoDataMeasure: string,
  assetsCount: number,
) => {
  // check stop is defined and is a number
  return [
    {
      title: 'general.totalConsumption',
      info: 'assets.totalConsumptionOnPeriod',
      icon: 'trending_up',
      iconColor: '#4786FF',
      value: totalConsumption ? `${totalConsumption}` : '-',
      label: totalConsumptionUnit,
    },
    // {
    //   title: 'general.noData',
    //   info: 'general.noDataInfo',
    //   value: totalTimeInNoData,
    //   label: totalTimeInNoDataMeasure,
    // },
    {
      title: 'alerts.alert',
      info: '',
      value: stops ? `${stops}` : '0',

      icon: 'emergency_home',
      iconColor: '#F6BD16',
      label: '',
      labelColor: '',
    },
    {
      title: 'assets.asset',
      info: '',
      value: assetsCount ? `${assetsCount}` : '0',
      label: '',
      labelColor: '',
    },
  ];
};
