import { atom } from 'recoil';

export interface AssetsFiltersLineState {
  label: string;
  value: string;
  children?: AssetsFiltersLineState[];
}

export const assetsFilterLineState = atom({
  key: 'assetsFilterLineState',
  default: [] as AssetsFiltersLineState[],
});
