import { useQuery } from 'react-query';
import {
  fetchTenantReport,
  ReportQueryParams,
  TENANT_REPORT_QUERY_KEY,
} from '@src/api';
import { fetchConfig } from '@src/config/http';
import { minToMs } from '@src/utils';
import { IReportExtraFilterState } from '@store/report/report-extra-filter-state';

export function useTenantReport(
  id: string,
  filters: ReportQueryParams & IReportExtraFilterState,
) {
  return useQuery(
    [TENANT_REPORT_QUERY_KEY, id, filters],
    () => fetchTenantReport(id, filters),
    {
      ...(fetchConfig as any),
      enabled: !!id,
      refetchInterval: minToMs(5),
    },
  );
}
