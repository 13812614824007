import { API } from 'aws-amplify';
import { ASSET_API } from '@src/api';

export async function fetchAssetSensors(tenantId?: string, assetId?: string) {
  if (tenantId && assetId) {
    return await API.get(
      ASSET_API,
      `/tenant/${tenantId}/${assetId}/sensors`,
      {},
    );
  }

  return [];
}
