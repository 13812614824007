// import { getRandomArbitrary } from '@src/routes/Help/help.mock';
// l'alias non funziona se veine richiamato qualcosa dallo storybook
import { getRandomArbitrary } from '@src/routes/Help/help.mock';

import { extractFormattedMinutesBetween, lastDays } from '../utils';
import { t, t2 } from './heatmap.mock';
import { capitalizeFirstLetter } from '@src/utils/string.utils';
import {
  consumptionColorMapping,
  consumptionMapping,
} from '@src/utils/mapping.utils';

export const data2 = [
  ...t,
  ...t2,
  ...t.map(i => ({ ...i, Name: i.Name + ' - 2' })),
  ...t.map(i => ({ ...i, Name: i.Name + ' - 3' })),
  ...t.map(i => ({ ...i, Name: i.Name + ' - 4' })),
  ...t.map(i => ({ ...i, Name: i.Name + ' - 5' })),
  ...t.map(i => ({ ...i, Name: i.Name + ' - 6' })),
  ...t.map(i => ({ ...i, Name: i.Name + ' - 7' })),
];

export const data3 = [];

export const mapTempHeatmap = [
  { label: 'Normal', color: '#18CD4B', min: 700, max: 1000 },
  { label: 'Warning', color: '#F6BD16', min: 300, max: 699 },
  { label: 'Alarm', color: '#E8684A', min: 0, max: 299 },
  { label: 'No Data', color: '#ececec', min: -1, max: 0 },
];

const startTimestamp = '2022-03-16T00:00:00';
const endTimestamp = '2022-03-17T00:00:00';
const endTimestamp2 = '2022-03-16T08:00:00';

export const dataTestFormatted = (
  assets: number,
  isFullDay: boolean,
  step?: number,
  name?: string,
) => {
  const lastDays2 = lastDays(assets);

  return Array(assets)
    .fill(null)
    .map((_, index) =>
      extractFormattedMinutesBetween(
        startTimestamp,
        isFullDay ? endTimestamp : endTimestamp2,
        step,
      ).map(item => {
        let v = 0;
        const l1 = 0.94;
        const l2 = 0.98;
        const randomNumber = Math.random();
        if (randomNumber < l1) {
          v = getRandomArbitrary(300, 1000);
        }
        if (randomNumber >= l1 && randomNumber < l2) {
          v = getRandomArbitrary(101, 299);
        }
        if (randomNumber >= l2) {
          v = getRandomArbitrary(0, 100);
        }

        let nameFormatted = '';
        if (name === 'date') {
          nameFormatted = lastDays2[index];
        } else {
          nameFormatted = 'Nome Asset ' + index;
        }

        return {
          Time: item,
          Name: nameFormatted,
          Value: Math.floor(v),
        };
      }),
    )
    .flat();
};

export const dataSquareTestFormatted = (x: number, y: number) => {
  return Array(x)
    .fill(null)
    .map((_, indexY) =>
      Array(y)
        .fill(indexY)
        .map((item, indexX) => {
          return {
            Name: 'Asset ' + indexX,
            Time: String(indexY + 1), // 1-31
            Value: Math.floor(getRandomArbitrary(0, 100)),
          };
        }),
    )
    .flat();
};
