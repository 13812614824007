import { Area, AreaConfig } from '@ant-design/plots';
import Slider from '@components/atoms/slider';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { CommonChart } from '../common.interface';
import { dataMapping, plotComponents } from './chart-area.config';

import dayjs from 'dayjs';
import { formatDateOnRangePicker } from '@src/utils';
import { debounce } from 'lodash';
import {
  GetReportAssetPointResponseDto,
  ReportUpdateThresholdItemDto,
} from '@asset-manager/types';

dayjs.extend(require('dayjs/plugin/utc'));
// import { extractMinutesBetween } from './utils';

interface Props extends CommonChart, Partial<AreaConfig> {
  unit?: string;
  dateRange: number;
  thresholds?: ReportUpdateThresholdItemDto[];
  assetId?: string;
}

const ChartArea = ({ dark, ...props }: Props) => {
  const [minMax, setMinMax] = useState<[number, number]>([
    0,
    props?.data?.length ? props?.data?.length - 1 : 0,
  ]);
  const [range, setRange] = useState([0, minMax[1]]);

  const config = useCallback(
    (dark: boolean): AreaConfig | any => ({
      ...dataMapping,
      ...plotComponents(dark, props.unit),
      ...(props?.thresholds?.length
        ? {
            color: (data: GetReportAssetPointResponseDto) => {
              const status = data?.status?.split('_')[0];
              const sortedThresholds = [...(props?.thresholds || [])];
              sortedThresholds.sort((a, b) => b.value - a.value);
              const foundThreshold = sortedThresholds.find(
                threshold => threshold.id === status,
              );
              return foundThreshold?.hexColor || '#e6e6e6';
            },
          }
        : { color: () => 'lightblue' }),
      animation: false,
    }),
    [props.unit, props?.thresholds],
  );

  const areaData = useMemo(
    () => props?.data?.filter((r, i) => i >= range[0] && i <= range[1]),
    [props?.data, range],
  );

  const sliderFormatter = useCallback(
    (value?: number): ReactNode => {
      try {
        if (value === undefined || value === null) return value;
        const data = (props as any).data[value];

        if (!data) return value;
        return formatDateOnRangePicker(data.time, props.dateRange);
      } catch (e) {
        return value;
      }
    },
    [props],
  );

  useEffect(() => {
    const max = props?.data?.length ? props?.data?.length - 1 : 0;
    setMinMax([0, max]);
    setRange([0, max]);
  }, [props?.data?.length]);

  const debounceChange = useMemo(() => debounce(setRange, 300), []);

  return (
    <>
      <Area
        key={`${minMax[1]}_${props?.data?.[0]?.time}`}
        className="cssid--area"
        renderer="svg"
        {...config(!!dark)}
        slider={false}
        {...props}
        data={areaData}
      />
      <div style={{ marginLeft: 16 }}>
        <Slider
          dark
          key={`${minMax[1]}_${props?.data?.[0]?.time}`}
          tooltip={{
            formatter: sliderFormatter,
          }}
          range={{ draggableTrack: true }}
          min={minMax[0]}
          max={minMax[1]}
          defaultValue={[0, props.data ? minMax[1] : 0]}
          onChange={debounceChange}
          style={{ marginTop: 16 }}
        />
      </div>
    </>
  );
};

export default ChartArea;
