import { Form, Input, Row } from 'antd';
import AuthPage from './AuthPage';
import {
  Subtitle,
  Title,
  Text,
  ButtonLink,
  Section,
  ButtonSubmit,
  StyledForm,
} from './common';

export default function CodeConfirmationPage() {
  return (
    <AuthPage>
      <Section>
        <Title>Codice di verifica</Title>
        <Subtitle>Inserisci il codice che hai ricevuto per email</Subtitle>
        <StyledForm
          name="basic"
          initialValues={{ remember: true }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            // noStyle
            label="Code"
            name="code"
            rules={[{ required: true, message: 'Code is required' }]}
            style={{ marginBottom: 0 }}
          >
            <Input placeholder="Codice a 6 cifre" />
          </Form.Item>

          <Form.Item style={{ width: '100%' }}>
            <ButtonSubmit dark variant="primary" htmlType="submit">
              Accedi
            </ButtonSubmit>
          </Form.Item>
        </StyledForm>
        <Row justify="center" align="middle">
          <Text style={{ marginRight: 8 }}>Non hai ricevuto l'email?</Text>
          <ButtonLink>Invia di nuovo</ButtonLink>
        </Row>
      </Section>
    </AuthPage>
  );
}
