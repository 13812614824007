// noinspection com.haulmont.rcb.ArrayToJSXMapInspection

import { Col, Row as RowAntd } from 'antd';

import styled from 'styled-components';
import Info, { InfoProps } from '@components/molecules/info';
import { ReactNode, useMemo } from 'react';
import { OverviewListItem } from '@components/organisms/overview';
import Card from '../card/card';
import { fontSize } from '@src/globalStyle';
import Tag, { TagType } from '@components/atoms/tag';
import { CardType } from '../card';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export interface Props {
  title: string;
  statusTag?: TagType;
  labelTag?: string;
  subtitle?: string | ReactNode;
  list?: OverviewListItem[];
  status?: CardType;
  infoDirection?: InfoProps['displayMode'];
  additionalInfo?: Array<{ title: string; value: string }>;
  chosenMapStatus?: any;
}

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

// Typography.Title level={5}
const Title = styled.h3`
  font-weight: 700;
  ${fontSize.m}
  color: white;
`;

// Typography.Text
const Text = styled.span`
  ${fontSize.xs};
  font-weight: 500;
  color: white;
  min-height: 16px;
  display: block;
`;

const Row = styled(RowAntd)`
  margin-top: 10px;
`;

const OverviewInfo = (props: { title: string; value: string }) => {
  return (
    <>
      <Col span={12}>
        <Text
          style={{
            // bold font
            fontWeight: 700,
            color: '#919191',
          }}
        >
          {props.title}
        </Text>
      </Col>
      <Col span={12}>
        <Text>{props.value}</Text>
      </Col>
    </>
  );
};

export default function CardOverview({
  title,
  subtitle,
  statusTag,
  labelTag,
  list,
  status,
  infoDirection,
  additionalInfo,
  chosenMapStatus,
}: Props) {
  const { t } = useTranslation();
  const listElement = useMemo(
    () =>
      (list ?? []).map((item, index) => (
        <Col key={index} span={24}>
          <Info
            displayMode={infoDirection}
            title={item.title}
            // info={item.info}
            icon={item.icon}
            iconColor={item.iconColor}
            value={item.value}
            label={item.label}
            labelColor={item.labelColor}
            contentSmall
          />
        </Col>
      )),
    [infoDirection, list],
  );

  return (
    <Card status={status} style={{ height: '100%' }}>
      <Container>
        <Header>
          <Title>{title}</Title>
          {(!!statusTag || !!labelTag) && (
            <Tag
              withBrighterForeground
              label={labelTag}
              status={statusTag}
              chosenMapStatus={chosenMapStatus}
            />
          )}
        </Header>
        {labelTag === 'OFF' && (
          <Text style={{ color: 'white' }}>
            {t('sensor.disconnectedSensor')}{' '}
            <Link
              to="/help"
              style={{
                color: 'white',
                textDecoration: 'underline',
                fontWeight: 700,
              }}
            >
              {t('general.contactUs')}
            </Link>
          </Text>
        )}

        <Text>{subtitle}</Text>

        {list && <Row gutter={[0, 8]}>{listElement}</Row>}
        {additionalInfo && additionalInfo.length > 0 && (
          <Row gutter={[0, 8]}>
            {additionalInfo.map((item, index) => (
              <OverviewInfo key={index} title={item.title} value={item.value} />
            ))}
          </Row>
        )}
      </Container>
    </Card>
  );
}
