import { useQuery } from 'react-query';
import { fetchTenants } from '@src/api';
import { GET_TENANTS_QUERY_KEY } from '@src/api';
import { fetchConfig } from '@src/config/http';
import { useRecoilState } from 'recoil';
import { isAuthenticatedState } from '@asset-manager/amplify';

export function useGetTenants() {
  const [isAuthenticated] = useRecoilState(isAuthenticatedState);
  return useQuery(GET_TENANTS_QUERY_KEY, fetchTenants, {
    ...(fetchConfig as any),
    enabled: !!isAuthenticated,
  });
}
