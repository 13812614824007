import { darkTheme } from '@theme/dark';
import { lightTheme } from '@theme/light';
import { ConfigProvider, Slider as SliderAntd, SliderSingleProps } from 'antd';
import { SliderRangeProps } from 'antd/es/slider';
import styled from 'styled-components';
import { HTMLAttributes, useRef } from 'react';

type SliderType = (SliderSingleProps | SliderRangeProps) &
  React.RefAttributes<unknown>;

export type Props = SliderType & {
  dark?: boolean;
  containerProps?: HTMLAttributes<HTMLDivElement>;
};

const Styledlider = styled(SliderAntd)<{ dark: Props['dark'] }>`
  margin: 0;
  &.ant-slider-horizontal {
    transform: translateY(50%);
  }
`;

const Container = styled.div<{ dark: Props['dark'] }>`
  background-color: ${props => (props.dark ? '#373737' : '#E3E3E3')};
  padding-inline: 28px;
  height: 24px;
  border-radius: 4px;
`;

export default function Slider({
  dark = false,
  containerProps,
  ...props
}: Props) {
  const ref = useRef(null);
  return (
    <ConfigProvider theme={dark ? darkTheme : lightTheme}>
      <Container dark={dark} {...containerProps} data-cypress="ant-slider">
        <Styledlider dark={dark} {...props} ref={ref} />
      </Container>
    </ConfigProvider>
  );
}
