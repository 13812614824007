export function filterUnique(array: any, property: string) {
  const seen: any = {};
  return array.filter((item: { [x: string]: any }) => {
    const value = item[property];
    if (seen[value]) {
      return false;
    }
    seen[value] = true;
    return true;
  });
}
