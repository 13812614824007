import Cascader from '@components/atoms/cascader';
import EmptyStateChart from '@components/atoms/empty-state/empty-state-chart';
import { TimeFrame } from '@components/atoms/select/select';
import CardChart from '@components/molecules/card/card-title-extra';
import { HeatmapConfigType } from '@components/molecules/charts/heatmap';
import { ConfigType } from '@components/molecules/charts/heatmap/heatmap';
import Header from '@components/organisms/header';
import { useTimeframeSelector } from '@hooks';
import { useTenant } from '@src/modules/asset/hooks/useTenant';
import { useTenantReport } from '@src/modules/asset/hooks/useTenantReport';
import CardAssetChart from '@src/routes/Report/components/card-asset-chart';
import { stopPropagationHelper } from '@src/utils';
import {
  assetFilterPlant,
  AssetFilterPlantOptions,
} from '@store/asset/assetFilterPlantOptions';
import { reportFiltersState } from '@store/report/reportFiltersState';
import { lightTheme } from '@theme/light';
import { Col, ConfigProvider, Row, Spin } from 'antd';
import { differenceInDays } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { Loader } from '../Assets/components/drawer-contents/common';
import { filterCascaderReportState } from '@store/filters/filter-state-report';
import { reportExtraFilterStructure } from '@store/report/report-extra-filter-structure';
import useReportExtraFilters from '@src/api/report/use-report-extra-filters';
import PieChart from '@components/molecules/charts/pie';
import { useTranslation } from 'react-i18next';
import CardAlertsNew from './components/card-alerts-new';
import { GetReportAssetAlertResponseDto } from '@asset-manager/types';
import {
  InputWrapper,
  LabelWrapper,
  StyledSelect,
  WrapDateTimeFrameSelect,
} from './components/helpers';
import DateTimeFrameSelect from '@components/atoms/date-time-frame-select';
import { useGetAssets } from '@src/api';
import dayjs from 'dayjs';
import ChartLineConsumption from './components/chart-line-consumption';

dayjs.extend(require('dayjs/plugin/utc'));

export interface HeathMapDataTooltip {
  prop: string;
  value: string | number;
  consumption?: any;
}

export interface HeathMapData {
  Time: string;
  TimeHHMM?: string;
  TimeDate?: string;
  Name: string;
  Value: number | string;
  Tooltips: HeathMapDataTooltip[];
}

type DataPoint = {
  date: string;
  value: number;
};

type DataPoint2 = {
  date: string;
  count: number;
};

const ColLeft = styled(Col)`
  @media (max-width: 1023px) {
    margin-bottom: 16px;
  }
`;

const RowFilter = styled(Row)`
  gap: 8px;
  margin-bottom: 24px;
`;

function mergeDataPoints(
  data1: DataPoint[],
  data2: DataPoint[],
): [DataPoint[], DataPoint2[]] {
  const allDates = new Set<string>([
    ...data1.map(p => p.date),
    ...data2.map(p => p.date),
  ]);
  const mergedData1: DataPoint[] = [];
  const mergedData2: DataPoint2[] = [];
  let lastValue1 = 0;
  let lastValue2 = 0;
  for (const date of Array.from(allDates).sort()) {
    const point1 = data1.find(p => p.date === date);
    const point2 = data2.find(p => p.date === date);
    if (point1) {
      mergedData1.push({ date, value: point1.value });
      lastValue1 = point1.value;
    } else {
      mergedData1.push({ date, value: lastValue1 });
    }
    if (point2) {
      mergedData2.push({ date, count: point2.value });
      lastValue2 = point2.value;
    } else {
      mergedData2.push({ date, count: lastValue2 });
    }
  }
  return [mergedData1, mergedData2];
}

// page constants
const am8 = 0; // 08:00
const pm6 = 100; // 18:00

export default function Report() {
  const { t } = useTranslation();
  const [timeFrame, setTimeFrame] = useState<TimeFrame>(TimeFrame.today);

  const [assetFilters, setAssetFilters] =
    useRecoilState<AssetFilterPlantOptions>(assetFilterPlant);

  const updateDefaultCascader = useCallback((): [string, string][] => {
    const result: [string, string][] = [];
    for (const val of assetFilters.assets || []) {
      result.push(['assets', val]);
    }
    for (const val of assetFilters.lines || []) {
      result.push(['lines', val]);
    }
    for (const val of assetFilters.departments || []) {
      result.push(['department', val]);
    }
    for (const val of assetFilters.plants || []) {
      result.push(['plants', val]);
    }
    for (const val of assetFilters.types || []) {
      result.push(['type', val]);
    }

    return result;
  }, [
    assetFilters.assets,
    assetFilters.departments,
    assetFilters.lines,
    assetFilters.plants,
    assetFilters.types,
  ]);

  const [defaultCascader, setDefaultCascader] = useState<[string, string][]>(
    updateDefaultCascader(),
  );

  useEffect(() => {
    setDefaultCascader(() => updateDefaultCascader());
  }, [assetFilters, updateDefaultCascader]);

  const [reportFilter, setReportFilters] = useRecoilState(reportFiltersState);

  const dateRange = differenceInDays(
    Number(reportFilter.endDate) * 1000,
    Number(reportFilter.startDate) * 1000,
  );

  // retrieve data
  const { selectedTimeFrame } = useTimeframeSelector();

  useEffect(() => {
    setTimeFrame(selectedTimeFrame);
  }, [selectedTimeFrame]);

  const { tenant, selectedTenantId } = useTenant();

  const [cascaderOptions] = useRecoilState(reportExtraFilterStructure);

  const { reportExtraFilter, updateReportsExtraFilters } =
    useReportExtraFilters();

  const { tenantReport, isReportLoading } = useTenantReport();
  const { data: assetsData } = useGetAssets(selectedTenantId);

  const [consumption, setConsumption] = useState<any[]>([]);
  const [heatMapData, setHeatMapData] = useState<HeathMapData[]>([]);
  const [heatMapType, setHeatMapType] = useState<ConfigType>(ConfigType.BAR);
  // const [stopChart, setStopChart] = useState<any[]>(tenantReport?.stops || []);
  const [alertChart, setAlertChart] = useState<GetReportAssetAlertResponseDto>(
    (tenantReport as any)?.alertTemp || undefined,
  );

  const [assetTypes, setAssetTypes] =
    useState<{ value: string; label: string }[]>();
  const [filterCascader, setFilterCascader] = useRecoilState(
    filterCascaderReportState,
  );

  useEffect(() => {
    if (assetsData?.filters?.types) {
      const assetTypes = [{ value: '', label: t('general.all') as string }];
      assetsData?.filters?.types?.forEach((assetType: any) => {
        assetTypes.push({ value: assetType, label: assetType });
      });
      setAssetTypes(assetTypes);
    }
  }, [assetsData, t]);

  useEffect(() => {
    if (!tenantReport?.consumption) return;
  }, [tenantReport, dateRange]);

  useEffect(() => {
    // setStopChart(tenantReport?.stops || []);
    setAlertChart((tenantReport as any)?.alertTemp || undefined);

    if ((tenantReport as any)?.heatMapPoints) {
      setHeatMapType(dateRange < 1 ? ConfigType.BAR : ConfigType.SQUARE);
      setHeatMapData((tenantReport as any).heatMapPoints);
    }
    if (tenantReport?.consumption) {
      setConsumption(
        tenantReport.consumption.map(i => ({
          ...i,
          count: i.value,
        })),
      );
    }
  }, [dateRange, tenantReport]);

  const efficiencyData = useMemo(
    () => mergeDataPoints(tenantReport?.uptime || [], consumption || []),
    [consumption, tenantReport?.uptime],
  );

  const resetHeatMapData = useCallback(() => {
    setHeatMapData([]);
  }, []);

  const resetCascader = useCallback(() => {
    setAssetFilters({});
    setDefaultCascader([]);
    setFilterCascader({});
  }, [setAssetFilters]);

  const confirmCascader = useCallback(
    (value: Record<string, string[]>) => {
      setHeatMapData([]);
      setAssetFilters(filters => ({
        assets: value.assets || undefined,
        departments: value?.department || undefined,
        lines: value?.lines || undefined,
        plants: value?.plants || undefined,
        types: value?.type || undefined,
      }));
    },
    [setAssetFilters],
  );

  const clearCascader = useCallback(() => {
    setHeatMapData([]);
    setAssetFilters(filters => ({}));
  }, [setAssetFilters]);

  const pieData = useMemo(() => {
    const d = [];
    let total = 0;
    tenantReport?.uptime?.forEach(el => {
      total += el.value;
    });

    const totalUp = +(total / (tenantReport?.uptime?.length ?? 1)).toFixed(0);

    d.push(
      {
        type: t('report.healthy'),
        value: totalUp,
        color: '#E8684A',
      },
      {
        type: t('report.notHealthy'),
        value: 100 - totalUp,
        color: '#18CD4B',
      },
    );

    return d;
  }, [t, tenantReport?.uptime]);

  const onChangeAssetType = useCallback(
    (val: any) => {
      setReportFilters(() => ({
        ...reportFilter,
        types: val,
      }));
    },
    [reportFilter, setReportFilters],
  );

  return (
    <ConfigProvider theme={lightTheme}>
      <div>
        <Header title={t('report.report')} />
        <Spin size={'large'} spinning={isReportLoading}>
          <>
            <RowFilter>
              <WrapDateTimeFrameSelect onClick={stopPropagationHelper}>
                <DateTimeFrameSelect />
              </WrapDateTimeFrameSelect>
              <InputWrapper>
                <LabelWrapper>
                  {(t('report.assetType') as string).toUpperCase()}
                </LabelWrapper>
                <StyledSelect
                  style={{
                    width: 180,
                    marginRight: 0,
                    paddingLeft: 5,
                  }}
                  size="small"
                  defaultValue={reportFilter.types}
                  options={assetTypes}
                  onChange={onChangeAssetType}
                />
              </InputWrapper>

              <Cascader
                filter={filterCascader}
                setFilter={setFilterCascader}
                onReset={() => updateReportsExtraFilters({})}
                options={cascaderOptions}
                onConfirm={updateReportsExtraFilters}
                onClear={() => updateReportsExtraFilters({})}
              />
            </RowFilter>
            {/* Improve reload without using heatMapData?.length */}
            {isReportLoading && !efficiencyData?.length ? (
              <Loader>
                <Spin size="large" />
              </Loader>
            ) : (
              <Row gutter={16}>
                <ColLeft xs={24} lg={tenant?.type !== 'GENERIC' ? 12 : 8}>
                  {tenant?.type !== 'GENERIC' && (
                    <CardChart
                      title={t('general.totalConsumption')}
                      titleTooltip={t('report.consumption')}
                      style={{ marginBottom: 16, paddingTop: 24 }}
                      data-cypress="efficienza-chart"
                    >
                      {!consumption?.length ? (
                        <EmptyStateChart style={{ height: 200 }} />
                      ) : (
                        <ChartLineConsumption
                          min={0}
                          max={consumption?.length - 1}
                          data={consumption}
                          dateRange={dateRange}
                          unitOfMeasure={
                            (tenantReport as any)?.consumptionUnitOfMeasure ||
                            ''
                          }
                        />
                      )}
                    </CardChart>
                  )}

                  {tenant?.type === 'GENERIC' && (
                    <>
                      <CardChart
                        title={t('general.state')}
                        titleTooltip={t('general.deviceStatus')}
                        style={{ marginBottom: 16, paddingTop: 24 }}
                        data-cypress="stato-chart"
                      >
                        {efficiencyData.length &&
                        efficiencyData[0].length - 1 >= 0 ? (
                          <PieChart height={278} autoFit data={pieData} />
                        ) : (
                          <EmptyStateChart style={{ height: 200 }} />
                        )}
                      </CardChart>
                    </>
                  )}

                  {heatMapType !== HeatmapConfigType.BAR && (
                    <CardAssetChart
                      data={heatMapData || []}
                      defaultValue={[am8, pm6]}
                      chartType={heatMapType}
                      timeFrame={timeFrame}
                    />
                  )}
                </ColLeft>
                <Col xs={24} lg={12} data-cypress="alerts-chart">
                  <CardAlertsNew data={alertChart} dateRange={dateRange} />
                </Col>
                {/*tenant?.type !== 'GENERIC' && (
                  <Col xs={24} lg={12} data-cypress="fermi-chart">
                    <CardStops data={stopChart} dateRange={dateRange} />
                  </Col>
                )*/}
                {/* tenant?.type === 'GENERIC' && (
                  <Col xs={24} lg={16} data-cypress="alerts-chart">
                    <CardAlerts data={alertChart} dateRange={dateRange} />
                  </Col>
                ) */}
              </Row>
            )}
          </>
        </Spin>
      </div>
    </ConfigProvider>
  );
}
