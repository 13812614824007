import { Form, FormInstance, Input } from 'antd';
import {
  durationSelect,
  FormFields,
  ruleConditions,
  ruleConditionsOptions,
  ruleConditionValueUnit,
  ruleConjunctionsOptions,
  ruleStatusValues,
  ruleStatusValuesOptions,
  statusSelectOptions,
} from './modal-add-alert.types';
import Button from '@components/atoms/button';
import {
  Divider,
  FormErrorList,
  LabelCol,
  OperandsSelect,
  StyledFormItem,
  StyledRadioGroup,
  StyledRow,
} from './modal-add-alert.components';
import Icon from '@components/atoms/icon';
import { useWatch } from 'antd/es/form/Form';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { ReportUpdateThresholdItemDto } from '@asset-manager/types';

const ConditionControlContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`;

const Rules = ({
  form,
  initialValues,
  thresholds,
}: {
  form: FormInstance<FormFields>;
  initialValues?: Partial<FormFields>;
  thresholds?: ReportUpdateThresholdItemDto[];
}) => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 1024;

  const rules = useWatch('rules', form);

  const [showThresholds, setShowThresholds] = useState(false);

  useEffect(() => {
    if (thresholds?.length) setShowThresholds(true);
    else setShowThresholds(false);
  }, [thresholds]);

  return (
    <>
      <Form.List
        name="rules"
        rules={[
          {
            validator: async (_, intervals) => {
              if (!intervals || intervals.length < 1) {
                return Promise.reject(
                  new Error('Inserisci almeno un intervallo'),
                );
              }
              return Promise.resolve();
            },
          },
        ]}
        initialValue={initialValues?.rules}
      >
        {(fields, { add, remove }) => (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {fields.map(({ name, key, ...field }, index) => (
              <div key={key}>
                {index !== 0 && (
                  <StyledRow isMobile={isMobile} paddingTop>
                    {index === 1 && (
                      <Form.Item name={[name, 'ruleConjunction']}>
                        <OperandsSelect
                          size="large"
                          popupClassName={'custom-dropdown'}
                          options={ruleConjunctionsOptions.map(r => ({
                            value: r.value,
                            label: r.label(),
                          }))}
                        />
                      </Form.Item>
                    )}

                    {index !== 1 && (
                      <p style={{ margin: 0, padding: 0, color: '#fff' }}>
                        {ruleConjunctionsOptions
                          .find(r => r.value === rules[1].ruleConjunction)
                          ?.label()}
                      </p>
                    )}

                    <ConditionControlContainer>
                      {!isMobile && <Divider style={{ margin: 0 }} />}

                      <Button
                        type="button"
                        style={{
                          color: '#fff',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'left',
                          width: '100%',
                          marginBlock: 8,
                          paddingInline: 0,
                        }}
                        icon={<Icon name="delete" style={{ fontSize: 16 }} />}
                        variant="ghost-black"
                        onClick={() => remove(index)}
                      >
                        {t('modal.deleteCondition')}
                      </Button>
                    </ConditionControlContainer>
                  </StyledRow>
                )}
                <StyledRow isMobile={isMobile} paddingTop>
                  <LabelCol>{t('general.hasValueOf')}</LabelCol>
                  <Form.Item
                    name={[name, 'ruleStatusValue']}
                    initialValue={ruleStatusValuesOptions[0].value}
                  >
                    <OperandsSelect
                      size="large"
                      options={ruleStatusValuesOptions}
                      popupClassName={'custom-dropdown'}
                    />
                  </Form.Item>
                </StyledRow>
                {showThresholds &&
                rules?.[index].ruleStatusValue ===
                  ruleStatusValues.CONSUMPTION ? (
                  <StyledRow isMobile={isMobile} paddingTop>
                    <Form.Item
                      name={[name, 'ruleCondition']}
                      initialValue={ruleConditionsOptions[0].value}
                    >
                      <OperandsSelect
                        size="large"
                        popupClassName={'custom-dropdown'}
                        options={ruleConditionsOptions.map(cond => ({
                          label: t(cond.label),
                          value: cond.value,
                        }))}
                      />
                    </Form.Item>
                    {rules?.at(index)?.ruleCondition ===
                    ruleConditions.THRESHOLD ? (
                      <Form.Item name={[name, 'ruleConditionValue']}>
                        <StyledRadioGroup
                          options={thresholds?.map(e => ({
                            label: e?.label || '',
                            value: e?.id || '',
                          }))}
                          optionType="button"
                          buttonStyle="solid"
                          size="large"
                        />
                      </Form.Item>
                    ) : (
                      <div style={{ display: 'flex' }}>
                        <StyledFormItem
                          name={[name, 'ruleConditionValue']}
                          rules={[
                            {
                              required: true,
                              message: t('modal.enterCondition'),
                            },
                          ]}
                        >
                          <Input
                            size="large"
                            type="number"
                            style={{ maxWidth: 90, marginRight: 16 }}
                            min={0}
                            placeholder="0"
                          />
                        </StyledFormItem>
                        <LabelCol>
                          {
                            ruleConditionValueUnit[
                              (rules?.[index]
                                .ruleStatusValue as ruleStatusValues) ??
                                ruleStatusValues.CONSUMPTION
                            ]
                          }
                        </LabelCol>
                      </div>
                    )}
                  </StyledRow>
                ) : (
                  <StyledRow isMobile={isMobile} paddingTop>
                    <Form.Item
                      name={[name, 'ruleCondition']}
                      initialValue={
                        ruleConditionsOptions.filter(
                          e =>
                            !showThresholds &&
                            e.value !== ruleConditions.THRESHOLD,
                        )[0].value
                      }
                    >
                      <OperandsSelect
                        size="large"
                        popupClassName={'custom-dropdown'}
                        options={ruleConditionsOptions
                          .filter(
                            e =>
                              !showThresholds &&
                              e.value !== ruleConditions.THRESHOLD,
                          )
                          .map(cond => ({
                            label: t(cond.label),
                            value: cond.value,
                          }))}
                      />
                    </Form.Item>
                    <div style={{ display: 'flex' }}>
                      <StyledFormItem
                        name={[name, 'ruleConditionValue']}
                        rules={[
                          {
                            required: true,
                            message: t('modal.enterCondition'),
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          type="number"
                          style={{ maxWidth: 90, marginRight: 16 }}
                          min={0}
                          placeholder="0"
                        />
                      </StyledFormItem>
                      <LabelCol>
                        {
                          ruleConditionValueUnit[
                            (rules?.[index]
                              .ruleStatusValue as ruleStatusValues) ??
                              ruleStatusValues.CONSUMPTION
                          ]
                        }
                      </LabelCol>
                    </div>
                  </StyledRow>
                )}
              </div>
            ))}

            <FormErrorList errors={form.getFieldError('rules')} />

            <StyledRow isMobile={isMobile} paddingTop>
              <div />
              <ConditionControlContainer>
                {!isMobile && <Divider style={{ margin: 0 }} />}

                <Button
                  type="button"
                  style={{
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    width: '100%',
                    marginBlock: 8,
                    paddingInline: 0,
                  }}
                  icon={<Icon name="add_circle" style={{ fontSize: 16 }} />}
                  variant="ghost-black"
                  onClick={() =>
                    add({
                      ruleStatus: statusSelectOptions.HAS_VALUE,
                      ruleCondition: showThresholds
                        ? ruleConditionsOptions[0].value
                        : ruleConditionsOptions.filter(
                            e =>
                              !showThresholds &&
                              e.value !== ruleConditions.THRESHOLD,
                          )[0].value,
                      ruleConjunction: ruleConjunctionsOptions[0].value,
                      ruleStatusValue: ruleStatusValues.CONSUMPTION,
                    })
                  }
                >
                  {t('modal.addCondition')}
                </Button>
              </ConditionControlContainer>
            </StyledRow>
          </div>
        )}
      </Form.List>
      <StyledRow isMobile={isMobile} paddingTop>
        <Form.Item
          name="durationSelect"
          initialValue={initialValues?.durationSelect}
        >
          <OperandsSelect
            size="large"
            options={durationSelect()}
            popupClassName={'custom-dropdown'}
          />
        </Form.Item>
        <div style={{ display: 'flex' }}>
          <StyledFormItem
            name="duration"
            rules={[
              {
                required: true,
                message: t('modal.enterDuration'),
              },
              {
                validator: async (_, value) => {
                  if (Number(value) < 1) {
                    return Promise.reject(
                      new Error(t('modal.enterBiggerDurationOfZero')),
                    );
                  }
                },
              },
            ]}
          >
            <Input
              size="large"
              type="number"
              style={{ maxWidth: 90, marginRight: 16 }}
              min={0}
              placeholder="0"
            />
          </StyledFormItem>
          <LabelCol>{t('general.minutes')}</LabelCol>
        </div>
      </StyledRow>
    </>
  );
};

export default Rules;
