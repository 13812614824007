import { API } from 'aws-amplify';
import { REPORT_API } from '@src/api';
import { GeneratedEventsByAssetQueryParams } from './types';
import { GetReportAssetAlertResponseDto } from '@asset-manager/types';

export async function fetchGeneratedEventsReport(
  tenantId: string,
  assetId: string,
  filters: GeneratedEventsByAssetQueryParams,
) {
  const qs = `?${new URLSearchParams(filters).toString()}`;
  return (
    ((await API.get(
      REPORT_API,
      `/tenant/${tenantId}/asset/${assetId}/alert${qs}`,
      {},
    )) as GetReportAssetAlertResponseDto) ?? []
  );
}
