import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ErrorOrEmptyContainer,
  renderAlertMenu,
  renderBoldCell,
  renderSeverityIcon,
  RowFilters,
  StyledTable,
  TableEmptyState,
} from './alerts.components';
import { useAlertsTable } from '@src/api/alert/use-asset-alerts';
import useAlertTableExtraFilters from '@src/api/alert/use-alert-table-extra-filters';
import { useRecoilState } from 'recoil';
import {
  alertTableFiltersState,
  currentAlertIdState,
} from '@store/alert/alert-filter-state';
import { useDeleteAlert } from '@src/api/alert/use-delete-alert';
import { Checkbox, ConfigProvider, Input, TablePaginationConfig } from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import Icon from '@components/atoms/icon';
import { useDebounce } from '@hooks';
import Cascader from '@components/atoms/cascader';
import Error from '@components/atoms/error';
import {
  Alert,
  AlertSortOptions,
  AlertSortOrder,
  ToggleAlertBody,
} from '@src/api/alert/types';
import Button from '@components/atoms/button/button-old';
import StopIcon from '@src/assets/icons/StopIcon';
import { useToggleAlert } from '@src/api/alert/use-toggle-alert';
import ConfirmModal from '@components/molecules/confirm-modal';
import AlertDetailsModal from '@components/organisms/modals/modal-alert-details';
import { filterCascaderRulesState } from '@store/filters/filter-state-rules';
import { AlertSeverityEnumDto, UnitsEnum } from '@asset-manager/types';
import { stopPropagationHelper } from '@src/utils';
import { useTranslation } from 'react-i18next';
import { mapDbUnitsToRuleConditionValueUnit } from './Alerts';
import { queryClient } from '@src/App';
import { GET_ASSET_ALERTS_TABLE_QUERY_KEY } from '@src/api';

const mapAlertSortColumn = new Map<string, AlertSortOptions>(
  Object.entries({
    severity: 'severity',
  }),
);

const mapAlertSortOption = new Map<string, AlertSortOrder>(
  Object.entries({
    ascend: 'asc',
    descend: 'desc',
  }),
);

const AlertsSetTab = ({
  setNewAlertModalOpen,
  isRule,
}: {
  setNewAlertModalOpen: Dispatch<SetStateAction<boolean>>;
  isRule?: boolean;
}) => {
  interface AlertItem {
    id: string;
    active: boolean;
  }

  const { t } = useTranslation();
  const {
    getAlertsTable: alerts,
    updateAlertsTableFilters,
    pageNumber,
    pageSize,
  } = useAlertsTable();

  const {
    extraFiltersOptions: cascaderOptions,
    updateAlertsTableExtraFilters,
    updateAlertsTableInputSearch,
  } = useAlertTableExtraFilters();

  const [filters] = useRecoilState(alertTableFiltersState);

  const deleteAlert = useDeleteAlert();
  const toggleAlert = useToggleAlert();

  const [, setCurrentAlertId] = useRecoilState(currentAlertIdState);
  const [currentEventId, setCurrentEventId] = useState<any>('');
  const [selectedIds, setSelectedIds] = useState<AlertItem[]>([]);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [isConfirmDeactivateModalOpen, setIsConfirmDeactivateModalOpen] =
    useState(false);
  const [actualId, setActualId] = useState('');

  const ref = useRef<HTMLDivElement>(null);

  function handleAlertsTableChange(
    pagination: TablePaginationConfig,
    _filter: Record<string, any>,
    sorter: SorterResult<any>,
  ) {
    updateAlertsTableFilters({
      page: pagination.current || 1,
      limit: pagination.pageSize || 10,
      sort: mapAlertSortColumn.get((sorter?.column?.dataIndex as string) || ''),
      order: mapAlertSortOption.get(sorter?.order || ''),
    });
  }

  const renderSeverityIconWrapper = (severity: AlertSeverityEnumDto) => {
    return renderSeverityIcon(severity, t);
  };

  const alertsTableColumns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'id',
        render: (id: string, rowData: any) => {
          const { active } = rowData;
          const isChecked = selectedIds?.some(alert => alert.id === id);
          const isDisabled =
            selectedIds.length > 0 && active !== selectedIds[0].active;

          return (
            <Checkbox
              onClick={stopPropagationHelper}
              onChange={e => {
                if (e.target.checked) {
                  setSelectedIds(prevSelectedAlerts => [
                    ...prevSelectedAlerts,
                    { id, active: active },
                  ]);
                } else {
                  setSelectedIds(prevSelectedAlerts =>
                    prevSelectedAlerts.filter(alert => !(alert.id === id)),
                  );
                }
              }}
              checked={isChecked}
              disabled={isDisabled}
            />
          );
        },
      },
      {
        title: t('alerts.alert'),
        dataIndex: 'name',
        render: renderBoldCell,
      },
      {
        title: t('general.state'),
        dataIndex: 'active',
        render: (status: boolean) => {
          return (
            <span
              style={{
                display: 'inline-block',
                fontWeight: 700,
                lineHeight: '24px',
                borderRadius: 12,
                paddingInline: 8,
                color: status === true ? '#0D6626' : '#7A7A7A',
                backgroundColor:
                  status === true ? 'rgba(24, 205, 75, 0.2)' : '#ECECEC',
              }}
            >
              {status === true ? t('general.active') : t('general.unactive')}
            </span>
          );
        },
      },
      {
        title: t('general.priority'),
        dataIndex: 'severity',
        render: renderSeverityIconWrapper,
        sorter: true,
      },
      /*       {
        title: t('assets.asset'),
        dataIndex: 'assets',
        render: (
          assets: {
            id: string;
            name: string;
          }[],
        ) => {
          return (
            <div>
              {assets?.map((a, i) => (
                <span key={a.id}>
                  {i <= 1 && a.name}
                  {assets.length > 1 && i < 1 && ', '}
                  {assets.length > 2 && i === 1 && '...'}
                </span>
              ))}
              {assets.length > 2 && <span>+{assets.length - 2}</span>}
            </div>
          );
        },
      }, */
      {
        title: t('general.typology'),
        render: ({ unit }: { unit?: string }) => {
          return mapDbUnitsToRuleConditionValueUnit[unit as UnitsEnum]
            ? t(
                `general.${
                  mapDbUnitsToRuleConditionValueUnit[unit as UnitsEnum]
                }`,
              )
            : t('general.notAvailable');
        },
      },
      {
        title: t('general.generated'),
        dataIndex: 'events',
        render: renderBoldCell,
      },
      {
        title: '',
        dataIndex: 'id',
        render: (id: string, row: any) =>
          renderAlertMenu({
            active: row?.active,
            onDelete: () => {
              setActualId(id);
              setIsConfirmDeleteModalOpen(true);
              ref.current?.click();
            },
            onOpenDetails: () => {
              setCurrentAlertId(id);
              setAlertDetailsModalOpen(true);
              ref.current?.click();
            },
            onActive: () => {
              setSelectedIds(prevSelectedIds =>
                prevSelectedIds.filter(alert => alert.id !== id),
              );
              const body: ToggleAlertBody = {
                alertIds: [id],
                enable: true,
              };
              toggleAlert.mutate({ body });
              ref.current?.click();
            },
            onDisactivate: () => {
              setActualId(id);
              setSelectedIds(prevSelectedIds =>
                prevSelectedIds.filter(alert => alert.id !== id),
              );
              setIsConfirmDeactivateModalOpen(true);
              ref.current?.click();
            },
            t,
          }),
      },
    ],
    [t, renderSeverityIconWrapper, selectedIds, setCurrentAlertId, toggleAlert],
  );

  const updateGeneratedEventsTableInitalFilters = useCallback((): [
    string,
    string,
  ][] => {
    const newFilters = { ...filters };
    const result: [string, string][] = [];
    for (const val of newFilters.severities || []) {
      result.push(['severity', val]);
    }
    for (const val of newFilters.actives || []) {
      result.push(['actives', val]);
    }
    for (const val of newFilters.assetIds || []) {
      result.push(['assets', val]);
    }
    for (const val of newFilters.departmentIds || []) {
      result.push(['departments', val]);
    }
    for (const val of newFilters.plantIds || []) {
      result.push(['plants', val]);
    }
    for (const val of newFilters.lineIds || []) {
      result.push(['lines', val]);
    }
    const filteredResult = result.filter(
      entry => entry[1] !== AlertSeverityEnumDto.MEDIUM,
    );
    return filteredResult;
  }, []);

  const [filterCascader, setFilterCascader] = useRecoilState(
    filterCascaderRulesState,
  );
  const [searchInput, setSearchInput] = useState(filters.text || '');

  const searchInputDebounced = useDebounce(searchInput, 300);

  const [alertDetailsModalOpen, setAlertDetailsModalOpen] = useState(false);

  useEffect(() => {
    updateAlertsTableInputSearch(searchInputDebounced);
  }, [searchInputDebounced, updateAlertsTableInputSearch]);

  useEffect(() => {
    if (searchInput.length) {
      updateAlertsTableFilters({
        page: 1,
      });
    }
  }, [searchInput]);

  return (
    <div ref={ref}>
      {selectedIds.length > 0 && (
        <RowFilters>
          <div className="action-containers">
            {selectedIds.length > 0 && (
              <span>
                {selectedIds.length}{' '}
                {selectedIds.length === 1
                  ? t('general.selectedElement')
                  : t('general.selectedElements')}
              </span>
            )}
            <div className="action-buttons">
              <Button
                icon={<StopIcon />}
                onClick={() => {
                  const alertIds = selectedIds.map(alert => alert.id);
                  const firstSelectedAlertStatus = selectedIds[0];
                  const enable = !firstSelectedAlertStatus.active;

                  const body: ToggleAlertBody = {
                    alertIds: alertIds,
                    enable: enable,
                  };
                  toggleAlert.mutate({ body });
                  setSelectedIds([]);
                  ref.current?.click();
                }}
              >
                {selectedIds[0].active
                  ? t('general.secondUnactive')
                  : t('general.secondActive')}
              </Button>
            </div>
          </div>
        </RowFilters>
      )}
      {selectedIds.length === 0 && (
        <RowFilters>
          <Cascader
            options={cascaderOptions.map(el => ({ ...el, label: t(el.label) }))}
            filter={filterCascader}
            setFilter={setFilterCascader}
            defaultValue={updateGeneratedEventsTableInitalFilters()}
            onReset={() => updateAlertsTableExtraFilters({})}
            onConfirm={updateAlertsTableExtraFilters}
            onClear={() => updateAlertsTableExtraFilters({})}
          />
          <Input
            allowClear
            value={searchInput}
            onChange={e => setSearchInput(e.target.value)}
            placeholder={t('general.search')}
            prefix={<Icon name="search" color="#373737" />}
          />
        </RowFilters>
      )}

      {alerts.isError ? (
        <ErrorOrEmptyContainer>
          <Error subtitle={t('alerts.errorFetchingAlerts')} />
        </ErrorOrEmptyContainer>
      ) : (
        <ConfigProvider
          renderEmpty={() => (
            <TableEmptyState
              title={t('alerts.noAlertSet')}
              subtitle={t('alerts.setNewAlert')}
            />
          )}
        >
          <StyledTable
            columns={alertsTableColumns}
            // @ts-ignore
            rowKey={record => record?.id}
            dataSource={alerts.data?.items}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: alerts.data?.count,
            }}
            onRow={record => {
              return {
                onClick: (e: any) => {
                  // @ts-ignore
                  if (
                    e.target?.classList['0']?.includes('ant-checkbox') ||
                    e.target.classList.contains('pop-over-custom') ||
                    e.target.closest('.pop-over-custom')
                  ) {
                    return;
                  }
                  setCurrentAlertId((record as Alert).id);
                  setCurrentEventId(record);
                  setAlertDetailsModalOpen(true);
                },
              };
            }}
            loading={alerts.isLoading}
            // @ts-ignore
            onChange={handleAlertsTableChange}
          />
          <ConfirmModal
            title={t('alerts.deleteAlert')}
            description={t('alerts.confirmDeleteAlert')}
            open={isConfirmDeleteModalOpen}
            onCancel={() => setIsConfirmDeleteModalOpen(false)}
            onConfirm={() => {
              deleteAlert.mutate(actualId);
              ref.current?.click();
              setIsConfirmDeleteModalOpen(false);
            }}
            confirmLoading={deleteAlert.isLoading}
            firstButtonText={t('general.dontDelete')}
            secondButtonText={t('general.delete')}
            light
          />
          <ConfirmModal
            title={t('alerts.deactivate')}
            description={t('alerts.deactivateAlertConfirmation')}
            secondDescription={t('alerts.noMoreAlertNotification')}
            open={isConfirmDeactivateModalOpen}
            onCancel={() => setIsConfirmDeactivateModalOpen(false)}
            onConfirm={() => {
              const body: ToggleAlertBody = {
                alertIds: [actualId],
                enable: false,
              };
              toggleAlert.mutate({ body });
              ref.current?.click();
              setIsConfirmDeactivateModalOpen(false);
            }}
            confirmLoading={toggleAlert.isLoading}
            firstButtonText={t('general.cancel')}
            secondButtonText={t('general.secondUnactive')}
            light
          />
          {alertDetailsModalOpen && (
            <AlertDetailsModal
              open={alertDetailsModalOpen}
              isRule={isRule}
              event={currentEventId}
              onCancel={() => {
                setAlertDetailsModalOpen(false);
              }}
              onOk={() => {
                queryClient
                  .invalidateQueries(GET_ASSET_ALERTS_TABLE_QUERY_KEY)
                  .catch(e => console.log('Error', e.message));
              }}
            />
          )}
        </ConfigProvider>
      )}
    </div>
  );
};

export default AlertsSetTab;
