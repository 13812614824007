import { fontSize } from '@src/globalStyle';
import { HTMLAttributes, ReactNode } from 'react';
import styled, { css } from 'styled-components';

interface Props extends HTMLAttributes<HTMLDivElement> {
  dark?: boolean;
  image?: ReactNode;
  title: string;
  subtitle?: string;
  fillDimensions?: boolean;
}
const Title = styled.h3`
  font-family: 'Satoshi-Variable';
  font-weight: 700;
  ${fontSize.m}
`;

const Subtitle = styled.h4`
  font-family: 'Satoshi-Variable';
  font-weight: 500;
  ${fontSize.s}
  line-height: 20px;
  text-align: center;
`;

const StyledEmptyState = styled.div<{
  dark: string;
  fillDimensions?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  color: ${props => (props.dark === 'true' ? '#fff' : '#373737')};
  ${props =>
    props.fillDimensions &&
    css`
      width: 100%;
      height: 100%;
      align-self: center;
    `}
`;

export default function EmptyState({
  dark = false,
  image,
  title,
  subtitle,
  fillDimensions,
  ...props
}: Props) {
  return (
    <StyledEmptyState
      dark={dark.toString()}
      fillDimensions={fillDimensions}
      {...props}
    >
      {image}
      <Title>{title}</Title>
      {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
    </StyledEmptyState>
  );
}
