import { atom } from 'recoil';
import dayjs from 'dayjs';
import { NoteQueryParams } from '@src/api';

dayjs.extend(require('dayjs/plugin/utc'));

// @ts-ignore
const utcDayjs = dayjs.utc;
export const noteFiltersState = atom({
  key: 'noteFiltersState',
  default: {
    startDate: utcDayjs().startOf('day').subtract(30, 'days').unix().toString(),
    endDate: utcDayjs().endOf('day').unix().toString(),
    limit: '10',
    skip: '0',
    sort: 'createdAt',
    order: 'desc',
    dateRange: 'today',
  } as NoteQueryParams & { dateRange: string },
});
