// noinspection com.haulmont.rcb.ArrayToJSXMapInspection

import Button from '@components/atoms/button';
import EmptyState from '@components/atoms/empty-state';
import Icon from '@components/atoms/icon';
import SectionCollapse from '@components/molecules/section-collapse';
import Header from '@components/organisms/header';
import { flex_direction } from '@components/type';
import { useMediaQuery } from '@hooks';
import { useFetchFaq } from '@src/api/faq';
import { fontSize } from '@src/globalStyle';
import { device } from '@theme/breakpoints';
import { lightTheme } from '@theme/light';
import {
  Anchor as AnchorAntd,
  Col,
  ConfigProvider,
  Input,
  Space,
  Spin,
} from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Loader } from '../Assets/components/drawer-contents/common';
import AssistanceCard from '@src/routes/Help/components/assistance-card';
import { useTenant } from '@src/modules/asset';
import { t } from 'i18next';

const Row = styled.div<{ direction: flex_direction }>`
  display: flex;
  flex-direction: ${props => props.direction};
  padding: 16px;

  @media ${device.laptop} {
    gap: 16px;
  }
`;

const LeftCol = styled(Col)`
  @media ${device.laptop} {
    width: 318px;
  }
`;

const RightCol = styled(Col)`
  flex: 1;
`;

const Summary = styled.div<{ heightSummary: number }>`
  /* top: 100px; // ad occhio */
  /* border: 5px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.laptop} {
    position: sticky;
    top: 0;
    min-height: ${props => props.heightSummary} - 137;
    padding-bottom: 32px;
  }
`;

const SummaryTitle = styled.span`
  display: block;
  font-family: 'Satoshi-Variable';
  font-weight: 700;
  ${fontSize.h2}
  color: #373737;
  margin-bottom: 24px;
`;

// c'è nel tema
const Anchor = styled(AnchorAntd)``;

const AnchorButton = styled(Button)`
  display: inline-flex;
`;

const AnchorButtonIcon = styled(Icon)`
  font-size: 16px;
  line-height: inherit;
`;

const InputIcon = styled(Icon)`
  font-size: 16px;
  margin-right: 4px;
  color: #373737 !important;
`;

const StyledInput = styled(Input)`
  margin-bottom: 36px;
  border-radius: 30px;
  border: none;
`;

export default function Report() {
  const isLaptop = useMediaQuery('laptop');

  const [heightSummary, setHeightSummary] = useState(1000);
  const [search, setSearch] = useState('');
  const { data, isLoading } = useFetchFaq();
  const { tenant } = useTenant();

  const listener = useCallback(() => setHeightSummary(window.innerHeight), []);

  useEffect(() => {
    listener();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [listener]);

  const summaryData = useMemo(
    () =>
      data?.listPerCategory
        ?.filter((el: any) => {
          return tenant?.type !== 'GENERIC' || el.id !== 'stops-asset';
        })
        ?.map((i: any, index: number) => (
          <a href={`#${i.id}`}>
            <AnchorButton
              size="s"
              variant="grey-white"
              key={index}
              icon={
                i.icon && (
                  <AnchorButtonIcon name={i.icon} color="currentColor" />
                )
              }
            >
              {i.name}
            </AnchorButton>
          </a>
        )),
    [data?.listPerCategory, tenant?.type],
  );

  const dataMap = useMemo(
    () =>
      data?.listPerCategory
        .filter(
          (el: any) =>
            el.faqs.length > 0 &&
            (tenant?.type !== 'GENERIC' || el.id !== 'stops-asset'),
        )
        .map((item: any, index: any) => {
          const filteredData = item.faqs
            .filter((r: any) => {
              if (search.length === 0) return true;
              return (
                r.description.toLowerCase().includes(search.toLowerCase()) ||
                r.title.toLowerCase().includes(search.toLowerCase())
              );
            })
            .map((i: any) => ({
              header: i.title,
              content: i.description,
            }));
          if (search.length !== 0 && filteredData.length === 0) {
            return null;
          }

          return (
            <SectionCollapse
              key={index}
              id={item.id}
              title={item.name}
              iconName={item.icon}
              data={filteredData}
            />
          );
        }),
    [data?.listPerCategory, search],
  );

  return (
    <ConfigProvider theme={lightTheme}>
      <Header title="Help" />

      {isLoading ? (
        <Loader>
          <Spin size="large" />
        </Loader>
      ) : (
        <Row
          direction={isLaptop ? 'row' : 'column'}
          // ref={setContainer}
        >
          <LeftCol>
            {/* <Affix offsetTop={0} style={{border: '1px solid red'}}> */}
            <Summary heightSummary={heightSummary}>
              <div>
                <SummaryTitle>{t('help.frequentlyAskedQuestion')}</SummaryTitle>
                {!isLaptop ? null : (
                  <Space size={8} direction="vertical">
                    {summaryData}
                  </Space>
                )}
              </div>
              {/* TODO: verificare il responsiveness della pagina quando la card verrà ripristinata */}
              {isLaptop && <AssistanceCard />}
            </Summary>
            {/* </Affix> */}
          </LeftCol>
          <RightCol>
            <StyledInput
              prefix={<InputIcon name="search" />}
              placeholder={t('general.searchArgument')}
              size="large"
              onChange={e => setSearch(e.target.value)}
            />
            <Space size={36} direction="vertical" style={{ width: '100%' }}>
              {!dataMap?.filter((el: any) => !!el)?.length ? (
                <EmptyState
                  style={{ height: 200 }}
                  image={
                    <Icon
                      name="emergency_home"
                      style={{ fontSize: 48 }}
                      color="currentColor"
                    />
                  }
                  title={t('general.noArgumentFound')}
                />
              ) : (
                dataMap
              )}
            </Space>
            {!isLaptop && <AssistanceCard />}
          </RightCol>
        </Row>
      )}
    </ConfigProvider>
  );
}
