import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { GET_ALERT_REPORT_QUERY_KEY } from '@src/api';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { fetchConfig } from '@src/config/http';
import { generatedEventsByAssetFiltersState } from '@store/alert/generated-events-filter-state';
import { fetchGeneratedEventsReport } from './fetch-generated-events-report';
import { selectedAssetIdState } from '@store/asset';

export function useGeneratedEventsReport() {
  const [tenantId] = useRecoilState(selectedTenantIdState);
  const [filters] = useRecoilState(generatedEventsByAssetFiltersState);
  const [assetId] = useRecoilState(selectedAssetIdState);

  return useQuery(
    [GET_ALERT_REPORT_QUERY_KEY, tenantId, filters],
    () => fetchGeneratedEventsReport(tenantId, assetId, filters),
    {
      ...(fetchConfig as any),
      enabled: !!tenantId,
    },
  );
}
