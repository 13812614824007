import { useCurrentUser } from '@asset-manager/api';
import Button from '@components/atoms/button';
import CircleAcronyms from '@components/atoms/circle-acronyms';
import Divider from '@components/atoms/divider';
import Icon from '@components/atoms/icon';
import RowInput from '@components/molecules/row-input';
import Header from '@components/organisms/header';
import { fontSize } from '@src/globalStyle';
import { useMediaQuery } from '@src/hooks/useMediaQuery';
import { useTenant } from '@src/modules/asset';
import { device } from '@theme/breakpoints';
import { Row, Spin } from 'antd';
import { Navigate } from 'react-router-dom';
import styled from 'styled-components';
import { useAmplify } from '@src/modules/amplify';
import { Loader } from '../Assets/components/drawer-contents/common';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const LogoutText = styled.span`
  @media (max-width: 1023px) {
    display: none !important;
  }
`;

const Prehead = styled.span`
  font-weight: 500;
  ${fontSize.xs}
  letter-spacing: 0.03em;
  color: #7a7a7a;

  @media (max-width: 1023px) {
    display: none;
  }
`;

const Title = styled.span`
  font-weight: 700;
  ${fontSize.s}
  color: #373737;

  @media ${device.laptop} {
    margin-top: 12px;
    ${fontSize.l}
  }
`;

const Subtitle = styled.span`
  font-weight: 500;
  ${fontSize.xs}
  color: #373737;

  @media ${device.laptop} {
    margin-top: 2px;
    ${fontSize.s}
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  @media ${device.laptop} {
    margin-left: 48px;
  }
`;

const RowHead = styled(Row)`
  padding: 0 16px;

  @media ${device.laptop} {
    padding: 32px 64px;
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default function User() {
  const { t } = useTranslation();
  const isLaptop = useMediaQuery('laptop');
  const { signOut, isAuthenticated } = useAmplify();
  const { tenant } = useTenant();

  const {
    data: currentUser,
    isLoading: isCurrentUserLoading,
    isError: isCurrentUserError,
    refetch: refetchUser,
  } = useCurrentUser(tenant?.id);

  const initials = useMemo(
    () =>
      (currentUser?.name?.charAt(0) ?? '').toUpperCase() +
        (currentUser?.surname?.charAt(0) ?? '').toUpperCase() ?? '',
    [currentUser?.name, currentUser?.surname],
  );

  const checkPage = useMemo(
    () =>
      (Object.keys(currentUser || {}).length ||
        (Array.isArray(currentUser) && currentUser.length)) &&
      !isCurrentUserLoading &&
      !isCurrentUserError,
    [currentUser, isCurrentUserError, isCurrentUserLoading],
  );
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Header
        title={t('user.profile')}
        extra={
          isLaptop ? (
            <Button
              center
              variant={'outline-black'}
              onClick={signOut}
              icon={
                <Icon
                  name="logout"
                  color="currentColor"
                  // color="#1C1B1F"
                  style={{ fontSize: 16 }}
                />
              }
            >
              <LogoutText>{t('user.logout')}</LogoutText>
            </Button>
          ) : (
            <Button
              variant="grey"
              center
              shape="square"
              shapeSize={44}
              onClick={signOut}
            >
              <Icon
                name="logout"
                color="currentColor"
                // color="#1C1B1F"
              />
            </Button>
          )
        }
      />
      {isCurrentUserLoading ? (
        <Loader>
          <Spin size="large" />
        </Loader>
      ) : checkPage ? (
        <>
          {/* span={3} offset={1}> span={20} */}
          <RowHead align="middle">
            {!!initials && (
              <CircleAcronyms
                fontSize={`${isLaptop ? 40 : 16}px`}
                size={`${isLaptop ? 96 : 40}px`}
              >
                {initials}
              </CircleAcronyms>
            )}
            <Info>
              <Prehead style={{ textTransform: 'capitalize' }}>
                {tenant.name}
              </Prehead>
              <Title style={{ textTransform: 'capitalize' }}>
                {currentUser.name} {currentUser.surname}
              </Title>
              <Subtitle>{currentUser.email}</Subtitle>
            </Info>
          </RowHead>
          <Divider />
          <Form>
            <RowInput
              inputValueDefault={currentUser.name}
              label={t('user.name')}
              propName="name"
              disabled={false}
              userId={currentUser.id}
              onUpdate={refetchUser}
              bordered={true}
            />
            <RowInput
              inputValueDefault={currentUser.surname}
              label={t('user.surname')}
              propName="surname"
              disabled={false}
              userId={currentUser.id}
              onUpdate={refetchUser}
              bordered={true}
            />
            <RowInput
              type="email"
              label={t('user.email')}
              propName="email"
              inputValueDefault={currentUser.email}
              disabled={true}
              userId={currentUser.id}
              bordered={false}
              onUpdate={refetchUser}
            />
          </Form>
        </>
      ) : null}
    </>
  );
}
