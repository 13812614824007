import Icon from '@components/atoms/icon';
import Tooltip, { InfoIcon } from '@components/atoms/tooltip';
import { fontSize } from '@src/globalStyle';
import { device } from '@theme/breakpoints';
import { Col, Row } from 'antd';
import { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 16px;
  @media ${device.laptop} {
    margin-top: 32px;
  }
`;

// Typography.Title level={5}
const Title = styled.h3`
  font-weight: 700;
  ${fontSize.m}
  color: white;
`;

// Typography.Text
const Subtitle = styled.p`
  font-weight: 500;
  ${fontSize.s}
  color: #919191;
  margin: 2px 0 0;
`;

const Content = styled.div`
  margin-top: 16px;
`;

const DataRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
`;

const DataCol = styled(Col)`
  @media (max-width: 1023px) {
    width: 100%;
  }
`;

interface Prop extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  titleTooltip?: string;
  subtitle?: string;
  children?: ReactNode;
  extra?: ReactNode;
}

export default function DrawerSection({
  title,
  titleTooltip,
  subtitle,
  children,
  extra,
  ...props
}: Prop) {
  // const isLaptop = useMediaQuery('laptop');
  // const colSpan = isLaptop ? 12 : 24;
  // const colWidth = isLaptop ? '50%' : '100%';
  // xs={2} sm={4} md={6} lg={8} xl={10}
  // https://ant.design/components/grid#col

  return (
    <Container {...props}>
      <DataRow
        className="cssid--drawer-section-datarow"
        // justify={'space-between'} align={'middle'}
      >
        <DataCol>
          {(!!title || !!titleTooltip) && (
            <Title>
              {title}
              {!!titleTooltip && (
                <Tooltip title={titleTooltip}>
                  {/* InfoIcon di Tooltip non piace */}
                  {/* <InfoIcon space={!!title} /> */}
                  <Icon
                    name="info"
                    style={{
                      fontSize: 16,
                      position: 'absolute',
                      paddingLeft: !!title ? 8 : 0,
                    }}
                  />
                </Tooltip>
              )}
            </Title>
          )}
          {!!subtitle && <Subtitle>{subtitle}</Subtitle>}
        </DataCol>
        {extra && <DataCol>{extra}</DataCol>}
      </DataRow>
      {!!children && (
        <Content style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          {children}
        </Content>
      )}
    </Container>
  );
}
