import { useQuery } from 'react-query';
import { useRecoilState } from 'recoil';
import { GET_GENERATED_EVENTS_QUERY_KEY } from '@src/api';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { fetchConfig } from '@src/config/http';
import {
  EventsDefaultOrder,
  EventsDefaultSort,
  eventsByAlertIdFiltersState,
} from '@store/alert/generated-events-filter-state';
import { fetchGeneratedEvents } from './fetch-generated-events';
import { GeneratedEventsSortOptions } from './types';
import { currentAlertIdState } from '@store/alert/alert-filter-state';

export function useGeneratedEventsByAlert() {
  const [tenantId] = useRecoilState(selectedTenantIdState);
  const [filters, setFilters] = useRecoilState(eventsByAlertIdFiltersState);
  const [alertId] = useRecoilState(currentAlertIdState);

  const filtersWithAlert = {
    ...filters,
    alertIds: alertId ? [alertId] : undefined,
  };

  const {
    data: generatedEvents,
    isLoading,
    isError,
  } = useQuery(
    [GET_GENERATED_EVENTS_QUERY_KEY, tenantId, alertId, filtersWithAlert],
    () => fetchGeneratedEvents(tenantId, filtersWithAlert),
    {
      ...(fetchConfig as any),
      enabled: !!tenantId && !!alertId,
    },
  );

  const pageSize = Number(filters.limit);
  const pageNumber = Number(filters.skip) / pageSize + 1;

  const updateFilters = ({
    page,
    sort,
    order,
  }: {
    page: number;
    sort?: GeneratedEventsSortOptions;
    order?: 'asc' | 'desc';
  }) => {
    setFilters({
      ...filters,
      skip: `${(page - 1) * Number(filters.limit)}`,
      sort: sort ?? EventsDefaultSort,
      order: order ?? EventsDefaultOrder,
    });
  };

  return {
    generatedEvents,
    isLoading,
    isError,
    pageNumber,
    pageSize,
    updateFilters,
  };
}
