import { Column, Line } from '@ant-design/plots';
import Slider from '@components/atoms/slider';
import {
  LineTooltip,
  tooltip_domStyles,
} from '@components/molecules/charts/components/chart-tooltip';
import Legend from '@components/molecules/charts/components/legend';
import { formatDateOnRangePicker } from '@src/utils';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

type Props = {
  data: any;
  dateRange: number;
  unitOfMeasure: string;
  min: number;
  max: number;
};

export default function ChartLineConsumption({
  data,
  dateRange,
  unitOfMeasure,
  min,
  max,
}: Props) {
  const [minMax, setMinMax] = useState<[number, number]>([min || 0, max || 1]);

  const [defaultMinMax, setDefaultMinMax] = useState<[number, number]>([
    min || 0,
    max || 1,
  ]);

  useEffect(() => {
    setDefaultMinMax([min || 0, max || 1]);
    setMinMax([min || 0, max || 1]);
  }, [min, max]);
  const xAxisFormatter = useCallback(
    (val: any) => {
      return formatDateOnRangePicker(new Date(val).getTime(), dateRange);
    },
    [dateRange],
  );

  const getTitle = useCallback(
    (title: string) => {
      try {
        const date = new Date(title);
        if (dateRange !== undefined)
          return formatDateOnRangePicker(date.getTime(), dateRange);
      } catch (e) {
        return title;
      }
    },
    [dateRange],
  );

  const tooltipCustomContent = useCallback(
    (title: any, data: any) => {
      return (
        <LineTooltip
          tooltipColor="#E8684A"
          title={getTitle(title) || ''}
          data={data}
          dateRange={dateRange}
          tooltipLabel="Consumo"
          unitOfMeasure={unitOfMeasure || ''}
        />
      );
    },
    [dateRange, unitOfMeasure, getTitle],
  );

  const consumptionData = useMemo(
    () =>
      data?.filter((r: any, i: any) => {
        return i >= minMax[0] && i <= minMax[1];
      }) as Record<string, any>[],
    [minMax, data],
  );

  const tooltipSliderFormatter = useCallback(
    (value?: number): ReactNode => {
      try {
        if (value === undefined || value === null) return value;
        const elem = data[value];
        if (!elem) return value;
        return new Date(elem.date).toLocaleString('it-IT', {
          day: '2-digit',
          month: '2-digit',
          minute: '2-digit',
          hour: '2-digit',
        });
      } catch (e) {
        return value;
      }
    },
    [data],
  );

  return (
    <>
      <Column
        slider={undefined}
        legend={false}
        height={278}
        autoFit
        color={'#E8684A'}
        data={consumptionData}
        yField="value"
        xField="date"
        xAxis={{ label: { formatter: xAxisFormatter } }}
        yAxis={{
          label: {
            formatter: (v: any) =>
              Math.round(Number(v) * 100) / 100 + ' ' + unitOfMeasure || '',
          },
        }}
        animation={false}
        tooltip={{
          customContent: tooltipCustomContent,
          domStyles: tooltip_domStyles,
        }}
      />
      <Slider
        tooltip={{
          // @ts-ignore
          formatter: tooltipSliderFormatter,
        }}
        range={{ draggableTrack: true }}
        min={defaultMinMax[0]}
        max={defaultMinMax[1]}
        defaultValue={defaultMinMax}
        value={minMax}
        onChange={setMinMax}
        style={{ marginTop: 16 }}
      />
      <Legend
        center
        style={{ marginTop: 16 }}
        data={[{ color: '#E8684A', label: 'Consumo kWh' }]}
      />
    </>
  );
}
