import { isAuthenticatedState } from '@asset-manager/amplify';
import { useTenant } from '@src/modules/asset';
import analytics from '@src/utils/analytics';
import { Auth } from 'aws-amplify';
import { format } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

export function useAmplify() {
  const [isAuthenticated, setIsAuthenticated] =
    useRecoilState(isAuthenticatedState);
  const [newPasswordRequired, setNewPasswordRequired] =
    useState<boolean>(false);
  const [signInResult, setSignInResult] = useState();
  const [loginError, setLoginError] = useState('');
  const [isInitialStateLoading, setIsInitialStateLoadin] = useState(true);
  const [user, setUser] = useState<any>(null);

  const { tenant } = useTenant();

  const signIn = useCallback((email: string, password: string) => {
    Auth.signIn(email, password)
      .then(result => {
        if (result.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setSignInResult(result);
          setNewPasswordRequired(true);
          return;
        }

        setIsAuthenticated(true);
        setLoginError('');

        analytics.logEvent('login', {
          userId: result.username,
          tenant: tenant?.name,
          tenantId: tenant?.id,
          touchpoint: 'Astra web',
          time: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
        });
      })
      .catch(e => {
        // UserNotFoundException: User does not exist.
        // Incorrect username or password.
        setLoginError(e);
        console.log('[useAmplify] signIn error', e);
      });
  }, []);

  const signOut = useCallback(() => {
    Auth.signOut()
      .then(result => {
        setIsAuthenticated(false);
      })
      .catch(e => {
        console.log('[useAmplify] signOut error', e);
      });
  }, []);

  const completeNewPassword = useCallback(
    (password: string) => {
      Auth.completeNewPassword(signInResult, password)
        .then(result => {
          setIsAuthenticated(true);
        })
        .catch(e => {
          console.log('[useAmplify] completeNewPassword error', e);
        });
    },
    [signInResult],
  );

  const forgotPassword = useCallback((email: string) => {
    return Auth.forgotPassword(email);
  }, []);

  const forgotPasswordSubmit = useCallback(
    (email: string, code: string, password: string) => {
      return Auth.forgotPasswordSubmit(email, code, password);
    },
    [],
  );

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        setUser(user);
        setIsAuthenticated(true);
      })
      .catch(e => {
        // The user is not authenticated
        setIsAuthenticated(false);
      })
      .finally(() => setIsInitialStateLoadin(false));
  }, []);

  return {
    isAuthenticated,
    signIn,
    newPasswordRequired,
    completeNewPassword,
    signOut,
    loginError,
    forgotPassword,
    forgotPasswordSubmit,
    isInitialStateLoading,
    user,
  };
}
