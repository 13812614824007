import { useGeneratedEvents } from '@src/api/alert/use-generated-events';
import {
  BoldCell,
  ErrorOrEmptyContainer,
  renderDescriptionOrName,
  renderEventMenu,
  renderSeverityIcon,
  RowFilters,
  StyledTable,
  TableEmptyState,
} from './alerts.components';
import {
  Avatar,
  ConfigProvider,
  Input,
  Row,
  TablePaginationConfig,
} from 'antd';
import { SorterResult } from 'antd/es/table/interface';
import useGeneratedEventsTableExtraFilters from '@src/api/alert/use-generated-events-table-extra-filters';
import { generatedEventsFiltersState } from '@store/alert/generated-events-filter-state';
import { useRecoilState } from 'recoil';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebounce, useScreenType } from '@hooks';
import Icon from '@components/atoms/icon';
import { format } from 'date-fns';
import Cascader from '@components/atoms/cascader';
import Error from '@components/atoms/error';
import { useDeleteEvent } from '@src/api/event/use-delete-event';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import AlertDetailsModal from '@components/organisms/modals/modal-alert-details';
import { currentAlertIdState } from '@store/alert/alert-filter-state';
import {
  AlertSeverityEnumDto,
  AssetEventGetAssetEventResponseDto,
  UnitsEnum,
} from '@asset-manager/types';
import { filterCascaderGeneratedEventsState } from '@store/filters/filter-state-generated-events';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { mapDbUnitsToRuleConditionValueUnit } from './Alerts';
import { queryClient } from '@src/App';
import { GET_GENERATED_EVENTS_QUERY_KEY } from '@src/api';

dayjs.extend(utc);

const GeneratedEventsTab = () => {
  const { t } = useTranslation();
  const { isMobile } = useScreenType();
  const {
    getGeneratedEvents: generatedEvents,
    pageNumber,
    pageSize,
    updateFilters,
  } = useGeneratedEvents();

  const handleGeneratedEventsTableChange = (
    pagination: TablePaginationConfig,
    _filter: Record<string, any>,
    _sorter: SorterResult<any>,
  ) => {
    updateFilters({
      page: pagination.current || 1,
      limit: pagination.pageSize || 10,
    });
  };

  const {
    extraFiltersOptions: cascaderOptions,
    updateGeneratedEventsTableExtraFilters,
    updateGeneratedEventsTableInputSearch,
  } = useGeneratedEventsTableExtraFilters();

  const [tenantId] = useRecoilState(selectedTenantIdState);

  const deleteEvent = useDeleteEvent();

  const [filters] = useRecoilState(generatedEventsFiltersState);

  const updateGeneratedEventsTableInitalFilters = useCallback((): [
    string,
    string,
  ][] => {
    const newFilters = { ...filters };
    const result: [string, string][] = [];
    for (const val of newFilters.severities || []) {
      result.push(['severity', val]);
    }
    for (const val of newFilters.assetIds || []) {
      result.push(['assets', val]);
    }
    for (const val of newFilters.departmentIds || []) {
      result.push(['departments', val]);
    }
    for (const val of newFilters.plantIds || []) {
      result.push(['plants', val]);
    }
    for (const val of newFilters.lineIds || []) {
      result.push(['lines', val]);
    }
    const filteredResult = result.filter(
      entry => entry[1] !== AlertSeverityEnumDto.MEDIUM,
    );
    return filteredResult;
  }, []);

  const [filterCascader, setFilterCascader] = useRecoilState(
    filterCascaderGeneratedEventsState,
  );

  const [searchInput, setSearchInput] = useState(filters.text || '');

  const searchInputDebounced = useDebounce(searchInput, 300);

  const [, setCurrentAlertId] = useRecoilState(currentAlertIdState);
  const [currentEventId, setCurrentEventId] = useState<any>('');
  const [alertDetailsModalOpen, setAlertDetailsModalOpen] = useState(false);

  useEffect(() => {
    updateGeneratedEventsTableInputSearch(searchInputDebounced);
  }, [searchInputDebounced, updateGeneratedEventsTableInputSearch]);

  useEffect(() => {
    if (searchInput.length) {
      updateFilters({
        page: 1,
      });
    }
  }, [searchInput]);

  const ref = useRef<HTMLDivElement>(null);

  const renderSeverityIconWrapper = (_data: any, data: any) => {
    console.log(data);
    let severity = data.severity || AlertSeverityEnumDto.MEDIUM;
    let description = data.description || '';
    return (
      <Row>
        {renderSeverityIcon(severity, t)}
        {description && description?.length > 0 ? (
          <Avatar.Group>
            <Avatar
              size={32}
              style={{ backgroundColor: '#2D364A', borderColor: '#28282A' }}
              icon={
                <Icon style={{ fontSize: 20 }} name="chat" color={'#4786FF'} />
              }
            />
          </Avatar.Group>
        ) : null}
      </Row>
    );
  };
  const trimString = (string: string): string => {
    const maxLength = 12;
    const trimLength = 9;

    if (string.length <= maxLength) {
      return string;
    } else if (string.length <= maxLength + 3) {
      return string;
    } else {
      return string.slice(0, trimLength) + '...';
    }
  };

  const generatedEventsTableColumns = [
    {
      title: t('alerts.alert'),
      dataIndex: ['description', 'alertName'],
      render: (_dataIndices: string | string[], rowData: any) => {
        // create two row, upper 2/3 for alert name, lower 1/3 for description
        if (rowData && rowData.description && rowData.alertName) {
          return (
            <>
              <Row>
                <BoldCell>{rowData?.alertName}</BoldCell>
              </Row>
              <Row style={{ fontSize: '0.8em' }} className="text-ellipsis">
                {trimString(rowData?.description.toUpperCase())}
              </Row>
            </>
          );
        } else if (rowData && rowData.alertName) {
          return <BoldCell>{rowData?.alertName}</BoldCell>;
        } else if (rowData && rowData.description) {
          return <BoldCell>{rowData?.description}</BoldCell>;
        } else {
          return <BoldCell>{rowData?.name}</BoldCell>;
        }
      },
    },
    {
      title: t('general.priority'),
      dataIndex: ['description', 'alertSeverity'],
      render: renderSeverityIconWrapper,
    },
    {
      title: t('general.date'),
      dataIndex: 'updatedAt',
      render: (updatedAt: string) => {
        return (
          <>
            <Row> {format(new Date(updatedAt), 'dd/MM/yyyy')}</Row>
            <Row> {format(new Date(updatedAt), 'HH:mm')}</Row>
          </>
        );
      },
    },
    {
      title: t('general.duration'),
      render: ({
        startTime,
        endTime,
      }: {
        startTime?: string;
        endTime?: string;
      }) => {
        if (!startTime && !endTime) return t('general.notAvailable');
        if (startTime && !endTime) return t('general.inProgress');
        if (!startTime && endTime) return t('general.notAvailable');
        const startDate = new Date(startTime as string);
        const endDate = new Date(endTime as string);
        const duration = dayjs
          .utc(dayjs(endDate).diff(dayjs(startDate)))
          .format('HH:mm');
        return duration;
      },
    },
    {
      title: t('general.typology'),
      render: ({ unit }: { unit?: string }) => {
        return mapDbUnitsToRuleConditionValueUnit[unit as UnitsEnum]
          ? t(
              `general.${
                mapDbUnitsToRuleConditionValueUnit[unit as UnitsEnum]
              }`,
            )
          : t('general.notAvailable');
      },
    },
    {
      title: t('assets.asset'),
      dataIndex: 'assetName',
    },
    {
      title: '',
      dataIndex: 'id',
      render: (id: string, row: any) =>
        renderEventMenu({
          onOpenDetails: () => {
            setCurrentAlertId(row.alertId);
            setAlertDetailsModalOpen(true);
            ref.current?.click();
          },
          onDelete: () => {
            deleteEvent.mutate({ tenantId, assetId: row.assetId, eventId: id });
            ref.current?.click();
          },
          t,
        }),
    },
  ];

  return (
    <div ref={ref}>
      <RowFilters>
        <Cascader
          options={cascaderOptions.map(el => ({ ...el, label: t(el.label) }))}
          filter={filterCascader}
          setFilter={setFilterCascader}
          defaultValue={updateGeneratedEventsTableInitalFilters()}
          onReset={() => updateGeneratedEventsTableExtraFilters({})}
          onConfirm={updateGeneratedEventsTableExtraFilters}
          onClear={() => updateGeneratedEventsTableExtraFilters({})}
        />
        <Input
          allowClear
          value={searchInput}
          onChange={e => setSearchInput(e.target.value)}
          placeholder={t('general.search')}
          prefix={<Icon name="search" color="#373737" />}
        />
      </RowFilters>
      {generatedEvents.isError ? (
        <ErrorOrEmptyContainer>
          <Error subtitle={t('alerts.errorFetchingHistoricalAlert')} />
        </ErrorOrEmptyContainer>
      ) : (
        <ConfigProvider
          renderEmpty={() => (
            <TableEmptyState
              title={t('alerts.noAlertGenerated')}
              subtitle={t('alerts.noAlertGeneratedSecondDescription')}
            />
          )}
        >
          <StyledTable
            columns={generatedEventsTableColumns}
            // @ts-ignore
            rowKey={record => record?.id}
            dataSource={generatedEvents.data?.items || []}
            pagination={{
              current: pageNumber,
              pageSize: pageSize,
              total: generatedEvents.data?.count,
              simple: isMobile ? true : false,
            }}
            loading={generatedEvents.isLoading}
            onRow={record => {
              return {
                onClick: (e: any) => {
                  if (
                    e.target.classList.contains('pop-over-custom') ||
                    e.target.closest('.pop-over-custom')
                  ) {
                    return;
                  }
                  setCurrentAlertId(
                    (record as AssetEventGetAssetEventResponseDto)
                      .alertId as string,
                  );
                  setCurrentEventId(
                    record as AssetEventGetAssetEventResponseDto,
                  );
                  setAlertDetailsModalOpen(true);
                },
              };
            }}
            // @ts-ignore
            onChange={handleGeneratedEventsTableChange}
          />
          {alertDetailsModalOpen && (
            <AlertDetailsModal
              open={alertDetailsModalOpen}
              event={currentEventId}
              onCancel={() => {
                setAlertDetailsModalOpen(false);
              }}
              onOk={() => {
                queryClient
                  .invalidateQueries(GET_GENERATED_EVENTS_QUERY_KEY)
                  .catch(e => console.log('Error', e.message));
                generatedEvents.refetch().catch(() => console.log('Refetched'));
              }}
            />
          )}
        </ConfigProvider>
      )}
    </div>
  );
};

export default GeneratedEventsTab;
