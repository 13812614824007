import { AssetGetAssetResponseDto } from '@asset-manager/common/dist/asset';
import {
  AssetEventGetAssetEventResponseDto,
  GetReportAssetStateResponseDto,
  ReportUpdateThresholdItemDto,
} from '@asset-manager/types';
import Divider from '@components/atoms/divider';
import EmptyState from '@components/atoms/empty-state';
import EmptyStateChart from '@components/atoms/empty-state/empty-state-chart';
import Icon from '@components/atoms/icon';
import ChartArea from '@components/molecules/charts/area';
import ChartTooltip, {
  tooltip_domStyles,
} from '@components/molecules/charts/components/chart-tooltip';
import Legend from '@components/molecules/charts/components/legend';
import { HeatmapConfigType } from '@components/molecules/charts/heatmap';
import { mapTempHeatmap } from '@components/molecules/charts/heatmap/heatmap.config';
import { minutesInDay } from '@components/molecules/charts/utils';
import ModalAddStop from '@components/organisms/modals/modal-add-stop';
import Overview from '@components/organisms/overview';
import { flex_direction } from '@components/type';
import { fontSize, utils } from '@src/globalStyle';
import { useWindowWidth } from '@src/hooks/useMediaQuery';
import {
  useAssetConsumptionStateReport,
  useAssetHeatMapSingleAsset,
  useAssetReport,
  useAssetTemperatureStateReport,
  useAssetVibrationStateReport,
} from '@src/modules/asset';
import { useTenantSlimReport } from '@src/modules/tenant';
import { formatDateOnRangePicker } from '@src/utils';
import { darkTheme } from '@theme/dark';
import { Col, ConfigProvider, Row, Spin } from 'antd';
import { differenceInDays, format } from 'date-fns';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
  ListRowProps,
} from 'react-virtualized';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';

import { ContainerOverview, Loader, RowExtraOverview } from '../common';
import DrawerSection from '../section/section';
import DataDrawerContentHeatmap from './components/data-drawert-content-heatmap';
import { overviewList, overviewMonitronList } from './data-drawer-content.mock';
import { DashboardRangePickerNode } from '@components/organisms/range-picker-node/range-picker-node';
import DateTimeFrameSelectDashboard from '@components/atoms/date-time-frame-select-dashboard';
import { assetFilterDateState } from '@store/asset/assetFilterDateState';
import { useTranslation } from 'react-i18next';
import ModalModifyThresholds from '@components/organisms/modals/modal-modify-thresholds';
import { fetchGeneratedEvents } from '@src/api/alert/fetch-generated-events';
import CardAlerts from '@components/molecules/card-alerts';
import { currentAlertIdState } from '@store/alert/alert-filter-state';
import AlertDetailsModal from '@components/organisms/modals/modal-alert-details';
import { useCurrentUser } from '@asset-manager/api';
import { GET_GENERATED_EVENTS_QUERY_KEY, useGetAsset } from '@src/api';
import { selectedAssetIdState } from '@store/asset';
import { fetchAssetThresholds } from '@src/api/report/use-fetch-asset-thresholds';
import { StackedColumn } from '@components/molecules/charts/stacked-column/stacked-column';
import {
  consumptionColorMapping,
  consumptionMapping,
} from '@src/utils/mapping.utils';
import { queryClient } from '@src/App';

dayjs.extend(utc);
const utcDayjs = dayjs.utc;

interface DataDrawerContentProps {
  item?: AssetGetAssetResponseDto;
  fullscreen: boolean;
}
const StyledCardAlerts = styled(CardAlerts)`
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .cssid--data-drawer-content-overview {
    .cssid--drawer-section-datarow {
      @media (max-width: 1023px) {
        gap: 18px;
        flex-direction: column-reverse;
        align-items: self-start;
      }
    }
  }

  .cssid--data-drawer-content-drawer-section-stops {
    .cssid--drawer-section-datarow {
      @media (max-width: 1023px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        & > div {
          width: auto;
        }
      }
    }
  }
`;

const Content = styled(Row)<{ direction: flex_direction }>`
  display: flex;
  flex-direction: ${props => props.direction};

  .stops {
    height: 800px;
    overflow: auto;
    /* overflow-y: scroll; */
    overflow-scrolling: touch;
    position: relative;
    div {
      //-ms-overflow-style: none;
      //scrollbar-width: none;
      //&::-webkit-scrollbar {
      //  display: none;
      //}

      /* ===== Scrollbar CSS ===== */
      /* Firefox */
      * {
        scrollbar-width: auto;
        scrollbar-color: #7a7a7a #1c1c1e;
      }

      /* Chrome, Edge, and Safari */
      *::-webkit-scrollbar {
        width: 16px;
      }

      *::-webkit-scrollbar-track {
        background: #1c1c1e;
      }

      *::-webkit-scrollbar-thumb {
        background-color: #7a7a7a;
        border-radius: 10px;
        border: 3px solid #1c1c1e;
      }
    }

    ${utils.hiddenScrollbar}/* box-shadow: 0 0 10px #e90b0b inset; */
  }
`;

const ChartAreaContainer = styled.div`
  height: 300px;
  canvas {
    /* width: 300px !important; */
  }
`;

const Subtitle = styled.p`
  font-weight: 500;
  ${fontSize.s}
  color: #919191;
  margin: 2px 0 0;
`;

const showRangePickerNode = (windowWidth: number, fullscreen: boolean) => {
  const isLaptop = windowWidth && windowWidth >= 1024 && windowWidth < 1358;
  const isLaptopM = windowWidth && windowWidth >= 1358;
  if (isLaptopM) return fullscreen;
  return isLaptop;
};

export default function DataDrawerContent({
  item,
  fullscreen,
}: DataDrawerContentProps) {
  const { t, i18n } = useTranslation<any>();
  const windowWidth = useWindowWidth();
  const { selectedTenantId } = useTenantSlimReport();

  const { data: currentUser } = useCurrentUser(selectedTenantId);

  const [consumptionReportState, setConsumptionStateReport] =
    useState<GetReportAssetStateResponseDto>();
  const [max, setMax] = useState<number>(0);

  const [temperatureReportState, setTemperatureStateReport] =
    useState<GetReportAssetStateResponseDto>();
  const [maxTemp, setMaxTemp] = useState<number>(0);

  const [vibrationReportState, setVibrationStateReport] =
    useState<GetReportAssetStateResponseDto>();
  const [maxVib, setMaxVib] = useState<number>(0);
  const [updateData, setUpdateData] = useState<number>(0);
  const [lastAlerts, setLastAlerts] = useState<
    AssetEventGetAssetEventResponseDto[]
  >([]);
  const [, setCurrentAlertId] = useRecoilState(currentAlertIdState);
  const [isLoadingLastAlerts, setIsLoadingLastAlerts] =
    useState<boolean>(false);

  const {
    assetReport,
    assetRefetch,
    isReportLoading: isAssetReportLoading,
  } = useAssetReport();

  const [assetId] = useRecoilState(selectedAssetIdState);
  const { data: currentAsset } = useGetAsset();

  const { assetTemperatureStateReport, assetTemperatureRefetch } =
    useAssetTemperatureStateReport(item?.type ?? '');

  const { assetVibrationStateReport, assetVibrationRefetch } =
    useAssetVibrationStateReport(item?.type ?? '');

  const { assetConsumptionStateReport, assetConsumptionRefetch } =
    useAssetConsumptionStateReport(item?.type ?? '');

  const { assetHeatMapReport } = useAssetHeatMapSingleAsset();
  const [assetFilterDate] = useRecoilState(assetFilterDateState);

  const [showThresholds, setShowThresholds] = useState<boolean>(false);
  const [thresholds, setThresholds] = useState<ReportUpdateThresholdItemDto[]>(
    [],
  );

  const handleOkAddModal = useCallback(() => {
    setIsAddModalOpen(false);
    assetConsumptionRefetch?.();
    assetTemperatureRefetch?.();
    assetVibrationRefetch?.();
    assetRefetch?.();
  }, [
    assetConsumptionRefetch,
    assetRefetch,
    assetTemperatureRefetch,
    assetVibrationRefetch,
  ]);

  const handleCancelAddModal = useCallback(() => {
    setIsAddModalOpen(false);
  }, []);

  const [isModifyThresholdsModalOpen, setIsModifyThresholdsModalOpen] =
    useState(false);
  const handleOkThresholdsModal = useCallback(() => {}, []);
  const handleCancelThresholdsModal = useCallback(() => {
    setIsModifyThresholdsModalOpen(false);
  }, []);

  const dateRange = useMemo(
    () =>
      differenceInDays(
        Number(assetFilterDate.endDate) * 1000,
        Number(assetFilterDate.startDate) * 1000,
      ),
    [assetFilterDate.endDate, assetFilterDate.startDate],
  );

  const subtitleDrawer = useMemo(
    () =>
      item &&
      item.updatedAt &&
      `${t('general.updatedAt')} ${format(new Date(item.updatedAt), 'HH:mm')}`,
    [item, t],
  );
  const showRangePicker = useMemo(
    () => showRangePickerNode(windowWidth || 0, fullscreen),
    [fullscreen, windowWidth],
  );

  useEffect(() => {
    (async () => {
      setIsLoadingLastAlerts(true);
      try {
        const response = await fetchGeneratedEvents(selectedTenantId, {
          limit: '100',
          startDate: utcDayjs()
            .startOf('day')
            .subtract(365, 'days')
            .unix()
            .toString(),
          endDate: utcDayjs().endOf('day').unix().toString(),
          skip: '0',
          sort: 'createdAt',
          order: 'desc',
          assetIds: [(item as any).id],
        });
        setLastAlerts(response.items);
      } catch {
        setLastAlerts([]);
      }
      setIsLoadingLastAlerts(false);
    })();
  }, [selectedTenantId, item, updateData]);

  useEffect(() => {
    if (!assetConsumptionStateReport) return;
    setConsumptionStateReport(assetConsumptionStateReport);
    setMax(assetConsumptionStateReport.maxPointValue);
  }, [assetConsumptionStateReport, max]);

  useEffect(() => {
    if (!assetTemperatureStateReport) return;
    setTemperatureStateReport(assetTemperatureStateReport);
    setMaxTemp(assetTemperatureStateReport.maxPointValue);
  }, [assetTemperatureStateReport, maxTemp]);

  useEffect(() => {
    if (!assetVibrationStateReport) return;
    setVibrationStateReport(assetVibrationStateReport);
    setMaxVib(assetVibrationStateReport.maxPointValue);
  }, [assetVibrationStateReport, maxVib]);

  useEffect(() => {
    if (currentUser && currentAsset) {
      const { influxStElapsed = 'THREE_WEEKS' } = currentUser;
      const { createdAt } = currentAsset;
      // Convert createAt to Date, then calculate if it's more than 3 weeks ago
      const isOlderThanThreeWeeks =
        influxStElapsed !== 'THREE_WEEKS' ||
        differenceInDays(new Date(), new Date(createdAt)) >= 21;
      setShowThresholds(isOlderThanThreeWeeks);
    }
  }, [currentUser, currentAsset]);

  // Fetch thresholds list
  useEffect(() => {
    // debugger;
    if (showThresholds) {
      fetchAssetThresholds(currentAsset?.id, selectedTenantId).then(
        thresholds => {
          // setShowThresholds(false);
          if (!thresholds?.items?.length) {
            setShowThresholds(false);
          } else {
            setThresholds(thresholds?.items);
            console.log('initialThresholds in useEffect: ', thresholds?.items);
          }
        },
      );
    }
  }, [showThresholds, currentAsset, selectedTenantId]);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const minutesOfInterval = useMemo(() => minutesInDay(5), []);
  const am8 = useMemo(
    () => minutesOfInterval.findIndex(i => i === '08:00') ?? 96,
    [minutesOfInterval],
  ); // 08:00
  const pm6 = useMemo(
    () => minutesOfInterval.findIndex(i => i === '18:00') ?? 216,
    [minutesOfInterval],
  ); // 18:00
  const isMobile = useMemo(
    () => windowWidth && windowWidth < 1024,
    [windowWidth],
  );

  const [isAlertDetailsModalOpen, setIsAlertDetailsModalOpen] = useState(false);
  const [currentEventId, setCurrentEventId] = useState<object>({});

  const onCancelAlertDetailsModal = useCallback(() => {
    setIsAlertDetailsModalOpen(false);
  }, []);

  const showModal = (alertId: string, event?: any) => {
    if (event) setCurrentEventId(event);
    setCurrentAlertId(alertId);
    setIsAlertDetailsModalOpen(true);
  };

  const chartAreaSize = useMemo(
    () =>
      fullscreen
        ? { width: 578, autoFit: true, height: 150 }
        : {
            height: 150,
            autoFit: true,
          },
    [fullscreen],
  );

  const alertsCache = useMemo(
    () =>
      new CellMeasurerCache({
        fixedWidth: true,
        minHeight: 90,
        defaultHeight: 90,
        // keyMapper: index => items?.[index],
      }),
    [],
  );

  // alerts key hack for react virtualized
  const listRef = useRef<List>(null);

  useEffect(() => {
    setTimeout(() => {
      listRef.current?.forceUpdate();
      listRef.current?.forceUpdateGrid();
      listRef.current?.recomputeRowHeights();
      alertsCache.clearAll();
    }, 500);
  }, [alertsCache, fullscreen, lastAlerts]);
  // alerts key hack for react virtualized - END

  const rowRenderer = useCallback(
    ({ key, index, isScrolling, isVisible, style, parent }: ListRowProps) => {
      const item = lastAlerts?.[index];
      if (!item) return null;

      const createdAt = new Date(item.createdAt);
      const startTime = item.startTime ? new Date(item.startTime) : null;
      const endTime = item.endTime ? new Date(item.endTime) : new Date();

      // get date in format dd/mm/yyyy  it locale
      const date = createdAt.toLocaleDateString('it-IT', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        minute: '2-digit',
        hour: '2-digit',
      });

      // Duration is calculated as the difference between the start and end time, in string format HH:mm
      const duration =
        startTime && endTime
          ? dayjs(endTime).diff(dayjs(startTime), 'minutes')
          : 0;

      return (
        <CellMeasurer
          key={key}
          cache={alertsCache}
          columnIndex={0}
          rowIndex={index}
          parent={parent}
        >
          <div style={style}>
            <StyledCardAlerts
              description={item.description}
              eventId={item.id}
              tenantId={selectedTenantId}
              assetId={item.assetId || ''}
              assetName={item.assetName || ''}
              alertName={item.alertName || ''}
              alertSeverity={(item as any).alertSeverity}
              subTypeSeverity={(item as any).subTypeSeverity}
              subTypeName={(item as any).subTypeName}
              date={date.split(',')[0]}
              time={date.split(',')[1].replaceAll(' ', '')}
              duration={duration}
              onClick={() => showModal((item as any).alertId, item)}
              style={{ cursor: 'pointer' }}
            />
          </div>
        </CellMeasurer>
      );
    },
    [alertsCache, lastAlerts, selectedTenantId, showModal],
  );

  const chartAreaFormatterXAxis = useCallback(
    (val: any) => {
      return formatDateOnRangePicker(val, dateRange);
    },
    [dateRange],
  );

  const chartAreaFormatterXAxisStacked = useCallback((text: string) => {
    return utcDayjs(+text).subtract(2, 'hours').format('DD/MM');
  }, []);

  const renderCustomTooltipContent = useCallback(
    (title: any, data: any) => {
      const empty = data[0]?.name.split('_')[0] === 'empty';

      return (
        <ChartTooltip
          unitOfMeasure={consumptionReportState?.unitOfMeasure || ''}
          title={formatDateOnRangePicker(title, 1)}
          type={t('general.power')}
          data={data.slice(-1).map((i: any) => {
            if (empty) return {};
            const state = i.name.split('_')[0];

            const sortedThresholds = [...(thresholds || [])];
            sortedThresholds.sort((a, b) => b.value - a.value);
            const foundThreshold = sortedThresholds.find(
              threshold => threshold.id === state,
            );
            return {
              name: foundThreshold?.label || 'Nessuna soglia',
              value: i.value,
              nameColor: foundThreshold?.hexColor,
            };
          })}
        />
      );
    },
    [consumptionReportState?.unitOfMeasure, t, thresholds],
  );

  const renderCustomTooltipContentForStacked = useCallback(
    (title: any, data: any) => {
      const empty = data[0]?.name.split('_')[0] === 'empty';
      const totalConsumption = data?.reduce?.(
        (p: number, c: any) => p + c?.data?.value,
        0,
      );

      return (
        <ChartTooltip
          unitOfMeasure={consumptionReportState?.unitOfMeasure || ''}
          title={dayjs(Number(title)).subtract(2, 'hours').format('DD/MM')}
          data={data.map((i: any) => {
            if (empty) return {};
            const state = i.name.split('_')[0];

            const sortedThresholds = [...(thresholds || [])];
            sortedThresholds.sort((a, b) => b.value - a.value);
            const foundThreshold = sortedThresholds.find(
              threshold => threshold.id === state,
            );
            return {
              name: foundThreshold?.label || 'Nessuna soglia',
              value: i.value,
              percentage: (Number(i.value) / totalConsumption) * 100,
              nameColor: foundThreshold?.hexColor,
            };
          })}
        />
      );
    },
    [consumptionReportState?.unitOfMeasure, thresholds],
  );

  const renderCustomTooltipContentTemperature = useCallback(
    (title: any, data: any, type?: string) => {
      // TODO BE: time equivale a "10/05, 00:15", dev'essere "10/05/2023 - 00:15"
      const empty = data[0]?.name.split('_')[0] === 'empty';
      console.log({ consumptionReportState });
      return (
        <ChartTooltip
          type={type}
          unitOfMeasure={temperatureReportState?.unitOfMeasure || ''}
          title={formatDateOnRangePicker(title, 1)}
          data={data.slice(-1).map((i: any) => {
            if (empty) {
              return {};
            }
            const state = i.name.split('_')[0];
            let label = 'Not Connected';
            if (state === 'on') label = 'Normal';
            else if (state === 'off') label = 'Alarm';
            else if (state === 'idle') label = 'Warning';
            else return {};
            return {
              name: label ?? i.name,
              nameColor: i.color,
              value: i.value,
            };
          })}
        />
      );
    },
    [consumptionReportState, temperatureReportState?.unitOfMeasure],
  );

  const overviewItems = useMemo(() => {
    return item?.type === 'monitron'
      ? overviewMonitronList(
          assetReport?.uptime || 0,
          assetReport?.workedTime ?? 0,
          assetReport?.workedTimeUnitOfMeasure ?? 'min',
          assetReport?.currentTemperature ?? 0,
          assetReport?.currentTemperatureUnitOfMeasure ?? '°C',
          assetReport?.currentVibration ?? 0,
          assetReport?.currentVibrationUnitOfMeasure ?? '',
          assetReport?.currentTemperatureStatus ?? 0,
          assetReport?.currentVibrationStatus ?? 0,
        )
      : overviewList(
          assetReport?.uptime || 0,
          assetReport?.workedTime ?? 0,
          assetReport?.workedTimeUnitOfMeasure ?? 'min',
          assetReport?.stops?.length ?? 0,
          assetReport?.totalConsumption ?? 0,
          assetReport?.totalConsumptionUnitOfMeasure ?? 'kWh',
          {
            noData: {
              totalTimeInNoData: Number(assetReport?.totalTimeInNoData).toFixed(
                2,
              ),
              totalTimeInNoDataMeasure:
                Number(assetReport?.totalTimeInNoData) > 0
                  ? t(`date.${assetReport?.totalTimeInNoDataMeasure}`)
                  : '',
            },
            thresholds: [
              {
                title: t('thresholds.low'),
                value: assetReport?.totalTimeInLow,
                label: t(`date.${assetReport?.totalTimeInLowMeasure}`),
                color: consumptionColorMapping[consumptionMapping.LOW],
                consumption: `${assetReport?.consumptionLow ?? ''} ${
                  assetReport?.consumptionLowUnitOfMeasure ?? ''
                }`,
              },
              {
                title: t('thresholds.medium'),
                value: assetReport?.totalTimeInMid,
                label: t(`date.${assetReport?.totalTimeInMidMeasure}`),
                color: consumptionColorMapping[consumptionMapping.MEDIUM],
                consumption: `${assetReport?.consumptionMid ?? ''} ${
                  assetReport?.consumptionMidUnitOfMeasure ?? ''
                }`,
              },
              {
                title: t('thresholds.high'),
                value: assetReport?.totalTimeInHigh,
                label: t(`date.${assetReport?.totalTimeInHighMeasure}`),
                color: consumptionColorMapping[consumptionMapping.HIGH],
                consumption: `${assetReport?.consumptionHigh ?? ''} ${
                  assetReport?.consumptionHighUnitOfMeasure ?? ''
                }`,
              },
            ],
          },
        );
  }, [
    assetReport?.consumptionHigh,
    assetReport?.consumptionHighUnitOfMeasure,
    assetReport?.consumptionLow,
    assetReport?.consumptionLowUnitOfMeasure,
    assetReport?.consumptionMid,
    assetReport?.consumptionMidUnitOfMeasure,
    assetReport?.currentTemperature,
    assetReport?.currentTemperatureStatus,
    assetReport?.currentTemperatureUnitOfMeasure,
    assetReport?.currentVibration,
    assetReport?.currentVibrationStatus,
    assetReport?.currentVibrationUnitOfMeasure,
    assetReport?.stops?.length,
    assetReport?.totalConsumption,
    assetReport?.totalConsumptionUnitOfMeasure,
    assetReport?.totalTimeInHigh,
    assetReport?.totalTimeInHighMeasure,
    assetReport?.totalTimeInLow,
    assetReport?.totalTimeInLowMeasure,
    assetReport?.totalTimeInMid,
    assetReport?.totalTimeInMidMeasure,
    assetReport?.totalTimeInNoData,
    assetReport?.totalTimeInNoDataMeasure,
    assetReport?.uptime,
    assetReport?.workedTime,
    assetReport?.workedTimeUnitOfMeasure,
    item?.type,
    t,
  ]);

  return (
    <ConfigProvider theme={darkTheme}>
      <Container>
        {!fullscreen && (
          <Col style={{ display: 'flex', flexDirection: 'column', rowGap: 16 }}>
            {showRangePicker ? (
              <DashboardRangePickerNode />
            ) : (
              <DateTimeFrameSelectDashboard />
            )}
          </Col>
        )}
        <DrawerSection
          title={t('assets.assetOverview').toUpperCase()}
          subtitle={subtitleDrawer}
          extra={
            <RowExtraOverview
              justify="center"
              align="middle"
              fadeIn={fullscreen}
              dir={fullscreen ? 'row' : 'column'}
            >
              {showRangePicker ? (
                <DashboardRangePickerNode />
              ) : (
                <DateTimeFrameSelectDashboard />
              )}
            </RowExtraOverview>
          }
        >
          {/* <div>
            <button onClick={() => changeLanguage('es')}>Switch to Spanish</button>
            <button onClick={() => changeLanguage('it')}>Switch to Italian</button>
          </div> */}
          {isAssetReportLoading ? null : (
            <ContainerOverview
              fullscreen={fullscreen}
              className="cssid--data-drawer-content-overview"
            >
              <Overview
                isLoading={isAssetReportLoading}
                split={fullscreen && !isMobile ? '4' : ''}
                list={overviewItems}
                listSpan={isMobile ? 12 : undefined}
                listGutter={
                  isMobile ? [16, 16] : fullscreen ? [60, 16] : [16, 16]
                }
                colStyle={{
                  flex: isMobile || !fullscreen ? '1 1 50%' : '1',
                }}
                data-cypress="overview"
              />
            </ContainerOverview>
          )}
        </DrawerSection>
        {isAssetReportLoading ? (
          <Loader>
            <Spin size="large" />
          </Loader>
        ) : (
          <>
            <Divider dark />
            <Content
              direction={fullscreen ? 'row' : 'column'}
              gutter={fullscreen ? 32 : undefined}
            >
              <Col
                span={isMobile ? 24 : fullscreen ? 12 : 24}
                className="efficiency"
              >
                {item?.type !== 'monitron' && (
                  <>
                    {consumptionReportState?.points?.length ? (
                      <>
                        <DrawerSection
                          /* extra={
                            fullscreen && showThresholds && (
                              <Button
                                variant={'grey-E3'}
                                size={'s'}
                                onClick={() =>
                                  setIsModifyThresholdsModalOpen(true)
                                }
                              >
                                <EditOutlined style={{}} />{' '}
                                {t('assets.modifyThresholds')}
                              </Button>
                            )
                          } */
                          title={t('general.efficiency').toUpperCase()}
                          titleTooltip={t('assets.assetTrendConsumption')}
                        >
                          {showThresholds ? (
                            <Legend
                              style={{
                                justifyContent: 'start',
                                marginBottom: 16,
                              }}
                              colorTitle={'white'}
                              data={thresholds?.map(threshold => ({
                                label: threshold?.label || '',
                                color: threshold?.hexColor || '',
                              }))}
                            />
                          ) : (
                            <Subtitle style={{ padding: 5 }}>
                              Dopo 3 settimane di monitoraggio il sistema offre
                              una segmenetazione automatica dei consumi per
                              stato di attività degli assett. In alternativa
                              puoi indicare manualmente le fasce.
                            </Subtitle>
                          )}
                          <ChartAreaContainer>
                            {dateRange < 1 ? (
                              <ChartArea
                                dateRange={dateRange}
                                dark
                                {...chartAreaSize}
                                legend={false}
                                isStack={false}
                                areaStyle={
                                  {
                                    // fill: 'rgba(0, 0, 0, 0)',
                                  }
                                }
                                data={consumptionReportState?.points || []}
                                xAxis={{
                                  label: {
                                    formatter: chartAreaFormatterXAxis,
                                  },
                                }}
                                //@ts-ignore
                                unit={
                                  consumptionReportState?.unitOfMeasure || ''
                                }
                                xField={'time'}
                                yField={'value'}
                                // seriesField={'status'}
                                {...(showThresholds
                                  ? { seriesField: 'status' }
                                  : {})}
                                tooltip={{
                                  customContent: renderCustomTooltipContent,
                                  domStyles: tooltip_domStyles,
                                }}
                                thresholds={thresholds}
                              />
                            ) : (
                              <StackedColumn
                                dateRange={dateRange}
                                dark
                                {...chartAreaSize}
                                legend={false}
                                isStack={true}
                                areaStyle={
                                  {
                                    // fill: 'rgba(0, 0, 0, 0)',
                                  }
                                }
                                data={consumptionReportState?.points || []}
                                xAxis={{
                                  label: {
                                    formatter: chartAreaFormatterXAxisStacked,
                                  },
                                }}
                                //@ts-ignore
                                unit={
                                  consumptionReportState?.unitOfMeasure || ''
                                }
                                xField={'time'}
                                yField={'value'}
                                // seriesField={'status'}
                                {...(showThresholds
                                  ? { seriesField: 'status' }
                                  : {})}
                                tooltip={{
                                  customContent:
                                    renderCustomTooltipContentForStacked,
                                  domStyles: tooltip_domStyles,
                                }}
                                thresholds={thresholds}
                              />
                            )}
                          </ChartAreaContainer>
                        </DrawerSection>
                        {!fullscreen && <Divider dark />}
                      </>
                    ) : (
                      <EmptyStateChart
                        dark
                        style={{ height: 200, marginTop: isMobile ? 0 : 99 }}
                      />
                    )}
                  </>
                )}
                {item?.type === 'monitron' && (
                  <>
                    {temperatureReportState?.points?.length ? (
                      <>
                        <DrawerSection
                          title={t('status.temperature').toUpperCase()}
                          titleTooltip={t(
                            'status.actualTemperatureTrend',
                          ).toUpperCase()}
                        >
                          <Legend
                            style={{
                              justifyContent: 'start',
                              marginBottom: 16,
                            }}
                            colorTitle={'white'}
                            data={mapTempHeatmap.map(i => ({
                              label: i.label,
                              color: i.color,
                            }))}
                          />{' '}
                          <ChartAreaContainer>
                            <ChartArea
                              dateRange={dateRange}
                              dark
                              {...chartAreaSize}
                              legend={false}
                              isStack={false}
                              areaStyle={
                                {
                                  // fill: 'rgba(0, 0, 0, 0)',
                                }
                              }
                              data={temperatureReportState?.points || []}
                              xAxis={{
                                label: {
                                  formatter: chartAreaFormatterXAxis,
                                },
                              }}
                              //@ts-ignore
                              unit={temperatureReportState?.unitOfMeasure || ''}
                              xField={'time'}
                              yField={'value'}
                              seriesField={'status'}
                              tooltip={{
                                customContent: (title, data) =>
                                  renderCustomTooltipContentTemperature(
                                    title,
                                    data,
                                    t('status.temperature'),
                                  ),
                                domStyles: tooltip_domStyles,
                              }}
                            />
                          </ChartAreaContainer>
                        </DrawerSection>
                        {!fullscreen && <Divider dark />}
                      </>
                    ) : (
                      <EmptyStateChart
                        dark
                        style={{ height: 200, marginTop: isMobile ? 0 : 99 }}
                      />
                    )}
                  </>
                )}
                {fullscreen && dateRange !== 0 && (
                  <div style={{ marginTop: 48 }}>
                    <Divider hideLine dark />
                    {assetHeatMapReport?.points?.length ? (
                      <DrawerSection
                        title={t('general.activity').toLocaleUpperCase()}
                        titleTooltip={t('status.assetActivityChart')}
                      >
                        <DataDrawerContentHeatmap
                          // @ts-ignore
                          data={assetHeatMapReport?.points}
                          defaultValue={[am8, pm6]}
                          configType={HeatmapConfigType.SQUARE}
                          hasLimitedPalette
                        />
                      </DrawerSection>
                    ) : (
                      <EmptyStateChart dark style={{ height: 200 }} />
                    )}
                  </div>
                )}
              </Col>
              <Col span={isMobile ? 24 : fullscreen ? 12 : 24}>
                {item?.type !== 'monitron' && (
                  <DrawerSection
                    className="cssid--data-drawer-content-drawer-section-stops"
                    title={(t('alerts.alerts') as string).toLocaleUpperCase()}
                    titleTooltip={t('alerts.list')}
                    /*      Questo bottone dovrebbe diventare un "Vedi Tutti" ??               extra={
                      <Button
                        center
                        shape="square"
                        shapeSize={32}
                        variant="grey-E3"
                        onClick={() => setIsAddModalOpen(true)}
                      >
                        <PlusCircleOutlined style={{ fontSize: '22px' }} />
                      </Button>
                    } */
                  >
                    {lastAlerts?.length ? (
                      <>
                        <div
                          className="stops"
                          style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            minHeight: 485,
                          }}
                        >
                          <Row
                            style={{
                              flex: 1,
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <AutoSizer>
                              {({ height, width }) => {
                                return (
                                  <List
                                    ref={listRef}
                                    width={width}
                                    height={height}
                                    rowCount={
                                      Math.min(lastAlerts?.length, 1000) || 0
                                    }
                                    rowHeight={alertsCache.rowHeight}
                                    deferredMeasurementCache={alertsCache}
                                    rowRenderer={rowRenderer}
                                  />
                                );
                              }}
                            </AutoSizer>
                          </Row>
                        </div>
                      </>
                    ) : isLoadingLastAlerts ? (
                      <Loader>
                        <Spin size="large" />
                      </Loader>
                    ) : (
                      <EmptyState
                        dark
                        style={{ height: 200 }}
                        image={
                          <Icon
                            name="emergency_home"
                            style={{ fontSize: 48 }}
                            color="currentColor"
                          />
                        }
                        title={t('alerts.noAlertFound')}
                      />
                    )}
                  </DrawerSection>
                )}
                {item?.type === 'monitron' && (
                  <>
                    {vibrationReportState?.points?.length ? (
                      <>
                        <DrawerSection
                          title={t('general.vibration').toUpperCase()}
                          titleTooltip={t('status.periodVibrationTrend')}
                        >
                          <Legend
                            style={{
                              justifyContent: 'start',
                              marginBottom: 16,
                            }}
                            colorTitle={'white'}
                            data={mapTempHeatmap.map(i => ({
                              label: i.label,
                              color: i.color,
                            }))}
                          />{' '}
                          <ChartAreaContainer>
                            <ChartArea
                              dateRange={dateRange}
                              dark
                              {...chartAreaSize}
                              legend={false}
                              isStack={false}
                              areaStyle={
                                {
                                  // fill: 'rgba(0, 0, 0, 0)',
                                }
                              }
                              data={vibrationReportState?.points || []}
                              xAxis={{
                                label: {
                                  formatter: chartAreaFormatterXAxis,
                                },
                              }}
                              //@ts-ignore
                              unit={vibrationReportState?.unitOfMeasure || ''}
                              xField={'time'}
                              yField={'value'}
                              seriesField={'status'}
                              tooltip={{
                                customContent: (title, data) =>
                                  renderCustomTooltipContentTemperature(
                                    title,
                                    data,
                                    t('general.vibrations'),
                                  ),
                                domStyles: tooltip_domStyles,
                              }}
                            />
                          </ChartAreaContainer>
                        </DrawerSection>
                        {!fullscreen && <Divider dark />}
                      </>
                    ) : (
                      <EmptyStateChart
                        dark
                        style={{ height: 200, marginTop: isMobile ? 0 : 99 }}
                      />
                    )}
                  </>
                )}
              </Col>
            </Content>
          </>
        )}
      </Container>
      <ModalAddStop
        open={isAddModalOpen}
        onOk={handleOkAddModal}
        onCancel={handleCancelAddModal}
      />
      <ModalModifyThresholds
        open={isModifyThresholdsModalOpen}
        thresholds={thresholds}
        onOk={handleOkThresholdsModal}
        onCancel={handleCancelThresholdsModal}
        consumptionReportState={consumptionReportState}
        dateRange={dateRange}
        chartAreaFormatterXAxis={chartAreaFormatterXAxis}
        tooltip_domStyles={tooltip_domStyles}
      />
      {isAlertDetailsModalOpen && (
        <AlertDetailsModal
          open={isAlertDetailsModalOpen}
          event={currentEventId}
          onCancel={onCancelAlertDetailsModal}
          onOk={() => {
            queryClient
              .invalidateQueries(GET_GENERATED_EVENTS_QUERY_KEY)
              .catch(e => console.log('Error', e.message));
            setUpdateData(updateData + 1);
          }}
        />
      )}
    </ConfigProvider>
  );
}
