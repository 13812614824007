import Button from '@components/atoms/button';
import { fontSize } from '@src/globalStyle';
import { Input, InputProps } from 'antd';
import { ReactNode, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTenant } from '@src/modules/asset';
import { useUpdateUser } from '@src/api/user';
import { device } from '@theme/breakpoints';
import { useTranslation } from 'react-i18next';

interface Prop extends InputProps {
  label: string;
  inputValueDefault?: string;
  extraCtas?: ReactNode;
  userId?: string;
  propName: string;
  onUpdate?: () => void;
}

const StyledRowInput = styled.div<{
  hasFocus: boolean;
}>`
  display: flex;
  padding: 14px;
  background: ${({ hasFocus }) =>
    !!hasFocus ? 'rgba(71, 134, 255, 0.12)' : '#fff'};
  border: 1.5px solid
    ${({ hasFocus }) => (!!hasFocus ? '#4786FF' : 'transparent')};
  border-radius: 6px;
  min-height: 74px;
  flex-direction: column;
  transition: border-color 0.3s linear;
  gap: 16px;

  .ant-input:focus {
    box-shadow: none;
  }

  input[type='email']:disabled {
    color: inherit;
  }

  input {
    font-weight: 700;
  }

  @media ${device.laptop} {
    flex-direction: row;
    align-items: center;
    gap: 0;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media ${device.laptop} {
  }
`;

const Label = styled.span`
  display: inline-block;
  font-weight: 700;
  ${fontSize.m}
  color: #373737;
  width: 80px;
  margin-right: 16px;

  @media ${device.laptop} {
    text-align: end;
    width: 128px;
    margin-right: 80px;
  }
`;

const StyledInput = styled(Input)`
  /* width: 208px; */
  height: 44px;

  @media ${device.laptop} {
    width: 208px;
  }
`;

const StyledSpan = styled.span`
  display: inline-block;
  font-weight: 700;
  ${fontSize.m}
  color: #373737;
  width: 80px;
  margin-right: 16px;

  @media ${device.laptop} {
    text-align: start;
    width: 128px;
    margin-right: 80px;
  }
`;

const Ctas = styled.div`
  gap: 16px;
  display: flex;
  flex: 1;
  justify-content: flex-end;

  @media ${device.laptop} {
    padding-right: 18px;
  }
`;

export const StyledButton = styled(Button)`
  height: 44px !important;
`;

export default function RowInput({
  extraCtas,
  label,
  userId,
  propName,
  inputValueDefault,
  onUpdate,
  ...props
}: Prop) {
  const { tenant } = useTenant();
  const { t } = useTranslation();
  const [defaultValue, setDefaultValue] = useState<string>(
    inputValueDefault ?? '',
  );
  const [inputValue, setInputValue] = useState<string>(inputValueDefault ?? '');
  const [hasFocus, setHasFocus] = useState(false);

  const updateUser = useUpdateUser();

  const onClickSave = useCallback(async () => {
    updateUser.mutate(
      {
        userId: userId as string,
        tenantId: tenant?.id,
        req: {
          [propName as string]: inputValue,
        },
      },
      { onSuccess: onUpdate },
    );
    setDefaultValue(inputValue);
    setHasFocus(false);
  }, [inputValue, onUpdate, propName, tenant?.id, updateUser, userId]);

  const onClickReset = useCallback(() => {
    setInputValue(defaultValue);
    setHasFocus(false);
  }, [defaultValue]);

  return (
    <StyledRowInput hasFocus={hasFocus}>
      <InputContainer>
        <Label>{label}</Label>
        {
          props.disabled ?
            <StyledSpan>{inputValue}</StyledSpan> :
            <StyledInput
              value={inputValue}
              onFocus={() => {
                setHasFocus(() => true);
              }}
              onChange={e => {
                setInputValue(e.target.value);
              }}
              onBlur={() => setHasFocus(() => false)}
              {...props}
            />
        }

      </InputContainer>
      <Ctas
        style={{
          display: hasFocus ? 'flex' : 'none',
          justifyContent: 'flex-end'
        }}
      >
        {extraCtas ?? (
          <>
            <StyledButton variant="ghost-black" size="l" onClick={onClickReset}>
              {t('general.cancel')}
            </StyledButton>
            <StyledButton variant="fill-white" size="l" onClick={onClickSave}>
            {t('general.save')}
            </StyledButton>
          </>
        )}
      </Ctas>
    </StyledRowInput>
  );
}
