export const TENANT_API = 'tenant';
export const ASSET_API = 'asset';
export const REPORT_API = 'report';
export const NOTE_API = 'note';
export const USER_API = 'user';
export const SENSOR_API = 'sensor';
export const FAQ_API = 'faq';
export const ALERT_API = 'alert';

// query keys must me unique
export const GET_ASSET_THRESHOLDS_QUERY_KEY = 'get-asset-thresholds';
export const GET_ASSET_DETAILS_QUERY_KEY = 'get-asset-details';
export const GET_ASSETS_QUERY_KEY = 'get-assets';
export const UPDATE_USER_QUERY_KEY = 'update-user';
export const GET_ASSET_SENSORS_QUERY_KEY = 'get-asset-sensors';
export const GET_TENANTS_QUERY_KEY = 'get-tenants';
export const GET_TENANT_BY_ID_QUERY_KEY = 'get-tenant-by-id';
export const ASSET_REPORT_QUERY_KEY = 'asset-report';
export const ASSET_HEATMAP_REPORT_QUERY_KEY = 'asset-heatmap-report';
export const ASSET_REPORT_CONSUMPTION_STATE_QUERY =
  'asset-report-consumption-state';
export const ASSET_REPORT_TEMPERATURE_STATE_QUERY =
  'asset-report-temperature-state';
export const ASSET_REPORT_VIBRATION_STATE_QUERY =
  'asset-report-vibration-state';
export const TENANT_REPORT_QUERY_KEY = 'tenant-report';
export const TENANT_SLIM_REPORT_QUERY_KEY = 'tenant-slim-report';
export const GET_ASSET_NOTES_QUERY_KEY = 'get-asset-notes';
export const USE_SENSORS_QUERY_KEY = 'use-sensors';
export const USE_FAQ_GET = 'use-faq-get';
export const GET_AUTHORS_QUERY_KEY = 'get-authors';
export const GET_ASSET_ALERTS_QUERY_KEY = 'get-alerts';
export const GET_ASSET_ALERTS_TABLE_QUERY_KEY = 'get-alerts-table';
export const GET_ALERT_DETAILS_QUERY_KEY = 'alert-details';
export const GET_ALERT_REPORT_QUERY_KEY = 'alerts-report';
export const GET_GENERATED_EVENTS_QUERY_KEY = 'get-generated-events';
