import { G2, Heatmap, HeatmapConfig } from '@ant-design/plots';
import classNames from 'classnames';
import { useMemo } from 'react';
import styled from 'styled-components';

import { baseConfigAxis } from '../common-configs';
import { CommonChart } from '../common.interface';
import { generateHeatMapColours, mapPointValueToColorBlend } from '../utils';
import {
  consumptionColorMapping,
  consumptionMapping,
  previousConsumptionMapping,
} from '@src/utils/mapping.utils';
import { capitalizeFirstLetter } from '@src/utils/string.utils';

export enum ConfigType {
  BASE = 'BASE',
  BAR = 'BAR',
  SQUARE = 'SQUARE',
}

const colorDisabledCell = 'ececec';

export interface Props extends CommonChart, Partial<HeatmapConfig> {
  configType?: keyof typeof ConfigType;
  hasLimitedPalette?: boolean;
  // isFullDay: boolean;
}

const StyledHeatmap = styled(Heatmap)`
  // TODO IMPROVEMENT: dentro label in ConfigType.SQUARE non funziona
  svg g:last-of-type g:last-of-type g text {
    pointer-events: none;
  }
`;

export const assetsConfig = {
  xField: 'Time',
  yField: 'Name',
  colorField: 'Tooltips', // or seriesField in some cases
  color: (prop: { Tooltips: { prop: string; value: string }[] }) => {
    const Value = prop.Tooltips;
    const array = Value.map(item => ({
      ...item,
      value: Number(item.value || 0),
    }));
    const maxItem = array.reduce((prev, current) =>
      prev.value > current.value ? prev : current,
    );

    if (
      maxItem.prop === capitalizeFirstLetter(previousConsumptionMapping.HIGH)
    ) {
      return consumptionColorMapping[consumptionMapping.HIGH];
    } else if (
      maxItem.prop === capitalizeFirstLetter(previousConsumptionMapping.MEDIUM)
    ) {
      return consumptionColorMapping[consumptionMapping.MEDIUM];
    } else if (
      maxItem.prop === capitalizeFirstLetter(previousConsumptionMapping.LOW)
    ) {
      return consumptionColorMapping[consumptionMapping.LOW];
    } else {
      return 'white';
    }
  },
};

const ChartHeatmap = ({
  configType = ConfigType.BASE,
  hasLimitedPalette,
  ...props
}: Props) => {
  const xAxisSubTickLineCount = props.data && props.data?.length < 40 ? 1 : 3; // teoricamente un barretta ogni 15'
  const commonConfig = useMemo(
    () => ({
      ...assetsConfig,
      heatmapStyle: {
        stroke: 'black',
        lineWidth: 0,
      },
      style: {
        lineWidth: 3,
      },
      yAxis: {
        label: {
          formatter: (text: string, item: any, index: number) => {
            return text;
          },
          autoEllipsis: true,
        },
        tickInterval: 1,
      },
      xAxis: {
        label: {
          formatter: (text: string, item: any, index: number) => {
            return new Date(item).toLocaleString('it-IT', {
              minute: '2-digit',
              month: '2-digit',
            });
          },
        },
      },
      animation: false,
    }),
    [],
  );

  const baseConfigAxisSquare = useMemo(
    () => ({
      ...baseConfigAxis,
      grid: {
        line: {
          style: {
            lineWidth: 0,
          },
        },
      },
    }),
    [],
  );

  const rowBricksNumber = useMemo(
    () =>
      [
        // @ts-ignore
        ...new Set(
          (
            props.data?.filter(el => el.Name === props.data?.[0].Name) ?? []
          ).map(item => item.Time),
        ),
      ]?.length ?? 0,
    [props.data],
  );

  const config = useMemo(
    () => ({
      [ConfigType.BASE]: { ...commonConfig },
      [ConfigType.BAR]: {
        ...commonConfig,
        shape: 'bar-polygon',
        xAxis: {
          ...baseConfigAxisSquare,
          tickInterval: props.data && props.data.length > 100 ? 2 : 1, // ovvero 60 / 5
          subTickLine: {
            count: xAxisSubTickLineCount, // 97 | 289
          },
        },
        yAxis: {
          ...baseConfigAxisSquare,
        },
      },
      [ConfigType.SQUARE]: {
        ...commonConfig,
        sizeField: 'Value',
        shape: 'square-polygon',
        label: {
          style: {
            fill: 'black',
            // shadowBlur: 2,
            // shadowColor: 'rgba(0, 0, 0, .45)',
          },
          formatter: (datum: any) => {
            if (datum.Time.includes(',') || rowBricksNumber > 7) return '';
            return '';
            // return `${datum.Value}%`;
          },
        },
        yAxis: {
          ...baseConfigAxisSquare,
        },
        xAxis: {
          ...baseConfigAxisSquare,
          position: 'top',
        },
      },
    }),
    [
      baseConfigAxisSquare,
      commonConfig,
      props.data,
      rowBricksNumber,
      xAxisSubTickLineCount,
    ],
  );

  const additionalProps = useMemo(
    () => [{ ...config[configType].xAxis, ...props.xAxis }],
    [config, configType, props.xAxis],
  );

  G2.registerShape('polygon', 'bar-polygon', {
    draw(cfg: any, container) {
      const group = container.addGroup();
      const points = cfg.points;

      const attrs = {
        stroke: cfg.color,
        lineWidth: 0,
        fill: cfg.color,
      };

      const heightSingleCell = points[1].y - points[0].y;
      const freeSpace = heightSingleCell / 3;
      const halfFreeSpace = freeSpace / 2;

      const path = [
        ['M', points[0].x, points[0].y + halfFreeSpace],
        ['L', points[1].x, points[1].y - halfFreeSpace],
        ['L', points[2].x, points[2].y - halfFreeSpace],
        ['L', points[3].x + 1, points[3].y + halfFreeSpace],
        ['Z'],
      ];

      // @ts-ignore
      attrs.path = this.parsePath(path);
      group.addShape('path', {
        attrs,
      });

      return group;
    },
  });

  G2.registerShape('polygon', 'square-polygon', {
    draw(cfg: any, container) {
      const group = container.addGroup();
      const points = cfg.points;
      const pointValue = cfg.data.Value / 100;

      const isFuture = (cfg?.data?.Tooltips || []).every(
        (i: any) => i.value === '0',
      );

      let color = hasLimitedPalette
        ? generateHeatMapColours(cfg?.data?.Tooltips)
        : mapPointValueToColorBlend(pointValue);

      if (isFuture) {
        color = colorDisabledCell;
      }

      const attrs = {
        stroke: 'white',
        lineWidth: 1,
        fill: `#${color}`,
      };
      if (points.length === 0) return group;
      const path = [
        ['M', points[0].x, points[0].y],
        ['L', points[1].x, points[1].y],
        ['L', points[2].x, points[2].y],
        ['L', points[3].x + 1, points[3].y],
        ['Z'],
      ];

      // @ts-ignore
      attrs.path = this.parsePath(path);
      group.addShape('path', {
        attrs,
      });

      return group;
    },
  });

  return (
    // @ts-ignore
    <StyledHeatmap
      className={classNames(
        'cssid--heatmap',
        `cssid--${configType.toLocaleLowerCase()}`,
      )}
      renderer="svg"
      {...config[configType]}
      {...props}
      {...additionalProps}
    />
  );
};

export default ChartHeatmap;
