import { API } from 'aws-amplify';
import { ASSET_API } from '@src/api';

// TODO: types from backend
export function fetchCreateEvent(
  tenantId: string,
  assetId: string,
  event: any,
) {
  return API.post(ASSET_API, `/tenant/${tenantId}/${assetId}/event`, {
    body: event,
  });
}
