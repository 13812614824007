import { Form, Input } from 'antd';
import { ButtonSubmit, Section, StyledForm, Title } from './common';

interface NewPasswordFormProps {
  // TODO: types
  onFinish: (values: any) => void;
  onFinishFailed: (e: any) => void;
}

export default function NewPasswordForm({
  onFinish,
  onFinishFailed,
}: NewPasswordFormProps) {
  return (
    <Section>
      <Title>Nuova password</Title>

      <StyledForm
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Password is required' }]}
        >
          <Input.Password placeholder="Password" />
        </Form.Item>

        <Form.Item
          label="Password Confirm"
          name="passwordConfirm"
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: 'Please confirm your password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('Passwords do not match'));
              },
            }),
          ]}
          style={{ marginBottom: 0 }}
        >
          <Input.Password placeholder="Conferma password" />
        </Form.Item>

        <Form.Item>
          <ButtonSubmit dark variant="primary" htmlType="submit">
            Aggiorna
          </ButtonSubmit>
        </Form.Item>
      </StyledForm>
    </Section>
  );
}
