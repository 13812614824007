import { API } from 'aws-amplify';
import { REPORT_API, ReportQueryParams } from '@src/api';
import { GetReportSlimTenantResponseDto } from '@asset-manager/types';

export async function fetchTenantSlimReport(
  id: string,
  filters: ReportQueryParams,
): Promise<GetReportSlimTenantResponseDto | undefined> {
  const qs = filters ? `?${new URLSearchParams(filters).toString()}` : '';
  if (!id) return;
  return await API.get(REPORT_API, `/tenant/${id}/slim${qs}`, {});
}
