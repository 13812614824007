import { API } from 'aws-amplify';
import { ALERT_API } from '@src/api';

export async function deleteAlert(tenantId?: string, alertId?: string) {
  if (tenantId && alertId) {
    return API.del(ALERT_API, `/tenant/${tenantId}/${alertId}`, {});
  }

  return [];
}
