import { API } from 'aws-amplify';
import { TENANT_API } from '@src/api';

export async function fetchTenantById(id?: string) {
  if (id) {
    return await API.get(TENANT_API, `/${id}`, {});
  }

  return [];
}
