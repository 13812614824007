import { useTenantReport as useTenantReportQuery } from '@src/api';
import { reportExtraFilterState } from '@store/report/report-extra-filter-state';
import { reportFiltersState } from '@store/report/reportFiltersState';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useRecoilState } from 'recoil';

export function useTenantReport() {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [reportFilters] = useRecoilState(reportFiltersState);
  const [reportExtraFilter] = useRecoilState(reportExtraFilterState);

  const {
    data: tenantReport,
    // isLoading: isReportLoading,
    isLoading: isReportLoading,
    isFetching: isReportFetching,
  } = useTenantReportQuery(selectedTenantId, {
    ...reportFilters,
    ...reportExtraFilter,
  });

  return {
    selectedTenantId,
    tenantReport,
    isReportLoading,
    isReportFetching,
  };
}
