import {
  ConfigProvider,
  DatePicker as DatePickerAntd,
  TimeRangePickerProps,
} from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useMemo } from 'react';

// TODO: not work
// dayjs.extend(require('dayjs/plugin/weekday'));
dayjs.extend(require('dayjs/plugin/utc'));

// @ts-ignore
const utcDayjs = dayjs.utc;

interface Prop extends TimeRangePickerProps {}

export default function RangePicker({ ...props }: Prop) {
  const rangePresets: {
    label: string;
    value: [Dayjs, Dayjs];
  }[] = useMemo(
    () => [
      {
        label: t('general.today'),
        value: [utcDayjs().startOf('day'), utcDayjs().endOf('day')],
      },
      {
        label: t('general.thisWeek'),
        value: [utcDayjs().startOf('week').add(1, 'day'), utcDayjs()],
      },
      {
        label: t('general.lastWeek'),
        value: [
          utcDayjs().subtract(1, 'week').startOf('week').add(1, 'day'),
          utcDayjs().subtract(1, 'week').endOf('week').add(1, 'day'),
        ],
      },
      {
        label: t('general.thisMonth'),
        value: [utcDayjs().startOf('month'), utcDayjs()],
      },
      {
        label: t('general.lastMonth'),
        value: [
          utcDayjs().subtract(1, 'month').startOf('month'),
          utcDayjs().subtract(1, 'month').endOf('month'),
        ],
      },
      // {
      //   label: t('general.thisYear'),
      //   value: [utcDayjs().startOf('year'), utcDayjs()],
      // },
      // {
      //   label: t('general.lastYear'),
      //   value: [
      //     utcDayjs().subtract(1, 'year').startOf('year'),
      //     utcDayjs().subtract(1, 'year').endOf('year'),
      //   ],
      // },
    ],
    [],
  );

  return (
    <DatePickerAntd.RangePicker
      format="DD/MM/YYYY"
      presets={rangePresets}
      {...props}
    />
  );
}
