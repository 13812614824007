import { API } from 'aws-amplify';
import { NOTE_API, NoteQueryParams, NoteResponse } from '@src/api';

export async function fetchAssetNotes(
  tenantId: string,
  assetId: string,
  filters: NoteQueryParams,
  numPagedAlreadyFetched?: number,
) {
  const params = new URLSearchParams();

  if (filters.limit) params.append('limit', filters.limit);
  if (filters.skip) {
    if (numPagedAlreadyFetched) {
      params.append('skip',`${numPagedAlreadyFetched * Number(filters.limit)}`);
    }
  }
  if (filters.text) params.append('text', filters.text);
  if (filters.order) params.append('order', filters.order);
  if (filters.sort) params.append('sort', filters.sort);
  if (filters.endDate) params.append('endDate', filters.endDate);
  if (filters.startDate) params.append('startDate', filters.startDate);
  if (filters.userId) params.append('userId', filters.userId);
  if (filters.eventId) params.append('eventId', filters.eventId);
  if (filters.alertId) params.append('alertId', filters.alertId);

  return (
    (await API.get(
      NOTE_API,
      `/tenant/${tenantId}/asset/${assetId}?${params.toString()}`,
      {},
    )) as NoteResponse
  ).items;
}
