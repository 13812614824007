import { useMutation } from 'react-query';
import { fetchCreateEvent } from '@src/api/event/fetch-create-event';
import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { selectedAssetIdState } from '@store/asset';

export function useCreateEvent() {
  // const queryClient = useQueryClient();
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [selectedAssetId] = useRecoilState(selectedAssetIdState);

  return useMutation({
    mutationFn: (data: {
      type: string;
      startTime: string;
      endTime: string;
      description: string;
    }) => fetchCreateEvent(selectedTenantId, selectedAssetId, data),
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: [ASSET_REPORT_QUERY_KEY] });
    },
  });
}
