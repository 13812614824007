import { Divider as DividerAntd } from 'antd';
import { HTMLAttributes } from 'react';
import styled from 'styled-components';

interface Props extends HTMLAttributes<HTMLDivElement> {
  hideLine?: boolean;
  dark?: boolean;
}

export const StyledDivider = styled(DividerAntd)<{
  hideline?: string;
  dark?: string;
}>`
  border-color: ${props => (props.dark === 'true' ? '#373737' : '#c8c8c8')};
  margin: 16px 0;
  ${props => (props.hideline === 'true' ? 'border: none;' : '')}
`;

export default function Divider({ hideLine = false, dark = false }: Props) {
  return (
    <StyledDivider hideline={hideLine.toString()} dark={dark.toString()} />
  );
}
