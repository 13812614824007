import { API } from 'aws-amplify';
import { UserUpdateUserRequestDto } from '@asset-manager/types';
import { USER_API } from '@src/api';

export async function postUserUpdate(
  tenantId?: string,
  userId?: string,
  req?: UserUpdateUserRequestDto,
) {
  if (tenantId && userId) {
    return await API.put(USER_API, `/tenant/${tenantId}/${userId}`, {
      body: req || {},
    });
  }

  return [];
}
