import { useEffect, useState } from 'react';
import { size, sizeKey } from '../theme/breakpoints';

export const useMediaQuery = (screen: sizeKey) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const query = `(min-width: ${size[screen]})`;
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, screen]);

  return matches;
};

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const listener = () => setWindowWidth(window.innerWidth);

  useEffect(() => {
    listener();
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, []);

  return windowWidth;
};

// onClient(() => {...});
export const onClient = (handler: any) => {
  if (typeof window !== 'undefined') handler();
};

// mediaQuery((device) => {...});
export const mediaQuery = (handler: any) => {
  onClient(() => {
    window.addEventListener('resize', () => {
      handler(window.innerWidth);
    });
    handler(window.innerWidth);
  });
};

export const useScreenType = () => {
  const width = useWindowWidth();

  const isMobile = width < 1024;

  return {
    isMobile,
  };
};
