import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Button from '@components/atoms/button';
import { Footer, ModalProp } from '@components/atoms/modal';
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  SelectProps,
  Space,
  Tag,
  Collapse as CollapseAntd,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledModal } from '../modal-add-stop/modal-add-stop';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import {
  GetReportAssetStatePointResponseDto,
  GetReportAssetStateResponseDto,
  ReportUpdateThresholdItemDto,
} from '@asset-manager/types';
import { useWatch } from 'antd/es/form/Form';
import { Text } from '@src/modules/amplify/components/common';
import ChartArea from '@components/molecules/charts/area';
import ChartPreview from '@src/routes/Assets/components/drawer-contents/data-drawer-content/data-drawer-chart-preview';
import { createThresholds } from '@src/api/report/create-thresholds';
import { useGetAsset } from '@src/api';
import { useTenantSlimReport } from '@src/modules/tenant';
import { CirclePicker, SketchPicker } from 'react-color';

const colorHexList = [
  '#FF0000',
  '#FF7F00',
  '#30795d',
  '#0000FF',
  '#4B0082',
  '#9c0daa',
  '#e1d73a',
];

interface Prop extends ModalProp {
  thresholds?: ReportUpdateThresholdItemDto[];
  dateRange: number;
  consumptionReportState: GetReportAssetStateResponseDto | undefined;
  chartAreaFormatterXAxis: (val: any) => string;
  tooltip_domStyles: any;
}

// const darkTheme = true;
const isDarkTheme = true;

const StyledInput = styled(Input)`
  width: 84px;
  :where(
      .ant-input-affix-wrapper:focus,
      .ant-input-affix-wrapper-focused,
      .ant-input:focus,
      .ant-input-focused
    ) {
    border-color: rgb(71, 134, 255) !important;
  }
`;

const Collapse = styled(CollapseAntd)`
  background-color: inherit;
  margin: -5px 0;

  .ant-collapse-item {
    border: none;
    margin: 5px 0;
    background-color: #fff;
    border-radius: 6px !important; // l'important serve per quando cen n'è solo uno
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 40px;
  }

  .ant-collapse-header {
    padding: 16px !important;
  }
`;

type TagRender = SelectProps['tagRender'];

export default function ModalModifyThresholds({
  onOk,
  onCancel,
  // thresholds,
  ...props
}: Prop) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const thresholds = useWatch('thresholds', form);

  const { data: currentAsset } = useGetAsset();
  const { selectedTenantId } = useTenantSlimReport();

  const [initialValues, setInitialValues] = useState<
    ReportUpdateThresholdItemDto[]
  >([]);

  const [updatedThresholds, setUpdatedThresholds] = useState<
    ReportUpdateThresholdItemDto[]
  >([]);

  useEffect(() => {
    if (props?.thresholds?.length) {
      setInitialValues(props?.thresholds);
      setUpdatedThresholds(props?.thresholds);
    }
  }, [props?.thresholds]);

  // TODO: types
  const onFinish = async () => {
    debugger;
    form
      .validateFields()
      .then(values => {
        const newThresholds = values?.thresholds?.map((threshold: any) => {
          return {
            value: threshold?.value || 0,
            id: '',
            hexColor: threshold?.hexColor || '',
            label: threshold?.label || '',
          };
        });
        const body = {
          items: newThresholds,
        };
        createThresholds(selectedTenantId, currentAsset?.id, body);
        onCancel && onCancel();
      })
      .catch(e => console.log('Error: ', e));
  };

  const onChangeSelect = (index: number) => (e: any) => {
    debugger;
    const newValue = e.hex;
    const newThresholds = [...(updatedThresholds || [])];
    if (!newThresholds.length) {
      newThresholds.push({ hexColor: '#FF0000', label: '', value: 0, id: '' });
    }
    newThresholds[index].hexColor = newValue;

    setUpdatedThresholds(newThresholds);
    setShowColorPicker(false);
  };

  const onChangeInputLabel = (index: number) => (e: any) => {
    const newValue = e.target.value;
    const newThresholds = [...(updatedThresholds || [])];
    if (!newThresholds.length) {
      newThresholds.push({ hexColor: '#FF0000', label: '', value: 0, id: '' });
    }
    newThresholds[index].label = newValue;
    setUpdatedThresholds(newThresholds);
  };

  const onChangeInputNumber = (index: number) => (e: any) => {
    // debugger;
    const newValue = e.target.value;
    const newThresholds = [...(updatedThresholds || [])];
    if (!newThresholds.length) {
      newThresholds.push({ hexColor: '#FF0000', label: '', value: 0, id: '' });
    }
    newThresholds[index].value = newValue;
    setUpdatedThresholds(newThresholds);
  };

  const [showColorPicker, setShowColorPicker] = useState(false);
  const [currentPickerIndex, setCurrentPickerIndex] = useState(0);

  const onClickColorPicker = (index: number) => () => {
    setCurrentPickerIndex(index);
    setShowColorPicker(prev => !prev);
  };

  return (
    <StyledModal
      width={640}
      dark={isDarkTheme}
      title={t('assets.modifyThresholds')}
      onCancel={onCancel}
      footer={
        <Footer>
          <>
            <Button
              type="button"
              variant="outline-white"
              onClick={onCancel}
              key="back"
            >
              {t('general.cancel')}
            </Button>
            <Button
              type="button"
              variant="grey"
              key="submit"
              onClick={onFinish}
            >
              {t('general.saveAndActive')}
            </Button>
          </>
        </Footer>
      }
      {...props}
    >
      <Form
        name="add-stop"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        form={form}
        autoComplete="off"
      >
        <Space size={16} direction="vertical" style={{ width: '100%' }}>
          <Form.List initialValue={initialValues} name="thresholds">
            {(fields, { add, remove }) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {fields.map(({ name, key, ...field }, index) => (
                  <div
                    style={{
                      display: 'flex',
                      padding: 10,
                    }}
                  >
                    <Col span={4}>
                      {' '}
                      <Form.Item
                        label={<Text>Colore</Text>}
                        labelCol={{ span: 24 }}
                        name={[name, 'hexColor']}
                        // initialValue={thresholds?.[index].hexColor}
                      >
                        <div>
                          <div
                            style={{
                              padding: '5px',
                              background: '#fff',
                              borderRadius: '1px',
                              boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                              display: 'inline-block',
                              cursor: 'pointer',
                            }}
                            onClick={onClickColorPicker(index)}
                          >
                            <div
                              style={{
                                width: '24px',
                                height: '24px',
                                borderRadius: '12px',
                                background: updatedThresholds[index].hexColor, // thresholds?.[index].hexColor,
                              }}
                            />
                          </div>
                          {showColorPicker && (
                            <div
                              id="colorPickerP"
                              style={{ position: 'absolute', zIndex: '1000' }}
                            >
                              <div
                                style={{
                                  position: 'fixed',
                                  top: '0px',
                                  right: '0px',
                                  bottom: '0px',
                                  left: '0px',
                                }}
                                onClick={() => setShowColorPicker(false)}
                              />
                              <SketchPicker
                                color={
                                  updatedThresholds?.[currentPickerIndex]
                                    .hexColor
                                }
                                onChangeComplete={onChangeSelect(
                                  currentPickerIndex,
                                )}
                              />
                            </div>
                          )}
                        </div>
                      </Form.Item>
                    </Col>
                    <Col span={8}>
                      <Form.Item
                        label={<Text>Nome fascia</Text>}
                        labelCol={{ span: 24 }}
                        name={[name, 'label']}
                        initialValue={thresholds?.[index].label}
                      >
                        <StyledInput onChange={onChangeInputLabel(index)} />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Row align={'bottom'} justify={'center'}>
                        <Text style={{ fontWeight: 'bold' }}>
                          Maggiore di:{' '}
                        </Text>
                        <Form.Item
                          label={<Text>Soglia Kwh</Text>}
                          labelCol={{ span: 24 }}
                          name={[name, 'value']}
                          initialValue={thresholds?.[index].value}
                        >
                          <StyledInput
                            onChange={onChangeInputNumber(index)}
                            type="number"
                            min={0}
                          />
                        </Form.Item>
                      </Row>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {index > 0 && (
                        <Button
                          center
                          shape="square"
                          shapeSize={32}
                          variant="grey-E3"
                          style={{
                            marginRight: 16,
                          }}
                          onClick={() => remove(index)}
                        >
                          <MinusCircleOutlined style={{ fontSize: '22px' }} />
                        </Button>
                      )}
                    </Col>
                  </div>
                ))}
                {fields?.length < 3 && (
                  <Row
                    gutter={16}
                    align="middle"
                    justify={'start'}
                    style={{
                      marginRight: 1,
                    }}
                  >
                    <Button
                      center
                      shape="square"
                      shapeSize={32}
                      variant="grey-E3"
                      onClick={() => {
                        add({
                          hexColor: '#FF0000',
                          label: '',
                          value: 0,
                          id: '',
                        });
                        setUpdatedThresholds([
                          ...(updatedThresholds || []),
                          { hexColor: '#FF0000', label: '', value: 0, id: '' },
                        ]);
                      }}
                    >
                      <PlusCircleOutlined style={{ fontSize: '22px' }} />
                    </Button>
                    <Text>Aggiungi fascia</Text>
                  </Row>
                )}
              </div>
            )}
          </Form.List>
        </Space>
      </Form>
      {/* <ChartPreview {...props} thresholds={updatedThresholds || []} consumptionReportState={updatedConsumptionReportState} /> */}
    </StyledModal>
  );
}
