import { useRecoilState } from 'recoil';
import { selectedTenantIdState } from '@store/tenant/selectedTenantIdState';
import { useAssetTemperatureStateReport as useAssetTemperatureStateReportQuery } from '@src/api';
import { selectedAssetIdState } from '@store/asset/selectedAssetIdState';
import { GetReportAssetStateResponseDto } from '@asset-manager/types';
import { assetFilterDateState } from '@store/asset/assetFilterDateState';

export interface IUseAssetTemperatureStateReport {
  selectedTenantId: string;
  assetTemperatureStateReport?: GetReportAssetStateResponseDto;
  isReportLoading: boolean;
  assetTemperatureRefetch?: () => void;
}
export function useAssetTemperatureStateReport(
  type: string,
): IUseAssetTemperatureStateReport {
  const [selectedTenantId] = useRecoilState(selectedTenantIdState);
  const [selectedAssetId] = useRecoilState(selectedAssetIdState);
  const [reportFilters] = useRecoilState(assetFilterDateState);

  const {
    data: assetTemperatureStateReport,
    isLoading: isReportLoading,
    refetch: assetTemperatureRefetch,
  } = useAssetTemperatureStateReportQuery(
    selectedTenantId,
    selectedAssetId,
    reportFilters,
    type,
  );

  return {
    selectedTenantId,
    assetTemperatureStateReport,
    isReportLoading,
    assetTemperatureRefetch,
  };
}
