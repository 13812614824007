import { TimeFrame } from '@components/atoms/select/select';
import { useRecoilState } from 'recoil';
import { reportFiltersState } from '@store/report/reportFiltersState';
import {
  DateRange,
  getLastMonthTimeFrame,
  getLastWeekTimeFrame,
  getLastYearTimeFrame,
  getThisMonthTimeFrame,
  getThisWeekTimeFrame,
  getThisYearTimeFrame,
  getTodayTimeFrame,
} from '@src/modules/utils/time';
import { selectedTimeframeState } from '@store/report/selected-timeframe-state';
import { AntdRangeValue } from '@components/atoms/date-picker/date-picker';
import dayjs from 'dayjs';
import { noteFiltersState } from '@store/note/note-filters-state';
import {
  eventsByAlertIdFiltersState,
  generatedEventsByAssetFiltersState,
  generatedEventsFiltersState,
} from '@store/alert/generated-events-filter-state';
import { assetFilterDateState } from '@store/asset/assetFilterDateState';

dayjs.extend(require('dayjs/plugin/utc'));

const utcDayjs = dayjs.utc;
export function useTimeframeSelector() {
  const [, setReportFilters] = useRecoilState(reportFiltersState);
  const [, setDashboardFilters] = useRecoilState(assetFilterDateState);
  const [, setNoteFilters] = useRecoilState(noteFiltersState);
  const [, setGeneratedEventsFilters] = useRecoilState(
    generatedEventsFiltersState,
  );
  const [, setGeneratedEventsByAssetFilters] = useRecoilState(
    generatedEventsByAssetFiltersState,
  );
  const [, setGeneratedEventsByAlertFilters] = useRecoilState(
    eventsByAlertIdFiltersState,
  );

  const [selectedTimeFrame, setSelectedTimeFrame] = useRecoilState(
    selectedTimeframeState,
  );

  const getDatesFromTimeFrame = (timeFrame: TimeFrame): DateRange => {
    let dates: DateRange;

    switch (timeFrame) {
      case TimeFrame.today:
        dates = getTodayTimeFrame();
        break;
      case TimeFrame.thisWeek:
        dates = getThisWeekTimeFrame();
        break;
      case TimeFrame.lastWeek:
        dates = getLastWeekTimeFrame();
        break;
      case TimeFrame.thisMonth:
        dates = getThisMonthTimeFrame();
        break;
      case TimeFrame.lastMonth:
        dates = getLastMonthTimeFrame();
        break;
      case TimeFrame.thisYear:
        dates = getThisYearTimeFrame();
        break;
      case TimeFrame.thisQuarter:
        dates = getThisYearTimeFrame();
        break;
      case TimeFrame.lastYear:
        dates = getLastYearTimeFrame();
        break;
    }

    return dates;
  };

  const onSelectedTimeFrameChange = (value: TimeFrame) => {
    let dates: Partial<DateRange> = getDatesFromTimeFrame(value);

    if (dates.startDate && dates.endDate) {
      setSelectedTimeFrame(value);
      setReportFilters(filters => ({ ...filters, ...dates, dateRange: value }));
    }
  };

  const onSelectedDatesRangeChange = (value: AntdRangeValue) => {
    if (value) {
      const startDate = utcDayjs(value[0])?.startOf('day').unix().toString();
      const endDate = utcDayjs(value[1])?.endOf('day').unix().toString();

      let timeFrame: string;

      // get time frame from dates
      if (startDate && endDate) {
        const values = [
          { timeFrame: TimeFrame.today, dates: getTodayTimeFrame() },
          { timeFrame: TimeFrame.thisWeek, dates: getThisWeekTimeFrame() },
          { timeFrame: TimeFrame.lastWeek, dates: getLastWeekTimeFrame() },
          { timeFrame: TimeFrame.thisMonth, dates: getThisMonthTimeFrame() },
          { timeFrame: TimeFrame.lastMonth, dates: getLastMonthTimeFrame() },
          { timeFrame: TimeFrame.thisYear, dates: getThisYearTimeFrame() },
          { timeFrame: TimeFrame.lastYear, dates: getLastYearTimeFrame() },
        ];
        const found = values.find(
          ({ dates }) =>
            dates.startDate === startDate && dates.endDate === endDate,
        );
        if (found) timeFrame = found.timeFrame;
        else {
          const from = Number(startDate) * 1000;
          const to = Number(endDate) * 1000;

          timeFrame = `${utcDayjs(from).format('DD/MM/YY')} - ${utcDayjs(
            to,
          ).format('DD/MM/YY')}`;
        }
      }

      if (startDate && endDate) {
        setReportFilters(filters => ({
          ...filters,
          startDate,
          endDate,
          dateRange: timeFrame,
        }));
      }
    }
  };

  const onSelectedDatesRangeChangeDashboard = (value: AntdRangeValue) => {
    if (value) {
      const startDate = utcDayjs(value[0])?.startOf('day').unix().toString();
      const endDate = utcDayjs(value[1])?.endOf('day').unix().toString();

      let timeFrame: string;

      // get time frame from dates
      if (startDate && endDate) {
        const values = [
          { timeFrame: TimeFrame.today, dates: getTodayTimeFrame() },
          { timeFrame: TimeFrame.thisWeek, dates: getThisWeekTimeFrame() },
          { timeFrame: TimeFrame.lastWeek, dates: getLastWeekTimeFrame() },
          { timeFrame: TimeFrame.thisMonth, dates: getThisMonthTimeFrame() },
          { timeFrame: TimeFrame.lastMonth, dates: getLastMonthTimeFrame() },
          { timeFrame: TimeFrame.thisYear, dates: getThisYearTimeFrame() },
          { timeFrame: TimeFrame.lastYear, dates: getLastYearTimeFrame() },
        ];
        const found = values.find(
          ({ dates }) =>
            dates.startDate === startDate && dates.endDate === endDate,
        );
        if (found) timeFrame = found.timeFrame;
        else {
          const from = Number(startDate) * 1000;
          const to = Number(endDate) * 1000;

          timeFrame = `${utcDayjs(from).format('DD/MM/YY')} - ${utcDayjs(
            to,
          ).format('DD/MM/YY')}`;
        }
      }

      if (startDate && endDate) {
        setDashboardFilters(filters => ({
          ...filters,
          startDate,
          endDate,
          dateRange: timeFrame,
        }));
      }
    }
  };

  const onSelectedNotesDatesRangeChange = (value: AntdRangeValue) => {
    if (value) {
      const startDate = utcDayjs(value[0])?.startOf('day').unix().toString();
      const endDate = utcDayjs(value[1])?.endOf('day').unix().toString();

      if (startDate && endDate) {
        setNoteFilters(filters => ({
          ...filters,
          startDate,
          endDate,
        }));
      }
    }
  };

  const onSelectedGeneratedEventsDatesRangeChange = (value: AntdRangeValue) => {
    if (value) {
      const startDate = utcDayjs(value[0])?.startOf('day').unix().toString();
      const endDate = utcDayjs(value[1])?.endOf('day').unix().toString();

      if (startDate && endDate) {
        setGeneratedEventsFilters(filters => ({
          ...filters,
          startDate,
          endDate,
        }));
      }
    }
  };

  const onSelectedGeneratedEventsByAlertDatesRangeChange = (
    value: AntdRangeValue,
  ) => {
    if (value) {
      const startDate = utcDayjs(value[0])?.startOf('day').unix().toString();
      const endDate = utcDayjs(value[1])?.endOf('day').unix().toString();

      if (startDate && endDate) {
        setGeneratedEventsByAlertFilters(filters => ({
          ...filters,
          startDate,
          endDate,
        }));
      }
    }
  };

  const onSelectedGeneratedEventsByAssetDatesRangeChange = (
    value: AntdRangeValue,
  ) => {
    if (value) {
      const startDate = utcDayjs(value[0])?.startOf('day').unix().toString();
      const endDate = utcDayjs(value[1])?.endOf('day').unix().toString();

      if (startDate && endDate) {
        setGeneratedEventsByAssetFilters(filters => ({
          ...filters,
          startDate,
          endDate,
        }));
      }
    }
  };

  const onSelectedDatesSelectChange = (value: TimeFrame) => {
    if (value) {
      const dates = getDatesFromTimeFrame(value);
      const from = Number(dates.startDate) * 1000;
      const to = Number(dates.endDate) * 1000;

      const startDate = utcDayjs(from).startOf('day').unix().toString();
      const endDate = utcDayjs(to).endOf('day').unix().toString();
      if (startDate && endDate) {
        setReportFilters(filters => ({
          ...filters,
          startDate,
          endDate,
          dateRange: value,
        }));
      }
    }
  };

  const onSelectedDatesSelectChangeDashboard = (value: TimeFrame) => {
    if (value) {
      const dates = getDatesFromTimeFrame(value);
      const from = Number(dates.startDate) * 1000;
      const to = Number(dates.endDate) * 1000;

      const startDate = utcDayjs(from).startOf('day').unix().toString();
      const endDate = utcDayjs(to).endOf('day').unix().toString();

      if (startDate && endDate) {
        setDashboardFilters(filters => ({
          ...filters,
          startDate,
          endDate,
          dateRange: value,
        }));
      }
    }
  };

  const onSelectedDatesSelectChangeModalDetail = (value: TimeFrame) => {
    if (value) {
      const dates = getDatesFromTimeFrame(value);
      const from = Number(dates.startDate) * 1000;
      const to = Number(dates.endDate) * 1000;

      const startDate = utcDayjs(from)?.startOf('day').unix().toString();
      const endDate = utcDayjs(to)?.endOf('day').unix().toString();

      if (startDate && endDate) {
        setGeneratedEventsByAlertFilters(filters => ({
          ...filters,
          startDate,
          endDate,
          dateRange: value,
        }));
      }
    }
  };

  const onSelectDatesSelectChangeNoteDrawerContent = (value: TimeFrame) => {
    if (value) {
      const dates = getDatesFromTimeFrame(value);
      const from = Number(dates.startDate) * 1000;
      const to = Number(dates.endDate) * 1000;

      const startDate = utcDayjs(from)?.startOf('day').unix().toString();
      const endDate = utcDayjs(to)?.endOf('day').unix().toString();

      if (startDate && endDate) {
        setNoteFilters(filters => ({
          ...filters,
          startDate,
          endDate,
          dateRange: value,
        }));
      }
    }
  };

  return {
    onSelectedDatesSelectChange,
    onSelectedDatesSelectChangeDashboard,
    getDatesFromTimeFrame,
    onSelectedTimeFrameChange,
    onSelectedDatesRangeChange,
    onSelectedDatesRangeChangeDashboard,
    onSelectedNotesDatesRangeChange,
    onSelectedGeneratedEventsDatesRangeChange,
    onSelectedDatesSelectChangeModalDetail,
    onSelectDatesSelectChangeNoteDrawerContent,
    onSelectedGeneratedEventsByAssetDatesRangeChange,
    onSelectedGeneratedEventsByAlertDatesRangeChange,
    selectedTimeFrame,
  };
}
