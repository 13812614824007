import { Collapse as CollapseAntd } from 'antd';
import { ReactNode } from 'react';
import styled from 'styled-components';
import Icon from '@components/atoms/icon';

interface CollapseItem {
  header?: ReactNode;
  content?: ReactNode;
}

interface Prop {
  title?: string;
  data?: CollapseItem[];
  id: string;
  iconName?: string;
}

const MaiHeader = styled.div`
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding: 0 16px;
`;

const Title = styled.span`
  display: block;
  font-family: 'Satoshi-Variable';
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #373737;
`;

const TitleIcon = styled(Icon)`
  font-size: 24px;
  margin-right: 16px;
`;

const Header = styled.span`
  font-family: 'Satoshi-Variable';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #373737;
`;

const Content = styled(Header)`
  font-weight: 500;
  color: #7a7a7a;
  /* padding-left: 40px; */
`;

const Section = styled.div`
  width: 100%;
`;

const Collapse = styled(CollapseAntd)`
  background-color: inherit;
  margin: -5px 0;

  .ant-collapse-item {
    border: none;
    margin: 5px 0;
    background-color: #fff;
    border-radius: 6px !important; // l'important serve per quando cen n'è solo uno
  }

  .ant-collapse-content > .ant-collapse-content-box {
    padding-left: 40px;
  }

  .ant-collapse-header {
    padding: 16px !important;
  }
`;

const Panel = styled(CollapseAntd.Panel)``;

export default function SectionCollapse({ id, iconName, title, data }: Prop) {
  return (
    <Section id={id}>
      {!!title && (
        <MaiHeader>
          {!!iconName && <TitleIcon name={iconName} color="#373737" />}
          <Title>{title}</Title>
        </MaiHeader>
      )}
      <Collapse bordered={false}>
        {data?.map((item, index) => (
          <Panel header={<Header>{item.header}</Header>} key={index}>
            <Content>{item.content}</Content>
          </Panel>
        ))}
      </Collapse>
    </Section>
  );
}
