import { Form, Input, Row } from 'antd';
import { Link } from 'react-router-dom';
import AuthPage from '../components/AuthPage';
import {
  Title,
  Text,
  ButtonLink,
  Section,
  ButtonSubmit,
  StyledForm,
} from '../components/common';

export default function SignUpPage() {
  return (
    <AuthPage>
      <Section>
        <Title>Crea un account</Title>
        <StyledForm
          name="basic"
          initialValues={{ remember: true }}
          // onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            // noStyle
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input placeholder="Nome" />
          </Form.Item>

          <Form.Item
            // noStyle
            label="Surname"
            name="surname"
            rules={[{ required: true, message: 'Surname is required' }]}
          >
            <Input placeholder="Cognome" />
          </Form.Item>

          <Form.Item
            // noStyle
            label="Company"
            name="company"
            rules={[{ required: true, message: 'Company is required' }]}
          >
            <Input placeholder="Azienda" />
          </Form.Item>

          <Form.Item
            // noStyle
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Password is required' }]}
          >
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item
            // noStyle
            label="Password"
            name="password2"
            rules={[{ required: true, message: 'Password is required' }]}
            style={{ marginBottom: 0 }}
          >
            <Input.Password placeholder="Conferma password" />
          </Form.Item>

          <Form.Item
            // wrapperCol={{ offset: 8, span: 16 }}
            style={{ width: '100%' }}
          >
            <ButtonSubmit dark variant="primary" htmlType="submit">
              Crea
            </ButtonSubmit>
          </Form.Item>
        </StyledForm>
        <Row justify="center" align="middle">
          <Text style={{ marginRight: 8 }}>Hai già un account?</Text>
          <Link to="/sign-in">
            <ButtonLink>Accedi</ButtonLink>
          </Link>
        </Row>
      </Section>
    </AuthPage>
  );
}
